import React from 'react'
import { dateAndTimeFormat, shouldNotSeeOSBrand } from '../utils/general'
import { format } from 'date-fns'

import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer'
import { Table, TableBody, TableHeader, TableCell, DataTableCell } from '@david.kucsai/react-pdf-table'
import PDFMachineInfoBlock from './pdf-machine-info'
import { MachineDoc } from './machine-inventory'

import logo from '../assets/logo-small.png'
import { actionTypeLabels } from './machine-action'

interface Props {
  actions?: any[];
  machine: MachineDoc;
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFF',
    padding: '10mm',
    paddingTop: '57mm'
  },
  logo: {
    height: '8mm',
    width: 'auto',
    marginRight: '5mm'
  },
  headerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    top: '5mm',
    position: 'absolute',
    left: '10mm'
  },
  divider: {
    width: '100%',
    height: '1pt',
    backgroundColor: '#DDD',
    marginBottom: '2mm',
    marginTop: '2mm'
  },
  tableCell: {
    padding: 5,
    justifyContent: 'flex-start',
    fontSize: 10
  },
  header: {
    fontSize: 24,
    color: '#000',
    textAlign: 'left'
  },
  subHeader: {
    fontSize: 16,
    top: '15mm',
    position: 'absolute',
    left: '10mm',
    color: '#000',
    textAlign: 'left'
  },
  tableHeader: {
    top: '49mm',
    position: 'absolute',
    left: '10mm',
    width: '100%'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    top: '7mm',
    right: '10mm',
    width: '10mm',
    color: 'grey',
    textAlign: 'right'
  }
})

const ActionsPDF: React.FC<Props> = ({ actions = [], machine }) => {
  const hiddenForBrandingReasons = shouldNotSeeOSBrand()
  return (
    <Document>
      <Page wrap size="A4" style={styles.page}>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
        <View style={styles.headerRow} fixed>
          {!hiddenForBrandingReasons && (
            <Image src={logo} style={styles.logo} />
          )}
          <Text style={styles.header}>Aktionsprotokoll</Text>
        </View>
        <View fixed style={{ ...styles.divider, top: '14mm', left: '10mm', position: 'absolute' }} />
        <PDFMachineInfoBlock machine={machine} fixed style={{ top: '18mm', left: '10mm', position: 'absolute' }} />
        <View fixed style={styles.tableHeader}>
          <Table>
            <TableHeader
              includeBottomBorder={true}
              includeLeftBorder={false}
              includeRightBorder={false}
              includeTopBorder={false}
            >
              <TableCell style={styles.tableCell} weighting={0.18} isHeader={true}>
                Zeit der Aktion
              </TableCell>
              <TableCell style={styles.tableCell} weighting={0.22} isHeader={true}>
                Aktionstyp
              </TableCell>
              <TableCell style={styles.tableCell} weighting={0.2} isHeader={true}>
                Verantwortlich
              </TableCell>
              <TableCell style={styles.tableCell} weighting={0.3} isHeader={true}>
                Instruktion
              </TableCell>
              <TableCell style={styles.tableCell} weighting={0.1} isHeader={true}>
                Fertig?
              </TableCell>
            </TableHeader>
          </Table>
        </View>
        <View>
          <Table data={actions}>
            <TableBody
              includeBottomBorder={false}
              includeLeftBorder={false}
              includeRightBorder={false}
              includeTopBorder={false}
            >
              <DataTableCell
                style={styles.tableCell}
                weighting={0.18}
                getContent={r => {
                  return format(
                    new Date(
                      r._id
                        .split(':')
                        .slice(1)
                        .join(':')
                    ),
                    dateAndTimeFormat
                  )
                }}
              />
              <DataTableCell
                style={styles.tableCell}
                weighting={0.22}
                getContent={r => actionTypeLabels[r.actionType]}
              />
              <DataTableCell
                style={styles.tableCell}
                weighting={0.2}
                getContent={r => r.assigneeName || r.responsible}
              />
              <DataTableCell style={styles.tableCell} weighting={0.3} getContent={r => r.instruction} />
              <DataTableCell style={styles.tableCell} weighting={0.1} getContent={r => (r.done ? 'Ja' : 'Nein')} />
            </TableBody>
          </Table>
        </View>
      </Page>
    </Document>
  )
}

export default ActionsPDF

import { getCustomerIdFromCouchDBRoles, getLocalSession } from './auth'
import PouchDB from 'pouchdb'
import * as H from 'history'
import { getRemote, getMonthDBNamesForCustomer } from './network'
import { Privileges } from '../types/users-roles-privileges'
import { UserRoles } from './user-roles'
import { reauthorize } from '../pouch'
import EventEmitter from 'events'
import { difference, get } from 'lodash'

// try to refresh every 60 seconds
const updateInterval = 1000 * 60

const debugLog = (...args: any) => {
  // console.log(...args)
}

let pendingMax = 0
let oneTimeSyncProgresses: number[] = []
const batch_size = 1000

export default class DatabaseManager {
  customerId?: string
  hasStarted: boolean
  syncs: any[]
  initialSyncIsDone: boolean
  initialSyncDoneResolves: any[]
  interval: any
  history?: H.History
  emitter: EventEmitter
  constructor(customerId?: string, history?: H.History) {
    // There should not be more than one instance of this manager
    debugLog('⚠️ RESETTING DB MANAGER STATE in constructor')
    this.customerId = customerId
    this.hasStarted = false
    this.syncs = []
    this.initialSyncIsDone = false
    this.initialSyncDoneResolves = []
    this.interval = undefined
    this.history = history
    this.getProgress = this.getProgress.bind(this)
    this.emitter = new EventEmitter()
  }

  public progress(): EventEmitter {
    return this.emitter
  }

  public startWithSession(customerId?: string | undefined): void {
    debugLog('startWithSession', customerId)
    if (customerId && customerId !== this.customerId) {
      debugLog('User is either logging in or switching customers')
      // User is either logging in or switching customers
      // so we reset the state
      debugLog('⚠️ RESETTING DB MANAGER STATE')
      this.stop() // will also set this.hasStarted back to false
      this.customerId = customerId
      this.initialSyncIsDone = false
      this.initialSyncDoneResolves = []
      this.syncs = []
    }
    // Try to singleton
    if (this.hasStarted) {
      return
    }

    // Don't do anything if there's no session at all (pre-login)
    const session = getLocalSession()
    if (!session || !session.roles || session.roles.length === 0) {
      return
    }

    this.start()
  }

  public async isInitialSyncDone(): Promise<boolean> {
    debugLog('❓isInitialSyncDone')
    return new Promise((resolve, reject) => {
      if (this.initialSyncIsDone) {
        debugLog('❓resolving early')
        return resolve(true)
      }
      this.initialSyncDoneResolves.push(resolve)
      debugLog('❓pushed promise to array:')
      debugLog('❓this.initialSyncDoneResolves', this.initialSyncDoneResolves)
    })
  }
  // Start is async but should not be awaited.
  public async start(): Promise<void> {
    debugLog('----------------------')
    debugLog('Starting DB Manager')
    const allDbNames = this.getAllDbNames()
    if (allDbNames.length === 0) return
    debugLog('allDbNames', allDbNames)
    try {
      debugLog('Trying one-time syncs')
      const allDbNamesWithoutAttachmentDBs = allDbNames.filter(
        (dbName: string): boolean => !dbName.includes('-attachments-')
      )
      await this.oneTimeSync(allDbNamesWithoutAttachmentDBs)
    } catch (e) {
      debugLog('❌ One-time syncs failed, notifying observers')
      this.initialSyncDoneResolves.map(resolve => resolve(false))
      if (e.error === 'unauthorized' && this.history) {
        await reauthorize(this.history)
        // Don’t do the other stuff below
        return
      }
    }
    debugLog('✅ One-time syncs succeeded, notifying observers')
    debugLog('this.initialSyncDoneResolves', this.initialSyncDoneResolves)
    this.initialSyncDoneResolves.map(resolve => resolve(true))
    this.initialSyncIsDone = true
    this.destroyOldDatabases()
    debugLog('Trying live syncs')
    // On the non-initial sync, we add in the attachment dbs, because we don’t want to slow down the
    // initial sync by uploading attachments, that should happen in the background
    this.startLiveSyncs(allDbNames)
  }

  public getProgress(pending: any, totalDBs: number, currentDB: number): number {
    var progress
    pendingMax = pendingMax < pending ? pending + batch_size : pendingMax // first time capture
    if (pendingMax > 0) {
      progress = 1 - pending / pendingMax
      if (pending === 0) {
        pendingMax = 0 // reset for live/next replication
      }
    } else {
      progress = 1 // 100%
    }
    const percent = Math.round(progress * 100)
    oneTimeSyncProgresses[currentDB] = percent
    debugLog(`🔄  progress for ${currentDB + 1}/${totalDBs}: ${percent}%`)
    const loadingPercent = Math.ceil(
      oneTimeSyncProgresses.reduce((value, accumulator) => {
        return value + accumulator
      }, 0) / totalDBs
    )
    debugLog('One-time sync load progress', loadingPercent)
    this.emitter.emit('progress', loadingPercent)
    if (loadingPercent >= 100) {
      this.emitter.emit('done')
    }
    return progress
  }

  private async oneTimeSync(dbNames: string[]): Promise<void> {
    debugLog('oneTimeSync()')
    for (const name of dbNames) {
      const i = dbNames.indexOf(name)
      await this.performReplicationOrSync(dbNames, name, i)
    }
  }

  private async performReplicationOrSync(dbNames: string[], dbName: string, index: number) {
    const gp = this.getProgress
    return new Promise<void>((resolve, reject) => {
      const local = new PouchDB(dbName)
      const remote = getRemote(dbName)
      try {
        debugLog('one-time sync', dbName)
        let sync
        if (dbName.includes('-attachments-')) {
          // attachments only go up, they never sync down
          debugLog('is attachment, only up', dbName)
          // Only try to sync if the local db exists, otherwise it will be created,
          // we don’t want that
          const dbExists = this.localDbExists(dbName)
          if (dbExists) {
            sync = PouchDB.replicate(dbName, remote)
              .on('change', function(info) {
                gp((info as any).change.pending, dbNames.length, index)
              })
              .on('complete', function(info) {
                gp(0, dbNames.length, index)
                resolve()
              })
          }
        } else {
          sync = PouchDB.sync(local, remote)
            .on('change', function(info) {
              gp((info as any).change.pending, dbNames.length, index)
            })
            .on('complete', function(info) {
              gp(0, dbNames.length, index)
              resolve()
            })
            // This handles the case of no remote db existing, we may not want this as this
            // overrides the catch below, which triggers a re-auth
            // The type of the error is, bizzarely, not
            // `PouchDb.Core.Error` but a JS TypeError
            // (not a TS TypeError!, this is at runtime)
            .on('error', function(err) {
              gp(0, dbNames.length, index)
              if (get(err, 'result.status') === "aborting") {
                // This is fine, we’re offline
                // Not reject!
                resolve()
              } else {
                // Let the catch below deal with it.
                throw err
              }
            })
        }
        debugLog('sync', dbName, sync)
      } catch (err) {
        // Should resolve if the error is 401
        debugLog(`Error: couldn’t sync to ${remote}`, err)
        if (err.error === 'unauthorized') {
          // If we get a 401 from the user’s customer DB, we know the session is invalid,
          // because that DB must exist with the correct _security doc on a minimum viable
          // customer setup. Monthdbs and import dbs might be missing, and that’s ok.
          const isCustomerDB = dbName.match(/^(cu_)[0-9,a-z]{8}$/i)
          if (isCustomerDB) {
            throw err
          }
        }
        reject(err)
      }
    })
  }

  public startLiveSyncs(allDbNames: string[]): void {
    debugLog('starting recurring one-time-sync')
    this.interval = setInterval(async () => {
      try {
        debugLog('Trying recurring one-time sync')
        await this.oneTimeSync(allDbNames)
      } catch (e) {
        debugLog('❌ One-time sync failed')
        if (e.error === 'unauthorized' && this.history) {
          debugLog('❌ One-time sync failed with 401')
          this.stop()
          await reauthorize(this.history)
        }
      }
    }, updateInterval)
    // this.syncs = allDbNames.map(dbName => {
    //   const local = new PouchDB(dbName)
    //   const remote = getRemote(dbName)
    //   debugLog('live sync for', dbName)
    //   return local
    //     .sync(remote, {
    //       retry: true,
    //       live: true
    //     })
    //     .on('error', function(error: {}) {
    //       debugLog('Cannot sync to remote', dbName, error)
    //     })
    // })
  }

  public stop(): void {
    // debugLog('stopping live sync for', this.syncs)
    clearInterval(this.interval)
    this.hasStarted = false
    debugLog('stopping recurring one-time-sync', this.interval)
    // this.syncs.forEach(sync => sync.cancel())
  }

  public async destroyOldDatabases(): Promise<void> {
    const lastTwelveMonths = getMonthDBNamesForCustomer(this.customerId, 12)
    // We give one month of buffer for now, but we only guarantee 3 Months of data on the device
    const lastFourMonths = getMonthDBNamesForCustomer(this.customerId, 4)
    let databaseNamesToDestroy = difference(lastTwelveMonths, lastFourMonths)
    const attachmentDbNamesToDestroy = databaseNamesToDestroy.map((dbName: string) => {
      return dbName.replace(this.customerId as string, `${this.customerId}-attachments`)
    })
    databaseNamesToDestroy = [...databaseNamesToDestroy, ...attachmentDbNamesToDestroy]
    debugLog('Destroying these old databases:', databaseNamesToDestroy)
    for (const dbName of databaseNamesToDestroy) {
      try {
        const db = new PouchDB(dbName, { skip_setup: true })
        await db.destroy()
      } catch (err) {
        debugLog(`Failed to destroy ${dbName}. That’s probably ok because it might not have existed.`, err)
      }
    }
  }

  private getAllDbNames(): string[] {
    // Add globals DBs that always need to be synced here
    let databaseNames: string[] = ['oel-products']
    // let databaseNames: string[] = []
    const userRoles = UserRoles.fromSession()
    const userCanSwitchCustomers = userRoles.hasPrivilege(Privileges.CAN_SWITCH_CUSTOMERS)
    if (userCanSwitchCustomers) {
      // Admins need at least this for the app to work
      if (userRoles.isReseller()) {
        // Danger: this must be the customerId OF THE RESELLER, not
        // the customer we're currently looking at
        const customerIdOfReseller = getCustomerIdFromCouchDBRoles(userRoles.roles)
        databaseNames.push(`${customerIdOfReseller}-customers`)
      } else {
        databaseNames.push('oel-customers')
      }
    }
    if (!this.customerId) {
      // Early return, since no other databases can be identified without the customerId
      return databaseNames
    }
    // Add customer-specific databases we need on inital sync on a new device
    const skipTheseNames = ['nh', 'oel']
    if (!skipTheseNames.includes(this.customerId)) {
      const monthDBs = getMonthDBNamesForCustomer(this.customerId, 3)
      databaseNames = [...databaseNames, `${this.customerId}`, `${this.customerId}-machine-import`, ...monthDBs]
      debugLog('getAllDbNames', this.customerId)
      for (let item in localStorage) {
        if (item.startsWith(`_pouch_${this.customerId}`)) {
          const pouchDBName = item.replace('_pouch_', '')
          if (databaseNames.indexOf(pouchDBName) === -1) {
            // Don’t sync any customer dbs from pouch
            if (!pouchDBName.endsWith('-customers')) {
              databaseNames.push(pouchDBName)
            }
          }
        }
      }
    }
    debugLog('AllDbNames', databaseNames)
    return databaseNames
  }

  private localDbExists(dbName: string): boolean {
    let exists = false
    for (let item in localStorage) {
      if (item.includes(dbName)) {
        exists = true
      }
    }
    return exists
  }
}

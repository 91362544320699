import React, { Component } from 'react'
import { Formik, FormikHelpers, FormikProps, Form } from 'formik'
import { isEqual, merge } from 'lodash'
import classNames from 'classnames'

/*

FormContainer is one of the two form helpers for more easily building Formik forms, the other is FormItem

FormContainer takes:
- initialValues: FormValues -> the initial values (collection of data objects, one per formItem)
- submitLabel?: string -> an optional text for the submit button, default is `Speichern`
- validationSchema: any -> a Yup validation schema
- putDocument(values: FormValues): void -> the `putDocument` function of the `react-pouchdb` `<Document>` element this form maps to
  ⚠️ This might be too restricting in the future, if we reach a point where a single form modifies multiple documents.
  In that case, this needs to be optional, and the PouchDB logic can optionally live in the `submitCallback`
- preSubmitCallback?(values: FormValues): object -> optional callback that receives the submitted form values, runs BEFORE the document put, and has the chance to modify the given values before then
- postSubmitCallback?(values: FormValues): void -> optional callback that receives the submitted values, and runs after document put
- enableReinitialize?: boolean -> reset the form after submission? (Formik param)
- className?: string -> additional classes for the `<Form>`
- disabled?: boolean -> Disable the whole form
- onSubmit?: function -> Allows you to completely replace the code that's run when you press the submit button
- deleteButton?: React.ReactElement -> an optional button that goes next to submit, usually used for deleting a thing. See Storybook in `forms.stories.js`
- and some children (a `FormItem`, at least, but whatever else you want, too)

It builds the Formik boilerplate, including validation, saving the corresponding PouchDB doc with `putDocument()`
and a submit button that is disabled if the form is not in a submittable state.

Full usage examples in `./stories/forms.stories.js`

*/

interface FormContainerProps {
  initialValues: FormValues
  submitLabel?: string
  // A Yup Schema or a function that returns a Yup schema, these are too tricky to type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema: any
  putDocument(values: FormValues): void
  preSubmitCallback?(values: FormValues): object
  postSubmitCallback?(values: FormValues): void
  enableReinitialize?: boolean
  className?: string
  disabled?: boolean
  onSubmit?: (v: FormValues, a: FormikHelpers<FormValues>) => Promise<void>
  onValuesChange?: (v: FormValues) => void
  deleteButton?: React.ReactElement | React.ReactNode
}

export interface FormValues {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export class FormContainer extends Component<FormContainerProps> {
  public render(): React.ReactNode {
    const {
      initialValues,
      validationSchema,
      putDocument,
      submitLabel = 'Speichern',
      preSubmitCallback,
      postSubmitCallback,
      enableReinitialize = false,
      className,
      disabled = false,
      onSubmit,
      deleteButton,
      onValuesChange
    } = this.props
    let submitFunction = async (values: FormValues, actions: FormikHelpers<FormValues>): Promise<void> => {
      actions.setSubmitting(true)
      let putValues = values
      if (preSubmitCallback) {
        putValues = merge(values, await preSubmitCallback(values))
      }
      putDocument(putValues)
      if (postSubmitCallback) {
        postSubmitCallback(putValues)
        // actions.resetForm()
      }
      actions.setSubmitting(false)
    }
    if (onSubmit) {
      submitFunction = onSubmit
    }
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitFunction}
        enableReinitialize={enableReinitialize}
      >
        {({ errors, isSubmitting, values, validateForm }: FormikProps<FormValues>): React.ReactNode => {
          const hasNoChanges = isEqual(initialValues, values)
          const isNotSubmittable = disabled || hasNoChanges || isSubmitting ? true : false
          const submitBtnClasses = classNames({
            button: true,
            'is-link': true,
            'is-loading': isSubmitting
          })
          return (
            <Form onChange={() => onValuesChange && onValuesChange(values)} className={className}>
              <fieldset disabled={disabled}>
                {this.props.children}
                {errors.general && <div className="notification is-danger general">{errors.general}</div>}
                <div className="buttons">
                  <button className={submitBtnClasses} type="submit" disabled={isNotSubmittable}>
                    {submitLabel}
                  </button>
                  {deleteButton ? deleteButton : null}
                </div>
              </fieldset>
            </Form>
          )
        }}
      </Formik>
    )
  }
}

import React, { useState, useEffect, useContext, ComponentType } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import MachineActivityTable from './machine-activity-table'
import { getMetricMetadata, MetricMeta, getLabelForId } from '../utils/default-metrics'
import Text from './graphs/measurement-text'
import Minmax from './graphs/measurement-minmax'
import Select from './graphs/measurement-select'
import LineChart, { LineChartProps } from './graphs/machine-events-graph'
import { groupBy, forEach, sortBy } from 'lodash'
import { stringToComponentName, dateFormat } from '../utils/general'
import { format, parseISO } from 'date-fns'
import { getMonthDBNamesForCustomer } from '../utils/network'
import GenericConnectionWarning from './generic-connection-warning'
import AppContext from '../AppContext'
import { attachUserInfoToActions } from '../utils/users'
import { useAllData } from '../hooks/useAllData'
import usePersons from '../hooks/usePersons'
import useInitialSyncCheck from '../hooks/useInitialSyncCheck'

import { GenericLoadingSpinner } from './partials'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MachineState } from './machine-inventory'
import { Measurement } from './graphs/waffle-measurements'
import { getImageFromMeasurement } from '../utils/measurements'

export interface InteractiveComponentProps {
  measurement: any;
  meta: MetricMeta;
}

// Collection of all defined measurement components
const components: { [key: string]: ComponentType<InteractiveComponentProps> } = {
  Minmax,
  Text,
  Select
}

interface Props extends RouteComponentProps {
  customerId: string;
  machineId: string;
  db?: PouchDB.Database;
}

interface LastImage {
  data: any;
  date: string;
}

interface GraphDataCollection {
  [key: string]: any;
}

const getLegendForEvent = (event: any): string => {
  const { from, to } = event
  if (event.eventType === 'materialChange') {
    const fromLabel = getLabelForId(from) || 'keinem Material'
    const toLabel = getLabelForId(to) || 'keinem Material'
    return `Materialwechsel \nvon ${fromLabel} zu ${toLabel}`
  }
  if (event.eventType === 'operationChange') {
    const fromLabel = getLabelForId(from) || 'keiner Operation'
    const toLabel = getLabelForId(to) || 'keiner Operation'
    return `Operationswechsel \nvon ${fromLabel} zu ${toLabel}`
  }
  if (event.eventType === 'fluidChange') {
    return `Fluidwechsel \nvon ${from ? from : 'keinem Fluid'} zu ${to ? to : 'keinem Fluid'}`
  }
  if (event.eventType === 'stateChange') {
    switch (to) {
      case MachineState.ACTIVE:
        return 'Maschine aktiviert'
      case MachineState.DEACTIVATED:
        return 'Maschine deaktiviert'
      case MachineState.IMPOUNDED:
        return 'Maschine blockiert'
      case MachineState.DELETED:
        return 'Maschine gelöscht'
    }
  }
  return ''
}

export const getIconForMeasurementStatus = (status: string): React.ReactNode => {
  if (status === 'within-threshold') {
    return (
      <span className="icon success">
        <FontAwesomeIcon icon="check" size="sm" />
      </span>
    )
  }
  if (status === 'below-threshold') {
    return (
      <span className="icon low">
        <FontAwesomeIcon icon="chevron-down" size="sm" />
      </span>
    )
  }
  if (status === 'above-threshold') {
    return (
      <span className="icon high">
        <FontAwesomeIcon icon="chevron-up" size="sm" />
      </span>
    )
  }
  return null
}

export const getIconForActionStatus = (done: boolean): React.ReactNode => {
  if (done) {
    return (
      <span className="icon success">
        <FontAwesomeIcon icon="check" size="sm" />
      </span>
    )
  }
  return (
    <span className="icon warning">
      <FontAwesomeIcon icon="times" size="sm" />
    </span>
  )
}

const getIconForEvent = (event: any): React.ReactNode => {
  if (event.eventType === 'materialChange') {
    return (
      <span className="icon material">
        <FontAwesomeIcon icon="cube" size="sm" />
      </span>
    )
  }
  if (event.eventType === 'operationChange') {
    return (
      <span className="icon operation">
        <FontAwesomeIcon icon="cog" size="sm" />
      </span>
    )
  }
  if (event.eventType === 'fluidChange') {
    return (
      <span className="icon fluid">
        <FontAwesomeIcon icon="tint" size="sm" />
      </span>
    )
  }
  if (event.eventType === 'stateChange') {
    if (event.to === MachineState.ACTIVE) {
      return (
        <span className="icon state">
          <FontAwesomeIcon icon="play" size="sm" />
        </span>
      )
    }
    if (event.to === MachineState.DEACTIVATED) {
      return (
        <span className="icon state">
          <FontAwesomeIcon icon="pause" size="sm" />
        </span>
      )
    }
    if (event.to === MachineState.IMPOUNDED) {
      return (
        <span className="icon state">
          <FontAwesomeIcon icon="ban" size="sm" />
        </span>
      )
    }
    if (event.to === MachineState.DELETED) {
      return (
        <span className="icon state">
          <FontAwesomeIcon icon="trash-alt" size="sm" />
        </span>
      )
    }
  }
  return null
}

const MachineProtocol: React.FC<Props> = props => {
  const [lastMeasurements, setLastMeasurements] = useState<any>([])
  const [timeSpan, setTimeSpan] = useState<number>(3)
  const [graphPh, setGraphPh] = useState<LineChartProps>({
    data: [],
    isFetching: true
  })
  const [graphNitrit, setGraphNitrit] = useState<LineChartProps>({
    data: [],
    isFetching: true
  })
  const [graphConcentration, setGraphConcentration] = useState<LineChartProps>({
    data: [],
    isFetching: true
  })
  const [graphFillLevel, setGraphFillLevel] = useState<LineChartProps>({
    data: [],
    isFetching: true
  })
  const [lastImage, setLastImage] = useState<LastImage>({
    data: '',
    date: new Date().toISOString()
  })
  const [allActions, setAllActions] = useState<any>(undefined)
  const [allEvents, setAllEvents] = useState<any>(undefined)
  const [error, setError] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [successfullySynced, setSuccessfullySynced] = useState<boolean>(true)
  const [routeKey, setRouteKey] = useState<string>(props.location.key!)
  const monthDBs = getMonthDBNamesForCustomer(props.customerId, timeSpan)
  const { measurements, measurementPoints, actions, events, hasLoaded: dataLoaded } = useAllData(
    props.customerId,
    monthDBs,
    timeSpan,
    props.machineId
  )
  const { persons, hasLoaded: personsLoaded } = usePersons(props.customerId, `${routeKey}-${String(timeSpan)}`)
  const { databaseManager } = useContext(AppContext)
  const { isSynced } = useInitialSyncCheck(databaseManager!)

  if (dataLoaded && measurements.length === 0 && timeSpan < 12) {
    // We only keep the last 3 months worth of data on the device.
    // If there’s no measurements for this machine in the local DBs,
    // force the useAllData hook to fetch data from the remote DBs by
    // setting the timeSpan higher than 3.
    setTimeSpan(12)
  }

  useEffect(() => {
    if (!dataLoaded || !personsLoaded || !isSynced) return

    const { customerId } = props

    const groupedMeasurements = groupBy(measurements, 'metricId')
    // Get most recent measurements per metric
    let lastMeasurements: any = []
    forEach(groupedMeasurements, metricMeasurements => {
      lastMeasurements.push(sortBy(metricMeasurements, 'measuredAt').pop())
    })

    const fetchImage = async () => {
      // Gets either a data URL or an actual remote URL, both go into the <img>’s `src`
      const mostRecentImage: Measurement = lastMeasurements.find((measurement: Measurement) => {
        return measurement.metricId === 'image'
      })
      if (mostRecentImage) {
        const image = await getImageFromMeasurement(mostRecentImage, customerId)
        setLastImage({
          data: image,
          date: mostRecentImage.measuredAt
        })
      } else {
        // Reset, otherwise navigating with the machine switcher will persist images
        setLastImage({
          data: '',
          date: new Date().toISOString()
        })
      }
    }
    fetchImage()

    // Format all the graphable data (all the minmax type measurments)
    const graphDataCollection: GraphDataCollection = {}
    forEach(groupedMeasurements, (group, groupName) => {
      const meta = getMetricMetadata(groupName)
      if (meta && meta.type === 'minmax') {
        graphDataCollection[groupName] = [
          {
            id: 'Minimum',
            data: group.map(measurement => {
              return {
                x: measurement.measuredAt.substring(0, 10),
                y: measurement.min
              }
            })
          },
          {
            id: 'Maximum',
            data: group.map(measurement => {
              return {
                x: measurement.measuredAt.substring(0, 10),
                y: measurement.max
              }
            })
          },
          {
            id: 'Sollwert',
            data: group.map(measurement => {
              return {
                x: measurement.measuredAt.substring(0, 10),
                y: measurement.should
              }
            })
          },
          {
            id: groupName,
            data: group.map(measurement => {
              return {
                x: measurement.measuredAt.substring(0, 10),
                y: measurement.value
              }
            })
          }
        ]
      }
    })

    // add person data to actions
    const allActions = attachUserInfoToActions(actions.reverse(), persons, props.customerId)

    setLastMeasurements(lastMeasurements)
    setGraphPh({
      data: graphDataCollection.ph,
      isFetching: false
    })
    setGraphConcentration({
      data: graphDataCollection.konzentration,
      isFetching: false
    })
    setGraphNitrit({
      data: graphDataCollection.nitrit,
      isFetching: false
    })
    setGraphFillLevel({
      data: graphDataCollection.fuellstand,
      isFetching: false
    })
    setAllActions(allActions)
    setAllEvents(events)
    setIsLoading(false)
    setError(undefined)
    setSuccessfullySynced(successfullySynced)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personsLoaded, dataLoaded, isSynced])

  useEffect(() => {
    if (props.location.key! !== routeKey) {
      setRouteKey(props.location.key!)
      setIsLoading(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location])

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (measurementPoints.length === 0) {
      setIsLoading(false)
      return setError(
        'Bevor für diese Maschine Messwerte angezeigt werden können, müssen unter dem Reiter "Daten" die zu messenden Metriken eingerichtet werden.'
      )
    }
    if (!lastMeasurements || lastMeasurements.length === 0) {
      setIsLoading(false)
      return setError('Es wurden an dieser Maschine noch keine Messungen vorgenommen.')
    }
  }, [isLoading, measurementPoints, lastMeasurements])

  if (isLoading) {
    return <GenericLoadingSpinner />
  }

  if (error) {
    return (
      <article className="message is-danger">
        <div className="message-header">
          <p>Fehler</p>
        </div>
        <div className="message-body">{error} </div>
      </article>
    )
  }

  // Map over all most recent measurements and display them according to their type
  // Also sort them so components of the same type are all next to each other
  const lastMeasurementDisplays = sortBy(lastMeasurements, measurement => {
    const meta = getMetricMetadata(measurement.metricId)
    return meta ? meta.type : undefined
  }).map((measurement: any) => {
    const meta: MetricMeta = getMetricMetadata(measurement.metricId)
    if (!meta) return null
    const componentName: string = stringToComponentName(meta.type)
    if (components[componentName]) {
      const Component = components[componentName]
      return <Component measurement={measurement} meta={meta} key={`measurement-display-${measurement.metricId}`} />
    }
    return null
  })

  let markers: any = []
  const machineId = props.machineId
  const markerLinkPrefix = `/maschine/${machineId && machineId.substr(3)}/`
  if (allActions) {
    markers.push(
      ...allActions.map((action: any) => {
        return {
          className: 'action',
          x: action._id.substr(12, 10),
          legend: action.instruction,
          id: action._id,
          icon: getIconForActionStatus(action.done)
        }
      })
    )
  }

  if (allEvents) {
    markers.push(
      ...allEvents.map((event: any) => {
        return {
          className: 'event',
          x: event._id.substr(12, 10),
          legend: getLegendForEvent(event),
          id: event._id,
          icon: getIconForEvent(event)
        }
      })
    )
  }

  markers = sortBy(markers, 'x')

  const renderMeasurementData = () => {
    if (isLoading) return <span>lade Messdaten</span>
    if (lastMeasurements.length === 0) return null
    return (
      <>
        <div className="row">
          <div className="mb2">
            <h3 className="title is-3">Aktuelle Messwerte</h3>
          </div>
          {lastMeasurementDisplays}
          {lastImage.data && (
            <div className="measurement-photo-container">
              <div className="measurement-label">
                <span className="measurement-name">Foto</span>
                <span className="measurement-date">vom {format(parseISO(lastImage.date), dateFormat)}</span>
              </div>
              <img src={lastImage.data} alt="Letztes aufgezeichnetes Bild" />
            </div>
          )}
        </div>
        <div className="row hide-on-mobile">
          <h4 className="title is-4">
            Messwerte der letzten{' '}
            <span className="select is-on-baseline">
              <select value={timeSpan.toString()} onChange={e => setTimeSpan(parseInt(e.currentTarget.value))}>
                <option value="3">3</option>
                <option value="6">6</option>
                <option value="12">12</option>
              </select>
            </span>{' '}
            Monate
          </h4>
          <LineChart
            data={graphPh.data}
            customMarkers={markers}
            isFetching={graphPh.isFetching}
            eventClickTargetPrefix={markerLinkPrefix}
          />
          <hr />
          <LineChart
            data={graphConcentration.data}
            customMarkers={markers}
            shortMarkers={false}
            isFetching={graphConcentration.isFetching}
            eventClickTargetPrefix={markerLinkPrefix}
          />
          <hr />
          <LineChart
            data={graphNitrit.data}
            customMarkers={markers}
            shortMarkers={false}
            isFetching={graphNitrit.isFetching}
            eventClickTargetPrefix={markerLinkPrefix}
          />
          <hr />
          <LineChart
            data={graphFillLevel.data}
            customMarkers={markers}
            shortMarkers={false}
            isFetching={graphFillLevel.isFetching}
            eventClickTargetPrefix={markerLinkPrefix}
          />
        </div>
      </>
    )
  }

  return (
    <div className="dashboard">
      {!successfullySynced && <GenericConnectionWarning />}
      {renderMeasurementData()}
      <MachineActivityTable machineId={machineId} customerId={props.customerId} location={props.location} />
    </div>
  )
}

export default MachineProtocol

import { useState, useEffect } from 'react'
import PouchDB from 'pouchdb'
import { groupBy, sortBy, compact, flatten, get } from 'lodash'
import { differenceInCalendarMonths } from 'date-fns'

import { Machine, MachineState } from '../components/machine-inventory'
import { isMachineDoc } from '../utils/general'
import { getRemote } from '../utils/network'
import { Measurement } from '../components/graphs/waffle-measurements'
import { Action } from '../components/machine-action'

/*

This hook is for use with analysis pages and fetches EVERYTHING:
- all machines
- all their measurement points
-all their actions and measurements for the last 3 months

*/

export interface AllDocsResult {
  doc?: PouchDB.Core.ExistingDocument<any & PouchDB.Core.AllDocsMeta> | undefined;
  id: string;
  key: string;
  value: {
    rev: string;
    deleted?: boolean | undefined;
  };
}

export const useAllData = (
  customerId: string | undefined,
  monthDBs: string[],
  key?: number | string,
  machineId?: string
) => {
  // const [monthDBs, setMonthDBs] = useState<string[]>([])
  const [machines, setMachines] = useState<AllDocsResult[]>()
  const [measurementPoints, setMeasurementPoints] = useState<AllDocsResult[]>([])
  const [measurements, setMeasurements] = useState<Measurement[]>([])
  const [actions, setActions] = useState<Action[]>([])
  const [events, setEvents] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [hasLoaded, setHasLoaded] = useState(false)
  const waitAndReturnEmptyDataSet = async () => {
    return new Promise(() => {setTimeout(() => {
      setMachines([])
      setMeasurementPoints([])
      setMeasurements([])
      setActions([])
      setEvents([])
      setIsLoading(false)
      setHasLoaded(true)
    }, 1000)})
  }
  useEffect(() => {
    setIsLoading(true)
    setHasLoaded(false)
    let didCancel = false
    const fetchData = async () => {
      const customerDB = new PouchDB(`${customerId}`)
      const machinesResult: PouchDB.Core.AllDocsResponse<Machine[]> = await customerDB.allDocs({
        startkey: machineId && `${machineId}`,
        endkey: machineId && `${machineId}\ufff0`,
        include_docs: true
      })
      const { machines, measurementPoints } = groupBy(machinesResult.rows, (row: any): string | null => {
        if (row.id.includes(':mp')) return 'measurementPoints'
        if (isMachineDoc(row)) return 'machines'
        return null
      })
      // Don’t show deleted or deactivated or machines
      const visibleMachines = machines.filter(machine => {
        const machineIsDeleted = get(machine, 'doc.state') === MachineState.DELETED
        const machineIsDeactivated = get(machine, 'doc.state') === MachineState.DEACTIVATED
        return !machineIsDeleted && !machineIsDeactivated
      })
      const allMeasurementsAndActionsByMonth = await Promise.all(
        monthDBs.map(async (monthDBName: any) => {
          const dateFragment = monthDBName.substr(12)
          const difference = differenceInCalendarMonths(new Date(dateFragment), new Date())
          let dbEndpoint = monthDBName
          if (difference <= -3) {
            dbEndpoint = getRemote(monthDBName)
          }
          const db = new PouchDB(dbEndpoint, { skip_setup: true })
          try {
            const res = await db.allDocs({
              startkey: machineId && `${machineId}:`,
              endkey: machineId && `${machineId}:\ufff0`,
              include_docs: true
            })
            return res
          } catch (e) {
            return { rows: [] }
          }
        })
      )
      // map out docs, remove empties, sort by _id, which effectively pre-groups
      // by metric and then by date, newest last
      const allMeasurementsAndActions: any[] = sortBy(
        compact(flatten(allMeasurementsAndActionsByMonth.map(m => m.rows)).map(m => m.doc)),
        '_id'
      )
      const { events, measurements, actions } = groupBy(allMeasurementsAndActions, (doc: any): string | undefined => {
        if (doc.type === 'measurement') {
          return 'measurements'
        }
        if (doc.actionType) {
          return 'actions'
        }
        if (doc.type === 'event' || doc.eventType) {
          return 'events'
        }
      })
      if (!didCancel) {
        setMachines(visibleMachines)
        setMeasurementPoints(measurementPoints || [])
        setMeasurements(measurements || [])
        setActions(actions || [])
        setEvents(events || [])
        setIsLoading(false)
        setHasLoaded(true)
      }
    }
    if (customerId && monthDBs.length > 0) {
      fetchData()
    } else {
      // This is a valid customer and machine, but the monthDBs don’t exist, then we’re looking at
      // a future timespan. In this case, we return an empty set.
      if (customerId && machineId)  {
        // Allow consuming component to reset/show loading spinner
        setIsLoading(true)
        setHasLoaded(false)
        waitAndReturnEmptyDataSet()
      }
    }
    // Clean up on unmount
    return () => {
      didCancel = true
    }
    // only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, machineId])
  return { machines, events, measurementPoints, measurements, actions, isLoading, hasLoaded }
}

import React, { useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Link, Redirect } from 'react-router-dom'
import * as Yup from 'yup'
import PouchDB from 'pouchdb'

import FormItem from '@luxx/forms'
import { FormContainer, FormValues } from '@luxx/forms'

import { Validations } from '../../utils/validations'
import { MachineDoc, MachineState } from '../machine-inventory'
import AppContext from '../../AppContext'
import useCrossMachineData from '../../hooks/useCrossMachineData'
import { GenericLoadingSpinner } from '../partials'
import { get } from 'lodash'
import { UserRoles } from '../../utils/user-roles'
import { Privileges } from '../../types/users-roles-privileges'
import { getCurrentMonthlyDb, shouldNotSeeOSBrand } from '../../utils/general'
import { format } from 'date-fns'
import MachineId from '../machine-id'
import { MachineUsables } from '../machine-usables'

type Props = {
  machine?: MachineDoc;
  reloadOnSave?: boolean;
}

const MachineSchema = Yup.object().shape({
  name: Validations.genericName,
  type: Yup.string().required('Pflichtfeld'),
  usables: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Bitte einen Namen angeben.'),
      usableType: Yup.string().required('Bitte einen Typ wählen.')
    })
  ),
  volume: Yup.number()
    .transform(value => (isNaN(value) ? undefined : value))
    .nullable()
})

const initialValues = {
  name: '',
  inventoryNumber: '',
  type: '',
  manufacturer: '',
  model: '',
  serialNumber: '',
  location: '',
  volume: ('' as unknown) as number,
  notes: '',
  tags: [],
  usables: []
}

const MachineDetailForm: React.FC<Props> = props => {
  const { machine: machineFromProps, reloadOnSave } = props
  const { currentCustomer } = useContext(AppContext)
  const { _id: customerId, name: customerName } = currentCustomer
  const [machine, setMachine] = useState<MachineDoc | undefined>()
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isQRModalVisible, setIsQRModalVisible] = useState(false)
  const [backToInventory, setBackToInventory] = useState(false)
  const { tagOptions, locationOptions, isLoading } = useCrossMachineData(customerId)
  const [modalSuccess, setModalSuccess] = useState(false)
  const hiddenForBrandingReasons = shouldNotSeeOSBrand()

  const userRoles = UserRoles.fromSession()
  const userCanManageMachines = userRoles.hasPrivilege(Privileges.CAN_MANAGE_MACHINES)
  const userCanSetAnyMachineState = userRoles.hasPrivilege(Privileges.CAN_SWITCH_CUSTOMERS)

  // This is the first time we’re here, or we’re switching machines
  if (get(machineFromProps, '_id') !== get(machine, '_id')) {
    setMachine(machineFromProps)
  }

  const deleteModalClasses = classNames({
    'is-active': isDeleteModalVisible,
    modal: true
  })

  const qrOrderModalClasses = classNames({
    'is-active': isQRModalVisible,
    modal: true
  })

  function orderLabel() {
    if (!machine) return
    if (!machine._id) return
    const doc = {
      _id: `${machine._id}:sendemail:${new Date().toISOString()}`,
      to: [process.env.REACT_APP_OEL_LABEL_ORDER_EMAIL],
      subject: '[LubIQ] QR-Labelbestellung',
      body: `Hallo,\nfür die Maschine ${machine._id} des Kunden ${customerName} wurde eine Labelbestellung beantragt.\n\nLubIQ`
    }
    const curDate = new Date()
    const monthlyDb = new PouchDB(`${customerId}-${format(curDate, 'yyyy-MM')}`)
    monthlyDb.put(doc).then(() => {
      setModalSuccess(true)
    })
  }

  const deleteMachine = async (): Promise<void> => {
    if (machine) {
      const customerDB = new PouchDB(customerId)
      await customerDB.put(({ ...machine, state: MachineState.DELETED } as unknown) as PouchDB.Core.ExistingDocument<
        any
      >)
    }
    setBackToInventory(true)
  }

  const renderDeleteButton = (): React.ReactNode => {
    if (!userCanManageMachines) return null
    if (machine) {
      return (
        <button className="button is-danger is-outlined" type="button" onClick={() => setIsDeleteModalVisible(true)}>
          <span className="icon">
            <FontAwesomeIcon icon="trash-alt" pull="left" />
          </span>
          <span>Maschine löschen</span>
        </button>
      )
    } else {
      return (
        <button className="button is-danger is-outlined" type="button" onClick={() => setBackToInventory(true)}>
          <span className="icon">
            <FontAwesomeIcon icon="trash-alt" pull="left" />
          </span>
          <span>Abbrechen</span>
        </button>
      )
    }
  }
  if (isLoading) {
    return <GenericLoadingSpinner message="Lade Maschine" />
  }
  if (backToInventory && !isDeleteModalVisible) {
    return <Redirect to={'/'} />
  }

  const shouldReload = () => {
    if (!reloadOnSave) return false
    return get(machineFromProps, '_rev') !== get(machine, '_rev')
  }

  const machineIsNotEditable =
    !userCanManageMachines ||
    get(machine, 'state') === MachineState.DEACTIVATED ||
    get(machine, 'state') === MachineState.IMPOUNDED ||
    get(machine, 'state') === MachineState.DELETED

  const toggleMachineState = async (newMachineState?: MachineState): Promise<void> => {
    if (machine && customerId) {
      // ACTIVE is the default if state is undefined
      const oldState = machine.state || MachineState.ACTIVE
      let newState: string = newMachineState || ''
      // only toggle state if no state was passed in
      if (newState === '') {
        if (machine.state === MachineState.DEACTIVATED) {
          newState = MachineState.ACTIVE
        }
        if (!machine.state || machine.state === MachineState.ACTIVE) {
          newState = MachineState.DEACTIVATED
        }
      }
      const customerDB = new PouchDB(customerId)
      const updatedDoc = ({ ...machine, state: newState } as unknown) as PouchDB.Core.ExistingDocument<any>
      const updateResponse = await customerDB.put(updatedDoc)
      // make sure this button can be pressed multiple times
      if (updateResponse && updateResponse.rev) {
        updatedDoc._rev = updateResponse.rev
        // Store the state change as an event
        const currentMonthDbName = getCurrentMonthlyDb(customerId)
        const currentMonthDb = new PouchDB(currentMonthDbName)
        const timestamp = new Date().toISOString()
        const stateEventDoc = {
          _id: `${machine._id}:${timestamp}`,
          type: 'event',
          eventType: 'stateChange',
          from: oldState,
          to: newState
        }
        await currentMonthDb.put(stateEventDoc)
      }
      setMachine(updatedDoc)
    }
  }

  const renderStateToggleButton = (): React.ReactNode => {
    if (!userCanManageMachines || !machine) return null
    if (machine.state === MachineState.DEACTIVATED) {
      return (
        <button className="button is-success" type="button" onClick={() => toggleMachineState()}>
          <span className="icon">
            <FontAwesomeIcon icon="play" pull="left" />
          </span>
          <span>Maschine aktivieren</span>
        </button>
      )
    }
    if (!machine.state || machine.state === MachineState.ACTIVE) {
      return (
        <button className="button is-warning" type="button" onClick={() => toggleMachineState()}>
          <span className="icon">
            <FontAwesomeIcon icon="pause" pull="left" />
          </span>
          <span>Maschine deaktivieren</span>
        </button>
      )
    }
  }
  const getOptionalLockButton = (): React.ReactNode => {
    if (machine!.state === MachineState.ACTIVE && userCanSetAnyMachineState) {
      return (
        <button className="button is-danger" type="button" onClick={() => toggleMachineState(MachineState.IMPOUNDED)}>
          <span className="icon">
            <FontAwesomeIcon icon="lock" pull="left" />
          </span>
          <span>Maschine blockieren (Admin-Funktion)</span>
        </button>
      )
    }
    return null
  }

  const getStateSection = (): React.ReactNode => {
    if (!machine) return null
    // We’re in the "create new machine" view
    if (!machine._rev) return null
    return (
      <div className="content">
        <h3 className="title is-3">Status</h3>
        {(!machine.state || machine!.state === MachineState.ACTIVE) && (
          <>
            <p>Diese Maschine ist aktiv.</p>
            <div className="buttons">
              {renderStateToggleButton()}
              {getOptionalLockButton()}
            </div>
          </>
        )}
        {machine!.state === MachineState.DEACTIVATED && (
          <>
            <p>Diese Maschine ist deaktiviert.</p>
            <div className="buttons">
              {renderStateToggleButton()}
              {getOptionalLockButton()}
            </div>
          </>
        )}
        {machine!.state === MachineState.DELETED && (
          <>
            <p>Diese Maschine wurde gelöscht.</p>
            <div className="buttons">
              {userCanSetAnyMachineState && (
                <button
                  className="button is-warning"
                  type="button"
                  onClick={() => toggleMachineState(MachineState.ACTIVE)}
                >
                  <span className="icon">
                    <FontAwesomeIcon icon="asterisk" pull="left" />
                  </span>
                  <span>Maschine wiederherstellen (Admin-Funktion)</span>
                </button>
              )}
              {getOptionalLockButton()}
            </div>
          </>
        )}
        {machine!.state === MachineState.IMPOUNDED && (
          <>
            <p>Diese Maschine ist blockiert</p>
            <div className="buttons">
              {userCanSetAnyMachineState && (
                <button
                  className="button is-warning"
                  type="button"
                  onClick={() => toggleMachineState(MachineState.ACTIVE)}
                >
                  <span className="icon">
                    <FontAwesomeIcon icon="unlock-alt" pull="left" />
                  </span>
                  <span>Maschine wiederherstellen (Admin-Funktion)</span>
                </button>
              )}
              {getOptionalLockButton()}
            </div>
          </>
        )}
      </div>
    )
  }

  return (
    <>
      {shouldReload() && machineFromProps && machineFromProps._id && (
        <Redirect to={`/maschine/${machineFromProps._id.substr(3)}`} />
      )}
      {machine && machine._id && (
        <div className="buttons">
          {!hiddenForBrandingReasons && (
            <button className="button is-info" onClick={() => setIsQRModalVisible(true)}>
              <span className="icon">
                <FontAwesomeIcon icon="qrcode" size="sm" />
              </span>
              <span>QR-Labels bestellen</span>
            </button>
          )}
          {navigator.share && (
            <button
              className="button is-info"
              onClick={() =>
                // Weird TS behaviour, see https://stackoverflow.com/a/56745054, the file is called `ambient.d.ts` here
                navigator!.share!({
                  title: `${machine.name} in LubIQ`,
                  text: `${machine.name} in LubIQ`,
                  url: window.location.href
                })
              }
            >
              <span className="icon">
                <FontAwesomeIcon icon="share-square" size="sm" />
              </span>
              <span>Maschine teilen</span>
            </button>
          )}
        </div>
      )}
      {getStateSection()}
      <h3 className="title is-3">Stammdaten</h3>
      <FormContainer
        initialValues={{ ...initialValues, ...machine }}
        validationSchema={MachineSchema}
        onSubmit={async (values: FormValues, actions: any): Promise<void> => {
          actions.setSubmitting(true)
          const customerDB = new PouchDB(customerId)
          let updatedDoc: PouchDB.Core.ExistingDocument<any> = { ...machine, ...values }
          if (!updatedDoc.state) {
            updatedDoc.state = MachineState.ACTIVE
          }
          const updateResponse = await customerDB.put(updatedDoc)
          // make sure this form can be submitted multiple times
          if (updateResponse && updateResponse.rev) {
            updatedDoc._rev = updateResponse.rev
          }
          actions.setSubmitting(false)
          setMachine(updatedDoc)
        }}
        disabled={machineIsNotEditable}
        submitLabel="Stammdaten speichern"
        deleteButton={!machineIsNotEditable && renderDeleteButton()}
        enableReinitialize={true}
        putDocument={(): void => { }}
      >
        <FormItem type="text" label="Name der Maschine" name="name" placeholder="Name" />
        <FormItem type="text" label="Inventarnummer" name="inventoryNumber" placeholder="Inventarnummer" />
        <FormItem component="select" name="type" label="Maschinentyp">
          <option value="" disabled>
            Bitte auswählen:
          </option>
          <option value="zerspanungsmaschine">Zerspanungsmaschine</option>
          <option value="umformmaschine">Umformmaschine</option>
          <option value="zentralschmieranlage">Zentralschmieranlage</option>
          {/* <option value="fahrzeug">Fahrzeug</option> */}
          {/* <option value="lagertank">Lagertank</option> */}
          <option value="hydraulisch">Hydraulische Maschine/Presse</option>
        </FormItem>
        <FormItem type="text" label="Hersteller" name="manufacturer" placeholder="Hersteller" />
        <FormItem type="text" label="Typenbezeichnung" name="model" placeholder="Typenbezeichnung" />
        <FormItem type="text" label="Seriennummer" name="serialNumber" placeholder="Seriennummer" />
        <FormItem type="number" label="Volumen (L)" name="volume" placeholder="Volumen" step="0.1"/>
        {/* INPUT TAGS: Select only 1 location at a time by either entering a new location or chose out of a list of existing locations */}
        <FormItem
          component="TagInput"
          label="Standort auswählen oder erstellen"
          name="location"
          placeholder="Standort"
          multiple={false}
          tagOptions={locationOptions}
          isClearable
          isCreatable={true}
          isDisabled={machineIsNotEditable}
        />
        {/* MULTIPLE INPUT TAGS: Create or select multiple tags out of a list of existing tags */}
        <FormItem
          component="TagInput"
          label="Tags auswählen oder erstellen"
          name="tags"
          placeholder="Tags"
          multiple={true}
          tagOptions={tagOptions}
          isClearable={false}
          isCreatable={true}
          isDisabled={machineIsNotEditable}
        />
        <FormItem component="textarea" name="notes" label="Anmerkungen" rows="5" />
        <MachineUsables isDisabled={machineIsNotEditable} />
      </FormContainer>
      {/* Modal for deleting */}
      <div className={deleteModalClasses}>
        <div className="modal-background"></div>
        {backToInventory ? (
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Maschine gelöscht</p>
            </header>
            <footer className="modal-card-foot">
              <Link to="/" className="button is-primary">
                Zurück zum Inventar
              </Link>
            </footer>
          </div>
        ) : (
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Löschen bestätigen</p>
                <button className="delete" aria-label="close" onClick={() => setIsDeleteModalVisible(false)}></button>
              </header>
              <section className="modal-card-body">
                <p>Maschine {machine && machine.name && <strong>{machine.name}</strong>} wirklich löschen?</p>
              </section>
              <footer className="modal-card-foot">
                <button className="button is-danger" onClick={deleteMachine}>
                  Ja, löschen
              </button>
                <button className="button" onClick={() => setIsDeleteModalVisible(false)}>
                  Abbrechen
              </button>
              </footer>
            </div>
          )}
      </div>
      {/* Modal for ordering QR labels*/}
      {machine && machine._id && (
        <div className={qrOrderModalClasses}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">
                QR-Labels für Maschine <strong>{machine.name}</strong> bestellen
              </p>
            </header>
            <section className="modal-card-body">
              {modalSuccess && (
                <>
                  <span className="icon is-large">
                    <FontAwesomeIcon icon="check" size="lg" />
                  </span>
                  <span className="title is-4">Label erfolgreich bestellt.</span>
                </>
              )}
              {!modalSuccess && (
                <>
                  <p className="content">
                    Hoch-widerstandsfähige Etikette mit QR-Code{' '}
                    <MachineId id={machine._id as string} clickable={false} /> für diese Maschine bei der Firma Willi
                    Schüler nachbestellen.
                    <br />
                    Alle Bestellungen eines Tages werden zusammengefasst und in einer Sendung verschickt.
                    <br />
                  </p>
                  <p className="content">
                    <em>Dieser Service ist kostenpflichtig.</em>
                  </p>
                </>
              )}
            </section>
            <footer className="modal-card-foot">
              <button
                className="button"
                onClick={() => {
                  setIsQRModalVisible(false)
                  setModalSuccess(false)
                }}
              >
                Schließen
              </button>
              {!modalSuccess && (
                <button className="button is-primary" onClick={orderLabel}>
                  Bestellen
                </button>
              )}
            </footer>
          </div>
        </div>
      )}
    </>
  )
}
export default MachineDetailForm

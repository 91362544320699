import { getLocalSession } from './auth'
import { forEach } from 'lodash'
import { PrivilegeDescriptions, Role, Privileges } from '../types/users-roles-privileges'

export const privilegeDescriptions: PrivilegeDescriptions = {
  canSwitchCustomers: 'Zwischen Kundenfirmen wechseln',
  canManageUsers: 'Benutzer verwalten',
  canAccessAnalytics: 'Betriebsweite Analysen einsehen',
  canEnterMeasurements: 'Messungen eingeben',
  canEnterActions: 'Arbeitsaufträge erstellen',
  canManageMachines: 'Maschinen verwalten',
  canManageBilling: 'Rechnungen einsehen und Abrechnungsdaten verwalten',
  canAssignCustomers: 'Angestellten Kunden zuweisen'
}

// Oelschueler Roles
const osRolesAndPrivileges: Role[] = [
  {
    id: 'os-admin',
    name: 'Öl Schüler-Admin',
    privileges: [
      Privileges.CAN_SWITCH_CUSTOMERS,
      Privileges.CAN_MANAGE_USERS,
      Privileges.CAN_ACCESS_ANALYTICS,
      Privileges.CAN_ENTER_MEASUREMENTS,
      Privileges.CAN_ENTER_ACTIONS,
      Privileges.CAN_MANAGE_MACHINES,
      Privileges.CAN_ASSIGN_CUSTOMERS,
    ]
  },
  {
    id: 'os-analyst',
    name: 'Öl Schüler-Analyst',
    privileges: [Privileges.CAN_ACCESS_ANALYTICS]
  },
  {
    id: 'os-inspector',
    name: 'Öl Schüler-Außendienst',
    privileges: [
      Privileges.CAN_SWITCH_CUSTOMERS,
      Privileges.CAN_MANAGE_USERS,
      Privileges.CAN_ACCESS_ANALYTICS,
      Privileges.CAN_ENTER_MEASUREMENTS,
      Privileges.CAN_ENTER_ACTIONS,
      Privileges.CAN_MANAGE_MACHINES
    ]
  }
]

// NH Roles
const nhRolesAndPrivileges = [
  {
    id: 'nh-admin',
    name: 'Neighbourhoodie-Admin',
    privileges: [
      Privileges.CAN_SWITCH_CUSTOMERS,
      Privileges.CAN_MANAGE_USERS,
      Privileges.CAN_ACCESS_ANALYTICS,
      Privileges.CAN_ENTER_MEASUREMENTS,
      Privileges.CAN_ENTER_ACTIONS,
      Privileges.CAN_MANAGE_MACHINES,
      Privileges.CAN_ASSIGN_CUSTOMERS,
    ]
  }
]

// Reseller Roles
const resellerRolesAndPrivileges = [
  {
    id: 're-admin',
    name: 'Reseller-Administrator',
    privileges: [
      Privileges.CAN_SWITCH_CUSTOMERS,
      Privileges.CAN_MANAGE_USERS,
      Privileges.CAN_ACCESS_ANALYTICS,
      Privileges.CAN_ENTER_MEASUREMENTS,
      Privileges.CAN_ENTER_ACTIONS,
      Privileges.CAN_MANAGE_MACHINES,
      Privileges.CAN_ASSIGN_CUSTOMERS,
    ]
  },
  {
    id: 're-analyst',
    name: 'Reseller-Analyst',
    privileges: [Privileges.CAN_SWITCH_CUSTOMERS, Privileges.CAN_ACCESS_ANALYTICS]
  },
  {
    id: 're-inspector',
    name: 'Reseller-Außendienst',
    privileges: [
      Privileges.CAN_SWITCH_CUSTOMERS,
      Privileges.CAN_MANAGE_USERS,
      Privileges.CAN_ACCESS_ANALYTICS,
      Privileges.CAN_ENTER_MEASUREMENTS,
      Privileges.CAN_ENTER_ACTIONS,
      Privileges.CAN_MANAGE_MACHINES
    ]
  }
]

// Customer Roles
const customerRolesAndPrivileges = [
  {
    id: 'cu-admin',
    name: 'Administrator',
    privileges: [
      Privileges.CAN_MANAGE_USERS,
      Privileges.CAN_ACCESS_ANALYTICS,
      Privileges.CAN_ENTER_MEASUREMENTS,
      Privileges.CAN_ENTER_ACTIONS,
      Privileges.CAN_MANAGE_MACHINES,
      Privileges.CAN_MANAGE_BILLING
    ]
  },
  {
    id: 'cu-analyst',
    name: 'Analyst',
    privileges: [Privileges.CAN_ACCESS_ANALYTICS]
  },
  {
    id: 'cu-inspector',
    name: 'Inspektor',
    privileges: [Privileges.CAN_ENTER_MEASUREMENTS, Privileges.CAN_ENTER_ACTIONS]
  },
  {
    id: 'cu-nologin',
    name: 'Mitarbeiter',
    privileges: []
  }
]

// Copy and merge all roles here
const allRolesAndPrivileges: Role[] = [...osRolesAndPrivileges, ...nhRolesAndPrivileges, ...resellerRolesAndPrivileges, ...customerRolesAndPrivileges]

class UserRoles {
  roles: string[]

  constructor(couchRoles: string[]) {
    this.roles = couchRoles
  }

  static fromSession() {
    const session = getLocalSession()
    if (session && session.roles) {
      return new UserRoles(session.roles)
    }
    return new UserRoles([])
  }

  hasPrivilege(privilegeId: Privileges, roleId?: string): boolean {
    let permission = false
    let rolesToCheck: string[] = []
    if (roleId) {
      rolesToCheck = [roleId]
    } else {
      rolesToCheck = this.roles
    }
    rolesToCheck.forEach((userRole: string) => {
      const matchingRole = allRolesAndPrivileges.find((role: Role): boolean => {
        return userRole === role.id
      })
      if (matchingRole && matchingRole.privileges.includes(privilegeId)) {
        permission = true
      }
    })
    return permission
  }

  getPrivilegeDescriptions(roleId?: string): string[] {
    let descriptions: string[] = []
    forEach(privilegeDescriptions, (privilegeDescription: string, privilegeId: string): void => {
      if (this.hasPrivilege(privilegeId as Privileges, roleId)) {
        descriptions.push(privilegeDescription)
      }
    })
    return descriptions
  }

  getRoleName(): string {
    const role = allRolesAndPrivileges.find((role: Role): boolean => {
      return this.roles.includes(role.id)
    })
    if (role) {
      return role.name
    }
  }

  isCustomer(): boolean {
    const customerRoles = this.roles.filter((role: string): boolean => {
      return role.indexOf('cu-') === 0 || role.indexOf('cu_') === 0
    })
    return customerRoles.length === 2
  }

  isOsUser(): boolean {
    const customerRoles = this.roles.filter((role: string): boolean => {
      return role.indexOf('os-') === 0
    })
    return customerRoles.length === 1
  }

  isNhUser(): boolean {
    const customerRoles = this.roles.filter((role: string): boolean => {
      return role.indexOf('nh-') === 0
    })
    return customerRoles.length === 1
  }

  isReseller() {
    return this.roles.some((role) => role.startsWith('re-'))
  }

  isResellee() {
    const session = getLocalSession()
    return session.resellee
  }

  isGlobalAdmin() {
    return this.roles.some((r) => ['nh-admin', 'os-admin'].includes(r))
  }
}

export { customerRolesAndPrivileges, osRolesAndPrivileges, nhRolesAndPrivileges, resellerRolesAndPrivileges, allRolesAndPrivileges, UserRoles }

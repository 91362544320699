import React from 'react'
import classNames from 'classnames'
import { types } from 'react-alert'

export const AlertTemplate: React.FC = ({ style, options, message, close }: any) => {
  const classes = classNames({
    'is-info': options.type === types.INFO,
    'is-success': options.type === types.SUCCESS,
    'is-danger': options.type === types.ERROR,
    notification: true,
    'alert-message': true
  })
  return (
    <div className={classes}>
      <button className="delete" onClick={close}></button>
      {message}
    </div>
  )
}

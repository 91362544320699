import * as Yup from 'yup'

const validateWrapper: (
  validation: Yup.Schema<any> | null
) => (value: any) => string | undefined = validation => value => {
  if (!validation) {
    return undefined
  }
  try {
    validation.validateSync(value)
    return undefined
  } catch (e) {
    return e.errors[0]
  }
}

const Validations: { [index: string]: Yup.Schema<any> } = {
  genericName: Yup.string()
    .min(1, 'Zu kurz für einen Namen')
    .max(50, 'Zu lang für einen Namen')
    .required('Es muss ein Name eingegeben werden')
}

const MetricValidations: { [index: string]: Yup.Schema<any> } = {
  ph: Yup.number()
    .transform(value => (isNaN(value) ? undefined : value))
    .nullable()
    .min(0, 'Der pH-Wert muss über null liegen')
    .max(14, 'Der pH-Wert muss unter 14 liegen'),
  nitrit: Yup.number()
    .transform(value => (isNaN(value) ? undefined : value))
    .nullable()
    .min(0, 'Der Nitrit-Wert muss über null liegen')
    .max(1000, 'Der Nitrit-Wert muss unter 1000 liegen'),
  nitrat: Yup.number()
    .transform(value => (isNaN(value) ? undefined : value))
    .nullable()
    .min(0, 'Der Nitrat-Wert muss über null liegen')
    .max(1000, 'Der Nitrat-Wert muss unter 1000 liegen')
}

export { Validations, MetricValidations, validateWrapper }

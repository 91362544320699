import React from 'react'
import { ResponsiveBullet } from '@nivo/bullet'
import { InteractiveComponentProps } from '../machine-protocol'
import { format } from 'date-fns'
import { dateFormat } from '../../utils/general'
import { isFinite } from 'lodash'

// const rangeColors = ['#1285d0', '#72c983', '#fabb2f']
const rangeColors = ['#ddf1fd', '#bce6c5', '#fde4af']
const markerColors = ['#333', '#3fa654']
// const measureColorOptions = ['#b7e1fa', '#41aa56', '#f9721a']
const measureColorOptions = ['#1285d0', '#72c983', '#fabb2f']

interface MarkerProps {
  x: number;
  value: number;
  index: number;
  size: number;
  color: string;
  onMouseEnter(): React.MouseEvent<SVGGElement>;
  onMouseMove(): React.MouseEvent<SVGGElement>;
  onMouseLeave(): React.MouseEvent<SVGGElement>;
}

const CustomMarker = (props: MarkerProps) => {
  const { x, index, color, onMouseEnter, onMouseMove, onMouseLeave } = props
  const isIst = index === 0
  const istY = 30
  const sollY = -10
  const textYOffset = 10
  const boxHeight = 20
  const boxWidth = isIst ? 30 : 47
  const triangleHeight = 5
  const top = isIst ? istY : sollY
  const bottom = isIst ? istY + boxHeight : sollY + boxHeight
  const textY = isIst ? istY + textYOffset : sollY + textYOffset
  return (
    <g
      transform={`translate(${x},0)`}
      onMouseEnter={onMouseEnter}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      {x < 50 ? (
        <>
          <path d={`M0 ${top} L ${boxWidth} ${top} L ${boxWidth} ${bottom} L 0 ${bottom} Z`} fill={color} />
          {isIst ? (
            <path d={`M0 ${top} L 6 ${top} L 0 ${top - triangleHeight} Z`} fill={color} />
          ) : (
              <path d={`M0 ${bottom} L 6 ${bottom} L 0 ${bottom + triangleHeight} Z`} fill={color} />
            )}
          <text
            transform={`translate(3, ${textY})`}
            textAnchor="start"
            dominantBaseline="central"
            style={{ fontWeight: 600 }}
            fill="#FFFFFF"
          >
            {isIst ? 'IST' : 'SOLL'}
          </text>
        </>
      ) : (
          <>
            <path d={`M-${boxWidth} ${top} L 0 ${top} L 0 ${bottom} L -${boxWidth} ${bottom} Z`} fill={color} />
            {isIst ? (
              <path d={`M-6 ${top} L 0 ${top} L 0 ${top - triangleHeight} Z`} fill={color} />
            ) : (
                <path d={`M-6 ${bottom} L 0 ${bottom} L 0 ${bottom + triangleHeight} Z`} fill={color} />
              )}
            <text
              transform={`translate(-${boxWidth - 3}, ${textY})`}
              textAnchor="start"
              dominantBaseline="central"
              style={{ fontWeight: 600 }}
              fill="#FFFFFF"
            >
              {isIst ? 'IST' : 'SOLL'}
            </text>
          </>
        )}
    </g>
  )
}

export default class Minmax extends React.PureComponent<InteractiveComponentProps> {
  public render(): React.ReactNode {
    const { measurement, meta } = this.props
    const localisedDate = format(new Date(measurement.measuredAt), dateFormat)
    const parsedValue = parseFloat(measurement.value)
    const parsedMax = parseFloat(measurement.max)
    const parsedShould = parseFloat(measurement.should)
    let graphData
    let measureColor = measureColorOptions[1]
    const hasValue = measurement.value || isFinite(parsedValue)
    if (hasValue) {
      let ceiling = meta.ceil ? meta.ceil : parsedValue * 1.25
      // Make sure that the ceiling isn’t lower than the max value plus padding
      if (measurement.max) {
        if (ceiling < parsedMax * 1.25) ceiling = parsedMax * 1.25
      }
      // Make sure that the ceiling isn’t lower than the measurement value plus padding
      if (parsedValue) {
        if (ceiling < parsedValue * 1.25) ceiling = parsedValue * 1.25
      }
      // However, if the ceiling is explicitly defined in the _measurement_, respect it
      if (measurement.ceil) ceiling = measurement.ceil
      // Dirty hack for metrics where the min is 0,
      // without it the first segment (zero to min) would be skipped (zero width),
      // making the the second segment (min to max) the first, in blue. It should
      // be green though. By making the first segment 0.01 wide, it’s blue but
      // not visible, and the second segment is correctly colored green
      let ranges = [measurement.min || 0.01]
      if (isFinite(parsedMax)) ranges.push(parsedMax)
      ranges.push(ceiling)
      let markers = []
      if (isFinite(parsedValue)) markers.push(parsedValue)
      if (isFinite(parsedShould)) markers.push(parsedShould)
      graphData = [
        {
          id: measurement.metricId,
          title: <text></text>,
          ranges,
          measures: [parsedValue],
          markers
        }
      ]
      if (parsedValue && measurement.max) {
        if (parsedValue > measurement.max) {
          measureColor = measureColorOptions[2]
        }
      }
      if (parsedValue && measurement.min) {
        if (parsedValue < measurement.min) {
          measureColor = measureColorOptions[0]
        }
      }
    }
    return (
      <div className="measurement-minmax-container">
        <div className="measurement-label">
          <span className="measurement-name">{meta.name}</span>
          <span className="measurement-value">
            {isFinite(parsedValue) ? parsedValue : '—'}
            {meta.unit ? meta.unit : ''}
          </span>
          <span className="measurement-date">vom {localisedDate}</span>
        </div>
        {hasValue ? (
          <ResponsiveBullet
            data={graphData}
            margin={{ top: 10, right: 10, bottom: 50, left: 10 }}
            spacing={46}
            rangeColors={rangeColors}
            measureColors={[measureColor]}
            measureSize={0.2}
            markerColors={markerColors}
            animate={false}
            markerComponent={CustomMarker}
          />
        ) : (
            <div className="empty-bullet-chart">Kein Messwert eingetragen</div>
          )}
      </div>
    )
  }
}

import { useState, useEffect } from 'react'
import PouchDB from 'pouchdb'

import { Machine } from '../components/machine-inventory'
import { sortBy } from 'lodash'
import { isMachineDoc, arrayOfStringsToArrayOfTagOptions } from '../utils/general'
import { TagOption } from '@luxx/types/forms'

interface AllDocsResult {
  doc?: PouchDB.Core.ExistingDocument<any & PouchDB.Core.AllDocsMeta> | undefined;
  id: string;
  key: string;
  value: {
    rev: string;
    deleted?: boolean | undefined;
  };
}

const useCrossMachineData = (customerId: string | undefined) => {
  const [tagOptions, setTagOptions] = useState<TagOption[]>([])
  const [locationOptions, setLocationOptions] = useState<TagOption[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [hasLoaded, setHasLoaded] = useState(false)
  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      const customerDB = new PouchDB(customerId)
      const machinesResult: PouchDB.Core.AllDocsResponse<Machine[]> = await customerDB.allDocs({
        'include_docs': true,
        startkey: 'ma_',
        endkey: 'ma_\ufff0'
      })
      const machines = machinesResult.rows.filter(isMachineDoc)
      let tags: string[] = []
      let locations: string[] = []

      // Get unique tags from all machines
      machines.forEach((machine: PouchDB.Core.ExistingDocument<any>): string[] | void => {
        if (machine.doc.tags && machine.doc.tags.length > 0) {
          machine.doc.tags.forEach((machineTag: string): void => {
            if (tags.indexOf(machineTag) === -1) {
              tags.push(machineTag)
            }
          })
        }
        // If location is existent get only unique location tags from locations
        if (machine.doc.location && locations.indexOf(machine.doc.location) === -1) {
          locations.push(machine.doc.location)
        }
      })
      const allTagOptions = sortBy(arrayOfStringsToArrayOfTagOptions(tags), 'value')
      const locationTags = sortBy(arrayOfStringsToArrayOfTagOptions(locations), 'value')
      if (!didCancel) {
        setTagOptions(allTagOptions)
        setLocationOptions(locationTags)
        setIsLoading(false)
        setHasLoaded(true)
      }
    }
    if (customerId) {
      fetchData()
    }
    // Clean up on unmount
    return () => {
      didCancel = true
    }
  }, [customerId])
  return { isLoading, hasLoaded, tagOptions, locationOptions }
}
export default useCrossMachineData

import React, { useContext, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import AppContext from '../AppContext'
import useCustomers from '../hooks/useCustomers'
import useInitialSyncCheck from '../hooks/useInitialSyncCheck'

/*

This view handles all requests to `/kunde/:customerId/whatever/else`,
tries to initial sync all data for `customerId`
and then redirects to `/whatever/else`

This effectively lets us deeplink anywhere in the app, which is
useful for links in cross-company emails, for example. Say a reseller gets
a notification for one of their resellees, or two os/nh users want to share
a customer page URL.

*/
interface MatchParams {
  customerId?: string;
}

const CustomerRedirect: React.FC<RouteComponentProps<MatchParams>> = props => {
  const { location } = props
  const { onCustomerSelect } = useContext(AppContext)
  const redirectTarget = props.location.pathname.replace(props.match.url, '')
  const { databaseManager } = useContext(AppContext)
  const { isSynced } = useInitialSyncCheck(databaseManager!)
  const { customers, hasLoadedCustomers } = useCustomers(location.key, props.match.params.customerId)
  databaseManager.startWithSession(props.match.params.customerId)
  useEffect(() => {
    if (isSynced && hasLoadedCustomers) {
      console.log('customers', customers)
      onCustomerSelect({
        _id: props.match.params.customerId,
        name: (customers[0] && customers[0] && customers[0].name) ? customers[0].name : ''
      }, redirectTarget)
    }
  }, [isSynced, hasLoadedCustomers, onCustomerSelect, props.match.params.customerId, redirectTarget, customers])
  return <span></span>
}

export default CustomerRedirect

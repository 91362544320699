import React, { useState, useEffect, useContext } from 'react'
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import PouchDB from 'pouchdb'
import { exec } from '../pouch'
import { DatabaseContext } from '../react-pouchdb'
import { getRemote } from '../utils/network'
import { generateId, dateAndTimeFormat } from '../utils/general'
import { format } from 'date-fns'
import { useAlert, types } from 'react-alert'
import useSortableData from '../hooks/useSortableData'
import { SortableHeaderButton } from './SortableHeaderButton'
const German = require('@uppy/locales/lib/de_DE')

interface MachineDocumentsProps {
  machineId: string;
  customerId: string;
}

const MachineDocuments: React.FC<MachineDocumentsProps> = props => {
  const [online, setOnline] = useState(window.navigator.onLine)
  const [formShown, setFormShown] = useState(false)
  const [documents, setDocuments] = useState<any>([])
  const [submitting, setSubmitting] = useState(false)
  const [name, setName] = useState('')
  const [uppy, setUppy] = useState<Uppy.Uppy | undefined>(undefined)
  const [flash, setFlash] = useState('')
  const { items: sortedItems, requestSort, sortConfig } = useSortableData(documents, {
    key: 'createdAt',
    direction: 'descending'
  })

  const getSortDirection = (name: string) => {
    if (!sortConfig) {
      return
    }
    return sortConfig.key === name ? sortConfig.direction : undefined
  }
  const getSortProps = (id: string) => ({
    id,
    sortDirection: getSortDirection(id),
    onRequestSort: () => requestSort(id)
  })

  const alert = useAlert()
  const { db } = useContext(DatabaseContext)!

  useEffect(() => {
    if (props.machineId) {
      pullDocs()
      try {
        uppy!.close()
      } catch {}
      setUppy(
        Uppy({
          locale: German,
          allowMultipleUploads: false,
          restrictions: {
            maxNumberOfFiles: 1,
            allowedFileTypes: ['application/pdf']
          }
        }).use(Dashboard, {
          inline: true,
          width: '100%',
          height: 200,
          target: '#file-browser',
          hideUploadButton: true,
          showLinkToFileUploadResult: false,
          replaceTargetContent: true,
          locale: {
            strings: {
              browse: 'Datei hinzufügen',
              dropPaste: '%{browse}'
            }
          }
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.machineId, db])

  useEffect(() => {
    if (!submitting) {
      pullDocs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting])

  useEffect(() => {
    window.addEventListener('online', onOnline)
    window.addEventListener('offline', onOffline)

    return function cleanup() {
      window.removeEventListener('online', onOnline)
      window.removeEventListener('offline', onOffline)
    }
  })

  function pullDocs() {
    db.allDocs({
      include_docs: true,
      startkey: `${props.machineId}:do_`,
      endkey: `${props.machineId}:do_\ufff0`
    }).then(data => {
      setDocuments(data.rows.map(row => row.doc))
    })
  }

  function submitForm(evt: React.MouseEvent): void {
    evt.preventDefault()
    if (!uppy) return
    if (online) {
      setSubmitting(true)

      if (uppy.getFiles().length !== 1) {
        setFlash('Bitte wählen Sie eine Datei zum Hochladen aus')
        setSubmitting(false)
        return
      }

      if (!name) {
        setFlash('Bitte tragen sie einen Namen für die Datei ein')
        setSubmitting(false)
        return
      }

      const docDb = new PouchDB(getRemote(`${props.customerId}-documents`))
      const id = generateId(8, 'do')
      const file = uppy.getFiles()[0]
      const createdAt = new Date().toJSON()
      const doc = {
        _id: `${props.machineId}:${id}`,
        createdAt,
        _attachments: {
          [file.name]: {
            content_type: file.type as string,
            data: file.data
          }
        }
      }
      exec(docDb.put(doc))
        .then(() => {
          const link = `/_api/${props.customerId}-documents/${props.machineId}%3A${id}/${file.name}`
          return exec(
            db.put({
              _id: `${props.machineId}:${id}`,
              name,
              fileName: file.name,
              createdAt,
              link
            })
          )
        })
        .then(res => {
          if (res.ok) {
            setName('')
            uppy.reset()
            setSubmitting(false)
            alert.show('Datei erfolgreich hochgeladen', {
              type: types.SUCCESS,
              timeout: 4000
            })
          }
        })
    } else {
      console.error('Somehow not online when attempting to submit form!')
    }
  }

  return (
    <>
      {flash && <div className="notification is-danger">{flash}</div>}
      <nav className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <h3 className="title is-3">Dokumente</h3>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            {online && !formShown && (
              <button className="button is-primary" onClick={() => setFormShown(true)}>
                Neues Dokument hochladen
              </button>
            )}
            {online && formShown && (
              <button className="button is-primary" onClick={() => setFormShown(false)}>
                Upload-Formular einklappen
              </button>
            )}
            {!online && (
              <button disabled className="button is-primary">
                Keine Internetverbindung
              </button>
            )}
          </div>
        </div>
      </nav>
      <div className={formShown ? '' : 'is-hidden'}>
        <h4 className="subtitle is-4">Neues Dokument hochladen</h4>
        <div className="field">
          <label className="label" htmlFor="name">
            Name
          </label>
          <input className="input" value={name} onChange={e => setName(e.currentTarget.value)} type="text" />
        </div>
        <div id="file-browser" className="content"></div>
        <button disabled={submitting} className="button is-primary" onClick={submitForm}>
          Hochladen
        </button>
      </div>
      {documents.length === 0 && <div>Keine Dokumente hochgeladen</div>}
      {documents.length > 0 && (
        <div className="table-container">
          <table className="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th><SortableHeaderButton label="Name" {...getSortProps('name')} /></th>
                <th><SortableHeaderButton label="Datum" {...getSortProps('createdAt')} /></th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {sortedItems.map((d: any, index: number) => (
                <tr key={`document-${index}`}>
                  <td>
                    <strong>{d.name}</strong>
                    {d.fileName && (
                      <>
                        {' '}
                        <code>{d.fileName}</code>
                      </>
                    )}
                  </td>
                  <td className="is-family-monospace">{d.createdAt ? `${format(new Date(d.createdAt), dateAndTimeFormat)}` : '–'}</td>
                  <td>
                    {online && (
                      <a className="link" href={d.link} target="_blank" rel="noopener noreferrer">
                        Öffnen
                      </a>
                    )}
                    {!online && (
                      <span className="disabled">Das Dokument kann nur mit Internetverbindung geöffnet werden</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )

  function onOnline() {
    setOnline(true)
  }

  function onOffline() {
    setOnline(false)
  }
}

export default MachineDocuments

import React, { Component } from 'react'
import * as Yup from 'yup'
import { Field } from 'formik'
import DatePicker from 'react-datepicker'
import { omit, get, isNumber } from 'lodash'
import { format, subMonths, startOfMonth, isAfter } from 'date-fns'
import { Redirect } from 'react-router-dom'
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import PouchDB from 'pouchdb'
import { ImageCompressorPlugin } from '@luxx/utils/general'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import './forms/uppy-overrides.scss'

import { DatabaseContext } from '../react-pouchdb/Database'
import { FormContainer } from '@luxx/forms'
import MachineMeasurementForm from './forms/machine-measurement-form'
import { MetricMeta, getLabelForId } from '../utils/default-metrics'
import { isNotEmpty } from '../utils/general'
import FluidInfoHOC from './fluid-info'
import { MachineState } from './machine-inventory'

const German = require('@uppy/locales/lib/de_DE')
interface Props {
  machine: any;
  mp: any;
  customerId: string;
  createdAt: Date;
  putDocument: (state: object) => void;
}

export interface MetricMeasurement extends MetricMeta {
  value: any;
}

interface State {
  type: string;
  createdAt?: Date;
  measuredAt?: Date;
  metrics: object;
  operation: string;
  material: string;
  isDone: boolean;
  image: any;
  uppy?: any;
}

interface FormValues {
  [key: string]: any;
}

const MeasurementSchema = Yup.object({
  type: Yup.string().required('Pflichtfeld'),
  createdAt: Yup.date(),
  measuredAt: Yup.mixed(),
  // operation: Yup.string().required('Pflichtfeld'),
  // material: Yup.string().required('Pflichtfeld'),
  metrics: Yup.array()
})

class MachineMeasurement extends Component<Props, State> {
  static contextType = DatabaseContext

  static defaultProps = {
    putDocument: (_state = {}): void => { }
  }

  public state = {
    type: 'measurement',
    createdAt: undefined,
    measuredAt: new Date(),
    // We need to turn the heavy metrics object we received from the measurement
    // point doc into a lighter format that's ready to be saved as part of the
    // measurement. To this effect, we call a function here that converts the
    // settable metrics into tuple arrays (arrays with an implied key-value function).

    metrics: this.mapMetricsToState(this.props.mp),
    operation: this.props.mp.doc.operation,
    material: this.props.mp.doc.material,
    isDone: false,
    image: {
      extension: '',
      type: '',
      data: undefined
    },
    uppy: undefined
  }

  public componentDidMount(): void {
    const machineIsEditable =
      get(this.props.machine, 'state') === MachineState.ACTIVE || !get(this.props.machine, 'state')
    if (!machineIsEditable) return
    const uppy = Uppy({
      locale: German,
      allowMultipleUploads: false,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*']
      }
    })
      .use(Dashboard, {
        inline: true,
        width: '100%',
        height: 200,
        target: '#file-upload',
        hideUploadButton: true,
        showLinkToFileUploadResult: false,
        locale: {
          strings: {
            browse: 'Foto hinzufügen',
            dropPaste: '%{browse}'
          }
        }
      })
      .use(ImageCompressorPlugin, {})

    this.setState({
      uppy
    })
  }

  public render(): React.ReactNode {
    const machineIsEditable =
      get(this.props.machine, 'state') === MachineState.ACTIVE || !get(this.props.machine, 'state')
    if (!machineIsEditable) {
      return (
        <div className="content">
          <p>An dieser Maschine kann keine Messung vorgenommen werden.</p>
        </div>
      )
    }
    let material = getLabelForId(this.state.material)
    let operation = getLabelForId(this.state.operation)
    let fluidId = this.props.mp.doc.fluid.id
    // The <FormContainer> in here has to explicitly set the `measuredAt` property
    // via a callback hook, since we Formik is unable to read the value from the datepicker,
    // which instead just updates the local state directly if you set a different
    // date or time.
    // We then have to directly replace the onSubmit method as defined by <FormContainer>,
    // because the situation requires us to create multiple documents at once. We do this
    // by creating an array of Promises, each of which creates one document, and then running
    // all of them at the same time using Promise.all.
    return (
      <>
        <FormContainer
          initialValues={this.state}
          validationSchema={MeasurementSchema}
          putDocument={this.props.putDocument}
          onSubmit={async (values: FormValues, actions: any): Promise<void> => {
            let image = null
            if (!isAfter(this.state.measuredAt, startOfMonth(subMonths(new Date(), 3)))) {
              actions.setErrors({ general: 'Das Datum darf nicht weiter als 3 Monate zurückliegen!' })
              actions.setSubmitting(false)
              return
            }
            if (this.state.uppy) {
              const uppy: any = this.state.uppy!
              if (uppy.getFiles().length === 1) image = uppy.getFiles()[0]
            }
            actions.setSubmitting(true)
            values.createdAt = this.props.createdAt
            const db = this.getMonthDb(this.props.customerId, this.state.measuredAt)
            let metricArray = values.metrics
            // If we have values for 'ca-hardness' and 'mg-hardness', we can calculate 'water-hardness'
            const caHardness = metricArray.find((m: MetricMeasurement) => m.id === 'ca-hardness')
            const mgHardness = metricArray.find((m: MetricMeasurement) => m.id === 'mg-hardness')
            const waterHardness = metricArray.find((m: MetricMeasurement) => m.id === 'water-hardness')
            if (caHardness && isNumber(caHardness.value) && mgHardness && isNumber(mgHardness.value)) {
              waterHardness.value = (caHardness.value * 1,4 + mgHardness.value * 2,307) / 10
            }

            let summary = {
              _id: `${this.props.mp.doc._id}:summary:${values.createdAt.toJSON()}`,
              metrics: metricArray,
              measuredAt: this.state.measuredAt,
              image: false
            }
            let promises = metricArray.map(async (m: MetricMeasurement) => {
              // Only write the doc if we actually have a value
              if (isNotEmpty(m.value)) {
                let doc: any = {
                  _id: `${this.props.mp.doc._id}:${m.id}:${values.createdAt.toJSON()}`,
                  type: 'measurement',
                  metricId: m.id,
                  ...omit(m, ['options', 'enabled', 'id', 'type']),
                  value: m.value,
                  material: values.material,
                  operation: values.operation,
                  createdAt: values.createdAt,
                  measuredAt: this.state.measuredAt
                }
                return db.put(doc)
              }
            })

            // We have to check for both the `image` and the `image.data` key,
            // because TypeScript will yell at us if we set `image` to undefined by
            // default, but not if we set it to undefined during runtime. This means
            // that we have to set `image` to an empty-ish object, which is why we
            // specifically have to check for the `data` key as well.
            if (image) {
              summary.image = true
              const doc: any = {
                _id: `${this.props.mp.doc._id}:image:${values.createdAt.toJSON()}`,
                type: 'measurement',
                metricId: 'image',
                createdAt: values.createdAt,
                measuredAt: this.state.measuredAt,
                // Add the filename, that lets us fetch the attatchment directly,
                // without having the attachment doc below
                attachmentFileName: `image.${image.extension}`
              }
              // Put the :image: doc _without_ the attachment in the monthDB
              await db.put(doc)
              // Put the :image: doc _with_ the attachment in the attachmentMonthDB
              const attachmentDb = this.getAttachmentMonthDb(this.props.customerId, this.state.measuredAt)
              const insertedAttachmentDoc = await attachmentDb.put(doc)
              await attachmentDb.putAttachment(
                doc._id,
                `image.${image.extension}`,
                insertedAttachmentDoc.rev,
                image.data,
                image.type
              )
            }

            await Promise.all(promises)
            // Here, we send off a kind of "summary" doc, this lists all the metrics separately in its own doc.
            // We use this in our email notifier component, because otherwise it would be really
            // hard to act on all of the separate docs being sent all at once.
            await db.put(summary)
            actions.setSubmitting(false)
            this.setState({ isDone: true })
          }}
        >
          {this.state.isDone && <Redirect to={`/maschine/${this.props.machine._id.slice(3)}/protokoll`} />}
          <h3 className="title is-3">Neue Messung</h3>
          <MachineMeasurementForm />
          <div className="well">
            <div className="field column">
              <div id="file-upload"></div>
            </div>
          </div>

          <div className="well">
            <div className="field column">
              <label className="label" htmlFor="measuredAt">
                Datum und Zeit der Messung
              </label>
              <Field
                className="input"
                component={DatePicker}
                name="measuredAt"
                selected={this.state.measuredAt}
                onChange={(date: Date) => {
                  this.setState({ measuredAt: date })
                }}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={10}
                timeCaption="Uhrzeit"
                dateFormat="dd.MM.yyyy HH:mm"
              />
            </div>
          </div>
        </FormContainer>
        <div className="has-padding info-list well has-background-white-bis">
          <h5>Aktuelle Maschineneinstellungen</h5>
          <ul>
            <li>
              Bearbeitetes Material: <strong>{material}</strong>
            </li>
            <li>
              Operation: <strong>{operation}</strong>
            </li>
          </ul>
        </div>
        <FluidInfoHOC currentFluidID={fluidId} />
      </>
    )
  }

  private mapMetricsToState(mp: any): any[][] {
    const metrics = mp.doc.metrics
    return metrics
      .filter((metric: MetricMeta) => metric.enabled)
      .map(
        (metric: MetricMeta): MetricMeasurement => {
          let defaultValue: any = ''
          if (metric.type === 'minmax') {
            // Allow number inputs to be nullable
            // https://github.com/jaredpalmer/formik/issues/321#issuecomment-478364302
            defaultValue = ('' as unknown) as number
          }
          return { ...metric, value: defaultValue, fluid: mp.doc.fluid, refraktometerAblesewert: 0}
        }
      )
  }

  private getMonthDb(customerId: any, measuredAt: Date): PouchDB.Database {
    return new PouchDB(`${customerId}-${format(measuredAt, 'yyyy-MM')}`)
  }

  private getAttachmentMonthDb(customerId: any, measuredAt: Date): PouchDB.Database {
    return new PouchDB(`${customerId}-attachments-${format(measuredAt, 'yyyy-MM')}`)
  }
}

export default MachineMeasurement

import { useState, useEffect } from 'react'
import PouchDB from 'pouchdb'

import { Action } from '../components/machine-action'

// `key` is some random string we can force this hook to re-run with
const useAction = (
  customerId: string | undefined,
  machineId: string | undefined,
  actionId: string | undefined,
  key?: string
) => {
  const [action, setAction] = useState<Action | undefined>(undefined)
  const [hasLoaded, setHasLoaded] = useState(false)
  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      let action: any | undefined
      if (actionId) {
        const yearMonthPrefix = (actionId as string).substr(0, 7)
        const dbName = `${customerId}-${yearMonthPrefix}`
        const docId = `${machineId}:${actionId}`
        const customerDB = new PouchDB(dbName)
        try {
          action = await customerDB.get(docId)
        } catch (e) {
          console.log(`${docId} doesn’t exist`)
          setAction(undefined)
          setHasLoaded(true)
        }
      } else {
        setAction(undefined)
        setHasLoaded(true)
      }
      if (!didCancel) {
        setAction(action)
        setHasLoaded(true)
      }
    }
    if (customerId && machineId) {
      fetchData()
    }
    // Clean up on unmount
    return () => {
      didCancel = true
    }
  }, [customerId, machineId, actionId, key])
  return { action, hasLoaded }
}
export default useAction

import React, { useMemo } from 'react'
import { FieldArray, FieldArrayRenderProps, useFormikContext, FormikProps } from 'formik'
import * as Yup from 'yup'

import FormItem from '@luxx/forms'
import { OsFluid, CustomerFluid } from '../../types/fluids'
import { MetricValidations, validateWrapper } from '../../utils/validations'
import { materials, operations, MetricMeta } from '../../utils/default-metrics'
import { TagOptionGroup, TagOption } from '@luxx/types/forms'
import { FormValues } from '@luxx/forms'
import { FluidInfo } from '../fluid-info'
import { isEmpty, map } from 'lodash'
import { shouldNotSeeOSBrand } from '../../utils/general'

// This component automatically renders a FormItem based on the props given to
// it. In a way, it serves the same purpose as FormItem, but it's a tad more
// specific, which makes it worth splitting off.
// TODO: In the future, we can use parts of this for the actual taking of
// measurements as well.

interface Props {
  osFluids: OsFluid[];
  customerFluids: CustomerFluid[];
  isDisabled?: boolean;
}

const MachineMeasurementPointMetricsForm = (props: Props) => {
  const { osFluids, customerFluids, isDisabled: formIsDisabled } = props
  const { values, errors, touched }: FormikProps<FormValues> = useFormikContext()
  const { metrics } = values
  const hiddenForBrandingReasons = shouldNotSeeOSBrand()
  const visibleMetrics = useMemo(() => metrics.filter((metric: MetricMeta) => !metric.hidden), [metrics])

  const fluidToOption = (fluid: OsFluid | CustomerFluid): TagOption => {
    return {
      label: fluid.name,
      value: fluid._id
    }
  }

  const emptyOSFluidsOption: TagOption = {
    label: `Konnte keine Fluide aus dem ${hiddenForBrandingReasons ? '' : 'Öl-Schüler-'}Katalog laden`,
    value: '',
    isDisabled: true
  }
  let osFluidsOptions = osFluids.map((fluid: OsFluid): TagOption => fluidToOption(fluid))
  if (osFluidsOptions.length === 0) {
    osFluidsOptions.push(emptyOSFluidsOption)
  }

  const emptyCustomerFluidsOption: TagOption = {
    label: 'Es wurden noch keine eigenen Fluide eingerichtet. Geben Sie einen Fluid-Namen ein, um dies zu tun.',
    value: '',
    isDisabled: true
  }
  let customerFluidsOptions = customerFluids.map((fluid: CustomerFluid): TagOption => fluidToOption(fluid))
  if (customerFluidsOptions.length === 0) {
    customerFluidsOptions.push(emptyCustomerFluidsOption)
  }

  const fluidOptions: TagOptionGroup[] = [
    {
      label: 'Eigene Fluide',
      options: customerFluidsOptions
    },
    {
      label: `Fluide aus dem ${hiddenForBrandingReasons ? '' : 'Öl-Schüler-'}Katalog`,
      options: osFluidsOptions
    }
  ]
  return (
    <>
      <FieldArray
        name="metrics"
        render={(arrayHelpers: FieldArrayRenderProps): React.ReactNode => {
          return (
            <>
              <h3 className="title is-3">Betriebsdaten</h3>
              <h4 className="title is-4">Aktueller Betrieb</h4>
              <FormItem component="select" name="material" label="Bearbeitetes Material">
                <option value="" disabled>
                  Bitte auswählen:
                </option>
                {materials.map(
                  (material): React.ReactElement => {
                    return (
                      <option value={material.id} key={material.id}>
                        {material.label}
                      </option>
                    )
                  }
                )}
              </FormItem>
              <FormItem component="select" name="operation" label="Operation">
                <option value="" disabled>
                  Bitte auswählen:
                </option>
                {operations.map(
                  (operation): React.ReactElement => {
                    return (
                      <option value={operation.id} key={operation.id}>
                        {operation.label}
                      </option>
                    )
                  }
                )}
              </FormItem>
              <h4 className="title is-4">Fluid</h4>
              <FormItem
                component="TagInput"
                label="Verwendetes Fluid"
                name="fluid.id"
                placeholder="Bitte ein Fluid wählen oder neu eingeben"
                multiple={false}
                tagOptions={fluidOptions}
                isClearable
                isCreatable
                hasComplexOptions={true}
                isDisabled={formIsDisabled}
              />
              <FluidInfo currentFluidID={values.fluid.id} osFluids={osFluids} customerFluids={customerFluids} />
              <FormItem type="number" name="fluid.rfm" label="Refraktometerwert" step="0.01" />
              <hr />
              <h4 className="title is-4">Zu erfassende Metriken</h4>
              {visibleMetrics.map(
                (metric: MetricMeta, index: number): React.ReactElement => {
                  // Basically return just to defer to another component to escape
                  // the indentation hell that would normally follow
                  let originalIndex = metrics.indexOf(metric)
                  return <FormItemWrapper {...metric} index={originalIndex} key={`mp${index}`} />
                }
              )}
            </>
          )
        }}
      />
      {!isEmpty(errors) && (
        <article className="message is-danger mt2">
          <div className="message-header">
            <p>Das Formular kann so nicht gespeichert werden</p>
          </div>
          <div className="message-body">
            <p>Bitte beheben Sie folgende Probleme: </p>
            <ul>
              {map(errors, errorsPerField => {
                const allErrors = errorsPerField
                return (allErrors as []).map((fieldErrors, index) => {
                  const metricName = metrics[index].name
                  const enabled = metrics[index].enabled
                  if (enabled && fieldErrors && touched.metrics) {
                    const errorsForField = Object.entries(fieldErrors)
                    return map(errorsForField, fieldError => {
                      const [label, value] = fieldError
                      const wasTouched = touched.metrics[index] ? touched.metrics[index][label] : false
                      if (wasTouched) {
                        return (
                          <li key={Math.ceil(Math.random() * 1e10)}>
                            - {metricName}: {value}
                          </li>
                        )
                      }
                    })
                  }
                  return null
                })
              })}
            </ul>
          </div>
        </article>
      )}
    </>
  )
}

// Normally it would be hard to define custom validations per field. For
// example, due to the dynamic nature of this form, it's possible to define a
// validation that says "every number field has to be above 0", but it's not
// possible to say "only for the field pH-Wert, the number can't go above 12".
//
// We solve this by using Formik's `validate` prop, which lets us pass in a
// custom validation function. These functions, which are just Yup validators
// wrapped in a thing that handles the errors properly, are defined here and
// pulled from src/utils/validations.ts. If the ID matches with any key from the
// `Validations` object defined in that file, we attach it to the relevant form field.
//
// For example, the metric with the id "ph" matches the key "ph" in the object
// defined in src/utils/validations.ts, therefore we wrap it in our validation
// function and pass it to the FormItem.

const FormItemWrapper = (props: any) => {
  let returning: React.ReactElement | null = null
  // This is where we pull the relevant Yup validation from the utils file and wrap it.
  let customValidation: Yup.Schema<any> = MetricValidations[props.id]
  let validation = validateWrapper(customValidation)
  if (props.type === 'minmax') {
    returning = (
      <div className="well columns is-desktop">
        <FormItem
          className="column"
          type="number"
          validate={validation}
          label="Minimum"
          name={`metrics[${props.index}].min`}
          step="0.1"
        />
        {props.should !== null ? (
          <FormItem
            className="column"
            validate={validation}
            type="number"
            label="Soll"
            name={`metrics[${props.index}].should`}
            step="0.1"
          />
        ) : (
          ''
        )}
        <FormItem
          className="column"
          type="number"
          validate={validation}
          label="Maximum"
          name={`metrics[${props.index}].max`}
          step="0.1"
        />
      </div>
    )
  }

  if (props.type !== 'header') {
    const label = `${props.name} ${props.unit ? `(in ${props.unit})` : ''}`
    return (
      <div>
        <FormItem type="checkbox" label={label} name={`metrics[${props.index}].enabled`} />
        {props.enabled ? returning : null}
      </div>
    )
  } else {
    return <h4 className="title is-4">{props.name}</h4>
  }
}

export default MachineMeasurementPointMetricsForm

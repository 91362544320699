import { chain, last, sortBy } from 'lodash'
import { blobToDataURL } from 'blob-util'
import PouchDB from 'pouchdb'

import { Measurement } from '../components/graphs/waffle-measurements'
import { getRemote, localDbExists } from './network'

export interface MeasurementIdInfo {
  machineId: string;
  measurementPointId: string;
  metricId: string;
  timestamp: string;
  yearMonth: string;
}

export const getInfoFromMeasurementId = (id: string): MeasurementIdInfo => {
  const [machineId, measurementPointId, metricId, ...dateTimeFragments] = id.split(':')
  const timestamp = dateTimeFragments.join(':')
  const yearMonth = timestamp.substr(0, 7)
  return {
    machineId,
    measurementPointId,
    metricId,
    timestamp,
    yearMonth
  }
}

export const getImageFromMeasurement = async (imageMeasurement: Measurement, customerId: string) => {
  const { yearMonth } = getInfoFromMeasurementId(imageMeasurement._id)
  const currentAttachmentMonthDbName = `${customerId}-attachments-${yearMonth}`

  const fetchAttachmentURLFromRemote = async () => {
    let attachmentDoc
    const remoteDbURL = getRemote(currentAttachmentMonthDbName)
    // Ideally, the measurement doc already includes the filename of the
    // attachment in the corresponding attachment doc. That means we can
    // determine the remote URL of the attachment with the measurement
    // doc we already have.
    if (imageMeasurement.attachmentFileName) {
      return `${remoteDbURL}/${imageMeasurement._id}/${imageMeasurement.attachmentFileName}`
    }
    // Measurement does not reference the attachment filename, so get
    // the attachment doc to get the file name (not the file itself)
    const currentRemoteAttachmentMonthDb = new PouchDB(remoteDbURL)
    try {
      attachmentDoc = await currentRemoteAttachmentMonthDb.get(imageMeasurement._id)
      if (attachmentDoc && attachmentDoc._attachments) {
        const firstAttachmentName = Object.keys(attachmentDoc._attachments)[0]
        return `${remoteDbURL}/${attachmentDoc._id}/${firstAttachmentName}`
      }
    } catch (err) {
      console.log('Could not fetch attachment URL from remote!', err)
    }
    return null
  }

  // If the measurement doc has _attachments, get the first one (should only have one anyway)
  if (imageMeasurement && imageMeasurement._attachments) {
    const currentMonthDb = new PouchDB(`${customerId}-${yearMonth}`)
    const firstAttachment = Object.keys(imageMeasurement._attachments)[0]
    try {
      const image = await currentMonthDb.getAttachment(imageMeasurement._id, firstAttachment)
      return blobToDataURL(image as any)
    } catch (e) {
      console.log('Couldn’t fetch attachment from local month db', e)
    }
    // This is a new style image measurement doc, where the attachment is in a
    // separate doc in a possibly unsynced DB
  } else {
    if (imageMeasurement) {
      let attachmentDoc
      // If we have the attachmentDB this attachment is possibly in on this device, check there first
      const currentAttachmentMonthDbExists = localDbExists(currentAttachmentMonthDbName)
      if (currentAttachmentMonthDbExists) {
        const currentAttachmentMonthDb = new PouchDB(currentAttachmentMonthDbName, { skip_setup: true })
        try {
          attachmentDoc = await currentAttachmentMonthDb.get(imageMeasurement._id)
          // We got an attachmentDoc from somewhere
          if (attachmentDoc && attachmentDoc._attachments) {
            const firstAttachment = Object.keys(attachmentDoc._attachments)[0]
            try {
              const image = await currentAttachmentMonthDb.getAttachment(attachmentDoc._id, firstAttachment)
              return blobToDataURL(image as any)
            } catch (e) {
              console.log('Couldn’t fetch attachment from local attachment  month db', e)
            }
          }
        } catch (err) {
          // The attachment doc isn’t on this device, try and get it from remote
          return fetchAttachmentURLFromRemote()
        }
      } else {
        // The attachmentDb this attachment should be in doesn’t exist on this device, so fetch from remote
        return fetchAttachmentURLFromRemote()
      }
    }
    return null
  }
}

export const getMostRecentMeasurementsPerMetric = (metrics: any) => {
  return chain(metrics)
    .groupBy('metricId')
    .forEach((ms, key, collection) => {
      collection[key] = last(sortBy(ms, 'measuredAt'))
    })
    .value()
}

import { useState, useEffect } from 'react'
import PouchDB from 'pouchdb'

import { CustomerFluid, OsFluid } from '../types/fluids'

const useFluids = (customerId: string | undefined) => {
  const [customerFluids, setCustomerFluids] = useState<CustomerFluid[]>([])
  const [osFluids, setOsFluids] = useState<OsFluid[]>([])
  const [isLoadingFluids, setIsLoadingFluids] = useState(true)
  const [hasLoadedFluids, setHasLoadedFluids] = useState(false)
  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      // Get customer fluids
      const customerDB = new PouchDB(customerId)
      const customerFluidRows = await customerDB.allDocs({
        include_docs: true,
        startkey: `fl_`,
        endkey: `fl_\ufff0`
      })
      const customerFluids = customerFluidRows.rows.map((row: any) => row.doc)
      // Get Öl Schüler fluids
      const fluidDb = new PouchDB('oel-products')
      const osFluidRows = await fluidDb.allDocs({ include_docs: true })
      const osFluids = osFluidRows.rows.map((row: any) => row.doc)
      if (!didCancel) {
        setCustomerFluids(customerFluids)
        setOsFluids(osFluids)
        setIsLoadingFluids(false)
        setHasLoadedFluids(true)
      }
    }
    if (customerId) {
      fetchData()
    }
    // Clean up on unmount
    return () => {
      didCancel = true
    }
  }, [customerId])
  return { customerFluids, osFluids, allFluids: customerFluids.concat(osFluids), isLoadingFluids, hasLoadedFluids }
}
export default useFluids

import React from 'react'
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer'
import {
  Style
} from '@react-pdf/types'
import { MachineDoc } from './machine-inventory'
import qr from 'qr-image'
import { OsFluid, CustomerFluid } from '../types/fluids'
import { MeasurementPoint } from './machine-measurement-point'
import { getLabelForId, getMetricMetadata } from '../utils/default-metrics'

interface Props {
  machine: MachineDoc;
  measurementPoint?: MeasurementPoint;
  fluidInfo?: OsFluid | CustomerFluid;
  fixed?: boolean;
  style?: Style;
  code?: string | Buffer;
}

const styles = StyleSheet.create({
  header: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 24
  },
  subHeader: {
    fontFamily: 'Helvetica',
    fontSize: 18,
    marginBottom: 8
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    top: '5mm',
    right: '10mm',
    width: '10mm',
    color: 'grey',
    textAlign: 'right'
  },
  divider: {
    width: '100%',
    height: '1pt',
    backgroundColor: '#DDD',
    marginBottom: '2mm'
  },
  label: {
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
    color: 'grey',
    textTransform: 'uppercase',
    marginTop: '2mm',
    marginRight: '8mm'
  },
  id: {
    fontSize: 18,
    color: 'grey',
    fontFamily: 'Courier'
  },
  field: {
    fontSize: 16,
    marginBottom: '4mm'
  },
  textRow: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  row: {
    flexDirection: 'row',
    alignContent: 'space-between'
  },
  stretchRow: {
    flexDirection: 'row',
    alignContent: 'stretch',
    width: '100%'
  },
  expand: {
    flexGrow: 2
  },
  rowItem: {
    marginRight: '8mm'
  },
  code: {
    height: '3cm'
  }
})

const Divider = () => <View style={styles.divider} />

const PDFMachineInfoBlock: React.FC<Props> = props => {
  const { machine, fixed, measurementPoint, fluidInfo, style: styleProp } = props
  let material = measurementPoint ? getLabelForId(measurementPoint.material) : undefined
  let operation = measurementPoint? getLabelForId(measurementPoint.operation) : undefined
  const getMetricValue = (metricId: string, value: string) => {
    const metric = measurementPoint.metrics.find((metric) => metric.id === metricId)
    const meta = getMetricMetadata(metricId)
    // Metric should exist and not be undefined or nulled by Formik
    if (metric && (metric[value] !== undefined && metric[value] !== '')) return `${metric[value]}${meta.unit || ''}`
    return '?'
  }
  if (machine) {
    const code = props.code || qr.imageSync(machine._id as string, {
      type: 'png',
      ec_level: 'L'
    })
    const { name, _id, inventoryNumber, location } = machine
    const machineIdSansPrefix = (_id as string).substr(3)
    const formattedMachineId = machineIdSansPrefix.substring(0, 4) + ' ' + machineIdSansPrefix.substring(4)
    return (
      <View fixed={fixed || false} style={{ width: '100%', ...styleProp }}>
        <View style={styles.stretchRow}>
          <View style={styles.expand}>
            <View style={styles.textRow}>
              <Text style={styles.header}>{name}</Text>
              <Text style={styles.id}>({formattedMachineId})</Text>
            </View>
            <View style={styles.row}>
              <View style={styles.rowItem}>
                <Text style={styles.label}>Inventarnummer</Text>
                <Text style={styles.field}>{inventoryNumber || '—'}</Text>
              </View>
              <View>
                <Text style={styles.label}>Standort</Text>
                <Text style={styles.field}>{location || '—'}</Text>
              </View>
            </View>
            {measurementPoint && (<View style={styles.row}>
              <View style={styles.rowItem}>
                <Text style={styles.label}>Material</Text>
                <Text style={styles.field}>{material || '—'}</Text>
              </View>
              <View>
                <Text style={styles.label}>Operation</Text>
                <Text style={styles.field}>{operation || '—'}</Text>
              </View>
            </View>)}
            {fluidInfo && measurementPoint && (<View style={styles.row}>
              <View style={styles.rowItem}>
                <Text style={styles.label}>Kühlschmierstoff</Text>
                <Text style={styles.field}>{fluidInfo.name || '—'}</Text>
              </View>
              <View style={styles.rowItem}>
                <Text style={styles.label}>Refraktometerwert</Text>
                <Text style={styles.field}>{measurementPoint.fluid.rfm || '—'}</Text>
              </View>
            </View>)}
            {measurementPoint && (<View style={styles.row}>
              <View style={styles.rowItem}>
                <Text style={styles.label}>Soll Konzentration</Text>
                <Text style={styles.field}>{getMetricValue('konzentration', 'min')} - {getMetricValue('konzentration', 'max')}</Text>
              </View>
              <View>
                <Text style={styles.label}>Soll pH</Text>
                <Text style={styles.field}>{getMetricValue('ph', 'min')} - {getMetricValue('ph', 'max')}</Text>
              </View>
              <View>
                <Text style={styles.label}>Soll Nitrit</Text>
                <Text style={styles.field}>{getMetricValue('nitrit', 'min')} - {getMetricValue('nitrit', 'max')}</Text>
              </View>
            </View>)}
          </View>
          <Image style={styles.code} source={{ data: code as Buffer, format: 'png' }} />
        </View>
        <Divider />
      </View>
    )
  }
  return null
}

export default PDFMachineInfoBlock

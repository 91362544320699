import { Customer } from '../hooks/useCustomers'
import { User, Role } from '../types/users-roles-privileges'
import { allRolesAndPrivileges } from './user-roles'

interface SessionData extends User {
  // Used to determine which customer an admin is currently looking at
  currentCustomer?: Customer;
}
// Session management helpers

export const setLocalSession = (session: SessionData): void => {
  localStorage.setItem('session', JSON.stringify(session))
}

export const getLocalSession = (): SessionData | undefined => {
  const session = localStorage.getItem('session')
  if (!session) {
    return { roles: [] }
  }
  return JSON.parse(session)
}

export const deleteLocalSession = (): void => {
  const session = localStorage.getItem('session')
  if (session) {
    localStorage.removeItem('session')
  }
}

// Admin helpers

// export const getAdminRoleFromSession = (): string | undefined => {
//   const session: SessionData | undefined = getLocalSession()
//   if (!session || !session.roles) return
//   return getAdminRoleFromCouchDBRoles(session.roles)
// }

// Customer helpers

export const getCustomerIdFromCouchDBRoles = (roles: string[]): string | undefined => {
  if (!roles) return
  return roles.find((role: string) => {
    return role.substr(0, 3) === 'cu_'
  })
}

export const getCustomerRoleFromCouchDBRoles = (roles: string[]): string | undefined => {
  if (!roles) return
  return roles.find((role: string) => {
    return role.match(/^cu-/)
  })
}

export const userIsViewingTheirOwnOrg = (roles: string[], customerId: string): boolean => {
  if (roles.includes(customerId)) {
    // user is customer user and current customer is theirs
    return true
  }
  if (customerId === 'nh' && isNhUser(roles)) {
    // user is nh user and current org is nh
    return true
  }
  if (customerId === 'oel' && isOsUser(roles)) {
    // user is os user and current org is oel
    return true
  }
  return false
}

export const getRoleDefinitionForId = (roleId: string): Role | undefined => {
  return allRolesAndPrivileges.find((item: any): string => {
    return roleId === item.id ? item.name : null
  })
}

export const getRoleNameForId = (roleId: string): string | undefined => {
  const definition = getRoleDefinitionForId(roleId)
  return definition ? definition.name : undefined
}

export const isOsUser = (roles: string[]): boolean => {
  if (!roles || roles.length === 0) return false
  const customerRoles = roles.filter((role: string): boolean => {
    return role.indexOf('os-') === 0
  })
  return customerRoles.length === 1
}

export const isNhUser = (roles: string[]): boolean => {
  if (!roles || roles.length === 0) return false
  const customerRoles = roles.filter((role: string): boolean => {
    return role.indexOf('nh-') === 0
  })
  return customerRoles.length === 1
}

export const getUserRoleFromCouchDBRoles = (roles: string[]): string | undefined => {
  if (!roles) return
  return roles.find((role: string) => {
    return role.match(/^[\w]{2}-[\w]*$/)
  })
}

export const getCustomerRoleFromSession = (): string | undefined => {
  const session: SessionData | undefined = getLocalSession()
  if (!session || !session.roles) return
  return getCustomerRoleFromCouchDBRoles(session.roles)
}

export const userIsCustomer = (): boolean => {
  return !!getCustomerRoleFromSession()
}

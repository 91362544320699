import React from 'react'
import { format } from 'date-fns'
import { getLabelForId, getMetricMetadata } from '../utils/default-metrics'
import { dateAndTimeFormat } from '../utils/general'
import { Link } from 'react-router-dom'
import { Action, actionTypeLabels } from './machine-action'
import { getIconForActionStatus } from './machine-protocol'
// This table component is generic and expects a `measurements` prop
// formatted like an array, where every array element represents a single
// measurement with its array elements representing the individual metrics.
// It accounts for empty metrics, as well.
// Currently only supports pH, Konzentration and Nitrit.

export const metricsToShow = ['ph', 'konzentration', 'nitrit']

interface Props {
  eventClickTargetPrefix?: string; // /maschine/62z262za/
  measurements: any[];
  actions: Action[];
  MeasurementsPDFLink?: JSX.Element;
  ActionsPDFLink?: JSX.Element;
}

const MeasurementTable = (props: Props) => {
  let measurements = props.measurements.reverse()
  const { actions, eventClickTargetPrefix, MeasurementsPDFLink, ActionsPDFLink } = props
  return (
    <>
      {actions.length === 0 ? (
        <div className="is-flex flex-on-baseline">
          <h5 className="title is-5" style={{ marginRight: 10 }}>
            Keine Aktionen in diesem Zeitraum
          </h5>
        </div>
      ) : (
        <>
          <div className="is-flex flex-on-baseline flex-wrap">
            <h5 className="title is-5" style={{ marginRight: 10 }}>
              Aktionen
            </h5>{' '}
            {ActionsPDFLink}
          </div>

          <div className="table-container">
            <table className="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>Zeit der Aktion</th>
                  <th>Aktionstyp</th>
                  <th>Verantwortliche Person</th>
                  <th>Instruktion</th>
                  <th>Fertig?</th>
                </tr>
              </thead>
              <tbody>
                {(!actions || actions.length === 0) && (
                  <tr key="no-actions">
                    <th>Keine Aktionen</th>
                  </tr>
                )}
                {actions.map((action: Action) => {
                  const date = action._id
                    .split(':')
                    .slice(1)
                    .join(':')
                  const url = `${eventClickTargetPrefix || '/'}aktion/${action._id.substr(12)}`
                  return (
                    <tr key={`action-${action.actionType}-${date}`} data-href={url}>
                      <td className="is-family-monospace">
                        <Link to={url}>{format(new Date(date), dateAndTimeFormat)}</Link>
                      </td>
                      <td>
                        <Link to={url}>{actionTypeLabels[action.actionType]}</Link>
                      </td>
                      <td>
                        <Link to={url}>{action.assigneeName || action.responsible}</Link>
                      </td>
                      <td>
                        <Link to={url}>{action.instruction}</Link>
                      </td>
                      <td>
                        <Link to={url}>{getIconForActionStatus(action.done)}</Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
      <hr />
      {measurements.length === 0 ? (
        <div className="is-flex flex-on-baseline">
          <h5 style={{ marginRight: 10 }} className="title is-5">
            Keine Messungen in diesem Zeitraum
          </h5>
          {MeasurementsPDFLink}
        </div>
      ) : (
        <>
          <div className="is-flex flex-on-baseline flex-wrap">
            <h5 style={{ marginRight: 10 }} className="title is-5">
              Messungen
            </h5>
            {MeasurementsPDFLink}
          </div>

          <div className="table-container">
            <table className="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>Zeit der Messung</th>
                  <th className="hide-on-mobile">Material</th>
                  <th className="hide-on-mobile">Operation</th>
                  {metricsToShow.map((metricId: string) => (
                    <th key={`${metricId}-table-header`}>{getMetricMetadata(metricId).name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {(!measurements || measurements.length === 0) && (
                  <tr key="no-measurements">
                    <th>Keine Messungen</th>
                  </tr>
                )}
                {measurements.map((measurement: any) => {
                  const firstNotEmpty = measurement.find((m: any) => m)
                  const material = getLabelForId(firstNotEmpty.material)
                  const operation = getLabelForId(firstNotEmpty.operation)
                  return (
                    <tr key={`metric-${measurement.metricId}-${firstNotEmpty.measuredAt}`}>
                      <td className="is-family-monospace">
                        {format(new Date(firstNotEmpty.measuredAt), dateAndTimeFormat)}
                      </td>
                      <td className="hide-on-mobile">{material}</td>
                      <td className="hide-on-mobile">{operation}</td>
                      {metricsToShow.map((metricId: string) => {
                        const meta = getMetricMetadata(metricId)
                        const metric = measurement.find(
                          (individualMeasurement: any) =>
                            individualMeasurement && individualMeasurement.metricId === metricId
                        )
                        if (metric && typeof metric.value !== 'undefined') {
                          return (
                            <td key={`metric-${metricId}-${firstNotEmpty.measuredAt}`}>
                              {metric && metric.value}
                              {meta.unit}
                            </td>
                          )
                        } else {
                          return <td key={`metric-${metricId}-${firstNotEmpty.measuredAt}`}> — </td>
                        }
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  )
}

export default MeasurementTable

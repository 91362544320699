export const getRemote = (name: string): string => {
  let unslashHref = window.location.origin
  if (unslashHref.endsWith('/')) {
    unslashHref = unslashHref.substr(0, unslashHref.length - 1)
  }
  const endpoint = process.env.REACT_APP_COUCHDB_ENDPOINT
  const remote = `${unslashHref}${endpoint}/${name}`
  return remote
}

export const subtractMonth = (yearAndMonth: string): string => {
  const [year, month] = yearAndMonth.split('-')
  let newMonth = parseInt(month, 10) - 1
  let newYear = parseInt(year, 10)
  if (newMonth < 1) {
    newYear = parseInt(year, 10) - 1
    newMonth = 12
  }
  const parts = [newYear.toString()]
  if (newMonth.toString().length === 1) {
    parts.push(`0${newMonth.toString()}`)
  } else {
    parts.push(newMonth.toString())
  }
  return parts.join('-')
}

export const getMonthDBNamesForCustomer = (customerId: string | undefined, monthCount: number = 3): string[] => {
  if (!customerId) return []
  if (customerId === 'nh') return []
  if (customerId === 'oel') return []
  const now = new Date().toISOString()
  const currentMonthDBName = now.substr(0, 7)
  let results = [currentMonthDBName]
  for (let index = 1; index < monthCount; index++) {
    results.push(subtractMonth(results[index - 1]))
  }
  return results.map((date: string) => `${customerId}-${date}`)
}

export const localDbExists = (dbName: string): boolean => {
  let exists = false
  for (let item in localStorage) {
    if (item.includes(dbName)) {
      exists = true
    }
  }
  return exists
}

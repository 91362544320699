import { useState, useEffect } from 'react'
import PouchDB from 'pouchdb'

import { Machine } from '../components/machine-inventory'
import { groupBy } from 'lodash'

/*

Gives access to all importable machines AND their measurementPoints

*/

interface AllDocsResult {
  doc?: PouchDB.Core.ExistingDocument<any & PouchDB.Core.AllDocsMeta> | undefined;
  id: string;
  key: string;
  value: {
    rev: string;
    deleted?: boolean | undefined;
  };
}

const useImportableMachines = (customerId: string | undefined, machineId: string | undefined) => {
  const [machines, setMachines] = useState<AllDocsResult[]>()
  const [measurementPoints, setMeasurementPoints] = useState<AllDocsResult[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [hasLoaded, setHasLoaded] = useState(false)
  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      const customerDB = new PouchDB(`${customerId}-machine-import`, { skip_setup: true })
      const machinesResult: PouchDB.Core.AllDocsResponse<Machine[]> = await customerDB.allDocs({ include_docs: true })
      const { machines, measurementPoints } = groupBy(machinesResult.rows, (row: any): string => {
        return row.id.includes(':mp') ? 'measurementPoints' : 'machines'
      })
      if (!didCancel) {
        setMachines(machines)
        setMeasurementPoints(measurementPoints)
        setIsLoading(false)
        setHasLoaded(true)
      }
    }
    if (customerId && machineId) {
      const skipTheseNames = ['nh', 'oel']
      if (!skipTheseNames.includes(customerId)) {
        fetchData()
      } else {
        setMachines([])
        setMeasurementPoints([])
        setIsLoading(false)
        setHasLoaded(true)
      }
    }
    // Clean up on unmount
    return () => {
      didCancel = true
    }
  }, [customerId, machineId])
  return { isLoading, hasLoaded, machines, measurementPoints }
}
export default useImportableMachines

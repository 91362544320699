import React from 'react'
import { Document, Page, Text, StyleSheet, View, Image } from '@react-pdf/renderer'
import { dateAndTimeFormat, shouldNotSeeOSBrand } from '../utils/general'
import { format } from 'date-fns'
import qr from 'qr-image'

import PDFMachineInfoBlock from './pdf-machine-info'

import logo from '../assets/logo-small.png'
import { MachineDoc } from './machine-inventory'

interface Props {
  machine: MachineDoc;
  actionTimestamp: string;
  actionType: string;
  responsible: string;
  instruction: string;
  done: boolean;
}

const styles = StyleSheet.create({
  page: { padding: 25 },
  logo: {
    height: '8mm',
    width: 'auto',
    marginRight: '5mm'
  },
  textRow: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  header: {
    fontSize: 24
  },
  label: {
    fontSize: 12,
    color: 'grey',
    textTransform: 'uppercase'
  },
  field: {
    fontSize: 16,
    marginBottom: '4mm'
  },
  divider: {
    width: '100%',
    height: '1pt',
    backgroundColor: '#DDD',
    marginBottom: '2mm',
    marginTop: '2mm'
  },
  emptyLine: {
    width: '80mm',
    height: '1pt',
    backgroundColor: '#DDD',
    marginBottom: '2mm',
    marginTop: '12mm',
    marginRight: '4mm'
  }
})

const Divider = () => <View style={styles.divider} />
const EmptyLine = () => <View style={styles.emptyLine} />

const OneActionPDF: React.FC<Props> = ({ machine, actionTimestamp, actionType, responsible, instruction, done }) => {
  const hiddenForBrandingReasons = shouldNotSeeOSBrand()

  const code = qr.imageSync(`${window.location.origin}/maschine/${machine._id.substr(3)}/aktion/${actionTimestamp}`, {
    type: 'png',
    ec_level: 'L'
  })

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.textRow}>
          {!hiddenForBrandingReasons && (
            <Image src={logo} style={styles.logo} />
          )}
          <Text style={styles.header}>Arbeitsauftrag</Text>
        </View>
        <Divider />
        <PDFMachineInfoBlock machine={machine} code={code} />
        <Text style={styles.label}>Datum:</Text>
        <Text style={styles.field}>{format(new Date(), dateAndTimeFormat)}</Text>
        <Text style={styles.label}>Aktionstyp:</Text>
        <Text style={styles.field}>{actionType}</Text>
        <Text style={styles.label}>Beauftragt:</Text>
        <Text style={styles.field}>{responsible}</Text>
        <Text style={styles.label}>Arbeitsanweisung:</Text>
        <Text style={styles.field}>{instruction}</Text>
        {done ? (
          <>
            <Text style={styles.label}>Arbeit bereits erledigt?</Text>
            <Text style={styles.field}>Ja</Text>
          </>
        ) : (
          <>
            <View style={styles.textRow}>
              <View>
                <Text style={styles.label}>Erledigt am:</Text>
                <EmptyLine />
              </View>
              <View>
                <Text style={styles.label}>Erledigt von:</Text>
                <EmptyLine />
              </View>
            </View>
          </>
        )}
      </Page>
    </Document>
  )
}

export default OneActionPDF

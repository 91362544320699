import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { QRCode } from 'react-qr-svg'

import { formatReadableId } from '../utils/general'

interface Props {
  id: string;
  clickable?: boolean;
  hideQRCode?: boolean;
  format?: boolean;
}

interface State {
  modalVisible: boolean;
}

export class MachineId extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      modalVisible: false
    }
    this.toggleModal = this.toggleModal.bind(this)
  }
  private toggleModal(): void {
    this.setState({ modalVisible: !this.state.modalVisible })
  }
  render(): React.ReactNode {
    const { id, clickable = false, hideQRCode = false, format = true } = this.props
    const readableId = format === false ? id : formatReadableId(id)
    const modalClasses = classNames({
      'is-active': this.state.modalVisible,
      modal: true
    })
    if (!clickable) {
      return (
        <span className="machine-id">
          {!hideQRCode && <FontAwesomeIcon icon="qrcode" size="sm" />} {readableId}
        </span>
      )
    }
    const idForQRCode = id.indexOf('ma_') === 0 ? id : `ma_${id}`
    return (
      <>
        <span className="machine-id" onClick={this.toggleModal}>
          {!hideQRCode && <FontAwesomeIcon icon="qrcode" size="sm" />} {readableId}
        </span>
        <div className={modalClasses}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">
                QR-Code für <span className="machine-id">{readableId}</span>
              </p>
            </header>
            <section className="modal-card-body">
              <QRCode bgColor="#FFFFFF" fgColor="#000000" level="L" value={idForQRCode} />
            </section>
            <footer className="modal-card-foot">
              <button className="button" onClick={this.toggleModal}>
                Schließen
              </button>
            </footer>
          </div>
        </div>
      </>
    )
  }
}

export default MachineId

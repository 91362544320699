import { ResellerStats } from '../hooks/useCustomers'

export interface User {
  id?: string;
  firstName?: string;
  lastName?: string;
  customer?: Customer;
  name?: string;
  email?: string;
  status?: string;
  active?: boolean;
  roles?: string[];
  notifications?: NotificationSettings;
  faves?: Favourites;
  confirmed?: boolean;
  resellee?: boolean;
  belongsTo?: string;
}

export interface Customer {
  _id?: string;
  name?: string;
  state?: string;
  stripe_id?: string;
  stripe_plan?: string;
  reseller?: boolean;
  resellerStats?: ResellerStats;
}

export interface Favourites {
  [customerID: string]: string[];
}

export interface NotificationSettings {
  [customerID: string]: {
    triggers: {
      [triggerID: string]: NotificationTrigger;
    };
    machineFilter?: string;
  };
}

export interface NotificationTrigger {
  active?: boolean;
  subOptions?: any;
}

export interface Person extends User {
  userId?: string;
  isAppUser?: boolean;
}

export interface Role {
  id: string;
  name: string;
  privileges: Privileges[];
}

// These privileges are assigned to roles in utils/user-roles.tsx
export enum Privileges {
  CAN_SWITCH_CUSTOMERS = 'canSwitchCustomers',
  CAN_MANAGE_USERS = 'canManageUsers',
  CAN_ACCESS_ANALYTICS = 'canAccessAnalytics',
  CAN_ENTER_MEASUREMENTS = 'canEnterMeasurements',
  CAN_ENTER_ACTIONS = 'canEnterActions',
  CAN_MANAGE_MACHINES = 'canManageMachines',
  CAN_MANAGE_BILLING = "canManageBilling",
  CAN_ASSIGN_CUSTOMERS = 'canAssignCustomers',
}

// Defines that the key in `privilegeDescriptions` must be in the enum `Privileges`,
// see https://stackoverflow.com/a/59213781
export type PrivilegeDescriptions = Record<Privileges, string>

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'

type HeaderButtonProps = {
  id: string;
  label: string;
  sortDirection: string;
  onRequestSort: () => void;
}

export const SortableHeaderButton = ({ id, label, sortDirection, onRequestSort }: HeaderButtonProps) => {
  const icon = sortDirection ? (sortDirection === 'ascending' ? 'sort-down' : 'sort-up') : 'sort'
  const classes = classNames({
    icon: true,
    'has-text-grey-light': !sortDirection
  })

  return (
    <button className="button" type="button" onClick={onRequestSort}>
      <span>{label}</span>
      <span className={classes}>
        <FontAwesomeIcon icon={icon} size="sm" />
      </span>
    </button>
  )
}

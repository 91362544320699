/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react'
import { Machine } from '../machine-inventory'
import { WaffleBoxProps } from './waffle-box'
import { groupBy, flatten, sortBy } from 'lodash'
import MachineId from '../machine-id'
import Waffle from './waffle'
import { format } from 'date-fns'
import { dateFormat } from '../../utils/general'
import { getIconForActionStatus } from '../machine-protocol'
import { Action } from '../machine-action'

const colors = {
  none: '#BBB',
  open: '#fabb2f',
  done: '#72c983'
}

interface ActionsForMachine {
  // ma_12345678: [Measurement, Measurement, …]
  [key: string]: Action[];
}

interface Props {
  actionsByMachine: ActionsForMachine[];
  machines: Machine[];
}

const ActionWaffle: React.FC<Props> = props => {
  const { actionsByMachine, machines } = props
  const waffleData: (WaffleBoxProps | null)[] = actionsByMachine.map(machineDataBundle => {
    let color: string = 'grey'
    const machineId = Object.keys(machineDataBundle)[0]
    const machineIdFragment = machineId.substring(3, 11)
    const machineData: Machine | undefined = machines ? machines.find(machine => machine.id === machineId) : undefined
    if (!machineData) return null
    let { done, open } = groupBy((machineDataBundle[machineId] as unknown) as Action[], (action: Action) =>
      action.done ? 'done' : 'open'
    )
    done = flatten(done)
    open = flatten(open)
    if (done && done.length >= 1) {
      color = 'green'
    }
    if (open && open.length >= 1) {
      color = 'orange'
    }
    const allRecentActions = sortBy([...done, ...open], action => {
      return action._id.substr(12)
    }).reverse()
    const tooltipElement: React.ReactElement = (
      <>
        <ul>
          <li>
            <strong>
              {machineData.doc.name} <MachineId id={machineIdFragment} />
            </strong>
          </li>
          <hr className="mini" />
          {machineData.doc.inventoryNumber && (
            <li>
              <span>
                <strong>Inventarnummer:</strong> {machineData.doc.inventoryNumber}
              </span>
            </li>
          )}
          {machineData.doc.location && (
            <li>
              <span>
                <strong>Standort:</strong> {machineData.doc.location}
              </span>
            </li>
          )}
          <hr className="mini" />
          {allRecentActions &&
            allRecentActions.map((action, index) => {
              const dateString = format(new Date(action._id.substr(12)), dateFormat)
              return (
                <li key={`action-${index}`}>
                  {getIconForActionStatus(action.done)}
                  <span className="date">{dateString}:</span> {action.instruction}
                </li>
              )
            })}
          {(!allRecentActions || allRecentActions.length === 0) && (
            <li key="no-actions">Keine Aktionen oder Arbeitsaufträge</li>
          )}
        </ul>
      </>
    )
    return {
      color,
      clickTarget: `/maschine/${machineIdFragment}/protokoll`,
      tooltipElement
    }
  })
  return (
    <div className="chart">
      <div className="legends">
        <ul>
          <li key="label-no-actions" style={{ color: colors.none }}>
            <span>Keine Arbeitsaufträge</span>
          </li>
          <li key="label-open-actions" style={{ color: colors.open }}>
            <span>Mit offenen Arbeitsaufträgen</span>
          </li>
          <li key="label-done-actions" style={{ color: colors.done }}>
            <span>Keine offenen Arbeitsaufträge</span>
          </li>
        </ul>
      </div>
      <Waffle data={waffleData} />
    </div>
  )
}

export default ActionWaffle

import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import * as Yup from 'yup'
import { isEmpty, get } from 'lodash'
import { Formik, Form, FormikHelpers, FormikProps } from 'formik'
import { Link } from 'react-router-dom'

import FormItem from '@luxx/forms'
import { defaultLogin, LoginCredentials } from '@luxx/utils/auth'
import { getCustomerIdFromCouchDBRoles } from '../utils/auth'
import AppContext from '../AppContext'
import { UserRoles } from '../utils/user-roles'
import { Privileges } from '../types/users-roles-privileges'

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Das Passwort muss mindestens sechs Zeichen lang sein')
    .required('Pflichtfeld'),
  email: Yup.string().required('Pflichtfeld')
})

interface FormProps extends LoginCredentials {
  general?: string;
}

class Login extends Component<RouteComponentProps> {
  public static contextType = AppContext
  public constructor(props: RouteComponentProps) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
  }
  private async onSubmit(values: LoginCredentials, actions: FormikHelpers<LoginCredentials>): Promise<void> {
    actions.setSubmitting(true)
    const postLoginTarget = get(this.props, 'location.state.from.pathname')
    const { REACT_APP_COUCHDB_ENDPOINT } = process.env
    const { onLogout } = this.context
    await fetch(REACT_APP_COUCHDB_ENDPOINT + '/_session', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json'
      },
      body: JSON.stringify({
        name: values.email,
        password: values.password
      })
    })
      .then(
        async (res): Promise<void> => {
          const data = await res.json()
          if (!data.ok) {
            actions.setFieldError('general', 'Name oder Passwort ungültig.')
            actions.setSubmitting(false)
            return
          }
          actions.setSubmitting(false)
          // encodeURIComponent because emails may have a `+` but couch doesn’t accept that in URLs
          const user = await fetch(
            `${REACT_APP_COUCHDB_ENDPOINT}/_users/org.couchdb.user%3A${encodeURIComponent(data.name)}`,
            {
              method: 'get',
              credentials: 'include'
            }
          )
          const userData = await user.json()
          if (userData.oelUser.active !== undefined && !userData.oelUser.active) {
            onLogout()
            actions.setFieldError(
              'general',
              'Ihr Zugang ist zurzeit deaktiviert. Bei Fragen kontaktieren Sie Ihren Administrator.'
            )
            return
          }
          const relevantUserData = {
            id: userData._id,
            name: userData.name,
            email: userData.oelUser.email,
            firstName: userData.oelUser.firstName,
            lastName: userData.oelUser.lastName,
            active: userData.oelUser.active,
            roles: userData.roles,
            faves: userData.oelUser.faves,
            notifications: userData.oelUser.notifications
          }
          const customerId = getCustomerIdFromCouchDBRoles(data.roles)
          if (customerId) {
            this.context.onLogin(customerId, relevantUserData, postLoginTarget)
          } else {
            const userRoles = new UserRoles(data.roles)
            if (userRoles.hasPrivilege(Privileges.CAN_SWITCH_CUSTOMERS)) {
              this.context.onLogin(undefined, relevantUserData, postLoginTarget)
            } else {
              console.warn('User has no roles that match a customer ID')
              actions.setFieldError('general', 'Nutzer hat keine ausreichenden Berechtigungen.')
              actions.setSubmitting(false)
            }
          }
        }
      )
      .catch((error): void => {
        console.error('session error', error)
        actions.setFieldError('general', 'Allgemeiner Fehler bei der Anmeldung.')
        actions.setSubmitting(false)
      })
  }
  public render(): React.ReactNode {
    const initialValues: LoginCredentials = defaultLogin
    return (
      <Formik
        validationSchema={LoginSchema}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        enableReinitialize={true}
      >
        {({ errors, isSubmitting }: FormikProps<FormProps>): React.ReactNode => {
          const hasErrors = !isEmpty(errors)
          const isNotSubmittable = hasErrors || isSubmitting ? true : false
          return (
            <Form>
              <h1 className="title">Anmelden</h1>
              <FormItem type="text" label="Email-Adresse" name="email" placeholder="Email-Adresse" />
              <FormItem type="password" label="Passwort" name="password" placeholder="Your passwort" />
              {errors.general && <div className="notification is-danger">{errors.general}</div>}
              <div className="mb-3">
                <Link to="/resetpassword" className="has-text-primary">
                  Passwort vergessen?
                </Link>
              </div>
              <button type="submit" disabled={isNotSubmittable} className="button is-primary">
                <span>Anmelden</span>
              </button>
            </Form>
          )
        }}
      </Formik>
    )
  }
}

export default Login

import React, { Component } from 'react'
import * as Yup from 'yup'
import { Formik, Form, FormikHelpers, FormikProps } from 'formik'
import { withAlert, types } from 'react-alert'

import FormItem from '@luxx/forms'
import { isEmpty } from 'lodash'
import { Redirect } from 'react-router-dom'

// https://github.com/jaredpalmer/formik/issues/90#issuecomment-582391396
const PasswordSchema = Yup.object({
  password: Yup.string()
    .required('Bitte geben Sie ein neues Passwort für Ihr Benutzerkonto ein')
    .min(8, 'Ihr Passwort muss mindestens 8 Zeichen lang sein')
    .max(128, 'Das ist jetzt doch ein Bißchen zu viel…'),
  confirmPassword: Yup.string().when('password', {
    is: val => val && val.length > 0,
    then: Yup.string()
      .oneOf([Yup.ref('password')], 'Beide Passwörter müssen identisch sein')
      .required('Bitte geben Sie das Passwort ein zweites Mal ein')
  })
})

interface MatchParams {
  token?: string
}

interface NewPasswords {
  password?: string
  confirmPassword?: string
}

interface FormProps extends NewPasswords {
  general?: string
}

interface Props extends FormProps {
  alert: any
  title: string
  token: string
  isReset?: boolean
}

interface State {
  goToLogin: boolean
}

class SetPassword extends Component<Props, State> {
  public constructor(props: Props) {
    super(props)
    this.state = {
      goToLogin: false
    }
    this.onSubmit = this.onSubmit.bind(this)
  }
  private async onSubmit(values: FormProps, actions: FormikHelpers<FormProps>): Promise<void> {
    const { REACT_APP_COUCHDB_ENDPOINT } = process.env
    const { alert, token } = this.props
    actions.setSubmitting(true)
    await fetch(REACT_APP_COUCHDB_ENDPOINT + `/_backend/auth/validate`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json'
      },
      body: JSON.stringify({
        token,
        password: values.password,
        isReset: this.props.isReset || false
      })
    })
      .then(
        async (res): Promise<void> => {
          const data = await res.json()
          if (res.status === 200) {
            alert.show(data.message, {
              type: types.SUCCESS,
              timeout: 10000
            })
            this.setState({ goToLogin: true })
            actions.resetForm()
          } else {
            alert.show(data.message, {
              type: types.ERROR
            })
            actions.setSubmitting(false)
          }
        }
      )
      .catch((error): void => {
        alert.show(`Fehler: ${error}`, {
          type: types.ERROR
        })
        actions.setSubmitting(false)
      })
  }
  public render(): React.ReactNode {
    const initialValues: FormProps = {
      password: '',
      confirmPassword: ''
    }
    if (this.state.goToLogin) {
      return <Redirect to="/login" />
    }
    return (
      <Formik
        validationSchema={PasswordSchema}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        enableReinitialize={true}
      >
        {({ errors, isSubmitting }: FormikProps<FormProps>): React.ReactNode => {
          const hasErrors = !isEmpty(errors)
          const isNotSubmittable = hasErrors || isSubmitting ? true : false
          return (
            <Form>
              <h1 className="title">{this.props.title}</h1>
              <p>Bitte wählen Sie ein Passwort für Ihr Benutzerkonto.</p>
              <FormItem type="password" label="Passwort" name="password" placeholder="Ihr Passwort" />
              <FormItem
                type="password"
                label="Passwort bestätigen"
                name="confirmPassword"
                placeholder="Nochmal dasselbe Passwort"
              />
              {errors.general && <div className="errorMessage general">{errors.general}</div>}
              <button type="submit" disabled={isNotSubmittable} className="button is-primary">
                <span>Passwort festlegen</span>
              </button>
            </Form>
          )
        }}
      </Formik>
    )
  }
}

export default withAlert<Props>()(SetPassword)

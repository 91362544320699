import React, { Component } from 'react'
import { RouteComponentProps, Redirect } from 'react-router'
import { Database, Aware } from '../react-pouchdb'
import PouchDB from 'pouchdb'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { random, groupBy } from 'lodash'

import DefaultMetrics, { MetricMeta } from '../utils/default-metrics'
import { generateId } from '../utils/general'
import { getRemote } from '../utils/network'
import { generateSeries } from '../stories/helpers'
import classNames from 'classnames'
import AppContext from '../AppContext'

const oneOf = (array: Array<any>): any => {
  return array[random(0, array.length - 1)]
}

const operationValues = ['drehen', 'bohren', 'fraesen', 'schleifen', 'tlbohren', 'raeumen']

const materialValues = [
  'alu',
  'messing',
  'kupfer',
  'stahl',
  'edelstahl',
  'hochstahl',
  'werkstahl',
  'mag',
  'guss',
  'titan',
  'kunst',
  'hart'
]

const machineTypes = ['zerspanungsanlage', 'umformmaschine', 'zentralschmieranlage', 'hydraulisch']

const possibleConcentrationThresholds = [
  {
    min: 20,
    should: 30,
    max: 40
  },
  {
    min: 35,
    should: 45,
    max: 55
  },
  {
    min: 10,
    should: 15,
    max: 20
  }
]

const possiblePhThresholds = [
  {
    min: 7,
    should: 8,
    max: 9
  },
  {
    min: 8,
    should: 9,
    max: 10
  },
  {
    min: 5,
    should: 7,
    max: 9
  }
]

const possibleNitritMaxValues = [20, 25, 30, 50]

const defaultMeasurementPoint = {
  name: 'main',
  material: 'alu', // overidden below
  operation: 'drehen', // overidden below
  fluid: {
    name: 'placeholder',
    id: 0,
    rfm: 0
  }
}

const generateMeasurementPoint = (machineId: string, now: string) => {
  const measurementPointId = `${machineId}:${generateId(8, 'mp')}`
  const operation = oneOf(operationValues)
  const material = oneOf(materialValues)
  const pHThresholds = oneOf(possiblePhThresholds)
  const concentrationThresholds = oneOf(possibleConcentrationThresholds)
  const metrics: MetricMeta[] = DefaultMetrics
  metrics[0].min = pHThresholds.min
  metrics[0].should = pHThresholds.should
  metrics[0].max = pHThresholds.max
  metrics[1].min = concentrationThresholds.min
  metrics[1].should = concentrationThresholds.should
  metrics[1].max = concentrationThresholds.max
  metrics[2].max = oneOf(possibleNitritMaxValues)
  return {
    ...defaultMeasurementPoint,
    _id: measurementPointId,
    metrics,
    operation,
    material,
    createdAt: now,
    previous: {
      name: '',
      material: '',
      operation: '',
      fluid: {
        name: '',
        id: 0,
        rfm: 0
      },
      metrics: DefaultMetrics
    }
  }
}

interface ComponentProps extends RouteComponentProps {
  db?: PouchDB.Database;
}

interface ComponentState {
  isLoading: boolean;
  codes: string[];
}

const debugMode = false

class DevComponent extends Component<ComponentProps, ComponentState> {
  public constructor(props: ComponentProps) {
    super(props)
    this.state = {
      isLoading: false,
      codes: []
    }
    this.addMachines = this.addMachines.bind(this)
    this.generateCodes = this.generateCodes.bind(this)
  }
  private generateMachineName(): string {
    const operations: string[] = ['Bohr', 'Schleif', 'Fräs', 'Säg', 'Stampf', 'Form', 'Press', 'Zerspan']
    const thing: string[] = ['-o-Matik', '-o-tron', 'Bot', 'Master', '-o-Lux', 'Bär', 'Dachs', 'Fix']
    const num: string[] = [' 2000', ' 3000', ' 4100', ' Mark III', ' Mark IV', ' 18', ' 100', ' 12']
    const suffix: string[] = ['XL', ' Super', ' Premium', ' Deluxe', ' Performance', ' Turbo']
    let parts: string[] = [oneOf(operations), oneOf(thing), oneOf(num), oneOf(suffix)]
    return parts.join('')
  }
  private addAmount = 10
  private async addMachines(): Promise<void> {
    if (debugMode) {
      console.warn('⚠️ Generating machines in debug mode, this does not save any machines to the DB.')
    }
    const { db } = this.props
    if (!db) {
      return
    }
    this.setState({ isLoading: true })
    let bulkDocs = []
    let bulkMeasurements: any[] = []
    let today = new Date().toJSON()
    for (let index = 0; index < this.addAmount; index++) {
      const now = new Date().toJSON()
      // Generate a machine
      const machineId = generateId(8, 'ma')
      const newMachine = {
        _id: machineId,
        name: this.generateMachineName(),
        type: oneOf(machineTypes),
        inventoryNumber: Math.round(Math.random() * 1e10),
        createdAt: now
      }
      bulkDocs.push(newMachine)
      // Generate a single measurement point for that machine
      const measurementPoint = generateMeasurementPoint(machineId, now)
      bulkDocs.push(measurementPoint)
      // Generate 2 months of concentration measurements
      const concentrationSeries = generateSeries({
        startItem: { x: '2019-12-01', y: 30 },
        amount: 2,
        minDayStep: 20,
        maxDayStep: 40
      })
      const { _id: measurementPointId, operation, material, metrics } = measurementPoint
      const concentrationThresholds = metrics.find(metric => metric.id === 'konzentration') as MetricMeta
      const pHThresholds = metrics.find(metric => metric.id === 'ph') as MetricMeta
      const nitritThresholds = metrics.find(metric => metric.id === 'nitrit') as MetricMeta
      concentrationSeries.forEach((datum: any): any => {
        const measurementDate = new Date(datum.x).toJSON()
        if (measurementDate < today) {
          const newMeasurement = {
            _id: `${measurementPointId}:konzentration:${measurementDate}`,
            createdAt: measurementDate,
            measuredAt: measurementDate,
            type: 'measurement',
            metricId: 'konzentration',
            min: concentrationThresholds.min,
            should: concentrationThresholds.should,
            max: concentrationThresholds.max,
            value: datum.y,
            operation,
            material
          }
          bulkMeasurements.push(newMeasurement)
        }
      })
      // Generate 2 months of ph measurements
      const pHSeries = generateSeries({
        startItem: { x: '2019-12-01', y: 8 },
        amount: 8,
        minDayStep: 7,
        maxDayStep: 7
      })
      pHSeries.forEach((datum: any): any => {
        const measurementDate = new Date(datum.x).toJSON()
        if (measurementDate < today) {
          const newMeasurement = {
            _id: `${measurementPointId}:ph:${measurementDate}`,
            createdAt: measurementDate,
            measuredAt: measurementDate,
            type: 'measurement',
            metricId: 'ph',
            min: pHThresholds.min,
            should: pHThresholds.should,
            max: pHThresholds.max,
            value: datum.y,
            operation,
            material
          }
          bulkMeasurements.push(newMeasurement)
        }
      })
      // Generate 2 months of nitrit measurements
      const nitritSeries = generateSeries({
        startItem: { x: '2019-12-01', y: 10 },
        amount: 2,
        minDayStep: 20,
        maxDayStep: 40
      })
      nitritSeries.forEach((datum: any): any => {
        const measurementDate = new Date(datum.x).toJSON()
        if (measurementDate < today) {
          const newMeasurement = {
            _id: `${measurementPointId}:nitrit:${measurementDate}`,
            createdAt: measurementDate,
            measuredAt: measurementDate,
            type: 'measurement',
            metricId: 'nitrit',
            min: 0,
            should: 0,
            max: nitritThresholds.max,
            value: datum.y,
            operation,
            material
          }
          bulkMeasurements.push(newMeasurement)
        }
      })
    }
    // Store machines and measurement points
    if (debugMode) {
      console.log('Generated machines and measurement points: ', bulkDocs)
    } else {
      await db.bulkDocs(bulkDocs)
    }
    // Group measurements, store in respective DBs and one-time sync those to remote
    const groupedBulkMeasurements = groupBy(bulkMeasurements, (measurement: any) => {
      return measurement.measuredAt.substring(0, 7)
    })
    const customerId = db.name
    const remoteBase = getRemote(customerId)
    for (var yearMonth in groupedBulkMeasurements) {
      const monthDB = new PouchDB(`${customerId}-${yearMonth}`)
      const monthDBRemote = `${remoteBase}-${yearMonth}`
      const monthBulkDocs = groupedBulkMeasurements[yearMonth]
      if (debugMode) {
        console.log('Generated measurements for: ', yearMonth, monthBulkDocs)
      } else {
        await monthDB.bulkDocs(monthBulkDocs)
        try {
          await monthDB.replicate.to(monthDBRemote)
        } catch (err) {
          console.log('error replicating test measurements to', monthDBRemote, err)
        }
      }
    }
    this.setState({ isLoading: false })
  }
  private generateCodes(): void {
    let codes = []
    for (let index = 0; index < 100; index++) {
      codes.push(generateId(8, 'ma'))
    }
    this.setState({ codes })
  }
  public render(): React.ReactNode {
    const { isLoading } = this.state
    const classes = classNames({
      button: true,
      'is-primary': true,
      'is-loading': isLoading
    })
    return (
      <>
        <section className="section">
          <h1 className="title">Dev Tools</h1>
          <div className="buttons">
            <button className={classes} onClick={this.generateCodes}>
              <span className="icon">
                <FontAwesomeIcon icon="plus-circle" size="sm" />
              </span>
              <span>100 Maschinen-Codes erzeugen</span>
            </button>
            <button className={classes} onClick={this.addMachines}>
              <span className="icon">
                <FontAwesomeIcon icon="plus-circle" size="sm" />
              </span>
              <span>{this.addAmount} Maschinen anlegen</span>
            </button>
          </div>
        </section>
        <section className="section">
          <ul>
            {this.state.codes.map(
              (code: string): React.ReactElement => (
                <li key={code}>{code}</li>
              )
            )}
          </ul>
        </section>
      </>
    )
  }
}

class Dev extends Component<RouteComponentProps, {}> {
  public static contextType = AppContext
  public render(): React.ReactNode {
    const { currentCustomer } = this.context
    const { _id: customerId } = currentCustomer
    if (!customerId) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: this.props.location }
          }}
        />
      )
    }
    const remote = `${process.env.REACT_APP_COUCHDB_ENDPOINT}/${customerId}`
    return (
      <Database database={customerId} remote={remote} onError={this.context.onPouchError}>
        <Aware>
          <DevComponent {...this.props} />
        </Aware>
      </Database>
    )
  }
}

export default Dev

import React, { useContext, useState, useEffect } from 'react'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import * as Yup from 'yup'
import PouchDB from 'pouchdb'
import { format } from 'date-fns'
import { de } from 'date-fns/locale'
import { groupBy, get, isEmpty } from 'lodash'
import { saveAs } from 'file-saver'

import { MachineDoc, MachineState } from './machine-inventory'
import AppContext from '../AppContext'
import useAction from '../hooks/useAction'
import FormItem from '@luxx/forms'
import { FormContainer, FormValues } from '@luxx/forms'
import { Person, Privileges } from '../types/users-roles-privileges'
import usePersons from '../hooks/usePersons'
import { TagOption, TagOptionGroup } from '@luxx/types/forms'
import OneActionPDF from './one-action-pdf'
import { GenericLoadingSpinner } from './partials'
import { pdf } from '@react-pdf/renderer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormikContext, FormikProps, Form, Formik, FormikHelpers } from 'formik'
import { UserRoles } from '../utils/user-roles'

const ValidationSchema = Yup.object({
  type: Yup.string()
    .matches(/action/)
    .required(),
  actionType: Yup.string()
    .oneOf([
      'arbeit',
      'kunde',
      'labor',
      'reinigung',
      'emwechsel',
      'sofort',
      'sofort-additiv',
      'sofort-konz-an',
      'sofort-konz-ab'
    ])
    .required(),
  responsible: Yup.string().required('Bitte eine Person beauftragen'),
  instruction: Yup.string().required('Dieses Feld darf nicht leer sein'),
  done: Yup.bool().required()
})


const MailtoValidation = Yup.object({
  mailto: Yup.string()
    .email('Keine gültige E-Mail-Adresse')
})

enum EventType {
  ACTION = 'action',
  EVENT = 'event'
}

enum ActionType {
  ARBEIT = 'arbeit',
  KUNDE = 'kunde',
  LABOR = 'labor',
  REINIGUNG = 'reinigung',
  EMWECHSEL = 'emwechsel',
  SOFORT = 'sofort',
  SOFORT_ADDITIV = 'sofort-additiv',
  SOFORT_KONZ_AN = 'sofort-konz-an',
  SOFORT_KONZ_AB = 'sofort-konz-ab'
}

enum RecipientType {
  SELF = 'self',
  ASSIGNEE = 'assignee',
  OTHER = 'other'
}

type ActionTypeLabel = {
  [key: string]: string;
}

export const actionTypeLabels: ActionTypeLabel = {
  arbeit: 'Arbeitsauftrag',
  kunde: 'Kundendienst',
  labor: 'Laborauftrag',
  reinigung: 'Maschinenreinigung',
  emwechsel: 'Emulsionswechsel',
  sofort: 'Sofortmaßnahme',
  'sofort-additiv': 'Sofortmaßnahme: Additivzugabe',
  'sofort-konz-an': 'Sofortmaßnahme: Konzentration anheben',
  'sofort-konz-ab': 'Sofortmaßnahme: Konzentration reduzieren'
}

export interface Action {
  _id: string;
  type: EventType.ACTION;
  actionType?: ActionType;
  responsible?: string;
  assigneeName?: string;
  instruction?: string;
  createdBy?: string;
  completedAt?: string;
  done: boolean;
}

interface Props {
  machine?: MachineDoc;
}

interface ListenerProps {
  setIsSofort(boolean): void;
  actionId?: string;
}

interface MatchParams {
  actionId?: string;
}

const initialValues: Action = {
  _id: '',
  type: EventType.ACTION,
  actionType: ActionType.ARBEIT,
  responsible: '',
  instruction: '',
  createdBy: '',
  completedAt: undefined,
  done: false
}

const SelectChangeListener: React.FC<ListenerProps> = props => {
  const { values }: FormikProps<Action> = useFormikContext()
  const { currentUser } = useContext(AppContext)
  const { actionId } = props
  useEffect(() => {
    if (!actionId) {
      if (values.actionType.includes('sofort')) {
        props.setIsSofort(true)
        values.done = true
        values.responsible = currentUser.name
      } else {
        props.setIsSofort(false)
      }
    }
  }, [values, props, currentUser, actionId])
  return null
}

export const getPersonById = (persons: Person[], id: string): Person | undefined => {
  return persons.find((person: Person): boolean => {
    return person.userId === id
  })
}

export const personIdToName = (persons: Person[], id: string, withEmail: boolean = true): string => {
  if (!id) return 'unbekannt'
  const foundPerson: Person | undefined = getPersonById(persons, id)
  if (foundPerson) {
    const { firstName, lastName, email } = foundPerson
    return `${firstName}${lastName ? ' ' + lastName : ''}${email && withEmail ? ' (' + email + ')' : ''}`
  }
  return id
}

export const MachineAction: React.FC<Props & RouteComponentProps<MatchParams>> = props => {
  const { actionId = undefined } = props.match.params
  const { currentCustomer, currentUser } = useContext(AppContext)
  const { _id: customerId } = currentCustomer
  const { machine } = props
  let machineId: string | undefined = undefined
  if (machine && machine._id) {
    machineId = machine._id
  }
  const [newActionId, setNewActionId] = useState<string | undefined>(undefined)
  const [sentSelfAsMail, setSentSelfAsMail] = useState(false)
  const [sentAssigneeAsMail, setSentAssigneeAsMail] = useState(false)
  const [isSofort, setIsSofort] = useState(false)
  const { action, hasLoaded } = useAction(customerId, machineId, actionId)
  const { persons, hasLoaded: hasLoadedPersons } = usePersons(customerId)

  const userRoles = UserRoles.fromSession()
  const userCanEnterActions = userRoles.hasPrivilege(Privileges.CAN_ENTER_ACTIONS)

  const personsIncludingSelf = [
    {...currentUser, isAppUser: true, userId: currentUser.email},
    ...persons
  ]
  // Set up person data
  const personsByType = groupBy(personsIncludingSelf, (person: Person) => {
    return person.isAppUser ? 'appUsers' : 'employees'
  })
  const personToOption = (person: Person): TagOption => {
    return {
      label: `${person.firstName}${person.lastName ? ' ' + person.lastName : ''}${
        person.email ? ' (' + person.email + ')' : ''
      }`,
      value: person.userId as string
    }
  }

  const generateActionPDF = () => {
    if (action && machine) {
      return (
        <OneActionPDF
          machine={machine}
          actionTimestamp={action._id.substr(12)}
          responsible={personIdToName(persons, action.responsible as string)}
          actionType={actionTypeLabels[action.actionType as string]}
          instruction={action.instruction as string}
          done={action.done}
        />
      )
    }
  }

  const downloadPDF = async () => {
    if (!action) return
    const document = generateActionPDF()
    if (!document) return
    const blob = await pdf(document).toBlob()
    saveAs(blob, `Arbeitsauftrag-${action._id}.pdf`)
  }

  const emptyUsersOption: TagOption = {
    label: 'Konnte keine Nutzer laden',
    value: '',
    isDisabled: true
  }

  const groupedAssigneeOptions: TagOptionGroup[] = [
    {
      label: 'App-Nutzerinnen',
      options: personsByType.appUsers
        ? personsByType.appUsers.map((person: Person): TagOption => personToOption(person))
        : [emptyUsersOption]
    }
  ]

  if (!hasLoaded || !hasLoadedPersons) {
    return <GenericLoadingSpinner />
  }
  if (actionId && !action) {
    return <span>Aktion konnte nicht gefunden werden (Link falsch?)</span>
  }
  if (!actionId && machineId && newActionId) {
    return <Redirect to={`/maschine/${machineId.substr(3)}/aktion/${newActionId}`} />
  }
  if (!machine) return null
  let assigneeEmail: string | undefined
  if (action) {
    // Check if the assignee can receive emails
    const assignee = getPersonById(persons, action.responsible as string)
    assigneeEmail = assignee ? assignee.email : undefined
  }

  const assigneeIsCurrentUser = currentUser ? assigneeEmail === currentUser.email : false

  const sendEmail = async (to: RecipientType, mailto?: string) => {
    const actionPDF = generateActionPDF()
    if (!action || !machineId || !actionPDF) return
    const currentDate = new Date()
    const assigneeName: string | undefined = action.responsible
      ? personIdToName(persons, action.responsible as string, false)
      : undefined
    const assignerNameAndEmail: string | undefined = action.createdBy
      ? personIdToName(persons, action.createdBy as string, true)
      : undefined
    let body: string = ''
    let recipient: string | undefined = ''
    let subject: string = ''
    switch (to) {
      case RecipientType.ASSIGNEE:
        recipient = assigneeEmail
        subject = `Arbeitsauftrag an Maschine ${machine.name}`
        body = `Guten Tag ${assigneeName},
${
  assignerNameAndEmail
    ? `${assignerNameAndEmail} hat Ihnen den angehängten Arbeitsauftrag zugewiesen.`
    : `Ihnen wurde der angehänge Arbeitsauftrag zugewiesen.`
}

Viel Erfolg und viele Grüße,
LubIQ
`
        break
      case RecipientType.SELF:
        recipient = currentUser.email
        subject = `Arbeitsauftrag für ${assigneeName} an Maschine ${machine.name}`
        body = `Guten Tag ${currentUser.firstName}${currentUser.lastName && ' ' + currentUser.lastName},

Anbei das PDF für den angeforderten Arbeitsauftrag für ${assigneeName}.

Viele Grüße,
LubIQ
`
        break
        case RecipientType.OTHER:
        recipient = mailto
        subject = `Arbeitsauftrag für ${assigneeName} an Maschine ${machine.name}`
        body = `Guten Tag,

${currentUser.firstName}${currentUser.lastName && ' ' + currentUser.lastName} hat Ihnen ein Arbeitsauftrag-PDF für ${assigneeName} geschickt.

Viele Grüße,
LubIQ
`
        break
    }
    if (!recipient) return
    const doc = {
      _id: `${machineId}:sendemail:${currentDate.toJSON()}`,
      expectedAttachmentCount: 1,
      sent: false,
      to: [recipient],
      subject,
      body,
      _attachments: {
        [`Arbeitsauftrag-${action._id}.pdf`]: {
          content_type: 'application/pdf',
          data: await pdf(actionPDF).toBlob()
        }
      }
    }
    const dbName = `${customerId}-${format(currentDate, 'yyyy-MM')}`
    const db = new PouchDB(dbName)
    db.put(doc)
    switch (to) {
      case RecipientType.ASSIGNEE:
        setSentAssigneeAsMail(true)
        break
      case RecipientType.SELF:
        setSentSelfAsMail(true)
        break
    }
  }
  const machineIsEditable = get(machine, 'state') === MachineState.ACTIVE || !get(machine, 'state')

  if (!action && !machineIsEditable) {
    return (
      <div className="content">
        <p>An dieser Maschine kann keine neue Aktion angelegt werden.</p>
      </div>
    )
  }

  return (
    <>
      {!personsByType.appUsers && (
        <div className="notification is-danger">
          Sie sind zurzeit ohne Internetverbindung, weswegen Sie keine neuen Aktionen eintragen können!
        </div>
      )}
      {action && machine ? (
        <>
          <h3 className="title is-3">
            Aktion vom {format(new Date(action._id.substr(12)), "cccc', den 'dd.MM.yyyy' um 'HH:mm", { locale: de })}
          </h3>
          <div className="buttons">
            <button className="button is-light" onClick={() => downloadPDF()}>
              <span className="icon">
                <FontAwesomeIcon icon="file-pdf" size="sm" />
              </span>
              <span>Als PDF herunterladen</span>
            </button>
            {sentSelfAsMail ? (
              <button className="button is-light" disabled>
                <span className="icon">
                  <FontAwesomeIcon icon="check" size="sm" />
                </span>
                <span>PDF gesendet an {currentUser.email}</span>
              </button>
            ) : (
              <button className="button is-light" onClick={() => sendEmail(RecipientType.SELF)}>
                <span className="icon">
                  <FontAwesomeIcon icon="paper-plane" size="sm" />
                </span>
                <span>PDF per E-Mail an {currentUser.email}</span>
              </button>
            )}
            {assigneeEmail &&
              !assigneeIsCurrentUser &&
              (sentAssigneeAsMail ? (
                <button className="button is-light" disabled>
                  <span className="icon">
                    <FontAwesomeIcon icon="check" size="sm" />
                  </span>
                  <span>PDF gesendet an {assigneeEmail}</span>
                </button>
              ) : (
                <button className="button is-light" onClick={() => sendEmail(RecipientType.ASSIGNEE)}>
                  <span className="icon">
                    <FontAwesomeIcon icon="paper-plane" size="sm" />
                  </span>
                  <span>PDF per E-Mail an {assigneeEmail}</span>
                </button>
              ))
            }

          </div>
          <Formik
            validationSchema={MailtoValidation}
            initialValues={{mailto: ''}}
            onSubmit={(values) => sendEmail(RecipientType.OTHER, values.mailto)}
            enableReinitialize={true}
          >
            {({ errors, isSubmitting }: FormikProps<{mailto: string}>): React.ReactNode => {
              const hasErrors = !isEmpty(errors)
              const isNotSubmittable = hasErrors || isSubmitting ? true : false
              return (
                <Form className="is-flex-tablet">
                  <FormItem type="text" label="" name="mailto" placeholder="Email-Adresse" />
                  <button type="submit" disabled={isNotSubmittable} className="button is-light ml-2">
                    <span className="icon">
                      <FontAwesomeIcon icon="paper-plane" size="sm" />
                    </span>
                    <span>PDF per E-Mail senden</span>
                  </button>
                </Form>
              )
            }}
          </Formik>
          <hr />
        </>
      ) : userCanEnterActions ? (
        <h3 className="title is-3">Neue Aktion aufzeichnen</h3>
      ) : (
        <Redirect to={'/'} />
      )}
      <FormContainer
        initialValues={action || initialValues}
        putDocument={() => {}}
        validationSchema={ValidationSchema}
        onSubmit={async (values: FormValues, _actions: FormikHelpers<FormValues>): Promise<void> => {
          _actions.setSubmitting(true)
          const currentDate = new Date()
          let dbName = ''
          if (action) {
            dbName = `${customerId}-${format(new Date(action._id.substr(12)), 'yyyy-MM')}`
          } else {
            dbName = `${customerId}-${format(currentDate, 'yyyy-MM')}`
          }
          const db = new PouchDB(dbName)
          let responsibleUserId = values.responsible
          const selectedPerson: Person | undefined = personsIncludingSelf.find((person: Person): boolean => {
            return person.userId === values.responsible
          })
          if (!selectedPerson) {
            _actions.setErrors({
              general: 'Sie können keine Sofortmaßnahmen anlegen (Sie sind u.U. nicht Mitglied dieser Firma)'
            })
            _actions.setSubmitting(false)
            return
          }
          responsibleUserId = (selectedPerson as Person).userId
          let doc: Action = {
            _id: `${machineId}:${currentDate.toJSON()}`,
            ...action,
            type: EventType.ACTION,
            actionType: values.actionType,
            instruction: values.instruction,
            done: values.done,
            createdBy: currentUser.email,
            responsible: responsibleUserId
          }
          const wasSetToDone = action ? !action.done && values.done : !initialValues.done && values.done
          if (wasSetToDone) {
            doc.completedAt = currentDate.toISOString()
          }
          const wasSetToNotDone = action && action.done && !values.done
          if (wasSetToNotDone) {
            doc.completedAt = undefined
          }
          await db.put(doc)
          _actions.setSubmitting(false)
          if (!action) {
            setNewActionId(currentDate.toJSON())
          }
        }}
        submitLabel={action ? 'Aktualisieren' : 'Speichern'}
      >
        <FormItem component="select" name="actionType" label="Aktionstyp" disabled={!personsByType.appUsers || action}>
          <option value="arbeit">Arbeitsauftrag</option>
          <option value="kunde">Kundendienst</option>
          <option value="labor">Laborauftrag</option>
          <option value="reinigung">Maschinenreinigung</option>
          <option value="emwechsel">Emulsionswechsel</option>
          <option value="sofort">Sofortmaßnahme</option>
          <option value="sofort-additiv">Sofortmaßnahme: Additivzugabe</option>
          <option value="sofort-konz-an">Sofortmaßnahme: Konzentration anheben</option>
          <option value="sofort-konz-ab">Sofortmaßnahme: Konzentration reduzieren</option>
        </FormItem>
        {!isSofort && (
          <FormItem
            component="TagInput"
            label="Beauftragt"
            name="responsible"
            placeholder="Bitte eine Person wählen oder neu eingeben"
            multiple={false}
            isCreatable={false}
            tagOptions={groupedAssigneeOptions}
            isClearable
            disabled={!personsByType.appUsers || action || isSofort}
            hasComplexOptions={true}
          />
        )}
        <FormItem
          component="textarea"
          label={isSofort ? 'Bemerkung' : 'Arbeitsanweisung'}
          name="instruction"
          disabled={!personsByType.appUsers || action}
        ></FormItem>
        <FormItem type="checkbox" label="Arbeit erledigt?" name="done" disabled={!personsByType.appUsers || isSofort} />
        <SelectChangeListener setIsSofort={setIsSofort} actionId={actionId || newActionId} />
      </FormContainer>
    </>
  )
}

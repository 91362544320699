import React from 'react'

import usePersons from '../hooks/usePersons'

interface Props {
  customerId?: string;
}

export const PersonGetter: React.FC<Props> = props => {
  usePersons(props.customerId)
  return null
}

// Won’t run twice for the same customerId, so this effectively only fetches
// a customer’s users after the first login, a hard reload, or an admin switching
// customers
export const MemoPersonGetter = React.memo(PersonGetter)

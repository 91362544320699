import { useState, useEffect } from 'react'
import PouchDB from 'pouchdb'

import { MachineDoc } from '../components/machine-inventory'
import { MeasurementPoint } from '../components/machine-measurement-point'

// `key` is some random string we can force this hook to re-run with
export const useMachine = (customerId: string | undefined, machineId: string | undefined, key?: string) => {
  const [machine, setMachine] = useState<MachineDoc | undefined>(undefined)
  const [machineMeasurementPoints, setMachineMeasurementPoints] = useState<MeasurementPoint[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [hasLoaded, setHasLoaded] = useState(false)
  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      const customerDB = new PouchDB(customerId)
      let machine: MachineDoc | undefined
      if (machineId) {
        try {
          const docId = machineId.substr(0, 3) === 'ma_' ? machineId : `ma_${machineId}`
          machine = await customerDB.get(docId)
        } catch (e) {
          // console.log(`ma_${machineId} doesn’t exist`, e)
          setMachine(undefined)
          setIsLoading(false)
          setHasLoaded(true)
        }
      }
      let machineMeasurementPoints = []
      if (machine) {
        const machineMeasurementPointRows = await customerDB.allDocs({
          include_docs: true,
          startkey: `ma_${machineId}:mp_`,
          endkey: `ma_${machineId}:mp_\ufff0`
        })
        machineMeasurementPoints = machineMeasurementPointRows.rows.map((row: any) => row.doc)
      }
      if (!didCancel) {
        setMachine(machine)
        setMachineMeasurementPoints(machineMeasurementPoints)
        setIsLoading(false)
        setHasLoaded(true)
      }
    }
    if (customerId && machineId) {
      const skipTheseNames = ['nh', 'oel']
      if (skipTheseNames.includes(customerId)) {
        setMachine(undefined)
        setMachineMeasurementPoints([])
        setIsLoading(false)
        setHasLoaded(true)
      } else {
        fetchData()
      }
    }
    // Clean up on unmount
    return () => {
      didCancel = true
    }
  }, [customerId, machineId, key])
  return { machine, machineMeasurementPoints, isLoading, hasLoaded }
}

import React, { useContext } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AppContext from '../AppContext'
import { MachineDoc } from './machine-inventory'

interface Props extends RouteComponentProps {
  machine: MachineDoc;
}

export const MachineSwitcher: React.FC<Props> = props => {
  const { inventoryUIState } = useContext(AppContext)
  const { machine } = props
  const machineId = machine._id!.substr(3)
  const { pathname } = props.location
  if (!inventoryUIState) return null
  const { filter, currentMachineIds } = inventoryUIState
  if (!currentMachineIds || !machineId) return null
  const machineDocId = `ma_${machineId}`
  const currentMachineIndex = currentMachineIds.indexOf(machineDocId)
  // This is a newly created machine, so this switcher can’t work
  if (currentMachineIndex === -1) return null
  const previousMachineId =
    currentMachineIds[currentMachineIndex === 0 ? currentMachineIds.length - 1 : currentMachineIndex - 1]
  const nextMachineId =
    currentMachineIds[currentMachineIndex === currentMachineIds.length - 1 ? 0 : currentMachineIndex + 1]
  const showArrowButtons = currentMachineIds.length > 1
  const renderDesc = () => {
    if (!showArrowButtons) {
      return (
        <span>
          Nur ein Treffer für Filter <strong className="does-not-wrap has-text-white">{filter}</strong>
        </span>
      )
    }
    return (
      <span>
        <strong className="has-text-white">{machine.name}</strong> - Maschine{' '}
        <strong className="has-text-white">{currentMachineIndex + 1}</strong> von{' '}
        <strong className="has-text-white">{currentMachineIds.length}</strong>
        {filter && (
          <span>
            {' '}
            für Filter <strong className="has-text-white no-wrap">{filter}</strong>
          </span>
        )}
      </span>
    )
  }
  if (!showArrowButtons && !filter) {
    // Only one machine and no filter, no point in showing this interface
    return null
  }
  let pathFragmentsAfterMachineID: string[] = pathname.split('/').slice(3)
  let postMachineIDPath: string = ''
  if (pathFragmentsAfterMachineID) {
    // Don't allow direct switching if we’re in the actions tab and we’re looking at a machine-specific action.
    // We remove the action id from the url, because it won’t exist on the next machine anyway.
    if (pathFragmentsAfterMachineID[0] === 'aktion' && pathFragmentsAfterMachineID.length > 1) {
      pathFragmentsAfterMachineID.pop()
    }
    postMachineIDPath = `/${pathFragmentsAfterMachineID.join('/')}`
  }
  // Make sure we never add trailing slashies
  if (postMachineIDPath.substr(-1) === '/') {
    postMachineIDPath = postMachineIDPath.substr(0, postMachineIDPath.length - 1)
  }
  return (
    <div className="machine-selector level is-mobile">
      {showArrowButtons && (
        <div className="level-left">
          <div className="level-item">
            <Link
              to={`/maschine/${previousMachineId.substr(3)}${postMachineIDPath}`}
              className="button is-primary is-radiusless"
            >
              <span className="icon">
                <FontAwesomeIcon icon="arrow-circle-left" size="lg" />
              </span>
            </Link>
          </div>
        </div>
      )}
      <div className="level-item has-text-centered has-text-white">{renderDesc()}</div>
      {showArrowButtons && (
        <div className="level-right">
          <div className="level-item">
            <Link
              to={`/maschine/${nextMachineId.substr(3)}${postMachineIDPath}`}
              className="button is-primary is-radiusless"
            >
              <span className="icon">
                <FontAwesomeIcon icon="arrow-circle-right" size="lg" />
              </span>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

import React, { useContext, useState, useEffect } from 'react'
import AppContext from '../AppContext'

const LoadingBar = function LoadingBar() {
  const [progress, setProgress] = useState(0)
  const [done, setDone] = useState(false)
  const { databaseManager } = useContext(AppContext)

  useEffect(() => {
    databaseManager!
      .progress()
      .on('progress', percent => setProgress(percent))
      .once('done', () => setDone(true))

    return function cleanup() {
      databaseManager!.progress().removeAllListeners()
    }
  })

  return !done && progress !== 0 ? (
    <progress className="progress" value={progress} max="100">
      {progress}%
    </progress>
  ) : null
}

export default React.memo(LoadingBar)

import React, { ErrorInfo } from 'react'

/*

Top-Level error boundary for nice runtime errors in prod and reporting.

⚠️ In local dev, this will show up for a second, and then create-react-app will plaster its own error UI over it. This is normal: https://stackoverflow.com/a/52097626

*/

interface State {
  error?: Error;
  errorInfo?: ErrorInfo;
}

export default class ErrorBoundary extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props)
    this.state = {
      error: undefined,
      errorInfo: undefined
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Rollbar reporting would go here
    // console.warn('Caught a top-level error:', error)
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render(): React.ReactNode {
    const { error, errorInfo } = this.state
    if (error) {
      return (
        <div className="App">
          <div className="container is-fluid">
            <article className="message is-danger">
              <div className="message-header">
                <p>Kritischer Fehler</p>
                <button className="delete" aria-label="delete"></button>
              </div>
              <div className="message-body">
                <p className="content">{error && error.toString()}</p>
                <pre>{errorInfo && errorInfo.componentStack}</pre>
              </div>
            </article>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

import React from 'react'

export interface BigNumberProps {
  id: string;
  value?: number | string;
  unit?: string;
  label?: string;
  date?: string;
  type?: string; // one of: low, good, high
}

export const BigNumber: React.FC<BigNumberProps> = props => {
  const { value, unit, label, date, type } = props
  return (
    <div className={`metricStatus ${type}`}>
      <div className="display">
        <div className="value">{isFinite(value as number) ? value : '-'}</div>
        <div className="unit">{unit}</div>
      </div>
      <div className="label">{label}</div>
      {date && <div className="label has-text-grey is-size-7">{date}</div>}
    </div>
  )
}

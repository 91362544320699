import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import { isEqual } from 'lodash'
import { Database } from '../react-pouchdb'

import { getRemote } from '../utils/network'
import { getCurrentMonthlyDb } from '../utils/general'
import MachineMeasurement from './machine-measurement'

interface Props extends RouteComponentProps {
  customerId: string;
  machineId: string;
  onDbError: any;

  db?: PouchDB.Database;
}

interface State {
  machineData: object;
  mpData: any[];
  error?: string;
  isLoading: boolean;
  routeKey?: string;
}

const defaultState = {
  machineData: {},
  mpData: [],
  error: undefined,
  isLoading: false,
  routeKey: undefined
}

class MachineMeasurements extends Component<Props, State> {
  public state = defaultState
  private fetchData = async (): Promise<void> => {
    const { db, machineId } = this.props
    if (!db) {
      return
    }
    this.setState({
      isLoading: true
    })

    const machine = await db.get(machineId)
    this.setState({
      machineData: machine
    })

    const mps = await db.allDocs({
      include_docs: true,
      startkey: `${machineId}:mp_`,
      endkey: `${machineId}:mp_\ufff0`
    })

    if (mps.rows.length === 0) {
      this.setState({
        error:
          'Bevor für diese Maschine Messwerte eingegeben werden können, müssen unter dem Reiter "Daten" die zu messenden Metriken eingerichtet werden.',
        routeKey: this.props.location.key
      })
    } else {
      this.setState({
        mpData: mps.rows,
        routeKey: this.props.location.key
      })
    }
  }

  public componentDidMount(): void {
    this.fetchData()
  }

  public shouldComponentUpdate(_nextProps: Props, nextState: State): boolean {
    if (this.state.routeKey && this.state.routeKey !== this.props.location.key) {
      return true
    }
    return !isEqual(this.state, nextState)
  }

  public componentDidUpdate(): void {
    // This ensures that switching directly between machine measurement pages works
    if (this.state.routeKey && this.state.routeKey !== this.props.location.key) {
      // Reset to empty state with `loading: true`
      this.setState(defaultState, () => {
        this.fetchData()
      })
    }
  }

  public render(): React.ReactNode {
    const { machineData: machine, mpData: mps, error }: any = this.state
    const currentTime = new Date()
    const databaseName = getCurrentMonthlyDb(this.props.customerId)
    const remote = getRemote(databaseName)

    if (error) {
      return (
        <article className="message is-danger">
          <div className="message-header">
            <p>Fehler</p>
          </div>
          <div className="message-body">{error}</div>
        </article>
      )
    }
    return mps.map(
      (measurementPoint: any): React.ReactElement => {
        return (
          <Database database={databaseName} key={measurementPoint} remote={remote} onError={this.props.onDbError}>
            <MachineMeasurement
              machine={machine}
              mp={measurementPoint}
              customerId={this.props.customerId}
              createdAt={currentTime}
            />
          </Database>
        )
      }
    )
  }
}

export default MachineMeasurements

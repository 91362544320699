import React, { useState, useEffect } from 'react'
import { subMonths, subYears, endOfYear, getYear, isSameYear, format } from 'date-fns'
import lodash from 'lodash'

import { useAllData } from '../hooks/useAllData'
import usePersons from '../hooks/usePersons'
import { useMachine } from '../hooks/useMachine'
import useFluids from '../hooks/useFluids'

import MeasurementTable, { metricsToShow } from './measurement-table'
import MeasurementsPDF from './measurements-pdf'
import ActionsPDF from './actions-pdf'
import MachineId from './machine-id'
import MachineCardPDF from './machine-card-pdf'
import { PDFDownloadButton, GenericLoadingSpinner } from './partials'

import { dateFormat } from '../utils/general'
import { UserRoles } from '../utils/user-roles'

import { Privileges } from '../types/users-roles-privileges'
import { CustomerFluid, OsFluid, FluidChangeEvent } from '../types/fluids'

interface Props {
  customerId: string;
  machineId: string;
  location: any;
}

const MachineActivityTable: React.FC<Props> = props => {
  const userRoles = UserRoles.fromSession()
  const userCanSwitchCustomers = userRoles.hasPrivilege(Privileges.CAN_SWITCH_CUSTOMERS)
  const curDateForPdf = format(new Date(), dateFormat)
  // Get the MonthDB data for the last 10 years, including the current one
  let years: any = {}
  let cur = new Date()
  for (let i = 0; i < 10; i++) {
    // If we're in the current year, we need to only get previous months
    if (isSameYear(new Date(), cur)) {
      let arr = []
      for (let j = 0; getYear(subMonths(cur, j)) === getYear(cur); j++) {
        arr.push(`${props.customerId}-${format(subMonths(cur, j), 'yyyy-MM')}`)
      }
      years[format(cur, 'yyyy')] = arr
    } else {
      const eoy = endOfYear(cur)
      // ...and if not, we can just get all 12 of them
      years[format(cur, 'yyyy')] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((_k, j) => {
        return `${props.customerId}-${format(subMonths(eoy, j), 'yyyy-MM')}`
      })
    }
    cur = subYears(cur, 1)
  }
  const [ready, setReady] = useState(false)
  const [groupedMeasurements, setGroupedMeasurements] = useState<any>([])
  const [actionsWithUsers, setActionsWithUsers] = useState<any>([])
  const [year, setYear] = useState<number>(getYear(new Date()))
  const [fluid, setFluid] = useState<OsFluid | CustomerFluid>(null)
  const [fluidChangeEvents, setFluidChangeEvents] = useState<FluidChangeEvent[]>(null)
  const { measurements, actions, hasLoaded: dataLoaded, measurementPoints, events } = useAllData(
    props.customerId,
    years[year] || [],
    year,
    props.machineId
  )
  const { persons, hasLoaded: personsLoaded } = usePersons(props.customerId, props.location.key)
  const { machine, hasLoaded: machineLoaded } = useMachine(props.customerId, props.machineId, props.location.key)
  const { allFluids, hasLoadedFluids } = useFluids(props.customerId)

  useEffect(() => {
    if (dataLoaded && personsLoaded && machineLoaded) {
      const grouped = lodash(measurements)
        .filter(t => metricsToShow.includes(t.metricId))
        .groupBy('measuredAt')
        .values()
        .sortBy(a => a[0].measuredAt)
        .value()
      const tempActions = actions.map((action: any) => {
        const user = persons.find((person: any): boolean => {
          return person.userId === action.responsible
        })
        if (user) {
          const assigneeName = [user.firstName, user.lastName].join(' ')
          action.assigneeName = assigneeName
        }
        return action
      })

      if (measurementPoints && measurementPoints[0] && measurementPoints[0].doc.fluid) {
        const fluid = allFluids.find(f => f._id === measurementPoints[0].doc.fluid.id)
        setFluid(fluid)
      }

      if(events) {
        const fluidChangeEvents: FluidChangeEvent[] = events.filter(event => event.eventType === 'fluidChange')
        setFluidChangeEvents(fluidChangeEvents)
      }

      setActionsWithUsers(tempActions)
      setGroupedMeasurements(grouped)
      setReady(true)
    } else {
      setReady(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded, personsLoaded, machineLoaded, hasLoadedFluids])

  if (ready && machine) {
    const nextYear = Number(Object.keys(years).pop()) + 1
    return (
      <div className="row">
        <h3 className="is-size-3">
          Protokoll für <strong>{machine.name}</strong> (<MachineId id={machine._id!} clickable={true} />
          ), Zeitraum:&nbsp;
          <span className="select is-on-baseline">
            <select value={year} onChange={e => setYear(Number(e.currentTarget.value))}>
              {Object.keys(years).map(y => (
                <option value={y} key={y}>
                  {y}
                </option>
              ))}
              <option value={nextYear} key={nextYear}>
                {nextYear}
              </option>
            </select>
          </span>
        </h3>
        {machine.location && (
          <h4 className="is-size-4">
            <strong>Standort: </strong>
            {machine.location}
          </h4>
        )}
        <hr />
        <MeasurementTable
          measurements={groupedMeasurements}
          actions={actionsWithUsers}
          eventClickTargetPrefix={`/maschine/${machine._id!.substr(3)}/`}
          MeasurementsPDFLink={<div className="buttons">
            {groupedMeasurements.length > 0 && (
              <PDFDownloadButton
                key={`Messprotokoll-${machine._id}-${year}-${curDateForPdf}`}
                fileName={`Messprotokoll-${machine._id}-${year}-${curDateForPdf}.pdf`}
                buttonLabel="Messprotokoll als PDF herunterladen"
                generatePDF={() => <MeasurementsPDF measurements={groupedMeasurements} measurementPoint={measurementPoints && measurementPoints[0] ? measurementPoints[0].doc : undefined} machine={machine} fluidInfo={fluid}/>}
              />
            )}
            {userCanSwitchCustomers && (
              <PDFDownloadButton
                key={`Maschinenkarte-${machine._id}-${year}-${curDateForPdf}`}
                fileName={`Maschinenkarte-${machine._id}-${year}-${curDateForPdf}.pdf`}
                buttonLabel={`${groupedMeasurements.length === 0 ? 'Leere ' : ''}Maschinenkarte als PDF herunterladen`}
                generatePDF={() => <MachineCardPDF measurements={groupedMeasurements} machine={machine} fluidInfo={fluid} measurementPoint={measurementPoints && measurementPoints[0] ? measurementPoints[0].doc : undefined} year={year} fluidChangeEvents={fluidChangeEvents}/>}
              />
            )}
          </div>}
          ActionsPDFLink={
            <PDFDownloadButton
              fileName={`Aktionsprotokoll-${machine._id}-${year}-${curDateForPdf}.pdf`}
              buttonLabel="Aktionsprotokoll als PDF herunterladen"
              generatePDF={() => <ActionsPDF actions={actionsWithUsers} machine={machine} />}
            />
          }
        />
      </div>
    )
  } else {
    return <GenericLoadingSpinner />
  }
}

export default MachineActivityTable

import React, { Component, ReactElement } from 'react'
import classNames from 'classnames'

import './graphs.scss'
import { Redirect } from 'react-router-dom'

interface WaffleBoxState {
  tooltipVisible: boolean;
  flipTooltip: boolean;
  doRedirect: boolean;
}

export interface WaffleBoxProps {
  color?: string;
  clickTarget?: string;
  tooltipElement?: React.ReactElement;
  containerWidth?: number;
}

export class WaffleBox extends Component<WaffleBoxProps, WaffleBoxState> {
  public constructor(props: WaffleBoxProps) {
    super(props)
    this.state = {
      tooltipVisible: false,
      flipTooltip: false,
      doRedirect: false
    }
  }
  public render(): React.ReactNode {
    const { color = 'grey', clickTarget, tooltipElement, containerWidth } = this.props
    const clickHandler = () => {
      this.setState({ doRedirect: true })
    }
    const mouseOverHandler = (event: React.MouseEvent<HTMLDivElement>) => {
      let element = event.currentTarget
      let isOnRightHalf = false
      if (element !== null) {
        let rect = element.getBoundingClientRect()
        if (containerWidth && rect.left > containerWidth / 2) {
          isOnRightHalf = true
        }
      }
      this.setState({
        tooltipVisible: true,
        flipTooltip: isOnRightHalf
      })
    }
    const mouseOutHandler = () => {
      this.setState({ tooltipVisible: false })
    }
    let boxProps: any = {}
    boxProps.className = classNames({
      [color]: true,
      'waffle-box': true,
      'is-clickable': clickTarget,
      right: this.state.flipTooltip
    })
    if (clickTarget) {
      boxProps.onClick = clickHandler
      if (this.state.doRedirect) {
        return <Redirect to={clickTarget} />
      }
    }
    if (tooltipElement) {
      boxProps.onMouseOver = mouseOverHandler
      boxProps.onMouseOut = mouseOutHandler
    }
    return (
      <div {...boxProps}>{this.state.tooltipVisible && <div className="tooltipContainer">{tooltipElement}</div>}</div>
    )
  }
}

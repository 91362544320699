import { TagOption } from '@luxx/types/forms'
import { UserRoles } from './user-roles'

const generate = require('nanoid/generate')
const { format } = require('date-fns')

export const appName = 'LubIQ'

// generate ids like:
// cu_H7HS
// ma_HS6J294R
// 63J9S6J297HS
export const generateId = (length: number = 8, prefix: string): string => {
  const characters = '123456789123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  // calculate the collision probability here: https://zelark.github.io/nano-id-cc/
  // no zero for readability
  const id = generate(characters, length)
  return prefix ? `${prefix}_${id}` : id
}

export const formatReadableId = (id: string = ''): string => {
  const withoutPrefix: string = id.replace(/^\w{2}_/, '')
  const parts: string[] = withoutPrefix.match(/.{1,4}/g) || []
  return parts.join(' ')
}

export const isMachineDoc = (doc: PouchDB.Core.ExistingDocument<any>): boolean => {
  const machineIdRegex = /^(ma_)[123456789123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ]{8}$/
  return !!(doc.id && doc.id.match(machineIdRegex))
}

export const getCurrentMonthlyDb = (customerId: string): string => {
  const currentTime = new Date()
  return `${customerId}-${format(currentTime, 'yyyy')}-${format(currentTime, 'MM')}`
}

export const arrayOfStringsToArrayOfTagOptions = (tagStrings: string[]): TagOption[] => {
  return tagStrings.map(
    (tag: string): TagOption => {
      return { value: tag, label: tag }
    }
  )
}

export const stringToComponentName = (inputString: string): string => {
  return inputString.substr(0, 1).toUpperCase() + inputString.substr(1)
}

export const isNotEmpty = (value: any): boolean => {
  if (value === undefined) return false
  if (value === null) return false
  if (typeof value === 'string') {
    if (value.length > 0) {
      return true
    } else {
      return false
    }
  }
  // Some machines don’t have isNaN, apparently
  if (isNaN && isNaN(value)) return false
  return true
}

export const shouldNotSeeOSBrand = (): boolean => {
  const userRoles = UserRoles.fromSession()
  if (userRoles.isResellee()) return true
  if (userRoles.isReseller()) return true
  return false
}

export const dateFormat = 'dd.MM.yyyy'
export const dateAndTimeFormat = 'dd.MM.yyyy HH:mm'

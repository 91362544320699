import React, { Component } from 'react'
import { Switch, Route, withRouter, RouteComponentProps, Redirect } from 'react-router'
import { Provider as AlertProvider, positions, transitions } from 'react-alert'
import PouchDB from 'pouchdb'
// Global Context
import { AppProvider, AppState, InventoryUIState } from './AppContext'
// Styles and Icons
import './styles/App.scss'
import './utils/icon-library'
// Components
import Header from './components/header'
// Views
import MachineInventory from './views/machine-inventory'
import MachineDetail from './views/machine-detail'
import Dev from './views/dev'
import ScanQR from './views/scan-qr'
import NoMatch from './views/no-match'
import Login from './views/login'
import Invite from './views/invite'
import { getCustomerIdFromCouchDBRoles, getLocalSession, isNhUser, isOsUser, setLocalSession } from './utils/auth'
import AppRefresh from './utils/app-refresh'
import Customers from './views/customers'
import Analysis from './views/analysis'
import CustomerFilter from './views/customer-filter'
import { UserRoles } from './utils/user-roles'
import DatabaseManager from './utils/database-manager'
import { reauthorize } from './pouch'
import UserManagement from './views/user-management'
import { User, Privileges } from './types/users-roles-privileges'
import { AlertTemplate } from './components/alert'
import { Customer } from './hooks/useCustomers'
import { MemoPersonGetter } from './components/person-getter'
import NotificationPreferences from './views/notification-preferences'
import Reset from './views/reset'
import ResetPassword from './views/reset-password'
import { get } from 'lodash'
import CustomerRedirect from './views/customer-redirect'

const alertOptions = {
  position: positions.TOP_RIGHT,
  timeout: 4000,
  transition: transitions.FADE
}

interface MatchParams {
  machineId?: string;
}

async function findAsyncSequential<T>(
  array: T[],
  predicate: (t: T) => Promise<boolean>,
): Promise<T | undefined> {
  for (const t of array) {
    if (await predicate(t)) {
      return t;
    }
  }
  return undefined;
}

class App extends Component<RouteComponentProps<MatchParams>, AppState> {
  private checkForCriticalEnvVars() {
    const { REACT_APP_COUCHDB_ENDPOINT } = process.env
    if (!REACT_APP_COUCHDB_ENDPOINT) {
      throw new Error('No CouchDB endpoint (REACT_APP_COUCHDB_ENDPOINT) defined in .env')
    }
  }
  public constructor(props: RouteComponentProps) {
    super(props)
    this.checkForCriticalEnvVars()
    const defaultState = {
      inventoryUIState: {
        filter: '',
        sortByWhat: 'name'
      },
      hasNewServiceWorker: false
    }

    // On app mount, try and get figure out which role the current user has by checking localStorage for
    // our session object
    const session = getLocalSession()
    let currentCustomer
    let currentUser
    if (session) {
      const { roles: rolesFromSession, currentCustomer: currentCustomerFromSession } = session
      currentCustomer = {
        ...currentCustomerFromSession,
        _id: getCustomerIdFromCouchDBRoles(rolesFromSession || []) || get(currentCustomerFromSession, '_id'),
      }
      currentUser = session
      if (currentUser.active === false) {
        reauthorize(this.props.history)
      }
    }

    // If we're either not in development mode or have the REACT_APP_GIT_ISH env variable
    // set, we start a polling service to check if the server has a more recent version of the
    // application ready for us. If so, we prompt the user to update by closing the tab and
    // reopening.
    const { NODE_ENV, REACT_APP_GIT_ISH, REACT_APP_COUCHDB_ENDPOINT } = process.env
    if (NODE_ENV !== 'development' || REACT_APP_GIT_ISH) {
      const appRefresh = new AppRefresh()
      appRefresh.listen({
        interval: 60 * 60 * 3 * 1000, // 3 hours
        onRefresh: () => {
          this.setState({
            hasNewServiceWorker: true
          })
        }
      })
    }

    const refreshUserData = (prevUser: any, overrideCustomerId?: string, runOnce: boolean = false) => async () => {
      let customerIdToAuthAgainst = overrideCustomerId || get(currentCustomer,'_id')
      if (isNhUser(prevUser.roles)) {
        customerIdToAuthAgainst = 'nh'
      }
      if (isOsUser(prevUser.roles)) {
        customerIdToAuthAgainst = 'oel'
      }
      try {
        const user = await fetch(
          `${REACT_APP_COUCHDB_ENDPOINT}/_backend/user/${encodeURIComponent(customerIdToAuthAgainst)}/${encodeURIComponent(prevUser.name)}`,
          {
            method: 'get',
            credentials: 'include'
          }
        )
        const userData = await user.json()
        let newCurrentCustomer = overrideCustomerId ? {
            _id: overrideCustomerId,
          } : currentCustomer
        this.setState({
          currentUser: userData.user,
          currentCustomer: newCurrentCustomer
        })
        setLocalSession({
          currentCustomer: newCurrentCustomer,
          ...userData.user
        })
      } catch (error) {
        console.log('Could not refresh user data (maybe offline?)', error)
      }
    }

    const databaseManager = new DatabaseManager(get(currentCustomer,'_id'), this.props.history)
    databaseManager.startWithSession()

    if (currentUser && currentUser.name) {
      // Immediately update user state on page load, and then every minute
      // CAREFUL: doesn’t actually happen _first_ on pageload, the on mount hook
      // in customers.tsx for example runs first
      refreshUserData(currentUser)()
    }

    /*
    This is the global app state which is accessible via the `AppConsumer` and set via the `AppProvider` from './appContext'
    */
    this.state = {
      ...defaultState,
      currentCustomer,
      currentUser,
      databaseManager,
      setInventoryUIState: (inventoryUIState: InventoryUIState): void => {
        this.setState({ inventoryUIState })
      },
      onPouchError: (error: PouchDB.Core.Error): React.ReactElement | null => {
        // We _can_ reach the remote Couch, but the session ended, so force logging in again
        if (error.status === 401) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        }
        return null
      },
      onCustomerSelect: (customer: Customer, redirectTarget?: string): void => {
        const session = getLocalSession()
        // Don’t try to reuse an invalid session
        if (!session.email) return null
        setLocalSession({
          ...session,
          currentCustomer: customer
        })
        this.setState({...this.state, currentCustomer: customer }, () => {
          if (redirectTarget) {
            this.props.history.push(redirectTarget)
          }
        })
        databaseManager.startWithSession(customer._id)
      },
      setCurrentCustomer: (currentUser: User): void => {
        const session = getLocalSession()
        // Don’t try to reuse an invalid session
        if (!session.email) return null
        if (currentUser.customer && currentUser.customer._id) {
          // the first refreshUserData may not have run yet (race condition),
          // so we only do this bit if it definitely has
          const currentCustomer = {
            _id: currentUser.customer._id,
            name: currentUser.customer.name
          }
          setLocalSession({
            ...session,
            currentCustomer
          })
          this.setState({ currentCustomer })
          databaseManager.startWithSession(currentUser.customer._id)
        }
      },
      updateCurrentUser: (userData: User): void => {
        this.setState({ currentUser: userData })
        setLocalSession({ ...userData })
      },
      onLogin: async (customerId: string, userData: User, postLoginTarget: string | undefined): Promise<void> => {
        const { roles } = userData
        if (!roles) {
          console.error('User has no roles')
          return
        }
        await refreshUserData({
          name: userData.name,
          roles: userData.roles
        }, customerId, true)()
        databaseManager.startWithSession(customerId)
        if (postLoginTarget && postLoginTarget !== '/login' && postLoginTarget !== '/') {
          this.props.history.push(postLoginTarget)
        } else {
          const userRoles = new UserRoles(roles)
          if (userRoles.hasPrivilege(Privileges.CAN_SWITCH_CUSTOMERS)) {
            this.props.history.push('/customers')
            return
          }
          this.props.history.push('/')
        }
      },
      onLogout: () => {
        setLocalSession({})
        databaseManager.stop()
        this.props.history.push('/login')
      },
      // Figure out which customer a machine id belongs to:
      // - ask the server first, if offline, try local dbs
      // - check if the machine ID in the url matches any of the machines in the local
      //   databases
      findCustomerForMachineId: async (id: string) => {
        let customerId
        // Try the server first
        const userDataResponse = await fetch(`${REACT_APP_COUCHDB_ENDPOINT}/_backend/customerbymachine/ma_${id}`, {
          method: 'GET',
          credentials: 'include'
        })
        if (userDataResponse && userDataResponse.status === 200) {
          customerId = await userDataResponse.text()
        } else {
          // If no result or response, look locally.
          let dbs = await (window.indexedDB as any).databases()
          let localCustomerDbs = dbs
            .filter(db => {return db.name.match(/(_pouch_cu_)\S{8}$/)})
            .map(db => db.name.substr(7))
              customerId = await findAsyncSequential(localCustomerDbs, async (dbName: string): Promise<boolean> => {
                let result = false
                const skipTheseNames = ['nh', 'oel']
                if (!skipTheseNames.includes(customerId)) {
                  try {
                    const customerDB = new PouchDB(dbName, {skip_setup: true})
                    const machineDoc = await customerDB.get(`ma_${id}`)
                    if (machineDoc) {
                      result = true
                    }
                  } catch {
                    // Is fine, no worries
                  }
                }
                return result
              })
        }
        if (customerId) {
          // FIXME: won’t work with resellers
          const customersDB = new PouchDB('oel-customers')
          const customerDoc: Partial<Customer> = await customersDB.get(customerId)
          this.state.onCustomerSelect(customerDoc as Customer)
        }
      }
    }
    if (!session) {
      this.props.history.push('/login')
      return
    }
  }
  public render(): React.ReactNode {
    return (
      <AppProvider value={this.state}>
        <MemoPersonGetter customerId={get(this.state.currentCustomer,'_id')} />
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <div className="App">
            <Header />
            <div className="container is-fluid">
              <Switch>
                <Route path="/kunde/:customerId" component={CustomerRedirect} />
                <Route exact path="/" component={MachineInventory} />
                <Route path="/maschine/:machineId?" component={MachineDetail} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/invite/:token" component={Invite} />
                <Route exact path="/resetpassword" component={ResetPassword} />
                <Route exact path="/reset/:token" component={Reset} />
                <Route exact path="/analyst/:customerId?" component={Analysis} />
                <Route exact path="/customers" component={Customers} />
                <Route exact path="/benutzerverwaltung/:userId/customers" component={CustomerFilter} />
                <Route exact path="/benutzerverwaltung" component={UserManagement} />
                <Route exact path="/benachrichtigungseinstellungen" component={NotificationPreferences} />
                <Route exact path="/qr" component={ScanQR} />
                <Route exact path="/dev" component={Dev} />
                <Route component={NoMatch} />
              </Switch>
            </div>
          </div>
        </AlertProvider>
      </AppProvider>
    )
  }
}

export default withRouter(App)

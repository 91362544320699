import * as H from 'history'
import isOnline from 'is-online'
import { deleteLocalSession } from './utils/auth'

export async function reauthorize(hist: H.History | null): Promise<any> {
  const history = hist
    ? hist
    : H.createBrowserHistory({
        forceRefresh: true
      })
  if (await isOnline()) {
    deleteLocalSession()
    history.push('/login')
  }
}

// Sandboxes a PouchDB operation, and redirects on 401.
export async function exec(op: any): Promise<any> {
  try {
    return await op
  } catch (err) {
    if (err.status === 401) await reauthorize(null)
    throw err
  }
}

export { setLocalSession, getLocalSession, deleteLocalSession } from './utils/auth'

import { LineSerieData } from '@nivo/line'
import { uniqBy, reverse, sortBy } from 'lodash'

export const cleanLineGraphData = (data: LineSerieData[]) => {
  if (!data) return []
  const sanitisedData = data.map((series: LineSerieData) => {
    return {
      id: series.id,
      // double reverse because we want uniqBy to return the last value in time, not the first
      data: reverse(
        uniqBy(
          reverse(
            sortBy(series.data, 'x')
              .map(datum => {
                return {
                  // cast as a number, if this reults in a non-number, the filter below will catch it
                  // This will cast null as NaN, but since we add any fake null values (used to stretch the x-axis)
                  // _after_ this function runs, we’re fine.
                  y: parseFloat(datum.y as string), // is the value
                  x: datum.x // is a date, leave as is
                }
              })
              .filter(datum => {
                if (datum.y === null) {
                  // allow null values to enable gaps in data
                  return true
                }
                // No NaN pls
                if (Number.isNaN(datum.y)) return false
                // No undefinedes either, and should be a number
                return datum.y !== undefined && typeof datum.y === 'number'
              })
          ),
          'x'
        )
      )
    }
  })
  return sanitisedData
}

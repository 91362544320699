import React from 'react'
import { dateFormat, dateAndTimeFormat, shouldNotSeeOSBrand } from '../utils/general'
import { format } from 'date-fns'
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer'
import { Table, TableBody, TableHeader, TableCell, DataTableCell } from '@david.kucsai/react-pdf-table'

import { getMetricMetadata } from '../utils/default-metrics'

import logo from '../assets/logo-small.png'
import { MachineWithMostRecentMeasurements } from '../views/analysis'
import { Font } from '@react-pdf/renderer'
import { sortBy } from 'lodash'
import { Measurement } from './graphs/waffle-measurements'

export const metricsToShow = ['ph', 'konzentration', 'nitrit', 'anmerkungen']

// Prevent React-pdf from hyphenating things. Weirdly.
// https://github.com/diegomura/react-pdf/issues/311#issuecomment-548301604
Font.registerHyphenationCallback(word => {
  return [word]
})

interface Props {
  data?: MachineWithMostRecentMeasurements[]
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFF',
    padding: '10mm',
    paddingTop: '18mm'
  },
  headerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    top: '5mm',
    position: 'absolute',
    left: '10mm'
  },
  logo: {
    height: '8mm',
    width: 'auto',
    marginRight: '5mm'
  },
  textRow: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  tableCell: {
    padding: 5,
    justifyContent: 'flex-start',
    fontSize: 11
  },
  header: {
    fontSize: 24
  },
  subHeader: {
    fontSize: 18,
    marginBottom: 8
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    top: '5mm',
    right: '10mm',
    width: '10mm',
    color: 'grey',
    textAlign: 'right'
  },
  smallText: {
    fontSize: 10,
    color: '#333',
    fontFamily: 'Courier'
  },
  divider: {
    width: '100%',
    height: '1pt',
    backgroundColor: '#DDD',
    marginBottom: '2mm',
    marginTop: '2mm'
  }
})

// No idea why this works better when the total is >1
const weightings = {
  name: 0.2,
  ph: 0.15,
  konzentration: 0.15,
  nitrit: 0.15,
  anmerkungen: 0.35
}

const GlobalMeasurementsPDF: React.FC<Props> = ({ data }) => {
  const hiddenForBrandingReasons = shouldNotSeeOSBrand()
  if (!data) return null

  const sortedData = sortBy(data, 'machine.name')

  // Mark all measurements before the earliest of the other
  // measurements as out of date so it’s not displayed
  // ws issue #470362460
  sortedData.forEach(measurementCollection => {
    let earliestMeasurementDate = new Date().toISOString()
    let anmerkungenEntry
    Object.entries(measurementCollection).forEach(entry => {
      const entryType = entry[0]
      const entryValue = entry[1]
      if (entryType !== 'machine') {
        if (entryType === 'anmerkungen') {
          anmerkungenEntry = entry
        } else {
          if ((entryValue as Measurement).measuredAt <= earliestMeasurementDate) {
            earliestMeasurementDate = (entryValue as Measurement).measuredAt
          }
        }
      }
    })
    if (anmerkungenEntry && anmerkungenEntry[1] && anmerkungenEntry[1].measuredAt < earliestMeasurementDate) {
      anmerkungenEntry[1].outOfDate = earliestMeasurementDate
    }
  })

  return (
    <Document>
      <Page wrap size="A4" orientation="landscape" style={styles.page}>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
        <View fixed style={styles.headerRow}>
          {!hiddenForBrandingReasons && <Image src={logo} style={styles.logo} />}
          <Text style={styles.header}>Protokoll vom {format(new Date(), dateAndTimeFormat)}</Text>
        </View>
        <View fixed style={{ ...styles.divider, top: '14mm', left: '10mm', position: 'absolute' }} />
        <View fixed>
          <Table>
            <TableHeader
              includeBottomBorder={false}
              includeLeftBorder={false}
              includeRightBorder={false}
              includeTopBorder={false}
            >
              <TableCell
                style={styles.tableCell}
                weighting={weightings.name}
                isHeader={true}
                key={`machine-table-header`}
              >
                Maschine
              </TableCell>
              {metricsToShow.map((metricId: string) => (
                <TableCell
                  style={styles.tableCell}
                  weighting={(weightings as any)[metricId]}
                  isHeader={true}
                  key={`${metricId}-table-header`}
                >
                  {getMetricMetadata(metricId).name}
                </TableCell>
              ))}
            </TableHeader>
          </Table>
        </View>
        <View>
          <Table data={sortedData}>
            <TableBody
              includeBottomBorder={false}
              includeLeftBorder={false}
              includeRightBorder={false}
              includeTopBorder={false}
            >
              <DataTableCell
                style={{ ...styles.tableCell, borderTop: '1pt solid black' }}
                weighting={weightings.name}
                getContent={r => {
                  const machineIdSansPrefix = (r.machine._id as string).substr(3)
                  const formattedMachineId =
                    machineIdSansPrefix.substring(0, 4) + ' ' + machineIdSansPrefix.substring(4)
                  return (
                    <Text>
                      {r.machine.name} <Text style={styles.smallText}>{formattedMachineId} </Text>
                    </Text>
                  )
                }}
              />
              {metricsToShow.map(metricId => {
                return (
                  <DataTableCell
                    key={metricId}
                    style={{ ...styles.tableCell, borderTop: '1pt solid black' }}
                    weighting={(weightings as any)[metricId]}
                    getContent={r => {
                      const meta = getMetricMetadata(metricId)
                      const measurement = r[metricId]
                      if (measurement && measurement.value) {
                        if (measurement.metricId === 'anmerkungen' && measurement.outOfDate) {
                          return <Text style={styles.smallText}>–</Text>
                        }
                        const date = format(new Date(measurement.measuredAt), dateFormat)
                        return (
                          <Text>
                            {measurement.value} {meta.unit ? meta.unit : ''}{' '}
                            <Text style={styles.smallText}>({date})</Text>
                          </Text>
                        )
                      } else {
                        return <Text style={styles.smallText}>–</Text>
                      }
                    }}
                  />
                )
              })}
            </TableBody>
          </Table>
        </View>
      </Page>
    </Document>
  )
}

export default GlobalMeasurementsPDF

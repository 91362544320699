import React, { FunctionComponent } from 'react'
import { Field, ErrorMessage, FormikContextType, connect } from 'formik'
import classNames from 'classnames'
import { get, flatten } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import TagInput from './tag-input'
import { TagOption, TagOptionGroup } from '@luxx/types/forms'
import './forms.scss'
// import { arrayOfStringsToArrayOfTagOptions } from '../../utils/general'

const arrayOfStringsToArrayOfTagOptions = (tagStrings: string[]): TagOption[] => {
  return tagStrings.map(
    (tag: string): TagOption => {
      return { value: tag, label: tag }
    }
  )
}

/*

FormItem is the other formhelper next to FormContainer.
Ideally, you build a form by wrapping some FormItems inside a FormContainer, and that's it.
You can wrap the FormItems in some additional layout markup as well.

The formItem props are
- a label for the input
- a name for the data key in the container’s `initialValues` object
- a type for the formik input type (such as `text`, `email` etc)
- OR a component for more complicated input types such as `select`
- any other props you want to pass through to the actual input element, such as `maxLength='5'` of Bulma’s `size` for select boxes, etc.

The formItem will automatically highlight itself depending on whether it’s valid or invalid, to do this,
it will acquire `errors` and `touched` from the Formik context provided by the parent FormContainer.

Full usage examples in `./stories/forms.stories.js`

*/

interface FormItemBaseProps {
  // so we can pass through custom stuff like `maxLength='5'`, `placeholder='Name'` etc
  [key: string]: string | number | object | undefined | boolean
  label: string
  name: string
  multiple?: boolean
  isCreatable?: boolean
  isClearable?: boolean
  className?: string
  tagOptions?: TagOption[] | TagOptionGroup[]
  hasComplexOptions?: boolean
  isDisabled?: boolean
}

// Props must include either type or component but not both, this is the shortest way to do this in Typescript
interface FormItemPropsWithType extends FormItemBaseProps {
  type: string
}
interface FormItemPropsWithComponent extends FormItemBaseProps {
  component: string // `select` or `tag`
}

interface FormValues {
  [key: string]: string | TagOption[] | string[]
}

// We’re exporting this using Formik‘s `connect()`, which adds the form context to the component,
// so we have stuff like errors and touched inside each FormItem without having to think about that
// while we’re writing forms
// see https://jaredpalmer.com/formik/docs/api/connect
interface FormikProps {
  formik: FormikContextType<FormValues>
}

const FormItem: FunctionComponent<FormikProps & FormItemPropsWithType | FormItemPropsWithComponent> = (
  props
): React.ReactElement => {
  // everything but `name`, `label`, `children` and `formik` is passed on to the <Field>,
  // so we can pass through custom stuff like `maxLength='5'` etc
  // we don’t destructure all props we use up here because of this through-passing
  const {
    name,
    label,
    children,
    formik,
    className,
    tagOptions,
    isClearable,
    hasComplexOptions,
    ...fieldAttributes
  } = props
  // get Formik Form state via the `connect`ed context

  const { errors, touched, values, setFieldValue, setFieldTouched, submitCount } = formik as FormikContextType<
    FormValues
  >
  const isCheckbox = fieldAttributes.type === 'checkbox'
  const isTagInput = fieldAttributes.component === 'TagInput'
  const isSelect = fieldAttributes.component === 'select'
  const isMultiSelect = fieldAttributes.component === 'select' && props.multiple
  const hasInlineValidationIcons = !isCheckbox && !isSelect && !isTagInput
  const isRequiredField = fieldAttributes.required

  let containerClasses = classNames({
    field: !isCheckbox,
    checkbox: isCheckbox
  })

  containerClasses = className ? `${containerClasses} ${className}` : containerClasses
  // Figure out whether the contents of the form are valid or invalid (or neither! it’s a thing).
  // We use `get` here because name can be something like `discountStructure[0]discountRate` and that Just Works™️ with `get`
  const isTouched = get(touched, name)
  const hasError = get(errors, name)
  const displayAsInvalid = isTouched && hasError
  const displayAsValid = isTouched && !hasError
  const controlClasses = classNames({
    control: true,
    select: isSelect,
    'is-multiple': isMultiSelect,
    'has-icons-right': hasInlineValidationIcons
  })
  const fieldClasses = classNames({
    input: true,
    'is-danger': displayAsInvalid,
    'is-success': displayAsValid
  })
  let field
  if (isTagInput && tagOptions) {
    let formattedValues: TagOption | TagOption[]
    /*
    TagInput handles a lot of options:
    - options and values can be passed in as proper option objects with a label and a value (TagOption), or just a string that is used as both
    - the input can be either a multiselect or not, so the current value is either an array or a single item
    - the options can either be grouped or not

    */
    if (hasComplexOptions) {
      // If the options passed in are objects, not strings
      // handle grouped options
      const optionsFromGroups: any = (tagOptions as TagOptionGroup[]).map((group: TagOptionGroup): TagOption[] => {
        return group.options
      })
      const optionsFromAllGroupsCombined = flatten(optionsFromGroups)
      let allOptions = []
      if (optionsFromAllGroupsCombined.length) {
        allOptions = [...optionsFromAllGroupsCombined]
      } else {
        // …as well as regular, ungrouped options
        allOptions = [...tagOptions]
      }
      formattedValues = (allOptions as TagOption[]).find((opt: TagOption): boolean => {
        // Use `get` because `name` might be a path, like `fluid.id`
        return opt.value === get(values, name)
      }) as TagOption
    } else {
      // If the options passed in are strings, not objects
      // Our database sometimes provides string[], but TagInput expects TagOption or TagOption[], so we convert those
      // The opposite happens in tag-input.tsx
      if (props.multiple) {
        formattedValues = arrayOfStringsToArrayOfTagOptions(get(values, name) as string[])
      } else {
        // Even though input value is not multiple here we wrap it in an array for our helper arrayOfStringsToArrayOfTagOptions()
        formattedValues = arrayOfStringsToArrayOfTagOptions([get(values, name)] as string[])
      }
    }

    /**
     **** INPUT TAGS ****
     * Please remember to declare if the component is multiple (isMulti = true) - based on this declaration we get one of 2 different types of input tags showing up in the form:
     * 1. `Creatable` (single tag to select)
     * 2. `Createable Multiselect` (multiple tags to select)
     * More info: https://react-select.com/creatable
     */
    field = (
      <TagInput
        isMulti={props.multiple}
        name={name}
        tagOptions={tagOptions}
        isCreatable={props.isCreatable}
        value={formattedValues}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        error={errors[name]}
        touched={touched[name]}
        isDisabled={fieldAttributes.isDisabled as boolean}
        isClearable={props.isClearable}
      />
    )
  } else {
    field = (
      <Field {...fieldAttributes} name={name} className={fieldClasses}>
        {children}
      </Field>
    )
  }
  let required = ''
  if (isRequiredField) {
    required = '*'
  }
  return (
    <div className={containerClasses}>
      {label && <label className="label" htmlFor={name}>
        {isCheckbox && field}
        {label}
        <span>{required}</span>
      </label>
      }
      {!isCheckbox && (
        <div className={controlClasses}>
          {field}
          {hasInlineValidationIcons && (
            <>
              {displayAsInvalid && (
                <span className="icon is-small is-right">
                  <FontAwesomeIcon icon="exclamation-triangle" />
                </span>
              )}
              {displayAsValid && (
                <span className="icon is-small is-right">
                  <FontAwesomeIcon icon="check" />
                </span>
              )}
            </>
          )}
        </div>
      )}
      {isMultiSelect && <span className="help">CTRL-click oder CMD-click (Mac) um mehrere auszuwählen</span>}
      <ErrorMessage component="div" className="help is-danger" name={name} />
    </div>
  )
}

// see https://jaredpalmer.com/formik/docs/api/connect
export default connect<FormItemPropsWithType | FormItemPropsWithComponent, FormValues>(FormItem)

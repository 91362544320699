import React, { useState, useContext } from 'react'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import PouchDB from 'pouchdb'

import AppContext from '../AppContext'
import { useMachine } from '../hooks/useMachine'
import useImportableMachines from '../hooks/useImportableMachines'
import GenericConnectionWarning from './generic-connection-warning'
import MachineImporter from './machine-importer'
import { Machine } from './machine-inventory'
import useInitialSyncCheck from '../hooks/useInitialSyncCheck'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { omit } from 'lodash'
import { generateId } from '../utils/general'
import { UserRoles } from '../utils/user-roles'
import MachineDetailForm from './forms/machine-detail-form'
import MachineMeasurementPoints from './machine-measurement-points'
import { initialValues } from './machine-measurement-point'
import MachineId from './machine-id'
import { GenericLoadingSpinner } from './partials'
import { Privileges } from '../types/users-roles-privileges'

interface MatchParams {
  machineId?: string;
}

const EditOrAddMachine: React.FC<RouteComponentProps<MatchParams>> = props => {
  const [addMethod, setAddMethod] = useState('none') // none, new or fromImportDB
  const [routeKey, setRouteKey] = useState('')
  const [currentMachineIdFragment, setCurrentMachineIdFragment] = useState<string | undefined>()
  const [hasImported, setHasImported] = useState(false)
  const { currentCustomer, databaseManager } = useContext(AppContext)
  const { _id: customerId } = currentCustomer
  const { isSynced } = useInitialSyncCheck(databaseManager!)
  const { machineId: machineIdFromParams } = props.match.params
  if (!currentMachineIdFragment || (machineIdFromParams && currentMachineIdFragment !== machineIdFromParams)) {
    setCurrentMachineIdFragment(machineIdFromParams || generateId(8, ''))
  }
  // Force this component to re-render if the location.key changes,
  // this allows us to redirect from this page to this page (basically reload with a <Redirect>)
  const { key } = props.location
  if (key !== routeKey) {
    setRouteKey(key!)
    setHasImported(false)
    setAddMethod('none')
  }
  // useMachine receives a random key arg that can be used to force a refetch
  const { machine, machineMeasurementPoints, hasLoaded } = useMachine(customerId, currentMachineIdFragment, key)

  const { hasLoaded: hasLoadedMachines, machines, measurementPoints } = useImportableMachines(
    customerId,
    currentMachineIdFragment
  )

  // cast some boolean helpers
  const creatingNewMachineWithId = !!(currentMachineIdFragment && !machine)
  const creatingNewMachineWithoutId = !!(!currentMachineIdFragment && !machine)
  const isEditingExistingMachine = !!(currentMachineIdFragment && machine)
  const userRoles = UserRoles.fromSession()
  const userCanManageMachines = userRoles.hasPrivilege(Privileges.CAN_MANAGE_MACHINES)

  const getTitle = () => {
    switch (true) {
      case creatingNewMachineWithId:
        return (
          <span>
            Neue Maschine <MachineId id={currentMachineIdFragment as string} clickable={true} /> anlegen
          </span>
        )
      case creatingNewMachineWithoutId:
        return 'Neue Maschine anlegen'
    }
  }
  const selectHandler = async (machine: Machine) => {
    try {
      const customerDB = new PouchDB(customerId)
      const createdAt = new Date().toISOString()
      const newMachineId = `ma_${currentMachineIdFragment}`
      const newMachineDoc = {
        ...omit(machine.doc, '_rev'),
        _id: newMachineId,
        createdAt
      }
      const importResponse = await customerDB.put(newMachineDoc)
      const importMeasurementPointDoc = measurementPoints ? measurementPoints.find((mp: any): boolean => {
        return mp.id === `${machine.id}:mp`
      }) : undefined
      const importDB = new PouchDB(`${customerId}-machine-import`, { skip_setup: true })
      if (importMeasurementPointDoc) {
        const newMeasurementPointId = `${newMachineId}:${generateId(8, 'mp')}`
        const newMeasurementPointDoc = {
          ...omit(importMeasurementPointDoc.doc, '_rev'),
          _id: newMeasurementPointId,
          createdAt
        }
        await customerDB.put(newMeasurementPointDoc)
        const updatedImportMeasurementPointDoc = {
          ...importMeasurementPointDoc.doc,
          importedAs: newMeasurementPointId,
          imported: true
        }
        await importDB.put(updatedImportMeasurementPointDoc)
      }
      const updatedImportMachineDoc = { ...machine.doc, importedAs: newMachineId, imported: true }
      const updateMachineResponse = await importDB.put(updatedImportMachineDoc)
      if (importResponse.ok && updateMachineResponse.ok) {
        setHasImported(true)
      }
    } catch (e) {
      console.log(`failed to import ma_${currentMachineIdFragment}`, e)
    }
  }

  if (hasImported) {
    return <Redirect to={`/maschine/${currentMachineIdFragment as string}`} />
  }

  if (!hasLoaded || !hasLoadedMachines) {
    return <GenericLoadingSpinner message="Lade Maschinendaten" />
  }

  // If the importDB is empty, don’t offer to import stuff from there
  if (hasLoadedMachines && machines && machines.length === 0 && addMethod === 'none') {
    setAddMethod('new')
  }

  if (!userCanManageMachines && !isEditingExistingMachine) {
    return <Redirect to="/" />
  }

  return (
    <div>
      {!isSynced && <GenericConnectionWarning />}
      <h1 className="title">{getTitle()}</h1>
      <div>
        {!isEditingExistingMachine && addMethod === 'none' && (
          <div>
            <div className="buttons">
              <button className="button is-primary" onClick={() => setAddMethod('fromImportDB')}>
                <span className="icon">
                  <FontAwesomeIcon icon="folder-open" size="sm" />
                </span>
                <span>Maschine aus dem Firmenbestand importieren</span>
              </button>
              <button className="button" onClick={() => setAddMethod('new')}>
                <span className="icon">
                  <FontAwesomeIcon icon="plus-circle" size="sm" />
                </span>
                <span>Komplett neue Maschine erstellen</span>
              </button>
            </div>
          </div>
        )}
        {addMethod === 'new' && (
          <div className="buttons">
            {/* Only show the back button if there’s something to go back to */}
            {machines && machines.length > 0 && (
              <button className="button" onClick={() => setAddMethod('none')}>
                <span className="icon">
                  <FontAwesomeIcon icon="arrow-circle-left" size="sm" />
                </span>
                <span>Zurück</span>
              </button>
            )}
          </div>
        )}
        {addMethod === 'fromImportDB' && (
          <>
            <div className="buttons">
              <button className="button" onClick={() => setAddMethod('none')}>
                <span className="icon">
                  <FontAwesomeIcon icon="arrow-circle-left" size="sm" />
                </span>
                <span>Zurück</span>
              </button>
            </div>
            {machines ? (
              <MachineImporter
                importableMachines={machines as Machine[]}
                selectHandler={selectHandler}
                targetId={currentMachineIdFragment}
              />
            ) : (
              <span>Keine importierbaren Maschinen verfügbar</span>
            )}
          </>
        )}
        {isEditingExistingMachine && machine && (
          <>
            <MachineDetailForm machine={machine} reloadOnSave={true} />
            <MachineMeasurementPoints
              machine={machine}
              measurementPoints={
                machineMeasurementPoints.length
                  ? machineMeasurementPoints
                  : [
                      {
                        ...initialValues,
                        _id: `ma_${currentMachineIdFragment}:${generateId(8, 'mp')}`
                      }
                    ]
              }
            />
          </>
        )}
        {addMethod === 'new' && creatingNewMachineWithId && (
          <>
            <MachineDetailForm
              machine={{ _id: `ma_${currentMachineIdFragment}`, name: '', createdAt: new Date().toISOString() }}
              reloadOnSave={true}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default EditOrAddMachine

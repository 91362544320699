import React from 'react'
import { Machine } from '../machine-inventory'
import { WaffleBoxProps } from './waffle-box'
import { countBy, isFinite } from 'lodash'
import MachineId from '../machine-id'
import { getMetricMetadata, MetricMeta } from '../../utils/default-metrics'
import Waffle from './waffle'
import { CustomerFluid, OsFluid } from '../../types/fluids'
import { dateFormat } from '../../utils/general'
import { format } from 'date-fns'
import { getIconForMeasurementStatus } from '../machine-protocol'

interface MachineWithOptionalInlineFluid extends Machine {
  fluid?: CustomerFluid | OsFluid;
}

export interface Measurement {
  _id: string;
  _rev: string;
  createdAt: string;
  measuredAt: string;
  type: 'measurement';
  metricId: string;
  value: string | number;
  name?: string;
  floor?: number;
  min?: number;
  should?: number;
  max?: number;
  ceil?: number;
  material?: string;
  operation?: string;
  attachmentFileName?: string;
  _attachments?: PouchDB.Core.Attachments;
}

interface LastMeasurementsForMachine {
  // ma_12345678: [Measurement, Measurement, …]
  [key: string]: Measurement[];
}

interface Props {
  lastMeasurementsByMachine: LastMeasurementsForMachine[];
  machines: Machine[];
  metricId?: string;
}

const MeasurementWaffle: React.FC<Props> = props => {
  const { lastMeasurementsByMachine, machines, metricId } = props
  // generateWaffleData(lastMeasurementsByMachine, machines, 'nitrit')
  const waffleData: (WaffleBoxProps | null)[] = lastMeasurementsByMachine.map(machineDataBundle => {
    let color = 'grey'
    const machineId = Object.keys(machineDataBundle)[0]
    const machineIdFragment = machineId.substring(3, 11)
    const machineData: MachineWithOptionalInlineFluid | undefined = machines
      ? machines.find(machine => machine.id === machineId)
      : undefined
    if (!machineData) return null
    let relevantMeasurements = machineDataBundle[machineId]
    if (metricId) {
      relevantMeasurements = relevantMeasurements.filter((measurement: any) => {
        return measurement && measurement.metricId === metricId
      })
      const singleMeasurement = relevantMeasurements[0]
      // Check that min and max exist and are numbers
      if (singleMeasurement && isFinite(singleMeasurement.min) && isFinite(singleMeasurement.max)) {
        if (singleMeasurement.value < singleMeasurement!.min!) {
          color = 'blue'
        }
        if (singleMeasurement.value > singleMeasurement!.max!) {
          color = 'orange'
        }
        if (singleMeasurement.value >= singleMeasurement!.min! && singleMeasurement.value <= singleMeasurement!.max!) {
          color = 'green'
        }
      }
    } else {
      // reduce over all metrics and see whether they’re inside or outside their thresholds
      relevantMeasurements = relevantMeasurements.filter((measurement: any) => {
        return measurement.metricId !== 'image'
      })
      const metricsOutsideThresholds = countBy(relevantMeasurements, (measurement: any) => {
        return measurement.value < measurement.min || measurement.value > measurement.max
      })
      if (!metricsOutsideThresholds.false && metricsOutsideThresholds.true) {
        color = 'red'
      }
      if (metricsOutsideThresholds.false && metricsOutsideThresholds.true) {
        color = 'orange'
      }
      if (metricsOutsideThresholds.false && !metricsOutsideThresholds.true) {
        color = 'green'
      }
    }
    const tooltipElement: React.ReactElement = (
      <>
        <span></span>
        <ul>
          <li>
            <strong>
              {machineData.doc.name} <MachineId id={machineIdFragment} />
            </strong>
          </li>
          <hr className="mini" />
          {machineData.doc.inventoryNumber && (
            <li>
              <span>
                <strong>Inventarnummer:</strong> {machineData.doc.inventoryNumber}
              </span>
            </li>
          )}
          {machineData.doc.location && (
            <li>
              <span>
                <strong>Standort:</strong> {machineData.doc.location}
              </span>
            </li>
          )}
          {machineData.fluid && (
            <li>
              <span>
                <strong>Fluid:</strong> {machineData.fluid.name}
              </span>
            </li>
          )}
          <hr className="mini" />
          {relevantMeasurements.length === 0 && <span>Keine Messungen im Zeitraum</span>}
          {relevantMeasurements.map(
            (measurement: any): React.ReactElement => {
              let state = 'within-threshold'
              if (measurement.value < measurement.min) state = 'below-threshold'
              if (measurement.value > measurement.max) state = 'above-threshold'
              const meta: MetricMeta = getMetricMetadata(measurement.metricId) || {
                id: measurement.metricId,
                name: measurement.metricId,
                unit: '',
                type: ''
              }
              if (meta.type !== 'minmax') {
                state = ''
              }
              return (
                <li key={`measurement-${measurement.metricId}`}>
                  <strong>{meta.name}: </strong>
                  <span className={`metric-value ${state}`}>
                    {getIconForMeasurementStatus(state)} {measurement.value}
                    {meta.unit}
                  </span>{' '}
                  <small>({format(new Date(measurement.measuredAt), dateFormat)})</small>
                </li>
              )
            }
          )}
        </ul>
      </>
    )
    return {
      color,
      clickTarget: `/maschine/${machineIdFragment}/protokoll`,
      tooltipElement
    }
  })
  return <Waffle data={waffleData} />
}

export default MeasurementWaffle

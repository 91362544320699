import React from 'react'
import { InteractiveComponentProps } from '../machine-protocol'
import { format } from 'date-fns'
import { dateFormat } from '../../utils/general'

export default class Text extends React.PureComponent<InteractiveComponentProps> {
  public render(): React.ReactNode {
    const { measurement, meta } = this.props
    const localisedDate = format(new Date(measurement.measuredAt), dateFormat)
    return (
      <div className="measurement-text-container">
        <div className="measurement-label">
          <span className="measurement-name">{meta.name}</span>
          <span className="measurement-date">vom {localisedDate}</span>
          <textarea className="measurement-value textarea" value={measurement.value} readOnly disabled />
        </div>
      </div>
    )
  }
}

import React, { useState, useEffect, useContext } from 'react'
import classNames from 'classnames'
import { format } from 'date-fns'
import { useAlert, types } from 'react-alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Customer, MachineStateCount } from '../hooks/useCustomers'
import useSortableData from '../hooks/useSortableData'
import { UserRoles } from '../utils/user-roles'
import { SortableHeaderButton } from './SortableHeaderButton'
import AddCustomer from './forms/add-customer'
import AppContext from '../AppContext'
import { get } from 'lodash'
import { getStateLabel, MachineState, machineStateLabels } from './machine-inventory'

type TableProps = {
  customers: Array<Customer>;
  userRoles: UserRoles;
  error?: string;
  onCustomerSelect(customer: Customer, redirectTarget?: string): void;
  forceRefresh(): void;
}

interface DecoratedCustomer extends Customer {
  machineAmountForSorting?: number;
  reselleeAmount?: number;
  reselleeMachines?: MachineStateCount;
  resellerMachines?: MachineStateCount;
}

interface DecoratedCustomer extends Customer {
  reselleeAmount?: number
}

export default function CustomerTable({ customers, userRoles, onCustomerSelect, error, forceRefresh }: TableProps) {
  const alert = useAlert()
  const [filter, setFilter] = useState<string>('')
  const [filteredItems, setFilteredItems] = useState<DecoratedCustomer[]>(customers)
  const [showOnlyResellers, setShowOnlyResellers] = useState<boolean>(false)
  const { currentUser } = useContext(AppContext)
  const { customer: currentCustomer } = currentUser

  const userIsHighLevelAdmin = userRoles.isGlobalAdmin()

  const { items: sortedItems, requestSort, sortConfig } = useSortableData(filteredItems, {
    key: 'name',
    direction: 'ascending'
  })

  const showDeleteButtons = process.env.NODE_ENV === 'development'

  useEffect(() => {
    const filteredCustomers = customers.filter(customer => {
      // Filter out non-customer docs
      if (!customer.name) return false
      const allowedByResellerFilter = showOnlyResellers ? !!customer.resellerStats : true
      const allowedBySearchFilter = filter ? customer._id.toLowerCase().includes(filter.toLowerCase()) ||
      customer.name.toLowerCase().includes(filter.toLowerCase()) : true
      return allowedByResellerFilter && allowedBySearchFilter
    })
    const decoratedCustomers = filteredCustomers.map((customer: Customer): DecoratedCustomer => {
      const reselleeMachines = get(customer, 'resellerStats.reselleeMachines') || {}
      let totalActiveReselleeMachines = 0
      if (reselleeMachines) {
        for(const state in reselleeMachines) {
          if (state !== MachineState.DELETED && state !== MachineState.IMPOUNDED) {
            totalActiveReselleeMachines += reselleeMachines[state]
          }
        }
      }
      return {
        ...customer,
        // Make resellers and regular customers sortable by different metrics when sorting by "machines":
        // - regular customers simply use their machine count
        // - resellers use the sum of their resellees’ active and deactivated machines (we omit deleted and impounded)
        machineAmountForSorting: customer.resellerStats ? totalActiveReselleeMachines : customer.machines,
        reselleeAmount: get(customer, 'resellerStats.reselleeAmount') || 0,
        reselleeMachines,
        resellerMachines: get(customer, 'resellerStats.resellerMachines') || {}
      }
    })
    setFilteredItems(decoratedCustomers)
  }, [customers, filter, showOnlyResellers])

  const handleCustomerSelect = (event: React.MouseEvent<HTMLElement>): void => {
    const customerId = event.currentTarget.children[1].innerHTML
    const customer = customers.find(customer => {
      return customer._id === customerId
    })
    if (customer && onCustomerSelect) {
      onCustomerSelect(customer, '/')
    }
  }

  const handleCustomerDelete = async (event: React.MouseEvent<HTMLElement>, customerIdToDelete: string): Promise<void> => {
    event.stopPropagation()
    const { REACT_APP_COUCHDB_ENDPOINT } = process.env

    const resellerId = currentCustomer && currentCustomer.reseller ? `/${currentCustomer._id}` : ''

    await fetch(`${REACT_APP_COUCHDB_ENDPOINT}/_backend/customer/${customerIdToDelete}${resellerId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json'
      }
    })
      .then(
        async (res): Promise<void> => {
          if (res.status === 200) {
            alert.show('Kunde gelöscht', {
              type: types.SUCCESS
            })
            forceRefresh()
          } else {
            console.log('Could not delete customer: ', res)
          }
        }
      )
      .catch((error): void => {
        console.log('Error deleting customer: ', error)
      })
  }

  const getSortDirection = (name: string) => {
    if (!sortConfig) {
      return
    }
    return sortConfig.key === name ? sortConfig.direction : undefined
  }
  const getSortProps = (id: string) => ({
    id,
    sortDirection: getSortDirection(id),
    onRequestSort: () => requestSort(id)
  })

  if (!customers || customers.length === 0) {
    return <>
      <h4 className="title is-4">Keine Kunden zur Auswahl.</h4>
      <AddCustomer onSuccess={forceRefresh} customers={customers} userRoles={userRoles}/>
    </>
  }
  const customerTitle = customers.length === 1 ? 'Ein Kunde' : `${customers.length} Kunden`
  return (
    <>
      {error && <div className="notification is-warning">{error}</div>}
      <h4 className="title is-4">{customerTitle}</h4>
      <AddCustomer onSuccess={forceRefresh} customers={customers} userRoles={userRoles}/>
      <hr />
      <label className="label">Filtern</label>
      <div className="field has-addons">
        <div className="control has-icons-left is-expanded">
          <input
            className="input"
            type="text"
            placeholder="Firmenname/Kunden-ID"
            onChange={e => setFilter(e.currentTarget.value)}
            value={filter}
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon="search" />
          </span>
        </div>
        {filter && (
          <div className="control">
            <button className="button is-info" onClick={e => setFilter('')}>
              <span className="icon">
                <FontAwesomeIcon icon="times" size="sm" pull="left" />
              </span>
              <span>Filter entfernen</span>
            </button>
          </div>
        )}
      </div>
      {userIsHighLevelAdmin && (
        <div className="control">
          <div className="checkbox">
            <label className="label" htmlFor="resellersOnly">
              <input name="resellersOnly" type="checkbox" className="input" value="false" onChange={() => setShowOnlyResellers(!showOnlyResellers)}/>
              <span className="icon-text">
                <span className="icon">
                  <FontAwesomeIcon icon="handshake" size="sm" />
                </span>
                <span>Nur Reseller anzeigen</span>
              </span>
            </label>
          </div>
        </div>
      )}
      <div>
        <div className="table-container">
          <table className="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>
                  <SortableHeaderButton label="Kundenname" {...getSortProps('name')} />
                </th>
                <th>
                  <span className="button is-static">Kunden-ID</span>
                </th>
                <th>
                  <SortableHeaderButton label="Benutzer" {...getSortProps('users')} />
                </th>
                <th>
                  <SortableHeaderButton label="Maschinen" {...getSortProps('machineAmountForSorting')} />
                </th>
                {userIsHighLevelAdmin && (
                  <th>
                    <SortableHeaderButton label="Kunden" {...getSortProps('reselleeAmount')} />
                  </th>
                )}
                <th>
                  <span className="button is-static">Erste Maschine erstellt</span>
                </th>
                <th>
                  <span className="button is-static">Letzte Aktivität</span>
                </th>
                <th>
                  <span className="button is-static">Erstellt</span>
                </th>
                {showDeleteButtons && <th></th>}
              </tr>
            </thead>
            <tbody>
              {sortedItems.length === 0 && (
                <tr><td>Keine Kunden für diese Filtereinstellung</td></tr>
              )}
              {sortedItems.map(
                (customer: DecoratedCustomer): React.ReactElement => {
                  const { machines, error, isLockedForOsUsers, reselleeAmount, reselleeMachines, machineAmountForSorting } = customer
                  let reselleeStatDisplay = []
                  if (reselleeMachines) {
                    for(const state in reselleeMachines) {
                      reselleeStatDisplay.push(getStateLabel(state as MachineState, undefined, `: ${reselleeMachines[state]}`))
                    }
                  }
                  const isLocked = userRoles.isOsUser() && isLockedForOsUsers
                  const isReseller = customer.resellerStats
                  const rowClasses = classNames({
                    'is-row-link': !isLocked
                  })

                  return (
                    <tr className={rowClasses} onClick={isLocked ? () => {} : handleCustomerSelect} key={customer._id}>
                      <td>
                      <span className="icon-text">
                        {isLocked && (
                          <span className="icon">
                            <FontAwesomeIcon icon="lock" size="sm" />
                          </span>
                        )}
                        <span><strong>{customer.name}</strong></span>
                        {isReseller && (
                          <span className="icon">
                            <FontAwesomeIcon icon="handshake" size="sm" />
                          </span>
                        )}
                        </span>
                      </td>
                      <td id="id" className="is-family-monospace">
                        {customer._id}
                      </td>
                      <td>{customer.users || '-'}</td>
                      <td>{reselleeAmount
                        ? <span className="tag is-primary has-text-weight-bold">Abrechenbar: {machineAmountForSorting}</span>
                        : machines
                      }{reselleeStatDisplay && (
                        <ul>
                        {reselleeStatDisplay.map((d, index) => <li key={`state-${index}`}>{d}</li>)}
                        </ul>
                      )}</td>
                      {userIsHighLevelAdmin && (<td>{reselleeAmount}</td>)}
                      {customer.firstMachineDate ? (
                        <td>{format(new Date(customer.firstMachineDate), "dd.MM.yyyy 'um' kk:mm")}</td>
                      ) : (
                        <td>{error ? `Fehler: ${customer.error.message}` : '-'}</td>
                      )}
                      {customer.activityTime ? (
                        <td>{format(new Date(customer.activityTime), "dd.MM.yyyy 'um' kk:mm")}</td>
                      ) : (
                        <td>{error ? `Fehler: ${customer.error.message}` : 'Keine in diesem Kalendermonat'}</td>
                      )}
                      {customer.createdAt ? (
                        <td>{format(new Date(customer.createdAt), "dd.MM.yyyy 'um' kk:mm")}</td>
                      ) : (
                        <td>{error ? `Fehler: ${customer.error.message}` : '-'}</td>
                      )}
                      {showDeleteButtons && <td>
                        <button className="button is-danger" onClick={e => handleCustomerDelete(e, customer._id)}>
                          <span className="icon">
                            <FontAwesomeIcon icon="trash-alt" size="sm" />
                          </span>
                        </button>
                      </td>}
                    </tr>
                  )
                }
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

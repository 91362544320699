import React, { Component, ReactElement } from 'react'
import { ResponsiveLine, LineSerieData } from '@nivo/line'
import { LinearScale } from '@nivo/scales'
import { maxBy, minBy, last, concat, flatten, get } from 'lodash'
import classNames from 'classnames'
import differenceInDays from 'date-fns/differenceInDays'
import { area, curveMonotoneX } from 'd3-shape'

import './graphs.scss'
import { getMetricMetadata } from '../../utils/default-metrics'
import { cleanLineGraphData } from '../../utils/graph-data'
import { GenericLoadingSpinner } from '../partials'

/*

The Line Chart

Definitely see the readme.md in this folder for higher-level details and design decisions concerning graphing
Takes:

data: LineSerieData[]           // Nivo-formatted series of data points in this order: min, max, should, measurement
title?: string                  // The graph title
error?: Error                   // Used by the parent component to pass in errors from data fetching
isFetching: boolean             // Used by the parent component to tell the line chart to display a loading msg
customMarkers?: CustomMarker[]  // An array of vertical event markers (see interface below)
shortMarkers?:boolean           // show markers without labels

*/

const colors = {
  measurement: '#000',
  minimum: '#1285d0',
  maximum: '#fabb2f',
  sollwert: '#72c983'
}

const chartColors = [colors.minimum, colors.maximum, colors.sollwert, colors.measurement]

// All of our markers are vertical, so we only have an `x`-key
// Classname is the css classname for the svg container
// Legend is the displayed text
interface CustomMarker {
  x: string;
  className: string;
  legend: string;
  id?: string;
  icon?: React.ReactNode;
}

export interface LineChartProps {
  data: LineSerieData[];
  title?: string;
  error?: Error;
  isFetching: boolean;
  customMarkers?: CustomMarker[];
  shortMarkers?: boolean;
  eventClickTargetPrefix?: string; // /machine/62z262za/
}

interface LineChartState {
  sanitisedData?: LineSerieData[];
  startDate: string;
  endDate: string;
  daysOnXAxis: number; // How many days does the x-axis cover? (note: NOT how many ticks does the axis have)
  resolution: string; // Whether each tick is a day, month or year
}

const defaultState = {
  sanitisedData: undefined,
  startDate: '',
  endDate: '',
  daysOnXAxis: 0,
  resolution: 'day'
}

class LineChart extends Component<LineChartProps, LineChartState> {
  public constructor(props: LineChartProps) {
    super(props)
    this.state = defaultState
    this.parseData = this.parseData.bind(this)
    this.makeMarkers = this.makeMarkers.bind(this)
    this.customAreas = this.customAreas.bind(this)
    this.renderToolTip = this.renderToolTip.bind(this)
  }

  public parseData() {
    /*
      Collect data we need to calculate the x axis attributes:
        - startDate YYYY-MM-DD  // earliest date in all of the series in data
        - endDate YYYY-MM-DD    // latest date in all of the series or events/actions in data,
        - from that, calculate a resolution automatically (days, weeks, months)
    */
    const { data: unsanitisedData, customMarkers } = this.props
    const data = cleanLineGraphData(unsanitisedData) || []
    const hasData = data[0] && last(data)!.data.length > 0
    if (hasData) {
      const lastDatum = last(last(data)!.data)
      const lastEvent = last(customMarkers)
      // fix #95 by adding the last event’s date as a null data point,
      // this prevents the graph from being cut off too soon
      // Todo: a better fix would be to extend the threshold lines to the end with the same values
      // their last points have
      if (lastDatum && lastDatum.x && lastEvent) {
        if (lastDatum.x < lastEvent.x) {
          last(data)!.data.push({
            x: lastEvent.x,
            // cleanLineGraphData only returns number here, but null is ok too, Typescript, trust me
            y: (null as unknown) as number
          })
        }
      }
      const startDate = last(data)!.data[0].x as string
      const endDate = last(last(data)!.data)!.x as string
      const daysOnXAxis = differenceInDays(new Date(endDate), new Date(startDate))
      let resolution = 'day'
      // TODO: these magic numbers may need tweaking
      if (daysOnXAxis > 30) resolution = 'week'
      if (daysOnXAxis > 90) resolution = 'month'
      this.setState({
        sanitisedData: data,
        startDate,
        endDate,
        daysOnXAxis,
        resolution
      })
    } else {
      this.setState(defaultState)
    }
  }

  public componentDidMount() {
    this.parseData()
  }

  public shouldComponentUpdate(_nextProps: LineChartProps, nextState: LineChartState): boolean {
    if (!this.state.sanitisedData && nextState.sanitisedData) {
      return true
    }
    const firstSeriesNow = get(this.state, 'sanitisedData[0].data')
    const firstSeriesNext = get(nextState, 'sanitisedData[0].data')
    if (firstSeriesNow && firstSeriesNext && firstSeriesNow.length !== firstSeriesNext.length) {
      // This doesn’t really need to trigger this.parseData() again in componentDidUpdate…
      return true
    }
    if (get(_nextProps, 'data[0].data.length') !== get(this.props, 'data[0].data.length')) {
      return true
    }
    return false
  }

  public componentDidUpdate() {
    this.parseData()
  }

  /*
    Render the marker layer

    Renders vertical marker lines with a 30° slanted label at the top and stacks them in a manner that
    generally avoids the labels overlapping.
  */
  public makeMarkers(props: any): React.ReactNode {
    const { customMarkers, shortMarkers, eventClickTargetPrefix } = this.props
    if (!customMarkers) return null
    let prevHeight = 0
    let prevXPos = props.innerWidth
    // we draw the markers from right to left so we can stack them more easily
    const reversedMarkers = concat(customMarkers).reverse()
    return reversedMarkers.map(
      (markerData: CustomMarker, index: number): React.ReactNode => {
        const { x, className, legend, id, icon } = markerData
        // How far from the left edge of the x-axis is this marker in days?
        const daysToMarker = differenceInDays(new Date(x), new Date(this.state.startDate))
        // Don’t display this if it’s not visible (overflows left)
        if (daysToMarker < 0) return null
        // TODO: also handle overflow right?
        // How far is it in pixels?
        let pos = (props.innerWidth * daysToMarker) / this.state.daysOnXAxis || props.innerWidth
        // Fix multiplication by zero issue if daysToMarker is zero
        if (daysToMarker === 0) pos = 0
        // Which CSS classes will the marker receive?
        const classes = classNames({
          'line-marker': true,
          [className]: true
        })
        let [year, month, day] = x.split('-').map(str => parseFloat(str))
        // Some trigonometry to figure out whether the current marker would overlap the previous one,
        // and if so, how far it needs to be moved up to prevent this.
        // How high is each label line in pixels?
        const stackItemHeight = 18
        // What angle do we want the text label to be at? Note this won’t magically produce a perfect graph for all angles, you
        // will need to nudge the lines and the text label in the svg element below around a bit to make it look good.
        const labelAngle = 30
        // How far will this specific label be moved upwards so it will clear the one next to it?
        let labelYOffset = 0
        // How far away is this marker from the one to the right of it?
        const distanceToNextMarker = prevXPos - pos
        // `shadowLength` is the distance required to fit two labels defined by `labelAngle` and `stackItemHeight` next to each other
        // without them overlapping. To visualise the math:
        // http://cossincalc.com/#angle_a=30&side_a=18&angle_b=&side_b=&angle_c=90&side_c=&angle_unit=degree
        const degreesToRadians = (degrees: number): number => (degrees * Math.PI) / 180
        // omg trig
        const shadowLength = (Math.sin(degreesToRadians(90 - labelAngle)) * 18) / Math.sin(degreesToRadians(labelAngle))
        // totalStackLevels is used to calculate how high the stack of labels to the right of this marker is in levels, not pixels
        let totalStackLevels = Math.ceil(prevHeight / stackItemHeight) + 1
        if (totalStackLevels < 1) totalStackLevels = 1
        const needsToMoveUp: boolean = distanceToNextMarker < totalStackLevels * shadowLength
        // The rightmost marker never needs to move up, so the one with index 0 is exempt
        if (needsToMoveUp && index !== 0) {
          // Calculate how far left we need to move this marker so it cannot collide with the next stacked marker somewhere to its right
          labelYOffset = (1 - distanceToNextMarker / shadowLength) * stackItemHeight + prevHeight
        }
        // Store thew new vars for use in the next loop
        prevHeight = labelYOffset
        prevXPos = pos
        let target
        if (id && className === 'action') {
          target = `${eventClickTargetPrefix || '/'}aktion/${id.substr(12)}`
        }
        let formattedLegend = legend
        const maxLabelLength = 23
        // Format the label that appears at the top of the marker
        if (legend.length > maxLabelLength) {
          if (legend.indexOf('\n') !== -1) {
            // If the label contains a newline, split there and only show the stuff before it in the marker label
            formattedLegend = legend.split('\n')[0]
          } else {
            // If not, truncate label if necessary
            formattedLegend = `${legend.substr(0, maxLabelLength)}…`
          }
        }
        // Random key suffix allows us to have multiple markers per day
        const rando = Math.ceil(Math.random() * 1e8)
        // Render a text label and two lines (one long vertical one and a short tick from the top of the first to the label)
        return (
          <g className={classes} transform={`translate(${pos}, ${labelYOffset * -1})`} key={`marker-${x}-${rando}`}>
            <line x1="0" x2="0" y1="0" y2={props.innerHeight + labelYOffset}></line>
            {shortMarkers !== true && (
              <>
                <line x1="0" x2="12" y1="0" y2="-7"></line>
                <a href={target}>
                  <g transform={`translate(15, -9) rotate(-${labelAngle})`}>
                    <foreignObject className="iconContainer">{icon}</foreignObject>
                    <text
                      className="label"
                      textAnchor="start"
                      dominantBaseline="central"
                      style={{ transform: 'translate(1em, 0)' }}
                      dx="5px"
                    >
                      {day}.{month}: {formattedLegend}
                    </text>
                  </g>
                </a>
                <foreignObject className="tooltipContainer">
                  <div className="tooltip">
                    <div className="label">Datum</div>
                    <div>
                      {day}.{month}.{year}
                    </div>
                    <div className="label">Anmerkung</div>
                    <div>{legend}</div>
                    {className === 'action' && (
                      <>
                        <div className="label">Erledigt</div>
                        <div>{icon}</div>
                      </>
                    )}
                  </div>
                </foreignObject>
              </>
            )}
          </g>
        )
      }
    )
  }

  public customAreas(props: any): React.ReactNode {
    const { sanitisedData } = this.state
    if (!sanitisedData) return null
    const { series, xScale, yScale, innerHeight } = props
    // The top of the graph is at 0
    // The bottom of the graph is at `innerHeight`
    // To convert a data value to a pixel point on an axis, use the xScale() and yScale() helpers
    // You give them a value on their respective axis and you get back a pixel value/position
    const areaGeneratorMax = area()
      .x((d: any) => xScale(d.data.x))
      // y0 is the bottom line
      .y0((d: any) => yScale(d.data.y))
      // y1 is the top/base line
      .y1((d: any) => 0)
      .curve(curveMonotoneX)
    const areaGeneratorMin = area()
      .x((d: any) => xScale(d.data.x))
      // y0 is the bottom/base line
      .y0((d: any) => Math.min(innerHeight, yScale(d.data.y - 40)))
      // y1 is the top line
      .y1((d: any) => yScale(d.data.y))
      .curve(curveMonotoneX)
    const minSeries = series.find((serie: LineSerieData) => serie.id === 'Minimum')
    const maxSeries = series.find((serie: LineSerieData) => serie.id === 'Maximum')
    return (
      <>
        {minSeries && (
          <path
            d={areaGeneratorMin(minSeries.data) as string | undefined}
            fill="#ddf1fd"
            fillOpacity={0.5}
            strokeWidth={0}
          />
        )}
        {maxSeries && (
          <path
            d={areaGeneratorMax(maxSeries.data) as string | undefined}
            fill="#fde4af"
            fillOpacity={0.5}
            strokeWidth={0}
          />
        )}
      </>
    )
  }

  // Render for the tooltip. `slice` includes all available data in the vertical strip of the graph where the mouse is
  // Nivo isn’t 100% TSified, hence the two `any`s here
  private renderToolTip({ slice }: any): React.ReactNode {
    // There could conceivably be gaps in all data series at this slice,
    // so the tooltip would have no data to show
    if (!slice.points[0]) return null
    const day = new Date(slice.points[0].data.x)
    // We can’t be sure which series is where in the points array, or if it‘s there at all
    const min = slice.points.find((point: any) => point.serieId === 'Minimum')
    const max = slice.points.find((point: any) => point.serieId === 'Maximum')
    const should = slice.points.find((point: any) => point.serieId === 'Sollwert')
    const measurement = slice.points.find(
      (point: any) => point.serieId !== 'Sollwert' && point.serieId !== 'Minimum' && point.serieId !== 'Maximum'
    )
    const meta = getMetricMetadata(measurement.serieId as string)
    return (
      <div
        style={{
          background: 'white',
          padding: '9px 12px',
          border: '1px solid #ccc'
        }}
      >
        <div>{day.toLocaleDateString()}</div>
        <div style={{ color: measurement.serieColor }}>
          <strong>{meta.name}:</strong> {measurement.data.yFormatted}
          {`${meta.unit ? ` (${meta.unit})` : ''}`}
        </div>
        {max && (
          <div style={{ color: max.serieColor }}>
            <strong>{max.serieId}:</strong> {max.data.yFormatted}
            {`${meta.unit ? ` (${meta.unit})` : ''}`}
          </div>
        )}
        {should && (
          <div style={{ color: should.serieColor }}>
            <strong>{should.serieId}:</strong> {should.data.yFormatted}
            {`${meta.unit ? ` (${meta.unit})` : ''}`}
          </div>
        )}
        {min && (
          <div style={{ color: min.serieColor }}>
            <strong>{min.serieId}:</strong> {min.data.yFormatted}
            {`${meta.unit ? ` (${meta.unit})` : ''}`}
          </div>
        )}
      </div>
    )
  }
  public render(): React.ReactNode {
    const { sanitisedData } = this.state
    const { title, error, isFetching, customMarkers, shortMarkers } = this.props
    if (error) {
      return (
        <div className="notification is-warning">
          <strong>Fehler:</strong> {error.message || error}
        </div>
      )
    }
    if (isFetching) {
      return <GenericLoadingSpinner message="Lade Messdaten" noProgressBar={true} />
    }
    const noDatapoints = !sanitisedData || last(sanitisedData)!.data.length === 0
    if (noDatapoints || !sanitisedData) {
      return <div className="notification">Keine Messungen in diesem Zeitraum</div>
    }

    const measurementSeries = sanitisedData.find(
      (serie: LineSerieData) => serie.id !== 'Sollwert' && serie.id !== 'Minimum' && serie.id !== 'Maximum'
    )
    const meta = getMetricMetadata(measurementSeries!.id as string)
    // If there is only one data point, don’t show the graph. Caveat: we sometimes add points with
    // `null` as a value to the end of the _last_ (measurement) series to stretch the graph so it includes markers
    // that come after the last real measurement value.
    const notEnoughDatapoints = measurementSeries && measurementSeries.data.length <= 1
    if (notEnoughDatapoints) {
      return (
        <div className="notification">
          <strong>{meta.name}:</strong> Nicht genügend Messungen in diesem Zeitraum
        </div>
      )
    }
    const renderChart = (): React.ReactElement => {
      // Define Y axis and scale
      let axisLeft = null
      axisLeft = {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: `${meta.name}${meta.unit ? ` (${meta.unit})` : ''}`,
        // Don’t ask
        legendPosition: 'middle' as 'middle' | 'start' | 'end' | undefined,
        legendOffset: -50
      }
      let yScale: LinearScale = {
        type: 'linear',
        stacked: false,
        min: 'auto',
        max: 'auto'
      }
      // Calculate min/max for the yScale
      // The aim is to make sure that all points in all series are visible, and to add some vertical
      // padding, which `auto` regrettable doesn't do, and can’t be configured to do either
      const minY = minBy(flatten(sanitisedData.map(series => series.data)).map(datum => datum.y))
      const maxY = maxBy(flatten(sanitisedData.map(series => series.data)).map(datum => datum.y))
      if (minY && maxY) {
        // The `+` is Typescript foo: https://github.com/microsoft/TypeScript/issues/5710#issuecomment-157886246
        yScale.min = +minY - +minY * 0.1
        yScale.max = +maxY * 1.1
      }
      let topMargin = 50
      const hasMarkers = customMarkers && customMarkers.length > 0
      if (hasMarkers && !shortMarkers) {
        topMargin = 175
      }
      // Render the actual graph
      return (
        <>
          <ResponsiveLine
            data={sanitisedData}
            margin={{ top: topMargin, right: 250, bottom: 50, left: 70 }}
            xScale={{
              type: 'time',
              format: '%Y-%m-%d',
              precision: 'day'
            }}
            yScale={yScale}
            curve="monotoneX"
            enableSlices="x"
            sliceTooltip={this.renderToolTip}
            axisTop={null}
            axisLeft={axisLeft}
            axisRight={null}
            axisBottom={{
              format: '%d.%m.%y',
              tickValues: `every ${this.state.resolution}`,
              tickRotation: 30,
              tickSize: 5,
              tickPadding: 10
            }}
            enableGridX={false}
            enableGridY={true}
            colors={chartColors}
            lineWidth={3}
            pointSize={8}
            pointColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
            pointBorderWidth={2}
            pointBorderColor={'#f9f7fb'}
            pointLabel="y"
            pointLabelYOffset={0}
            useMesh={false}
            animate={false}
            layers={[
              'grid',
              'markers',
              this.customAreas,
              'areas',
              'lines',
              'slices',
              'axes',
              'points',
              this.makeMarkers
            ]}
          />
          {renderLegend()}
        </>
      )
    }
    const renderLegend = (): React.ReactElement | null => {
      if (!sanitisedData) return null
      const reversedDataForLegend = sanitisedData!.concat([])
      reversedDataForLegend.reverse()
      return (
        <div className="legends">
          <ul>
            {sanitisedData &&
              reversedDataForLegend.map(
                (line: LineSerieData, index: number): ReactElement => {
                  const currentColor = chartColors[chartColors.length - index - 1]
                  const meta = getMetricMetadata(line.id as string)
                  let legendLabel = line.id as string
                  if (meta) {
                    legendLabel = `${meta.name}${meta.unit ? ` (${meta.unit})` : ''}`
                  }
                  return (
                    <li key={`label-${line.id}`} style={{ color: currentColor }}>
                      <span>{legendLabel}</span>
                    </li>
                  )
                }
              )}
          </ul>
        </div>
      )
    }
    const classes = classNames({
      chart: true,
      line: true,
      collapsed: !sanitisedData || sanitisedData[0].data.length === 0
    })
    return (
      <div className={classes}>
        {title && <h2>{title}</h2>}
        {renderChart()}
      </div>
    )
  }
}

export default LineChart

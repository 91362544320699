import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Machine, MachineListItemElement } from './machine-inventory'
import MachineId from './machine-id'
import doFilter from '../utils/filter'

interface Props {
  importableMachines: Machine[];
  selectHandler: (importableMachine: Machine) => void;
  targetId?: string;
}

const MachineImporter: React.FC<Props> = ({ importableMachines, selectHandler, targetId }) => {
  const [query, setQuery] = React.useState('')
  const [loadMoreAmount, setLoadMoreAmount] = useState<number>(20)
  const [filteredMachines, setFilteredMachines] = useState<Machine[]>([])

  useEffect(() => {
    if (query && query.length > 0) {
      setFilteredMachines(doFilter(importableMachines, query, [
        'doc.inventoryNumber',
        'doc.name',
        'doc.tags',
        'doc.location'
      ]))
    } else {
      setFilteredMachines(importableMachines)
    }
  }, [importableMachines, query])

  return (
    <>
      <div className="field">
        <label className="label">Maschine auswählen</label>
        <div className="control has-icons-left">
          <input
            className="input"
            type="text"
            placeholder="Name/Inventarnummer/Standort/Tag"
            onChange={e => setQuery(e.target.value.toLowerCase())}
            value={query}
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon="search" />
          </span>
        </div>
      </div>
      <div className="results">
        {!query && <p className="content">Sie können nach Inventarnummer, Maschinennamen, Standort oder Tags suchen.</p>}
        {filteredMachines.map((machine: Machine, index: number) => {
            if (index >= loadMoreAmount) {
              return null
            }
            const { doc } = machine
            return (
              <div key={doc._id}>
                <MachineListItemElement
                  machine={machine}
                  filter={''}
                  footerButton={
                    <button className="button is-primary" onClick={() => selectHandler(machine)}>
                      <span className="icon is-small is-left">
                        <FontAwesomeIcon icon="plus-circle" />
                      </span>
                      {targetId ? (
                        <span>
                          Als neue Maschine mit der ID <MachineId id={targetId} hideQRCode={true} /> importieren
                        </span>
                      ) : (
                        <span>Als neue Maschine importieren</span>
                      )}
                    </button>
                  }
                />
              </div>
            )
          })}
      </div>
      {loadMoreAmount < filteredMachines.length && (
        <button
          className="button is-large is-fullwidth is-info is-light"
          onClick={() => setLoadMoreAmount(loadMoreAmount + 20)}
        >
          Mehr anzeigen
        </button>
      )}
    </>
  )
}

export default MachineImporter

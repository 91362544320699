import { useState, useEffect } from 'react'
import PouchDB from 'pouchdb'
import { sortBy, compact, flatten, groupBy, forEach } from 'lodash'
import { getMonthDBNamesForCustomer } from '../utils/network'
import { Measurement } from '../components/graphs/waffle-measurements'

/*

This hook fetches the most recent measurements per metric for one machine for the last three months:

*/

export interface AllDocsResult {
  doc?: PouchDB.Core.ExistingDocument<any & PouchDB.Core.AllDocsMeta> | undefined;
  id: string;
  key: string;
  value: {
    rev: string;
    deleted?: boolean | undefined;
  };
}

export const useMostRecentMeasurements = (
  customerId: string | undefined,
  machineId?: string,
  key?: number | string
) => {
  const monthDBs = getMonthDBNamesForCustomer(customerId)
  const [lastMeasurements, setLastMeasurements] = useState<Measurement[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [hasLoaded, setHasLoaded] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    setHasLoaded(false)
    let didCancel = false
    const fetchData = async () => {
      const allDataByMonth = await Promise.all(
        monthDBs.map(async (monthDBName: any) => {
          const db = new PouchDB(monthDBName, { skip_setup: true })
          try {
            const res = await db.allDocs({
              startkey: machineId && `ma_${machineId}:`,
              endkey: machineId && `ma_${machineId}:\ufff0`,
              include_docs: true
            })
            return res
          } catch (e) {
            return { rows: [] }
          }
        })
      )
      // map out docs, remove empties, sort by _id, which effectively pre-groups
      // by metric and then by date, newest last
      const allData: any[] = sortBy(compact(flatten(allDataByMonth.map(m => m.rows)).map(m => m.doc)), '_id')
      const measurements = allData.filter((doc: any): boolean => {
        return doc.type === 'measurement'
      })
      const groupedMeasurements = groupBy(measurements, 'metricId')
      // Get most recent measurements per metric
      let lastMeasurements: any = []
      forEach(groupedMeasurements, metricMeasurements => {
        lastMeasurements.push(sortBy(metricMeasurements, 'measuredAt').pop())
      })

      if (!didCancel) {
        setLastMeasurements(lastMeasurements || [])
        setIsLoading(false)
        setHasLoaded(true)
      }
    }
    if (customerId) {
      fetchData()
    }
    // Clean up on unmount
    return () => {
      didCancel = true
    }
    // only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, machineId])
  return { lastMeasurements, isLoading, hasLoaded }
}

import { useState, useEffect } from 'react'
import PouchDB from 'pouchdb'

import { Person } from '../types/users-roles-privileges'

// `key` is some random string we can force this hook to re-run with
const usePersons = (customerId: string | undefined, key?: string) => {
  const [users, setUsers] = useState<Person[]>([])
  const [hasLoaded, setHasLoaded] = useState(false)
  const [responseIsFromCache, setResponseIsFromCache] = useState(true)
  const [responseStatus, setResponseStatus] = useState<number | undefined>(undefined)
  const fetchDataFromCache = async (db: PouchDB.Database, didCancel: boolean) => {
    const localPersonDocs = await db.allDocs({ include_docs: true })
    const localPersons = localPersonDocs.rows.map((row: any) => row.doc)
    if (!didCancel) {
      setUsers(localPersons)
      setResponseIsFromCache(true)
      setHasLoaded(true)
      return
    }
  }
  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      const { REACT_APP_COUCHDB_ENDPOINT } = process.env
      let customerPersons = new PouchDB(`persons-${customerId}`)
      const userDataResponse = await fetch(`${REACT_APP_COUCHDB_ENDPOINT}/_backend/user/${customerId}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json'
        }
      }).catch(async e => {
        // Unable to connect at all
        fetchDataFromCache(customerPersons, didCancel)
      })
      let formattedUserDocs = []
      if (userDataResponse) {
        if (userDataResponse.status === 200) {
          let userDocs
          const data = await userDataResponse.json()
          userDocs = data.users
          formattedUserDocs = userDocs.map((userDoc: any) => {
            return {
              _id: userDoc.email,
              ...userDoc,
              userId: userDoc.email,
              isAppUser: true
            }
          })
          // remove all synced persons and recreate the db with the new ones
          // ⚠️ This can fail if too many instances of the app are open on the same client
          try {
            await customerPersons.destroy()
            customerPersons = new PouchDB(`persons-${customerId}`)
            customerPersons.bulkDocs(formattedUserDocs)
          } catch (err) {
            console.log('Error while updating the local persons cache db', err)
          }
          if (!didCancel) {
            setUsers(formattedUserDocs)
            setResponseIsFromCache(false)
            setResponseStatus(userDataResponse.status)
            setHasLoaded(true)
          }
        } else {
          // Connected to server, but invalid response
          if (!didCancel) {
            setResponseStatus(userDataResponse.status)
          }
          fetchDataFromCache(customerPersons, didCancel)
        }
      }
    }
    if (customerId) {
      fetchData()
    }
    // Clean up on unmount
    return () => {
      didCancel = true
    }
  }, [customerId, key])
  return { persons: users, responseIsFromCache, responseStatus, hasLoaded }
}
export default usePersons

import React, { useContext, useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import AppContext from '../AppContext'
import { UserRoles } from '../utils/user-roles'
import { Privileges } from '../types/users-roles-privileges'
import useCustomers from '../hooks/useCustomers'
import { GenericLoadingSpinner } from '../components/partials'
import CustomerTable from '../components/CustomerTable'

export default function Customers() {
  const { onCustomerSelect, setCurrentCustomer, currentUser } = useContext(AppContext)
  const [key, setKey] = useState<number>(1)
  const { customers, hasLoadedCustomers, error } = useCustomers(key.toString())

  const forceRefresh = () => {
    setKey(key + 1)
  }

  const userRoles = UserRoles.fromSession()
  const userCanSwitchCustomers = userRoles.hasPrivilege(Privileges.CAN_SWITCH_CUSTOMERS)

  useEffect(() => {
    setCurrentCustomer(currentUser)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!userCanSwitchCustomers) {
    return <Redirect to="/" />
  } else if (!hasLoadedCustomers) {
    return <GenericLoadingSpinner message="Lade Kunden" />
  }
  return <CustomerTable customers={customers} onCustomerSelect={onCustomerSelect} error={error} userRoles={userRoles} forceRefresh={forceRefresh}/>
}

import React, { useContext, useState } from 'react'
import * as Yup from 'yup'
import { FieldArray, FieldArrayRenderProps, FormikHelpers, useFormikContext } from 'formik'
import { types, useAlert } from 'react-alert'

import { FormContainer, FormValues } from '@luxx/forms'
import FormItem from '@luxx/forms'
import { basicInvitationTemplate } from './../templates/invitation-template'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Role, User } from '../../types/users-roles-privileges'
import AppContext from '../../AppContext'
import { appName } from '../../utils/general'
import { customerRolesAndPrivileges, nhRolesAndPrivileges, osRolesAndPrivileges, resellerRolesAndPrivileges, UserRoles } from '../../utils/user-roles'
import { get } from 'lodash'

const userInvitationSchema = Yup.object().shape({
  users: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string()
          .min(2, 'Der Vorname sollte mindst. 2 Zeichen haben.')
          .required('Bitte Vorname ausfüllen.'),
        lastName: Yup.string()
          .min(2, 'Der Nachname sollte mindst. 2 Zeichen haben.')
          .required('Bitte Nachname ausfüllen.'),
        email: Yup.string()
          .email('Die E-Mail ist ungültig.')
          .required('Bitte E-Mail ausfüllen.')
      })
    )
    .required('Bitte mindestens eine Person einladen'),
  // Using mixed() here allows different types of validation
  roleId: Yup.mixed()
    .default(undefined) // This allows to have empty values at the beginning
    .required('Bitte Rolle wählen.') // This ensures a roleId is being selected
})

interface InvitationState {
  roleId: string;
  users: User[];
  subject: string;
  emailBody: string;
}

const emptyUser = {
  firstName: '',
  lastName: '',
  email: ''
}

// https://jaredpalmer.com/formik/docs/api/fieldarray
const UserList = () => {
  const { values }: FormValues = useFormikContext()

  return (
    <FieldArray
      name="users"
      render={(arrayHelpers: FieldArrayRenderProps): React.ReactNode => {
        return (
          <div className="usersWrapper mb2">
            {values.users.map(
              (user: string, index: number): React.ReactNode => {
                return (
                  <div className="well is-padded" key={`add-user-row-${index}`}>
                    <label className="label">{`${index + 1}. Benutzer/in`}</label>
                    <div className="columns">
                      <div className="column is-one-quarter">
                        <FormItem
                          label="Vorname*"
                          name={`users.${index}.firstName`}
                          type="input"
                          placeholder="Vorname"
                        />
                      </div>
                      <div className="column is-one-quarter">
                        <FormItem
                          label="Nachname*"
                          name={`users.${index}.lastName`}
                          type="input"
                          placeholder="Nachname"
                        />
                      </div>
                      <div className="column is-one-quarter">
                        <FormItem label="Email*" name={`users.${index}.email`} type="email" placeholder="Email" />
                      </div>
                      {index >= 1 && (
                        <div className="column is-one-quarter deleteButton">
                          <button
                            id="deleteUser"
                            className="button is-danger"
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <span className="icon">
                              <FontAwesomeIcon icon="times" size="sm" />
                            </span>
                            <span>Entfernen</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )
              }
            )}
            <button id="addUser" className="button is-info" type="button" onClick={() => arrayHelpers.push(emptyUser)}>
              <span className="icon">
                <FontAwesomeIcon icon="plus-circle" size="sm" />
              </span>
              <span>Weitere Person hinzufügen</span>
            </button>
          </div>
        )
      }}
    />
  )
}

interface UserInvitationFormProps {
  invitingUser: User;
  postSuccessHandler?(): void;
}

const initialValues: InvitationState = {
  users: [
    {
      firstName: '',
      lastName: '',
      email: ''
    }
  ],
  roleId: '',
  subject: `Willkommen bei ${appName}, unserem Fluid-Management-Tool`,
  emailBody: basicInvitationTemplate('')
}

enum InvitationRelationships {
  INVITING_NH ='invitingNh',
  INVITING_OS ='invitingOs',
  INVITING_RESELLER ='invitingReseller',
  INVITING_CUSTOMER = 'invitingCustomer'
}

const UserInvitationForm: React.FC<UserInvitationFormProps> = props => {
  const alert = useAlert()
  const { invitingUser, postSuccessHandler } = props
  const { currentCustomer } = useContext(AppContext)
  const { _id: customerId } = currentCustomer
  const [ invitationState, setInvitationState ] = useState(initialValues)
  const [ isSending, setIsSending ] = useState(false)
  let relationship = InvitationRelationships.INVITING_CUSTOMER
  // If invitingUser is reseller AND currentCustomer is that same reseller,
  // we are inviting for the reseller
  if (get(invitingUser, 'customer.reseller')) {
    if (invitingUser.customer._id === customerId) {
      relationship = InvitationRelationships.INVITING_RESELLER
    }
  }
  // If invitingUser is high-level admin AND customer is reseller,
  // we are also inviting for the reseller
  const userCanInviteForReseller = invitingUser.roles.some((r) => ['nh-admin', 'os-admin', 'os-inspector'].includes(r))
  if (get(currentCustomer, 'resellerStats') && userCanInviteForReseller) {
    relationship = InvitationRelationships.INVITING_RESELLER
  }

  if (customerId === 'nh') {
    relationship = InvitationRelationships.INVITING_NH
  }
  if (customerId === 'os' || customerId === 'oel') {
    relationship = InvitationRelationships.INVITING_OS
  }

  const onRoleChange = (value: string): void => {
    setInvitationState({
      ...invitationState,
      roleId: value,
      emailBody: basicInvitationTemplate(value, invitingUser)
    })
  }

  const renderRoleSelector = (roles: Role[]): React.ReactElement => {
    return <>{
      roles.map((role: Role) => {
        return <option value={role.id} id={role.id} key={role.id}>
          {role.name}
        </option>
      })
    }</>
  }

  const getPossibleRoles = (): React.ReactElement => {
    if (relationship === InvitationRelationships.INVITING_NH) {
      return renderRoleSelector(nhRolesAndPrivileges)
    }
    if (relationship === InvitationRelationships.INVITING_OS) {
      return renderRoleSelector(osRolesAndPrivileges)
    }
    if (relationship === InvitationRelationships.INVITING_RESELLER) {
      return renderRoleSelector(resellerRolesAndPrivileges)
    }
    return renderRoleSelector(customerRolesAndPrivileges)
  }

  return (
    <div className="userInvitationForm">
      <h3 className="title is-size-3">Weitere Personen einladen</h3>
      <FormContainer
        initialValues={invitationState}
        validationSchema={userInvitationSchema}
        submitLabel={isSending ? 'Versende Einladungen…' : 'Einladen'}
        onSubmit={async (values: FormValues, actions: FormikHelpers<FormValues>): Promise<void> => {
          actions.setSubmitting(true)
          setIsSending(true)
          const { REACT_APP_COUCHDB_ENDPOINT } = process.env
          await fetch(`${REACT_APP_COUCHDB_ENDPOINT}/_backend/user/${customerId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              accept: 'application/json'
            },
            body: JSON.stringify({
              users: values.users,
              role: values.roleId,
              mailer: {
                subject: values.subject,
                body: invitationState.emailBody
              }
            })
          })
            .then(
              async (res): Promise<void> => {
                const data = await res.json()
                if (res.status === 201) {
                  alert.show(data.message, {
                    type: types.SUCCESS
                  })
                  actions.resetForm()
                  if (postSuccessHandler) {
                    postSuccessHandler()
                  }
                } else {
                  alert.show(data.message, {
                    type: types.ERROR
                  })
                }
              }
            )
            .catch((error): void => {
              alert.show(`Fehler beim Anlegen der Nutzer: ${error}`, {
                type: types.ERROR
              })
            })
            .finally((): void => {
              setIsSending(false)
              actions.setSubmitting(false)
            })
        }}
        // Return nothing just to satify required demand
        putDocument={(): void => { }}
      >
        <FormItem
          component="select"
          name="roleId" // Maps with the object key in the state block
          label="Welche Rolle sollen die eingeladenen Benutzer/innen haben?*"
          /* NOTE: Formik has a built-in 'onChange' handler that leads to side-effects such as wiping away data shortly after it has been changed. As a workaround we use 'validate' instead of 'onChange' here.*/
          validate={(value: string) => {
            // If user has selected one of the 3 role options
            if (typeof value !== 'undefined') {
              return onRoleChange(value)
            }
          }}
        >
          <option value="">Bitte wählen:</option>
          {getPossibleRoles()}
        </FormItem>
        <label className="label">Bitte fügen Sie eine oder meherere Personen hinzu</label>
        <UserList />
        {invitationState.roleId !== 'cu-nologin' && (
          <>
            <FormItem type="text" label="Vorschau: Betreff der Einladungsnachricht" name="subject" disabled />
            <label className="label">Vorschau: Text der Einladungsnachricht</label>
            <div className="content messageBody">
              <p>{invitationState.emailBody || 'Bitte wählen Sie eine Rolle aus.'}</p>
            </div>
          </>
        )}
      </FormContainer>
    </div>
  )
}

export default UserInvitationForm

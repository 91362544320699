interface ListenOptions {
  interval: number;
  onRefresh(): void;
}

export default class AppRefresh {
  ish: string
  constructor() {
    // set git_ish or throw
    const { REACT_APP_GIT_ISH } = process.env
    if (!REACT_APP_GIT_ISH) {
      throw new Error('Found no REACT_APP_GIT_ISH in process.env')
    }
    this.ish = REACT_APP_GIT_ISH
  }

  async listen(options: ListenOptions) {
    this.fetchIsh(options) // run once on startup
    this.schedule(options)
  }

  schedule(options: ListenOptions) {
    const boundFetchIsh = this.fetchIsh.bind(this, options)
    window.setTimeout(boundFetchIsh, options.interval)
  }

  async fetchIsh(options: ListenOptions) {
    const { onRefresh } = options
    const url = `/_backend/git_ish?${Date.now()}`
    try {
      const response = await fetch(url)
      if (response.status === 200) {
        const gitIsh = await response.text()
        if (gitIsh.length > 40) {
          throw new Error('Invalid gitIsh')
        }
        const hasNewVersion = gitIsh.trim() !== this.ish.trim()
        if (hasNewVersion) {
          // if not equal: call onRefresh: schedule next event
          await onRefresh()
        }
        // if equal: schedule next event
        this.schedule(options)
      }
    } catch (error) {
      // if get fails: ignore, schedule next event
      this.schedule(options)
    }
  }
}

import { random, round } from 'lodash'

// Configurable helper to generate Nivo-formatted data series for line charts
export const generateSeries = ({
  startItem = { x: '2019-01-01', y: 8 }, // we gotta start somewhere
  amount = 100, // how many data points you want
  minDayStep = 1, // minimum days between data points
  maxDayStep = 10 // maximum days between data points (can be same as minDayStep for consistent gaps)
}) => {
  const series = []
  let prev = startItem

  // Left-paaad
  const padNumber = n => {
    return n.toString().padStart(2, '0')
  }

  for (let i = 1; i <= amount; i++) {
    let [year, month, day] = prev.x.split('-').map(str => parseFloat(str, 10))
    day += random(minDayStep, maxDayStep)
    // handle month and year overflow
    if (day > 30) {
      day = day % 30
      if (day === 0) day = 1
      month += 1
      if (month > 12) {
        year++
        month = 1
      }
    }
    // How far can our next data point move?
    const range = prev.y * 0.1
    let point = {
      x: `${year}-${padNumber(month)}-${padNumber(day)}`,
      // Wobble about a bit in a semi-plausible manner
      y: round(random(prev.y - range, prev.y + range), 3)
    }
    prev = point
    series.push(point)
  }
  return series
}

import { format } from 'date-fns'
import { last, sortBy } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import useSortableData from '../hooks/useSortableData'
import { getLabelForId, getMetricMetadata } from '../utils/default-metrics'
import { dateFormat } from '../utils/general'
import { MachineWithMostRecentMeasurements } from '../views/analysis'
import { Measurement } from './graphs/waffle-measurements'
import { SortableHeaderButton } from './SortableHeaderButton'

export const metricsToShow = ['ph', 'konzentration', 'nitrit']

interface Props {
  data?: MachineWithMostRecentMeasurements[];
}

const GlobalMeasurementTable = (props: Props) => {
  const { data } = props
  const { items, requestSort, sortConfig } = useSortableData(data, {
    key: 'machine.name',
    direction: 'ascending'
  })

  const getSortDirection = (name: string) => {
    if (!sortConfig) {
      return
    }
    return sortConfig.key === name ? sortConfig.direction : undefined
  }
  const getSortProps = (id: string) => ({
    id,
    sortDirection: getSortDirection(id),
    onRequestSort: () => requestSort(id)
  })

  if (!data || data.length === 0) {
    return <span>No machines</span>
  }
  return (
    <div className="table-container">
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th><SortableHeaderButton label="Maschine" {...getSortProps('machine.name')} /></th>
            <th className="hide-on-mobile"><span className="button">Material</span></th>
            <th className="hide-on-mobile"><span className="button">Operation</span></th>
            {metricsToShow.map((metricId: string) => (
              <th key={`${metricId}-table-header`}><SortableHeaderButton label={getMetricMetadata(metricId).name} {...getSortProps(`${metricId}.value`)} /></th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map(
            (datum: MachineWithMostRecentMeasurements): React.ReactNode => {
              if (!datum.machine || !datum.machine._id) return null
              const mostRecentMeasurement: Measurement | undefined = last(
                sortBy([datum.konzentration, datum.ph, datum.nitrit], 'measuredAt')
              ) as Measurement | undefined
              let material = '-'
              let operation = '-'
              if (mostRecentMeasurement) {
                material = getLabelForId(mostRecentMeasurement.material as string) || '-'
                operation = getLabelForId(mostRecentMeasurement.operation as string) || '-'
              }
              const machineId = datum.machine._id
              const url = `/maschine/${machineId.substr(3, 11)}`
              return (
                <tr key={`machine-${machineId}`} data-href={url}>
                  <td>
                    <Link to={url}>{datum.machine.name}</Link>
                  </td>
                  <td className="hide-on-mobile">
                    <Link to={url}>{material}</Link>
                  </td>
                  <td className="hide-on-mobile">
                    <Link to={url}>{operation}</Link>
                  </td>
                  {metricsToShow.map((metricId: string) => {
                    const measurement: Measurement = datum[metricId] as Measurement
                    if (measurement) {
                      const meta = getMetricMetadata(measurement.metricId)
                      return (
                        <td key={`machine-${datum.machine._id}-${metricId}`}>
                          <Link to={url}>
                            {measurement.value}
                            {meta.unit ? meta.unit : ''}{' '}
                            <small>({format(new Date(measurement.measuredAt), dateFormat)})</small>
                          </Link>
                        </td>
                      )
                    } else {
                      return (
                        <td key={`machine-${datum.machine._id}-${metricId}`}>
                          <Link to={url}>-</Link>
                        </td>
                      )
                    }
                  })}
                </tr>
              )
            }
          )}
        </tbody>
      </table>
    </div>
  )
}

export default GlobalMeasurementTable

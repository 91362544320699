import React from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { pdf } from '@react-pdf/renderer'
import LoadingBar from './LoadingBar'

interface SelfActivatingNavlinkProps {
  to: string;
  label: string;
  exact?: boolean;
}

/*

A helper to generate list items with tab links for use in Bulma’s Tab bar component
(see https://bulma.io/documentation/components/tabs/)
This helper is necessary because Bulma has its `is-active` styling on the `<li>`,
while `react-router-dom` expects it on the `<a>`

To use with an icon, pass the icon in as a child:

<SelfActivatingNavlink to={`${url}/aktion`} label='Aktion'>
  <FontAwesomeIcon icon='hand-point-up' size='sm' pull='left'/>
</SelfActivatingNavlink>

*/
export const SelfActivatingNavlink: React.FC<SelfActivatingNavlinkProps> = ({ to, label, children, exact }) => {
  let classes = classNames({
    'is-active': exact ? to === window.location.pathname : window.location.pathname.includes(to)
  })
  return (
    <li className={classes}>
      <NavLink to={to}>
        {children}
        {label}
      </NavLink>
    </li>
  )
}

interface PDFDownloadButtonProps {
  fileName: string;
  generatePDF(): React.ReactElement; // a function that returns a pdf, example:
  buttonLabel?: string;
}

// Example usage:
// <PDFDownloadButton
//   fileName={`Messprotokoll-${machine._id}-${someDateString}.pdf`}
//   generatePDF={() => <MeasurementsPDF measurements={groupedMeasurements} machine={machine} />}
// />
export const PDFDownloadButton: React.FC<PDFDownloadButtonProps> = props => {
  const downloadPDF = async () => {
    const document = props.generatePDF()
    if (!document) return
    const blob = await pdf(document).toBlob()
    saveAs(blob, props.fileName)
  }
  return (
    <button className="button is-light" onClick={() => downloadPDF()}>
      <span className="icon">
        <FontAwesomeIcon icon="file-pdf" size="sm" />
      </span>
      <span>{props.buttonLabel || 'Als PDF herunterladen'}</span>
    </button>
  )
}

interface GenericLoadingSpinnerProps {
  message?: string;
  noProgressBar?: boolean;
}

export const GenericLoadingSpinner: React.FC<GenericLoadingSpinnerProps> = props => {
  return (
    <div className="centered-flexbox box">
      <h1 className="title is-6">{props.message || 'Lade Daten…'}</h1>
      {!props.noProgressBar && <LoadingBar />}
      <FontAwesomeIcon icon="sync" spin size="4x" />
    </div>
  )
}

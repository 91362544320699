import React, { useState, useEffect, useContext } from "react";
import { FieldArray, FieldArrayRenderProps, useFormikContext, FormikProps } from 'formik'
import { MetricValidations, validateWrapper } from '../../utils/validations'
import * as Yup from 'yup'

import FormItem from '@luxx/forms'
import { getMetricMetadata, MetricMeta } from '../../utils/default-metrics'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppContext from '../../AppContext'
import useFluids from '../../hooks/useFluids'
import { CommonFluid } from '../../types/fluids'
import { round } from 'lodash'

interface FormValues {
  [key: string]: any;
}

const MachineMeasurementForm = (props: any) => {
  const { values }: FormikProps<FormValues> = useFormikContext()
  const metrics = values.metrics
  return (
    <FieldArray
    name="metrics"
    render={(arrayHelpers: FieldArrayRenderProps): React.ReactNode => {
      return (
        <>
            {metrics.map(
              (metric: MetricMeta, index: number): React.ReactElement => {
                return <FormItemWrapper {...metric} index={index} key={`mp${index}`}/>
              }
            )}
          </>
        )
      }}
    />
  )
}

interface FormItemWrapperProps extends MetricMeta {
  index: number;
  key: string;
}

const FormItemWrapper = (props: FormItemWrapperProps) => {
  let returning: React.ReactElement | null = null
  let customValidation: Yup.Schema<any> = MetricValidations[props.id]
  let validation = validateWrapper(customValidation)
  const [manualConcentrationInput, setManualConcentrationInput] = useState(false);
  const { currentCustomer } = useContext(AppContext)
  const { _id: customerId = '' } = currentCustomer
  const { allFluids, hasLoadedFluids } = useFluids(customerId)
  const [fluidInfo, setFluidInfo] = useState<CommonFluid>(undefined)
  const {
    setFieldValue
  } = useFormikContext();
  const {id, fluid, index} = props

  useEffect(() => {
    if (!manualConcentrationInput) {
      // Calculates Konzentration by multiplying refractometer value and reading
      if (id === 'konzentration') {
        if (fluid.rfm && props.refraktometerAblesewert) {
          setFieldValue(`metrics[${index}].value`, round(fluid.rfm * props.refraktometerAblesewert, 2))
        } else {
          setFieldValue(`metrics[${index}].value`, '')
        }
      }
    }

  }, [manualConcentrationInput, id, fluid, index, props.refraktometerAblesewert, setFieldValue])

  useEffect(() => {
    if (fluid && hasLoadedFluids) {
      const fluidInfo = allFluids.find(f => f._id === fluid.id)
      setFluidInfo(fluidInfo)
    }

  },
  [fluid, allFluids, hasLoadedFluids])

  // We have to reference the full version of metrics, which is why we draw
  // that from the DefaultMetrics array
  let label = `${props.name}`
  const meta: MetricMeta = getMetricMetadata(props.id)
  if (meta.unit) {
    label += ` in ${meta.unit}`
  }
  if (props.should || MetricValidations.should) {
    label += ` (Sollwert: ${props.should || MetricValidations.should})`
  }
  const containerClasses = classNames({
    'well': true,
    'is-hidden': props.hidden,
    'columns': true,
    'is-multiline': true,
    'is-concentration': id === 'konzentration'
  })
  if (props.type === 'minmax') {
    if(props.id !== 'konzentration') {
      returning = (
        <div className={containerClasses}>
          <FormItem
            className="column"
            type="number"
            validate={validation}
            label={label}
            name={`metrics[${props.index}].value`}
            step="0.01"
          />
        </div>
      )
    } else {
      returning = (
        <div className={containerClasses}>
          <div className="column">
            <FormItem
              type="number"
              validate={validation}
              label= 'Refraktometerwert (KSS)'
              name={`metrics[${props.index}].fluid.rfm`}
              step="0.01"
              disabled={true}
              />
            { fluidInfo && fluidInfo.name && <p className="help">Verwendetes Fluid: {fluidInfo.name}</p> }
          </div>
          <span className="column concentration-operator">×</span>
          {/* { props.isDesktop ? <span className="mt-6">×</span> : <span className="column has-text-centered">×</span>} */}
          <FormItem
            className="column"
            type="number"
            validate={validation}
            label= 'Ablesewert auf dem Refraktometer'
            name={`metrics[${props.index}].refraktometerAblesewert`}
            step="0.01"
            disabled={manualConcentrationInput}
          />
          <span className="column concentration-operator">=</span>
          {/* { props.isDesktop ? <span className="mt-6">=</span> : <span className="column has-text-centered">=</span>} */}
          <div className="column">
            <FormItem
              type="number"
              validate={validation}
              label= {label}
              name={`metrics[${props.index}].value`}
              step="0.01"
              disabled={!manualConcentrationInput}
            />
          </div>
            <div className="column is-full">
              <div className="control p-3">
              <button className="button is-info" onClick={() => {setManualConcentrationInput((value => !value))}} type="button">
                <span className="icon-text">
                  <span className="icon">
                    <FontAwesomeIcon icon={manualConcentrationInput ? 'lock' : 'unlock-alt'} size="sm" />
                  </span>
                  <span>{manualConcentrationInput ? 'Konzentration aus Ablesewert errechnen' : 'Konzentration direkt manuell eingeben'}</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      )
    }
  }

  if (props.type === 'select') {
    returning = (
      <div className={containerClasses}>
        <FormItem className="column" component="select" label={props.name} name={`metrics[${props.index}].value`}>
          {meta.options!.map((option: string[], key: number) => (
            <option value={option[0]} key={key}>
              {option[1]}
            </option>
          ))}
        </FormItem>
      </div>
    )
  }

  if (props.type === 'text') {
    returning = (
      <div className={containerClasses}>
        <FormItem className="column" type="input" label={props.name} name={`metrics[${props.index}].value`} />
      </div>
    )
  }

  return <div>{returning}</div>
}

export default MachineMeasurementForm

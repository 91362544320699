import React, { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAlert, types } from 'react-alert'

import AppContext from '../AppContext'
import { User, NotificationTrigger, NotificationSettings } from '../types/users-roles-privileges'
import { remove, cloneDeep, find, set, get, isArray } from 'lodash'
import { metrics, MetricMeta } from '../utils/default-metrics'
import classNames from 'classnames'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { isOsUser, isNhUser, userIsViewingTheirOwnOrg } from '../utils/auth'

interface NotificationSettingOption {
  id?: string; // a setting without an ID is just a separating title used to structure the list
  label: string;
  subSettings?: string;
}
// This must mirror the notifications options defined in oelschueler-back at new-notifications/lib/notifications.js
const allNotifications: NotificationSettingOption[] = [
  {
    label: 'Benutzer'
  },
  {
    id: 'NEW_USER_G',
    label: 'Neuer Nutzer wurde angelegt'
  },
  // {
  //   id: 'CHANGED_USER_G',
  //   label: 'Nutzerprofil wurde geändert'
  // },
  {
    label: 'Messungen'
  },
  {
    id: 'NEW_MEASUREMENT_G',
    label: 'Neue Messung wurde angelegt'
  },
  {
    id: 'FILTER_NEW_MEASUREMENT_G',
    label: 'Neue Messung für bestimmte Metriken wurde angelegt',
    subSettings: 'metrics'
  },
  {
    id: 'NEW_MEASUREMENT_CRITICAL_G',
    label: 'Neue Messung lag außerhalb der Grenzwerte'
  },
  {
    label: 'Aktionen und Arbeitsaufträge'
  },
  {
    id: 'NEW_ACTION_G',
    label: 'Neue Aktion'
  },
  {
    id: 'ACTION_DONE_G',
    label: 'Aktion wurde abgeschlossen'
  },
  {
    id: 'NEW_ACTION_P',
    label: 'Neue Aktion mit Ihrer Beteiligung'
  },
  {
    id: 'ACTION_DONE_P',
    label: 'Aktion mit Ihrer Beteiligung wurde abgeschlossen'
  },
  {
    label: 'Maschinen - Betriebswerte'
  },
  {
    id: 'MP_CHANGED_THRESHOLD_G',
    label: 'Sollwert einer Maschine wurde geändert'
  },
  {
    id: 'MP_MATERIAL_G',
    label: 'Material einer Maschine wurde geändert'
  },
  {
    id: 'MP_OPERATION_G',
    label: 'Operation einer Maschine wurde geändert'
  },
  {
    id: 'MP_FLUID_G',
    label: 'Fluid einer Maschine wurde geändert'
  },
  {
    label: 'Maschinen'
  },
  {
    id: 'MACHINE_NEW_G',
    label: 'Neue Maschine wurde angelegt'
  },
  {
    id: 'MACHINE_ACTIVATION_G',
    label: 'Maschine wurde aktiviert oder deaktiviert'
  },
  {
    id: 'MACHINE_DELETION_G',
    label: 'Maschine wurde gelöscht'
  },
  {
    id: 'MACHINE_DOCUMENT_G',
    label: 'Dokument wurde an eine Maschine angehängt'
  }
]

const NotificationPreferences: React.FC = props => {
  const alert = useAlert()
  const { currentUser, currentCustomer, updateCurrentUser } = useContext(AppContext)
  const { _id: customerId = '' } = currentCustomer
  const defaultNotificationSettings: NotificationSettings = { [customerId]: { triggers: {} } }
  let { notifications: userNotifications = defaultNotificationSettings } = currentUser as User
  // User is nh or os and currentCustomer is their org
  const ownersViewingThemselves = (isOsUser(currentUser.roles) || isNhUser(currentUser.roles)) && userIsViewingTheirOwnOrg(currentUser.roles, customerId)

  // Mini-Migration for users that have `notifications: []`
  if (isArray(userNotifications)) {
    userNotifications = defaultNotificationSettings
  }
  const notificationSettingsForCurrentCustomer = find(
    userNotifications,
    (currentCustomerSettings, currentCustomerId) => {
      return currentCustomerId === customerId
    }
  ) || { triggers: {} }
  const [filter, setFilter] = useState<string | undefined>(notificationSettingsForCurrentCustomer.machineFilter)
  const [currentlyLoading, setCurrentlyLoading] = useState<string | undefined>(undefined)
  const updateNotificationSettings = async (newNotificationState: NotificationSettings) => {
    const { REACT_APP_COUCHDB_ENDPOINT } = process.env
    // Don’t use the user’s email as the ID, as that might change in the future
    const userId = currentUser!.id!.replace('org.couchdb.user:', '')
    await fetch(REACT_APP_COUCHDB_ENDPOINT + `/_backend/user/${customerId}/${encodeURIComponent(userId)}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json'
      },
      body: JSON.stringify({
        oelUser: {
          notifications: newNotificationState
        }
      })
    })
      .then(
        async (res): Promise<void> => {
          if (res.status === 200) {
            updateCurrentUser!({ ...currentUser, notifications: newNotificationState })
          } else {
            alert.show(`Einstellung konnte nicht übernommen werden (${res.status} - ${res.statusText})`, {
              type: types.ERROR,
              timeout: 4000
            })
          }
        }
      )
      .catch((error): void => {
        alert.show(`Einstellung konnte nicht übernommen werden: ${error.message || error}`, {
          type: types.ERROR,
          timeout: 4000
        })
      })
    setCurrentlyLoading(undefined)
  }

  const handleSubSettingToggle = async (
    notificationID: string,
    subsettingID: string,
    active: boolean
  ): Promise<void> => {
    setCurrentlyLoading(`${notificationID}:${subsettingID}`)
    let newNotificationState = cloneDeep(userNotifications)
    if (active) {
      // add the newly active suboption
      // TS can’t figure this out on its own, therefore the double cast
      const existingSubOptionsArray: string[] =
        ((get(
          newNotificationState,
          `[${customerId}].triggers[${notificationID}].subOptions`
        ) as unknown) as string[]) || []
      set(newNotificationState, `[${customerId}].triggers[${notificationID}].subOptions`, [
        ...existingSubOptionsArray,
        subsettingID
      ])
    } else {
      // remove the newly disabled suboption
      remove(newNotificationState![customerId]!.triggers[notificationID].subOptions, item => {
        return item === subsettingID
      })
    }
    updateNotificationSettings(newNotificationState)
  }
  const handleNotificationToggle = async (notificationID: string, active: boolean): Promise<void> => {
    setCurrentlyLoading(notificationID)
    let newNotificationState = cloneDeep(userNotifications)
    set(newNotificationState, `[${customerId}].triggers[${notificationID}].active`, active)
    updateNotificationSettings(newNotificationState)
  }
  const saveFilter = (): void => {
    setCurrentlyLoading('filter')
    let newNotificationState = cloneDeep(userNotifications)
    set(newNotificationState, `[${customerId}].machineFilter`, filter)
    updateNotificationSettings(newNotificationState)
  }
  return (
    <section>
      <h3 className="title is-size-3">Benachrichtigungseinstellungen</h3>
      <h5 className="subtitle is-size-5">Für die Firma {currentCustomer.name}</h5>
      <p className="content">
        Aktivierte Benachrichtigungen werden an <code>{currentUser!.email}</code> gesendet.
      </p>
      {!ownersViewingThemselves && (<>
      <div className="notification">
        <h3 className="title is-size-4">Nach bestimmten Maschinen filtern</h3>
        <p className="content">
          Alle maschinenbezogenen Benachrichtigungen auf dieser Seite können optional gefiltert werden, d.h. Sie
          erhalten nur Benachrichtigungen zu Maschinen, auf denen der Filter zutrifft. Dieser Filter funktioniert
          genauso wie der im Maschineninventar, allerdings kann hier <em>nicht</em> nach Fluiden gefiltert werden.
        </p>
        <label className="label">Maschinenfilter</label>
        <div className="field has-addons">
          <div className="control is-expanded has-icons-left">
            <input
              className="input"
              type="text"
              placeholder="Name/ID/Inventarnummer/Tag"
              onChange={e => setFilter(e.currentTarget.value)}
              value={filter}
            />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon="search" />
            </span>
          </div>
          <div className="control">
            <button
              className={`button is-info ${currentlyLoading === 'filter' ? 'is-loading' : ''}`}
              onClick={() => saveFilter()}
            >
              Speichern
            </button>
          </div>
        </div>
      </div>
      </>)}
      <ul className="options-list">
        {allNotifications.map(
          (n, index): React.ReactElement => {
            // Somewhat hacky way of hiding all but the first two entries
            // (label and button for users) from high-level admins, since
            // these only have users but no machines etc.
            if (ownersViewingThemselves && index > 1) return null
            if (!n.id) {
              return (
                <li key={`separator-${index}`} className="section-title">
                  <h3 className="title is-size-4 mb1">{n.label}</h3>
                </li>
              )
            }
            const userSettingForThisNotification: NotificationTrigger = find(
              notificationSettingsForCurrentCustomer!.triggers,
              (triggerSettings, triggerId) => {
                return triggerId === n.id
              }
            ) || { active: false }
            const toggleButtonOptions = {
              isLoading: currentlyLoading === n.id,
              clickHandler: () => handleNotificationToggle(n.id!, !userSettingForThisNotification.active),
              icon: userSettingForThisNotification.active ? 'check' : 'times',
              label: userSettingForThisNotification.active ? 'aktiv' : 'inaktiv',
              state: userSettingForThisNotification.active ? 'success' : 'white'
            }
            return (
              <li key={`notification-${n.id}`} className="option">
                <div className="level mb1">
                  <div className="level-left">
                    <div className="level-item">
                      <ToggleButton {...toggleButtonOptions} />
                    </div>
                    <div className="level-item">{n.label}</div>
                  </div>
                </div>
                {n.subSettings && userSettingForThisNotification && (
                  <ul className="sub-settings">
                    {metrics.map(
                      (metric: MetricMeta): React.ReactElement => {
                        let userSettingForThisSubNotification: boolean = false
                        // This notification setting may not have an array of subsettings yet
                        if (userSettingForThisNotification!.subOptions) {
                          userSettingForThisSubNotification =
                            (userSettingForThisNotification!.subOptions as string[]).indexOf(metric.id) >= 0
                        }
                        const toggleButtonOptions = {
                          isLoading: currentlyLoading === `${n.id}:${metric.id}`,
                          clickHandler: () =>
                            handleSubSettingToggle(n.id!, metric.id, !userSettingForThisSubNotification),
                          icon: userSettingForThisSubNotification ? 'check' : 'times',
                          state: userSettingForThisSubNotification ? 'success' : 'white'
                        }
                        return (
                          <li key={`metric-${metric.id}`}>
                            <ToggleButton {...toggleButtonOptions} />
                            <span>{metric.name}</span>
                          </li>
                        )
                      }
                    )}
                  </ul>
                )}
              </li>
            )
          }
        )}
      </ul>
    </section>
  )
}

interface ToggleButtonProps {
  isLoading: boolean;
  clickHandler(): void;
  icon?: string;
  label?: string;
  state: string; // a bulma button state, eg. `success`, `light`, without the `is-` prefix
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  isLoading,
  clickHandler,
  icon,
  label,
  state
}: ToggleButtonProps) => {
  const classes = classNames({
    button: true,
    'is-loading': isLoading,
    [`is-${state}`]: true
  })
  return (
    <button className={classes} onClick={clickHandler}>
      {icon && (
        <span className="icon">
          <FontAwesomeIcon icon={icon as IconProp} size="sm" />
        </span>
      )}
      {label && <span>{label}</span>}
    </button>
  )
}

export default NotificationPreferences

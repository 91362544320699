import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppContext, { AppConsumer } from '../AppContext'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'

import { Database, Aware } from '../react-pouchdb'

import { generateId, dateFormat } from '../utils/general'
import MachineId from '../components/machine-id'
import { GenericLoadingSpinner, SelfActivatingNavlink } from '../components/partials'
import MachineMeasurements from '../components/machine-measurements'
import { MachineAction } from '../components/machine-action'
import MachineDocuments from '../components/machine-documents'
import { BigNumberGroup } from '../components/graphs/big-number-group'

import { getRemote } from '../utils/network'
import MachineProtocol from '../components/machine-protocol'
import EditOrAddMachine from '../components/edit-or-add-machine'
import { useMachine } from '../hooks/useMachine'
import { useMostRecentMeasurements } from '../hooks/useMostRecentMeasurements'
import { UserRoles } from '../utils/user-roles'
import { Privileges } from '../types/users-roles-privileges'
import { MachineSwitcher } from '../components/machine-switcher'
import { Measurement } from '../components/graphs/waffle-measurements'
import { getMetricMetadata } from '../utils/default-metrics'
import { compact, sortBy, get, remove } from 'lodash'
import { getStateLabel, MachineState } from '../components/machine-inventory'
import { getFaveStar } from '../utils/faves'
import { updateUserDoc } from '../utils/users'

interface MatchParams {
  machineId?: string;
  measurementId?: string;
  actionId?: string;
}

interface BigNumber {
  id: string;
  value?: string | number;
  unit?: string;
  label?: string;
  date?: string;
  type?: 'good' | 'low' | 'high';
}

const MachineDetail: React.FC<RouteComponentProps<MatchParams>> = props => {
  const { path, url } = props.match
  const { machineId } = props.match.params
  const { currentCustomer, onPouchError, currentUser, updateCurrentUser, findCustomerForMachineId } = useContext(AppContext)
  const { _id: customerIdFromContext } = currentCustomer
  const [customerId, setCustomerId] = useState(customerIdFromContext)
  const [hasCheckedForCustomerId, setHasCheckedForCustomerId] = useState(false)

  // Handle deep linking when we don’t have a customer ID set. Our machine URLs don’t
  // include the customer Id because we want to pre-print the QR codes with those IDs,
  // and we don’t know in advance which customers will receive them.
  // This seems like it should be router logic, but since it only applies to this view and
  // its subroutes, it’s fine to have it here.
  // TODO: this should also happen before an admin user gets the "add new machine with
  // unknown ID" view when they navigate to a machine page that the client doesn’t know
  // yet, but there _is_ a customer Id (user switching customers via URL).
  useEffect(() => {
    let ignore = false
    async function updateCurrentCustomer() {
      await findCustomerForMachineId(machineId)
      if (!ignore) {
        setHasCheckedForCustomerId(true)
      }
    }
    // Only try if we have a session, but do not have the current customerId
    if (machineId && !customerId && currentUser.email) {
      updateCurrentCustomer()
    }
    return () => { ignore = true }
    // Only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCustomerId(customerIdFromContext)
  }, [customerIdFromContext])

  const { key } = props.location
  const { machine, hasLoaded: machineHasLoaded } = useMachine(customerId, machineId, key)
  const { lastMeasurements } = useMostRecentMeasurements(customerId, machineId, key)
  const userRoles = UserRoles.fromSession()
  const userCanEnterActions = userRoles.hasPrivilege(Privileges.CAN_ENTER_ACTIONS)
  const userCanEnterMeasurements = userRoles.hasPrivilege(Privileges.CAN_ENTER_MEASUREMENTS)

  useEffect(() => {
    let ignore = false
    async function updateCurrentCustomer() {
      await findCustomerForMachineId(machineId)
      if (!ignore) {
        setHasCheckedForCustomerId(true)
      }
    }
    if (machineHasLoaded && !machine) {
      updateCurrentCustomer()
    }
    return () => { ignore = true }
  }, [findCustomerForMachineId, machine, machineHasLoaded, machineId])

  if (!currentUser.email) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location }
        }}
      />
    )
  }

  if (!customerId && !hasCheckedForCustomerId) {
    return <GenericLoadingSpinner />
  }

  let machineDocId = generateId(8, 'ma')
  if (machineId) {
    machineDocId = `ma_${machineId}`
  }
  const remote = getRemote(customerId)

  const bigNumberMetrics = ['ph', 'konzentration', 'nitrit']
  const bigNumberValues = sortBy(
    compact(
      lastMeasurements.map((measurement: Measurement): BigNumber => {
        if (bigNumberMetrics.indexOf(measurement.metricId) !== -1) {
          const meta = getMetricMetadata(measurement.metricId)
          const result: BigNumber = {
            id: measurement.metricId,
            value: measurement.value,
            unit: meta.unit,
            label: meta.name,
            date: format(new Date(measurement.measuredAt), dateFormat),
            type: 'good'
          }
          if (measurement.max && measurement.value > measurement.max) {
            result.type = 'high'
          }
          if (measurement.min && measurement.value < measurement.min) {
            result.type = 'low'
          }
          return result
        }
        return null
      })
    ),
    (item: BigNumber): number => {
      return bigNumberMetrics.indexOf(item.id)
    }
  )
  const bigNumberDefaults = [
    {
      id: 'ph',
      label: 'pH-Wert'
    },
    {
      id: 'konzentration',
      value: undefined,
      unit: '%',
      label: 'Konzentration'
    },
    {
      id: 'nitrit',
      value: undefined,
      unit: 'mg/L',
      label: 'Nitrit'
    }
  ]
  // Fold in default empty values
  const bigNumbers = bigNumberDefaults.map((bigNumberDefault: BigNumber): BigNumber => {
    let realValue = bigNumberValues.find((bigNumberValue: BigNumber): boolean => {
      return bigNumberValue.id === bigNumberDefault.id
    })
    return realValue ? realValue : bigNumberDefault
  })

  const machineIsActive = machine ? (machine.state ? machine.state === MachineState.ACTIVE : true) : false

  const faves = get(currentUser, `faves[${customerId}]`) || []
  let thisMachineIsFaved = machine ? faves.indexOf(machine._id) >= 0 : false

  const toggleFave = async () => {
    if (!machine) return
    if (!currentUser || !updateCurrentUser) return
    // Don’t use the user’s email as the ID, as that might change in the future
    if (thisMachineIsFaved) {
      remove(faves, faveId => faveId === machine._id)
    } else {
      faves.push(machine._id)
    }
    const newFaves = { ...(get(currentUser, 'faves') || {}), [customerId]: faves }
    await updateUserDoc(currentUser, updateCurrentUser, customerId, { faves: newFaves })
  }

  const skipTheseNames = ['nh', 'oel']
  if (skipTheseNames.includes(customerId)) {
    return <span></span>
  }

  return (
    <>
      {machineId && machine && <MachineSwitcher {...props} machine={machine} />}
      <nav className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <Link to="/" className="button">
              <span className="icon">
                <FontAwesomeIcon icon="home" size="sm" pull="left" />
              </span>
              <span>Zurück zum Maschinen-Inventar</span>
            </Link>
          </div>
        </div>
      </nav>
      <Database database={customerId} remote={remote} onError={onPouchError}>
        {/* Only generate a header if the machine exists, if not, edit-or-add-machine.tsx will take care of it */}
        {machineId && machine && (
          <>
            <div className="machineHeaderContainer">
              <div className="mb2">
                <h3 className="is-size-3">
                  <strong>{machine.name}</strong> <MachineId id={machine._id as string} clickable={true} />{' '}
                  {getFaveStar(thisMachineIsFaved, toggleFave)}
                </h3>
                {machine.inventoryNumber && (
                  <h4 className="is-size-4">
                    <strong>Inventarnummer: </strong>
                    {machine.inventoryNumber}
                  </h4>
                )}
                {machine.location && (
                  <h4 className="is-size-4">
                    <strong>Standort: </strong>
                    {machine.location}
                  </h4>
                )}
                <h4 className="is-size-4">{getStateLabel(machine.state, 'is-medium')}</h4>
              </div>
              <BigNumberGroup bigNumbers={bigNumbers} />
            </div>
            <div className="tabs wrap-on-mobile">
              <ul>
                <SelfActivatingNavlink to={url} label="Daten" exact />
                {userCanEnterMeasurements && machineIsActive && (
                  <SelfActivatingNavlink to={`${url}/messung`} label="Messung">
                    <FontAwesomeIcon icon="eye-dropper" size="sm" pull="left" />
                  </SelfActivatingNavlink>
                )}
                {userCanEnterActions && machineIsActive && (
                  <SelfActivatingNavlink to={`${url}/aktion`} label="Aktion">
                    <FontAwesomeIcon icon="hand-point-right" size="sm" pull="left" />
                  </SelfActivatingNavlink>
                )}
                <SelfActivatingNavlink to={`${url}/dokumente`} label="Dokumente">
                  <FontAwesomeIcon icon="file-alt" size="sm" pull="left" />
                </SelfActivatingNavlink>
                <SelfActivatingNavlink to={`${url}/protokoll`} label="Protokoll">
                  <FontAwesomeIcon icon="chart-line" size="sm" pull="left" />
                </SelfActivatingNavlink>
              </ul>
            </div>
          </>
        )}
        <Switch>
          <Route exact path={path}>
            <EditOrAddMachine {...props} />
            {/* All measurement points for that machine */}
            {/* <MachineMeasurementPoints {...props} machineId={machineDocId} /> */}
          </Route>
          <Route path={`${path}/messung/:measurementId?`}>
            <Aware>
              <MachineMeasurements
                {...props}
                onDbError={onPouchError}
                customerId={customerId}
                machineId={machineDocId}
              />
            </Aware>
          </Route>
          <Route
            path={`${path}/aktion/:actionId?`}
            // Renderprops so we get this route’s match, not just the parent route’s
            render={props => <AppConsumer>{appContext => <MachineAction {...props} machine={machine} />}</AppConsumer>}
          ></Route>
          <Route path={`${path}/dokumente`}>
            <MachineDocuments machineId={machineDocId} customerId={customerId} />
          </Route>
          <Route path={`${path}/protokoll`}>
            <MachineProtocol {...props} customerId={customerId} machineId={machineDocId}/>
          </Route>
        </Switch>
      </Database>
    </>
  )
}

export default MachineDetail

import React, { Component, ReactElement } from 'react'
import ContainerDimensions from 'react-container-dimensions'

import { WaffleBox, WaffleBoxProps } from './waffle-box'
import './graphs.scss'
import classNames from 'classnames'

/*

Waffle is a waffle graph, custom built in HTML and completely customisable by us.

It takes a data prop which needs to be an array of WaffleBoxProps:

color?: string, either 'red', 'orange', 'green', or 'grey'
clickTarget?: string, a URL
tooltipElement?: React.ReactElement
containerWidth?: number, used to calculate where the tooltip can be without being out of bounds

See the src/stories/waffle.stories.js stories for examples

*/

interface WaffleProps {
  data: (WaffleBoxProps | null)[];
}

class Waffle extends Component<WaffleProps, {}> {
  public render(): React.ReactNode {
    const { data } = this.props
    const classes = classNames({
      waffle: true,
      small: data.length >= 100,
      large: data.length <= 10
    })
    return (
      <div className={classes}>
        <ContainerDimensions>
          {({ width }) => (
            <>
              {data.map((item: WaffleBoxProps | null, index: number): ReactElement | null => {
                if (item) {
                  return <WaffleBox key={`waffle-box-${index}`} {...item} containerWidth={width} />
                }
                return null
              })}
            </>
          )}
        </ContainerDimensions>
      </div>
    )
  }
}

export default Waffle

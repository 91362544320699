import { UserRoles } from './../../utils/user-roles'
import { User, Role } from '../../types/users-roles-privileges'
import { getRoleDefinitionForId } from '../../utils/auth'
import { appName } from '../../utils/general'

const basicInvitationTemplate = (userRoleId: string, invitingUser?: User) => {
  if (!userRoleId || !invitingUser) return ''

  const userRoles = UserRoles.fromSession()

  const privileges = userRoles.getPrivilegeDescriptions(userRoleId)

  const userRoleDefinition: Role | undefined = getRoleDefinitionForId(userRoleId)

  if (!userRoleDefinition) {
    console.error('Error: somehow attempting to assign an unknown role to new users.')
    return ''
  }

  let templateBody = `Willkommen!

Sie wurden von ${invitingUser.firstName} ${invitingUser.lastName} (${
    invitingUser.email
  }) als Nutzer von ${appName} registriert. Mit ${appName} messen, überwachen und verwalten wir die Schmierstoffe unserer Maschinen.

Sie sind als ${userRoleDefinition.name} registriert und können folgende Tätigkeiten in dem Dienst ausüben:

${privileges.map((p: string) => `- ${p}`).join('\n')}

Bitte klicken Sie auf den folgenden Link, um die Einladung anzunehmen und Ihr Benutzerkonto freizuschalten:

- {userInvitationLink}

Ihr Ansprechpartner für den Dienst ist ${invitingUser.firstName} ${invitingUser.lastName} (${invitingUser.email}).

Viel Erfolg mit ${appName}!
`

  return templateBody
}

export { basicInvitationTemplate }

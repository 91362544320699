import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form, FormikHelpers, FormikProps } from 'formik'
import { isEmpty } from 'lodash'
import { FormValues } from '@luxx/forms'
import FormItem from '@luxx/forms'
import { types, useAlert } from 'react-alert'
import { UserRoles } from '../../utils/user-roles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getCustomerIdFromCouchDBRoles } from '../../utils/auth'
import { Customer } from '../../hooks/useCustomers'

const AddCustomerSchema = Yup.object().shape({
  companyName: Yup.string().required('Pflichtfeld')
})

interface AddCustomerData {
  companyName: string;
  isReseller: boolean;
  resellerCustomerId: string;
}

interface FormProps extends AddCustomerData {
  general?: string;
}

const initialValues = {
  companyName: '',
  isReseller: false,
  resellerCustomerId: 'oel'
}

type Props = {
  onSuccess(): void;
  customers: Array<Customer>;
  userRoles: UserRoles;
}

const AddCustomer: React.FC<Props> = props => {
  const alert = useAlert()
  const resellerCustomers = props.customers.filter((customer: Customer) => customer.resellerStats)

  const [showUI, setShowUI] = useState(false)

  const { userRoles}  = props
  const userIsReseller = userRoles.isReseller()
  const userIsHighLevelAdmin = userRoles.isGlobalAdmin()

  const onSubmit = async (values: FormValues, actions: FormikHelpers<FormProps>) => {
    const { REACT_APP_COUCHDB_ENDPOINT } = process.env
    actions.setSubmitting(true)

    // To make this work for non-resellers (admins), this needs to transmit `oel` as `customerId`
    const customerIdOfCurrentUser = getCustomerIdFromCouchDBRoles(userRoles.roles)

    // If this is a reseller, it must transmit THE RESELLER‘s customer Id,
    // not the customer Id of the current customer
    const idToTransmit = userIsHighLevelAdmin ? values.resellerCustomerId : userIsReseller ? customerIdOfCurrentUser : ''

    await fetch(`${REACT_APP_COUCHDB_ENDPOINT}/_backend/customer/${idToTransmit}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json'
      },
      body: JSON.stringify({
        companyName: values.companyName,
        isReseller: values.isReseller
      })
    })
      .then(
        async (res): Promise<void> => {
          const data = await res.json()
          if (res.status === 200) {
            alert.show('Kunde angelegt', {
              type: types.SUCCESS
            })
            props.onSuccess()
            actions.resetForm()
          } else {
            alert.show(data.message, {
              type: types.ERROR
            })
            actions.setSubmitting(false)
          }
        }
      )
      .catch((error): void => {
        alert.show(`Fehler: ${error}`, {
          type: types.ERROR
        })
        actions.setSubmitting(false)
      })
  }

  return (
      <>
        {showUI
          ? (
            <div className="box">
              <Formik validationSchema={AddCustomerSchema} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
                {({ values, errors, isSubmitting }: FormikProps<FormProps>): React.ReactNode => {
                  const hasErrors = !isEmpty(errors)
                  const isNotSubmittable = hasErrors || isSubmitting ? true : false
                  return (
                    <Form>
                      <div className="content mb-4">
                        <FormItem type="text" label="Firmenname des neuen Kunden" name="companyName" placeholder="Firmenname" />
                        {errors.general && <div className="notification is-danger">{errors.general}</div>}
                        {!userIsReseller && (
                          <>
                          <FormItem type="checkbox" label="Neukunde als Reseller anlegen" name="isReseller" />
                          { !values.isReseller &&
                            <FormItem component="select" name="resellerCustomerId" label="Hinzufügen als Kunde von:">
                              <option key="oel" value="oel">Öl Schüler</option>
                              <option key="reseller-label" value="" disabled>RESELLER</option>
                              {resellerCustomers.map((customer: Customer): React.ReactElement => <option key={customer._id} value={customer._id}>{customer.name}</option>)}
                            </FormItem>
                          }
                          </>
                        )}
                        <div className="buttons">
                          <button type="submit" disabled={isNotSubmittable} className="button is-primary">
                            <span className="icon">
                              <FontAwesomeIcon icon="plus-circle" size="sm" />
                            </span>
                            <span>Kunden anlegen</span>
                          </button>
                          <button type="submit" onClick={() => setShowUI(false)} className="button">
                            <span className="icon">
                              <FontAwesomeIcon icon="times" size="sm" />
                            </span>
                            <span>Schließen</span>
                          </button>
                        </div>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          )
          : (
            <button className="button is-primary" onClick={() => setShowUI(true)}>
              <span className="icon">
                <FontAwesomeIcon icon="plus-circle" size="sm" />
              </span>
              <span>Neuen Kunden anlegen</span>
            </button>
          )
        }
      </>
  )
}

export default AddCustomer

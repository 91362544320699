import { flattenDeep, get } from 'lodash'

// Filters via several keys from an array of objects. Usage:
//
// const objects = [{
//   name: 'Evan',
//   age: 43
// },
// {
//   name: 'Layla',
//   age: 50
// }]
// const filteredObjects = filter(objects, 'evan', ['name'])
// => [{ name: 'Evan', age: 43 }]

function filter<T>(
  // Array of values to be filtered.
  values: T[],
  // The string to be used for filtering.
  filter: string,
  // Object keys to be used. Basically, this means that you can search the
  // `values` array via multiple metrics, for example, one by name, one by
  // age, etc
  objectKeys: string[]
): T[] {
  return values.filter(value => {
    const toFilter = flattenDeep(
      objectKeys.map(key => {
        return get(value, key, '')
      })
    )

    return toFilter.some(f =>
      f
        .toString()
        .toLowerCase()
        .includes(filter.toString().toLowerCase())
    )
  })
}

export default filter

import React, { PureComponent } from 'react'

export default class GenericConnectionWarning extends PureComponent<{}> {
  public render(): React.ReactNode {
    return (
      <article className="message is-warning">
        <div className="message-header">
          <p>Wichtiger Hinweis</p>
        </div>
        <div className="message-body">
          <p className="mb2">
            <strong>Diese Daten sind möglicherweise nicht aktuell.</strong>
          </p>
          <p className="mb2">
            Der Server konnte nicht erreicht werden; wahrscheinlich hat dieses Gerät gerade keine Internetverbindung.
            Sie sehen jetzt den letzten Stand von dem Moment, als dieses Gerät das letzte Mal online war.
          </p>
          <p>Sie können normal weiterarbeiten: Ihre Änderungen werden hochgeladen, sobald Sie wieder Netz haben.</p>
        </div>
      </article>
    )
  }
}

import React from 'react'
import { format, getISOWeek, getISOWeeksInYear } from 'date-fns'
import chunk from 'lodash/chunk'
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer'
import { Table, TableBody, TableHeader, TableCell, DataTableCell } from '@david.kucsai/react-pdf-table'

import { MachineDoc } from './machine-inventory'
import PDFMachineInfoBlock from './pdf-machine-info'

import logo from '../assets/logo-small.png'
import { Measurement } from './graphs/waffle-measurements'
import { flatten, groupBy, sample, sortBy } from 'lodash'
import { MeasurementPoint } from './machine-measurement-point'
import { OsFluid, CustomerFluid, FluidChangeEvent } from '../types/fluids'
import { dateFormat, shouldNotSeeOSBrand } from '../utils/general'

interface Props {
  measurements?: Measurement[];
  machine: MachineDoc;
  measurementPoint?: MeasurementPoint;
  fluidChangeEvents?: FluidChangeEvent[];
  fluidInfo?: OsFluid | CustomerFluid;
  year: number;
}

/**
 * Font Family: We're now using Helvetica, which is one of the 14 PDF Standard Fonts.
 *
 * A list of all default fontFamily values is avaliable here:
 * https://github.com/diegomura/react-pdf/blob/ee0b35b7e2c57e96d675a3d08cb1042d6c528802/src/font/standard.js
 */

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFF',
    padding: 10,
    fontFamily: 'Helvetica'
  },
  logo: {
    height: '8mm',
    width: 'auto',
    marginRight: '5mm'
  },
  textRow: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  tableCell: {
    fontSize: 9,
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
    paddingTop: 5,
    paddingRight: 0,
    paddingBottom: 5,
    paddingLeft: 0,
    textAlign: 'center'
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
  },
  footnote: {
    fontSize: 10,
    fontFamily: 'Helvetica',
    paddingTop: 5,
  },
  headerCell: {
    backgroundColor: '#666666',
    color: '#FFFFFF'
  },
  leadingCell: {
    textAlign: 'left',
    paddingLeft: 5,
  },
  header: {
    fontSize: 24
  },
  subHeader: {
    fontSize: 18,
    marginBottom: 8
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    top: '5mm',
    right: '7mm',
    width: '70mm',
    color: 'grey',
    textAlign: 'right'
  },
  divider: {
    width: '100%',
    height: '1pt',
    backgroundColor: '#DDD',
    marginBottom: '2mm',
    marginTop: '2mm'
  },
  verticalBreak: {
    width: '100%',
    height: '1pt',
    backgroundColor: '#FFF',
    marginBottom: '2mm',
    marginTop: '2mm'
  }
})

const Divider = () => <View style={styles.divider} />
const VerticalBreak = () => <View style={styles.verticalBreak} />

const MachineCardPDF: React.FC<Props> = ({ measurements = [], machine, measurementPoint, fluidChangeEvents, fluidInfo, year }) => {
  const hiddenForBrandingReasons = shouldNotSeeOSBrand()
  const printDate = format(new Date(), dateFormat)
  // Get ISO weeks in year (either 52 or 53), offset 10 days from the start of the year,
  // because these week indexes sometimes leak into the next year, so
  // getISOWeeksInYear(new Date(2021, 0, 1)) (for Jan 1st 2021) will return 53,
  // even though 2021 has 52 ISO weeks. However, Jan 1st 2021 still belongs to the last
  // week of 2020, which has 53 weeks
  const currentDate = new Date(year, 0, 10)
  const currentYear = year
  const weeksInYear = getISOWeeksInYear(currentDate)
  // Create an array with n=weeksInYear empty objects in it
  let measurementsByWeek = [...Array(weeksInYear).keys()].map(() => {return {}})
  flatten(measurements).forEach((measurement: Measurement) => {
    // -1 because ISOWeeks are not zero-indexed but start at 1
    const week = getISOWeek(new Date(measurement.measuredAt)) - 1
    let target = measurementsByWeek[week] ? measurementsByWeek[week][measurement.metricId] : undefined
    // Write measurement to week if there is none for this metric or if the
    // existing one is older
    if (!target || (target && target.measuredAt < measurement.measuredAt)) {
      if (!measurementsByWeek[week]) {
        measurementsByWeek[week] = {}
      }
      measurementsByWeek[week][measurement.metricId] = measurement
    }
  })
  // All of the following attempts to show a fluid/KSS change for a week if:
  // 1. the change is the last change _before_ the week’s measurement
  // 2. the change is the only change between the previous week’s measurement and the current week’s
  let fluidChanges = []
  // sortBy makes sure the events are in chronological order
  sortBy(fluidChangeEvents, '_id').map(event => {
    // -1 because ISOWeeks are not zero-indexed but start at 1
    let fluidChangeWeek = getISOWeek(new Date(event._id.substring(12))) - 1
    return fluidChanges.push({
      changedAt: event._id.substring(12),
      week: fluidChangeWeek, // again, zero indexed, don’t get confused
      from: event.from,
      to: event.to,
      weekRow: Math.floor(fluidChangeWeek/13)
    })
  })
  const kssChangesByWeek = groupBy(fluidChanges, 'week')
  const filteredKssChanges = []
  // Old-school for loop since we’re mutating the collection as we iterate over it
  // (but we’re only ever mutating the _next_ iteration)
  for (let week = 0; week < weeksInYear; week++) {
    const currentWeekChanges = kssChangesByWeek[week]
    if (currentWeekChanges && currentWeekChanges.length) {
      let changeForCurrentWeek = undefined
      // pick any measurement from the week and get its time
      const getRandomMeasurement: Measurement = sample(measurementsByWeek[week])
      const measurementTimeForCurrentWeek = getRandomMeasurement ? getRandomMeasurement.measuredAt : undefined
      currentWeekChanges.forEach(change => {
        if (!change.handled) {
          // If the change is before the measurement, pick it, overwriting the previous pick
          // This will give us the last change in the calendar week before the measurement
          if (!getRandomMeasurement || (change.changedAt < measurementTimeForCurrentWeek)) {
            change.handled = true
            changeForCurrentWeek = change
          } else {
            // Punt the change to the next week
            change.week++
            if (kssChangesByWeek[change.week]) {
              kssChangesByWeek[change.week].unshift(change)
            } else {
              kssChangesByWeek[change.week] = [change]
            }
          }
        }
      })
      if (changeForCurrentWeek) {
        filteredKssChanges.push(changeForCurrentWeek)
      }
    }
  }
  const chars = ['¹','²','³','*','°','ª' ]
  const getSuperscriptForWeek = (rowNumber, ISOWeek) => {
    let count = 0
    let result = ''
    filteredKssChanges.forEach((change) => {
      if (change.week === ISOWeek - 1) {
        result = ` ${chars[count]}`
      }
      if (change.weekRow === rowNumber) {
        count++
      }
    })
    return result
  }

  const getFootnotesForRow = (rowNumber) => {
    let count = 0
    return filteredKssChanges.map((change) => {
      if (change.weekRow === rowNumber) {
        const getFromString = () => {
          if (change.from) {
            return <>von <Text style={{...styles.bold}}>{change.from}</Text> </>
          }
          return ''
        }
        return <View key={change.week}>
          <Text style={{...styles.footnote}}>{chars[count++]} KSS-Wechsel {getFromString()}zu <Text style={{...styles.bold}}>{change.to}</Text> vor der Messung in KW {change.week + 1}</Text>
        </View>
      }
      return null
    })
  }

  const weeksPerRow = 13
  const measurementsPerRow = chunk(measurementsByWeek, weeksPerRow)

  return (
    <Document>
      <Page size="A4" style={styles.page} key="page-1">
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `Vom ${printDate} - Seite ${pageNumber} / ${totalPages}`}
          fixed
        />
        <View style={styles.textRow}>
          {!hiddenForBrandingReasons && (
            <Image src={logo} style={styles.logo} />
          )}
          <Text style={styles.header}>Maschinenkarte für {currentYear}</Text>
        </View>
        <Divider />
        <PDFMachineInfoBlock machine={machine} measurementPoint={measurementPoint} fluidInfo={fluidInfo}/>
        {measurementsPerRow.map((measurements, rowNumber) => {
          // 52 or 53 weeks in a year, so 13 weeks per row, array is zero-indexed, hence the +1s
          const weekOffset = weeksPerRow * rowNumber
          const ISOWeeks = [...Array(weeksPerRow + 1 + (weekOffset)).keys()].slice(1 + weekOffset)
          const measurementsByMetricId = [
            {
              metricLabel: 'Konzentration (%)',
              metricId: 'konzentration',
              weeks: []
            },
            {
              metricLabel: 'pH-Wert',
              metricId: 'ph',
              weeks: []
            },
            {
              metricLabel: 'Nitrit (mg/l)',
              metricId: 'nitrit',
              weeks: []
            }
          ]
          measurements.forEach((week, index) => {
            const metrics = Object.keys(week || {})
            metrics.forEach(metric => {
              const targetMetric = measurementsByMetricId.find((result) => result.metricId === metric)
              if (targetMetric) {
                targetMetric.weeks[index] = week[metric]
              }
            })
          })
          const leadingColumnWeighting = 0.15
          const weekColumnWeighting = (1 - leadingColumnWeighting) / weeksPerRow
          return (
            <View key={`key-row-${rowNumber}`}>
              <Table data={measurementsByMetricId}>
                <TableHeader
                  includeBottomBorder={true}
                  includeLeftBorder={true}
                  includeRightBorder={true}
                  includeTopBorder={true}
                >
                  <TableCell style={{...styles.tableCell, ...styles.headerCell, ...styles.leadingCell}} weighting={leadingColumnWeighting} isHeader={true}>
                    Kalenderwoche
                  </TableCell>
                  {ISOWeeks.map((ISOWeek) => {
                    return <TableCell style={{...styles.tableCell, ...styles.headerCell}} weighting={weekColumnWeighting} isHeader={true} key={`${ISOWeek}-table-header`}>
                      {ISOWeek <= weeksInYear ? `${ISOWeek}${getSuperscriptForWeek(rowNumber, ISOWeek)}` : ''}
                    </TableCell>
                  })}
                </TableHeader>
                <TableBody
                  includeBottomBorder={true}
                  includeLeftBorder={true}
                  includeRightBorder={true}
                  includeTopBorder={false}
                >
                  <DataTableCell
                    style={{...styles.tableCell, ...styles.leadingCell}}
                    weighting={leadingColumnWeighting}
                    getContent={(r) => r.metricLabel}
                  />
                  {[...Array(weeksPerRow).keys()].map((weekIndex) => (
                    <DataTableCell
                      key={`key-${weekIndex}-${Math.round(Math.random()*1e5)}`}
                      style={styles.tableCell}
                      weighting={weekColumnWeighting}
                      getContent={(r) => r.weeks[weekIndex] ? r.weeks[weekIndex].value : null}
                    />
                  ))}
                </TableBody>
              </Table>
                {getFootnotesForRow(rowNumber)}
              <VerticalBreak />
            </View>
          )
        })}
      </Page>
    </Document>
  )
}

export default MachineCardPDF

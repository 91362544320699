import React, { useContext, useState, useEffect, useRef } from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sortBy, get, remove } from 'lodash'

import MachineId from './machine-id'
import AppContext from '../AppContext'
import { MeasurementPoint } from './machine-measurement-point'
import useFluids from '../hooks/useFluids'
import { CustomerFluid, OsFluid } from '../types/fluids'
import { getLabelForId } from '../utils/default-metrics'
import { isMachineDoc } from '../utils/general'
import doFilter from '../utils/filter'
import { GenericLoadingSpinner } from './partials'
import { UserRoles } from '../utils/user-roles'
import { Privileges } from '../types/users-roles-privileges'
import classNames from 'classnames'
import { getFaveStar } from '../utils/faves'
import { updateUserDoc } from '../utils/users'

interface MachineWithOptionalInlineFluid extends Machine {
  fluid?: CustomerFluid | OsFluid;
}

interface MachineListItemElementProps {
  machine: MachineWithOptionalInlineFluid;
  filter?: string;
  footerButton?: JSX.Element;
}

// Defines that the key in `privilegeDescriptions` must be in the enum `Privileges`,
// see https://stackoverflow.com/a/59213781
export type MachineStateLabels = Record<MachineState, string>

export const machineStateLabels: MachineStateLabels = {
  active: 'Aktiv',
  deactivated: 'Deaktiviert',
  impounded: 'Blockiert',
  deleted: 'Gelöscht'
}

export enum MachineState {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
  IMPOUNDED = 'impounded',
  DELETED = 'deleted'
}

export const getStateLabel = (stateId: MachineState | undefined, moreClassNames?: string, suffix?: string) => {
  const stateLabelClasses = classNames({
    tag: true,
    'is-info': !stateId || stateId === MachineState.ACTIVE,
    'is-dark': stateId === MachineState.DEACTIVATED,
    'is-warning': stateId === MachineState.IMPOUNDED
  })
  const combinedClasses = [stateLabelClasses, moreClassNames].join(' ')
  const label = `${machineStateLabels[stateId || MachineState.ACTIVE]}${suffix ? suffix : ''}`
  return <span
    key={stateId}
    className={combinedClasses}>
      {label}
    </span>
}

export const MachineListItemElement: React.FC<MachineListItemElementProps> = props => {
  const { currentCustomer, currentUser, updateCurrentUser } = useContext(AppContext)
  const { _id: customerId } = currentCustomer
  const { machine, filter, footerButton } = props
  let machineIdURLFragment
  // Check if the ID matches our machine ID format (`ma_1234ABCD`)
  const machineIsFromImportDB = !isMachineDoc(machine)
  const machineWasAlreadyImported = !!machine.doc.importedAs
  if (!machineIsFromImportDB) {
    machineIdURLFragment = machine.id.substr(3)
  }
  const hasNamedFluid = get(machine, 'fluid.name')
  const hasMaterial = get(machine, 'measurementPoint.doc.material')
  const hasOperation = get(machine, 'measurementPoint.doc.operation')

  const userRoles = UserRoles.fromSession()
  const userCanEnterMeasurements = userRoles.hasPrivilege(Privileges.CAN_ENTER_MEASUREMENTS)
  const machineIsActive = machine ? (machine.doc.state ? machine.doc.state === MachineState.ACTIVE : true) : false

  const toggleFave = async () => {
    if (!machine) return
    if (!customerId || !currentUser || !updateCurrentUser) return
    const faves = get(currentUser, `faves[${customerId}]`) || []
    if (machine.doc.isFaved) {
      remove(faves, faveId => faveId === machine.id)
    } else {
      faves.push(machine.id)
    }
    const newFaves = { ...(get(currentUser, 'faves') || {}), [customerId]: faves }
    await updateUserDoc(currentUser, updateCurrentUser, customerId, { faves: newFaves })
  }

  return (
    <div className={`machine card mb2 state-${machine.doc.state || MachineState.ACTIVE}`}>
      <header className="card-header has-background-white-ter">
        <div className="card-header-title">
          <MachineId id={machine.id} hideQRCode={machineIsFromImportDB} format={!machineIsFromImportDB}/>
          {!machineIsFromImportDB &&
            <span className="machine-state">
              {/* cast undefined as false */}
              {getFaveStar(!!machine.doc.isFaved, toggleFave, true)}
              {getStateLabel(machine.doc.state)}
            </span>
          }
        </div>
      </header>
      <div className="card-content">
        <div className="media-content">
          <p className="title is-4">{machine.doc.name}</p>
          <ul className="subtitle is-6">
            <li className="columns">
              <span className="column is-2 is-flex is-align-items-center py-0 has-text-grey is-uppercase has-text-weight-bold is-size-7">Inventarnummer: </span>
              <span className="column py-0">{machine.doc.inventoryNumber}</span>
            </li>
            <li className="columns">
              <span className="column is-2 is-flex is-align-items-center py-0 has-text-grey is-uppercase has-text-weight-bold is-size-7">Standort: </span>
              <span className="column py-0">{machine.doc.location}</span>
            </li>
            {machine.measurementPoint && (
              <>
                {hasNamedFluid && (
                  <li className="columns">
                    <span className="column is-2 is-flex is-align-items-center py-0 has-text-grey is-uppercase has-text-weight-bold is-size-7">Fluid: </span>
                    <span className="column py-0">{machine!.fluid!.name}</span>
                  </li>
                )}
                {hasMaterial && (
                  <li className="columns">
                    <span className="column is-2 is-flex is-align-items-center py-0 has-text-grey is-uppercase has-text-weight-bold is-size-7">Material: </span>
                    <span className="column py-0">{getLabelForId(machine.measurementPoint.doc.material)}</span>
                  </li>
                )}
                {hasOperation && (
                  <li className="columns">
                    <span className="column is-2 is-flex is-align-items-center pt-0 has-text-grey is-uppercase has-text-weight-bold is-size-7">Operation: </span>
                    <span className="column pt-0">{getLabelForId(machine.measurementPoint.doc.operation)}</span>
                  </li>
                )}
              </>
            )}
          </ul>
        </div>
        {machine.doc.tags && (
          <div className="tags">
            {machine.doc.tags.map(
              (tag: string): React.ReactElement => {
                let tagClass = 'tag is-warning'
                if (filter && tag.toLowerCase().includes(filter.toLowerCase())) {
                  tagClass = 'tag is-success'
                }
                return (
                  <span key={`tag-${machine.id}-${tag}`} className={tagClass}>
                    {tag}
                  </span>
                )
              }
            )}
          </div>
        )}
        {machineIsFromImportDB && machineWasAlreadyImported && (
          <div className="content">
            <span className="icon">
              <FontAwesomeIcon icon="exclamation-triangle" size="sm" pull="left" />
            </span>
            <span>
              Bereits als{' '}
              <Link to={`/maschine/${(machine.doc.importedAs as string).substr(3)}`}>
                <MachineId id={machine.doc.importedAs as string} hideQRCode={true} />
              </Link>{' '}
              importiert
            </span>
          </div>
        )}
        {footerButton && footerButton}
      </div>
      {!machineIsFromImportDB && (
        <footer className="card-footer">
          <>
            <Link to={`/maschine/${machineIdURLFragment}`} className="card-footer-item">
              Öffnen
            </Link>
            {userCanEnterMeasurements && machineIsActive && (
              <Link to={`/maschine/${machineIdURLFragment}/messung`} className="card-footer-item">
                <FontAwesomeIcon icon="eye-dropper" size="sm" pull="left" />
                <span>Neue Messung</span>
              </Link>
            )}
          </>
        </footer>
      )}
    </div>
  )
}

interface MachineListElementProps {
  filteredSortedMachines: Machine[];
  filter?: string;
}

const MachineListElement: React.FC<MachineListElementProps> = props => {
  const { filteredSortedMachines, filter } = props
  const [loadMoreAmount, setLoadMoreAmount] = useState<number>(20)
  return (
    <div>
      {filteredSortedMachines.map(
        (machine: Machine, index: number): React.ReactNode => {
          if (index < loadMoreAmount) {
            return <MachineListItemElement machine={machine} key={machine.id} filter={filter} />
          } else {
            return null
          }
        }
      )}
      {loadMoreAmount < filteredSortedMachines.length && (
        <button
          className="button is-large is-fullwidth is-info is-light"
          onClick={() => setLoadMoreAmount(loadMoreAmount + 20)}
        >
          Mehr anzeigen
        </button>
      )}
    </div>
  )
}

export interface Usable {
  name: string;
  amount?: number;
  interval?: string;
}

export interface MachineDoc {
  _id?: string;
  _rev?: string;
  name: string;
  inventoryNumber?: string;
  tags?: string[];
  importedAs?: string;
  imported?: boolean;
  location?: string;
  volume?: number;
  state?: MachineState;
  isFaved?: boolean;
  createdAt?: string;
  usables?: Usable[];
}

export interface Machine {
  id: string;
  doc: MachineDoc;
  measurementPoint?: {
    doc: MeasurementPoint;
  };
}
interface Props extends RouteComponentProps {
  machines?: Machine[];
  isLoading: boolean;
  error?: string;
}

const MachineInventoryList: React.FC<Props> = props => {
  const { inventoryUIState, setInventoryUIState, currentCustomer, currentUser } = useContext(AppContext)
  const { _id: customerId } = currentCustomer
  const { allFluids, hasLoadedFluids } = useFluids(customerId)
  const [filter, setFilter] = useState<string>(inventoryUIState!.filter || '')
  const [sortByWhat, setSortByWhat] = useState<string>(inventoryUIState!.sortByWhat || 'name')
  const [machines, setMachines] = useState<Machine[]>([])
  const [favesOnly, setFavesOnly] = useState<boolean>(false)
  const machineRef = useRef(props.machines)
  const loadedRef = useRef<boolean>(false)

  // Gets called every time the machine props change. We use this to figure out when
  // machine props are actually inserted by diffing the ref (which points to the previous
  // prop state) and the new props. If there are new props and there previously weren't
  // any, we set the machine state, which triggers the filter effect.
  useEffect(() => {
    const previouslyHadNoMachines = !machineRef.current || machineRef.current.length === 0
    const nowHasMachines = props.machines && props.machines.length > 0
    if (previouslyHadNoMachines && nowHasMachines) {
      machineRef.current = props.machines
      props.machines && setMachines(props.machines)
    }
  }, [props.machines])

  // Gets called on machine, fluid, or fluid loading state. This filters when everything
  // has finished loading (only once with the help of a ref). This initial filter is used
  // to apply any stored inventory state from the app context.
  useEffect(() => {
    if (!loadedRef.current && hasLoadedFluids && allFluids.length > 0 && machines.length > 0) {
      sortAndFilter()
      loadedRef.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machines, allFluids, hasLoadedFluids])

  // This gets called every time the filter or sort input changes, and re-filters and/or re-sorts
  // the machines.
  useEffect(() => {
    sortAndFilter()
    // currentUser is in the deps because we want the machine list to update when the faves array in currentUser changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, sortByWhat, favesOnly, currentUser, customerId])

  const userRoles = UserRoles.fromSession()
  const userCanManageMachines = userRoles.hasPrivilege(Privileges.CAN_MANAGE_MACHINES)
  const faves = get(currentUser, `faves[${customerId}]`) || []

  function sortAndFilter(): void {
    if (!machines) return
    let filteredMachines: Machine[] = props.machines ? props.machines : []
    if (allFluids.length > 0) {
      filteredMachines = filteredMachines.map(machine => {
        const fluid = allFluids.find(f => {
          const machineFluidId = machine.measurementPoint && machine.measurementPoint.doc.fluid.id
          return f._id === machineFluidId
        })
        if (!fluid) return machine
        return { fluid, ...machine }
      })
    }
    // Add fav status to machine, so cards can show fav star
    filteredMachines.forEach(machine => {
      machine.doc.isFaved = faves.indexOf(machine.doc._id) >= 0
    })
    if (favesOnly) {
      filteredMachines = filteredMachines.filter(machine => machine.doc.isFaved)
    }
    if (filter && filter !== '') {
      filteredMachines = doFilter(filteredMachines, filter, [
        'doc._id',
        'doc.inventoryNumber',
        'doc.name',
        'doc.tags',
        'doc.type',
        'doc.location',
        'measurementPoint.doc.fluid.name',
        'fluid.name'
      ])
    }
    let filteredSortedMachines: Machine[] = filteredMachines
    if (sortByWhat) {
      filteredSortedMachines = sortBy(filteredMachines, [`doc.${sortByWhat}`])
    }
    setMachines(filteredSortedMachines)
    setInventoryUIState &&
      setInventoryUIState({
        filter,
        sortByWhat,
        currentMachineIds: filteredSortedMachines.map(m => m.id)
      })
  }

  // Render subcomponents
  function renderMachineListHeader(amount: number): React.ReactElement {
    return (
      <>
        <nav className="level is-mobile">
          <div className="level-left">
            <div className="level-item">
              <p className="subtitle is-5">
                {amount === 0 ? (
                  <span>Keine Maschinen</span>
                ) : (
                  <span>
                    <strong>{amount}</strong> {amount === 1 ? 'Maschine' : 'Maschinen'}
                  </span>
                )}
              </p>
            </div>
          </div>
          {userCanManageMachines && (
            <div className="level-right">
              <Link to="/maschine/" className="button is-primary level-item">
                <span className="icon">
                  <FontAwesomeIcon icon="plus-circle" size="sm" />
                </span>
                <span>Neue Maschine</span>
              </Link>
            </div>
          )}
        </nav>
        {amount > 0 && (
          <>
            <div className="field">
              <label className="label">Favoriten</label>
              {faves.length > 0 && (
                <div className="buttons">
                  <button
                    className="button is-warning"
                    onClick={e => {
                      setFavesOnly(!favesOnly)
                    }}
                  >
                    <span className="icon">
                      <FontAwesomeIcon icon="star" size="sm" />
                    </span>
                    {favesOnly ? <span>Alle Maschinen anzeigen</span> : <span>{faves.length} Favoriten anzeigen</span>}
                  </button>
                </div>
              )}
              {faves.length === 0 && <span>Keine Favoriten gespeichert</span>}
            </div>
            <div className="field">
              <label className="label">Sortieren</label>
              <div className="control has-icons-left is-expanded">
                <div className="select has-icons-left is-fullwidth">
                  <select onChange={e => setSortByWhat(e.currentTarget.value)} value={sortByWhat}>
                    <option value="name">Nach Maschinenname</option>
                    <option value="inventoryNumber">Nach Inventarnummer</option>
                    <option value="_id">Nach ID</option>
                  </select>
                </div>
                <div className="icon is-small is-left">
                  <FontAwesomeIcon icon="sort" />
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">Filtern</label>
              <div className="control has-icons-left">
                <input
                  className="input"
                  type="text"
                  placeholder="Name/ID/Inventarnummer/Fluid/Tag"
                  onChange={e => setFilter(e.currentTarget.value)}
                  value={filter}
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon="search" />
                </span>
              </div>
            </div>
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  {filter || favesOnly ? (
                    machines.length ? (
                      <span>
                        Filter aktiv, zeige <strong>{machines.length}</strong> von <strong>{amount}</strong> Maschinen
                        an.
                      </span>
                    ) : (
                      <span>
                        Keine Treffer für <strong>{filter}</strong> gefunden.
                      </span>
                    )
                  ) : (
                    <>
                      { machines.length === 1
                        ? <span>Zeige <strong>eine</strong> Maschine an.</span>
                        : <span>Zeige alle <strong>{amount}</strong> Maschinen an.</span>
                      }
                    </>
                  )}
                </div>
              </div>
              {filter && (
                <div className="level-right">
                  <div className="level-item">
                    <button className="button is-outlined" onClick={e => setFilter('')}>
                      <span className="icon">
                        <FontAwesomeIcon icon="times" size="sm" pull="left" />
                      </span>
                      <span>Filter entfernen</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </>
    )
  }

  function renderMachineListEmptyState(): React.ReactElement {
    return (
      <div className="centered-flexbox box">
        <h1 className="title is-6">Es wurden noch keine Maschinen angelegt</h1>
        <FontAwesomeIcon icon="folder-open" size="4x" />
      </div>
    )
  }

  function renderMachineListError(error: string): React.ReactElement {
    return (
      <article className="message is-warning">
        <div className="message-header">
          <p>
            <FontAwesomeIcon icon="exclamation-triangle" pull="left" />
            Fehler
          </p>
        </div>
        <div className="message-body">{error}</div>
      </article>
    )
  }

  function renderMachineListLoadingState(): React.ReactElement {
    return <GenericLoadingSpinner message="Lade Maschinen" />
  }

  const { machines: propMachines, isLoading, error } = props

  if (isLoading) {
    return renderMachineListLoadingState()
  }

  if (error) {
    return renderMachineListError(error)
  }

  return (
    <>
      {propMachines && (
        <>
          {renderMachineListHeader(propMachines.length)}
          {propMachines.length === 0 ? (
            renderMachineListEmptyState()
          ) : (
            <MachineListElement filteredSortedMachines={machines} filter={filter} />
          )}
        </>
      )}
    </>
  )
}

export default MachineInventoryList

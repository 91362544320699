import { Plugin } from '@uppy/core'
import Compressor from 'compressorjs'

export class ImageCompressorPlugin extends Plugin {
  opts: any

  constructor(uppy: any, opts: any) {
    super(uppy, opts)
    this.id = opts.id || 'ImageCompressor'
    this.type = 'modifier'

    const defaultOptions = {
      quality: 0.6
    }
    this.opts = Object.assign({}, defaultOptions, opts)

    this.setPluginState({})
    this.compress = this.compress.bind(this)
  }

  compress(file: any) {
    new Compressor(
      file.data,
      Object.assign(
        {
          maxWidth: 4096,
          maxHeight: 4096
        },
        this.opts,
        {
          success: (result: Blob) => {
            this.uppy.log(
              `[Compressor] Image ${file.id} size before/after compression: ${file.data.size} / ${result.size}`
            )
            this.uppy.setFileState(file.id, { data: result })
          },
          error: (err: any) => {
            this.uppy.log(`[Compressor] Failed to compress ${file.id}:`, 'warning')
            this.uppy.log(err, 'warning')
          }
        }
      )
    )
  }

  install() {
    this.uppy.on('file-added', this.compress)
  }
}

export interface LoginCredentials {
  email: string
  password: string
  general?: string
}

// If there are login credentials in the env, use them if we’re in dev
// This improves the developer experience by not having to log in 100 times a day
export const defaultLogin: LoginCredentials = {
  email:
    process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEFAULT_USERNAME
      ? process.env.REACT_APP_DEFAULT_USERNAME
      : '',
  password:
    process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEFAULT_PASSWORD
      ? process.env.REACT_APP_DEFAULT_PASSWORD
      : ''
}

// Customer helpers

export const getCustomerIdFromCouchDBRoles = (roles: string[]): string | undefined => {
  if (!roles) return
  return roles.find((role: string) => {
    return role.substr(0, 3) === 'cu_'
  })
}

export const getCustomerRoleFromCouchDBRoles = (roles: string[]): string | undefined => {
  if (!roles) return
  return roles.find((role: string) => {
    return role.match(/^cu-/)
  })
}

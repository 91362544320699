import React, { Component } from 'react'

import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { TagOptionGroup, TagOption } from '@luxx/types/forms'

// Options object as expected by react-select/creatable
// export interface TagOption {
//   value: string
//   label: string
//   isDisabled?: boolean
//   [key: string]: any
// }

// export interface TagOptionGroup {
//   label: string
//   options: TagOption[]
// }

// Most of these props come from Formik
interface Props {
  name: string
  isCreatable?: boolean
  isMulti?: boolean
  isClearable?: boolean
  value: TagOption | TagOption[]
  tagOptions: TagOption[] | TagOptionGroup[]
  // TODO: get these declarations from Formik?
  onChange(field: string, value: any, shouldValidate?: boolean | undefined): void
  onBlur(field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined): void
  error?: string
  touched?: boolean
  isDisabled?: boolean
}

export default class TagInput extends Component<Props> {
  handleChange = (newValue: any, actionMeta: any) => {
    // Our database expects string[], but TagInput provides TagOption[], so we convert those back
    // The opposite happens in form-item.tsx
    let formattedValues: string | string[]

    if (this.props.isMulti) {
      formattedValues = newValue ? newValue.map((tagObject: TagOption): string => tagObject.value) : []
    } else {
      formattedValues = newValue ? newValue.value : ''
    }
    this.props.onChange(this.props.name, formattedValues)
  }
  handleBlur = () => {
    this.props.onBlur(this.props.name)
  }
  render() {
    const { name, value, tagOptions, isCreatable, isMulti, isClearable, isDisabled } = this.props

    if (isCreatable) {
      return (
        <CreatableSelect
          name={name}
          isMulti={isMulti}
          options={tagOptions}
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          isClearable={isClearable}
          noOptionsMessage={({ inputValue }): any => 'Keine Optionen. Geben Sie etwas ein!'}
          formatCreateLabel={(inputValue): any => `"${inputValue}" erstellen`}
          placeholder="Bitte auswählen oder neu eingeben"
          isDisabled={isDisabled}
        />
      )
    } else {
      return (
        <Select
          name={name}
          isMulti={isMulti}
          options={tagOptions}
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          isClearable={isClearable}
          noOptionsMessage={({ inputValue }): any => 'Keine Optionen.'}
          placeholder="Bitte auswählen"
          isDisabled={isDisabled}
        />
      )
    }
  }
}

import React, { useContext } from 'react'

import { MachineDoc, MachineState } from './machine-inventory'
import MachineMeasurementPoint, { MeasurementPoint } from './machine-measurement-point'
import AppContext from '../AppContext'
import useFluids from '../hooks/useFluids'
import { GenericLoadingSpinner } from './partials'
import { get } from 'lodash'

interface Props {
  machine?: MachineDoc;
  measurementPoints: MeasurementPoint[];
}

const MachineMeasurementPoints: React.FC<Props> = props => {
  const { measurementPoints } = props
  const { currentCustomer } = useContext(AppContext)
  const { _id: customerId } = currentCustomer
  const { customerFluids, osFluids, hasLoadedFluids } = useFluids(customerId)

  if (!hasLoadedFluids) {
    return <GenericLoadingSpinner />
  }
  return (
    <>
      {measurementPoints.map(
        (measurementPoint: any): React.ReactElement => {
          return (
            <div key={measurementPoint._id}>
              <MachineMeasurementPoint
                isDisabled={
                  get(props, 'machine.state') === MachineState.DEACTIVATED ||
                  get(props, 'machine.state') === MachineState.IMPOUNDED ||
                  get(props, 'machine.state') === MachineState.DELETED
                }
                measurementPoint={measurementPoint}
                customerFluids={customerFluids}
                osFluids={osFluids}
              />
            </div>
          )
        }
      )}
    </>
  )
}

export default MachineMeasurementPoints

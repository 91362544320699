import React, { Component } from 'react'
import { Link, withRouter, NavLink } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import logo from '../assets/logo-small.png'

import AppContext from '../AppContext'
import { getLocalSession, isNhUser, isOsUser, userIsViewingTheirOwnOrg } from '../utils/auth'
import { UserRoles } from '../utils/user-roles'
import { Privileges } from '../types/users-roles-privileges'
import { appName } from '../utils/general'
import classNames from 'classnames'

class Header extends Component<RouteComponentProps> {
  public static contextType = AppContext
  public render(): React.ReactNode {
    const { currentCustomer, hasNewServiceWorker, onLogout } = this.context
    const { _id: customerId, name: customerName } = currentCustomer
    const session = getLocalSession()
    const hasSession = session && session.roles && session.roles.length !== 0
    const userRoles = UserRoles.fromSession()
    const userCanSwitchCustomers = userRoles.hasPrivilege(Privileges.CAN_SWITCH_CUSTOMERS)
    const userCanManageUsers = userRoles.hasPrivilege(Privileges.CAN_MANAGE_USERS)
    // User is nh or os and currentCustomer is their org
    const ownersViewingThemselves = (isOsUser(session.roles) || isNhUser(session.roles)) && userIsViewingTheirOwnOrg(session.roles, customerId)
    const userCanAccessAnalytics = userRoles.hasPrivilege(Privileges.CAN_ACCESS_ANALYTICS) && !ownersViewingThemselves
    const navbarBrandClasses = classNames({
      'navbar-brand': true,
      'large-logo': !userCanSwitchCustomers
    })
    return (
      <header className="app-header">
        {hasNewServiceWorker && (
          <div className="newApp">
            Eine neue Version dieser App ist verfügbar. <a href="/">Zum Update bitte hier klicken.</a>
          </div>
        )}
        <nav className="navbar is-transparent" role="navigation" aria-label="main navigation">
          <div className={navbarBrandClasses}>
            <Link to="/" className="header-logo navbar.item">
              <img src={logo} alt={appName} />
            </Link>
            {userCanSwitchCustomers && (
              <Link to="/customers" className="header-customer navbar-item">
                {customerName || 'Kundenübersicht'}
              </Link>
            )}
          </div>
          {hasSession && (
            <div className="navbar-menu">
              <div className="navbar-start"></div>

              <div className="navbar-end">
                <div className="navbar-item">
                  <div className="buttons">
                    {hasSession && (
                      <>
                        {customerId && userCanAccessAnalytics && (
                          <NavLink to={`/analyst/${customerId}`} className="button is-primary hide-on-mobile">
                            <span className="icon">
                              <FontAwesomeIcon icon="chart-bar" size="sm" />
                            </span>
                            <span>Analyse</span>
                          </NavLink>
                        )}
                        <NavLink to="/qr" className="button is-primary hide-on-mobile">
                          <span className="icon">
                            <FontAwesomeIcon icon="qrcode" size="sm" />
                          </span>
                          <span>QR</span>
                        </NavLink>
                        {userCanManageUsers && (
                          <NavLink to="/benutzerverwaltung" className="button is-primary hide-on-mobile">
                            <span className="icon">
                              <FontAwesomeIcon icon="users" size="sm" />
                            </span>
                            <span>Nutzer</span>
                          </NavLink>
                        )}
                        <NavLink to="/benachrichtigungseinstellungen" className="button is-primary">
                          <span className="icon">
                            <FontAwesomeIcon icon="bell" size="sm" />
                          </span>
                        </NavLink>
                        <button className="button is-primary" onClick={() => onLogout()}>
                          <span className="icon">
                            <FontAwesomeIcon icon="sign-out-alt" size="sm" />
                          </span>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </nav>
        {/* This is semantically speaking a footer, but it relates so closely to the header nav that
        it kinda belongs here. */}
        {hasSession && customerId && (
          <div className="tabs is-fullwidth fixed-bottom-nav only-on-mobile">
            <ul>
              <li>
                <Link to={`/analyst/${customerId}`} className="button is-primary">
                  <span className="icon">
                    <FontAwesomeIcon icon="chart-bar" size="sm" />
                  </span>
                  <span>Analyse</span>
                </Link>
              </li>
              <li>
                <Link to="/qr" className="button is-primary">
                  <span className="icon">
                    <FontAwesomeIcon icon="qrcode" size="sm" />
                  </span>
                  <span>QR</span>
                </Link>
              </li>
              {userCanManageUsers && (
                <li>
                  <Link to="/benutzerverwaltung" className="button is-primary">
                    <span className="icon">
                      <FontAwesomeIcon icon="users" size="sm" />
                    </span>
                    <span>Nutzer</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        )}
      </header>
    )
  }
}

export default withRouter(Header)

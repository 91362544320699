import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Privileges, User } from '../types/users-roles-privileges'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getRoleNameForId, getUserRoleFromCouchDBRoles } from '../utils/auth'
import AppContext from '../AppContext'
import { UserRoles } from '../utils/user-roles'
import { Customer } from '../hooks/useCustomers'

interface Props {
  currentUser: User
  currentCustomer: Customer
  users?: User[]
  isLoading: boolean
  resendHandler(userId: string): void
  deleteHandler(userId: string): void
  toggleActiveHandler(userId: string): void
}

class UserList extends Component<Props, {}> {
  public static contextType = AppContext
  public render(): React.ReactNode {
    const { users, currentUser, currentCustomer, isLoading } = this.props
    if (isLoading) {
      return (
        <div className="centered-flexbox box">
          <h1 className="title is-6">Lade Benutzer</h1>
          <FontAwesomeIcon icon="sync" spin size="4x" />
        </div>
      )
    }
    if (!users || users.length === 0) {
      return <span>Keine Nutzer gefunden.</span>
    }
    return (
      <div className="userList">
        <h3 className="title is-size-3">
          {users.length} {users.length === 1 ? 'Benutzer/in' : 'Benutzer/innen'}
        </h3>
        <div className="table-container">
          <table className="user-table table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>#</th>
                <th>Vorname</th>
                <th>Nachname</th>
                <th>Email-Adresse</th>
                <th>Rolle</th>
                <th>Status</th>
                <th>Aktionen</th>
              </tr>
            </thead>
            <tbody>
              {users.map(
                (user: User, index: number): React.ReactNode => {
                  if (!user.roles) return null
                  const userRole = getUserRoleFromCouchDBRoles(user.roles)
                  if (!userRole) return null
                  const userRoleName = getRoleNameForId(userRole)
                  const userConfirmedStatus = user.confirmed ? 'aktiv' : 'eingeladen'
                  let userStatus
                  if (user.active === undefined || (user.active !== undefined && user.active)) {
                    userStatus = userRole === 'cu-nologin' ? 'ohne login' : userConfirmedStatus
                  } else {
                    userStatus = <strong>deaktiviert</strong>
                  }
                  let canBeAssignedCustomers = UserRoles.fromSession().hasPrivilege(Privileges.CAN_ASSIGN_CUSTOMERS)
                  // If the current customer is a reseller and the current user doesn’t belong to that reseller,
                  // don’t allow editing customer permissions, because the current user isn’t allowed to see
                  // the reseller’s customers anyway, the permissions page would be empty.
                  if (currentCustomer.resellerStats && currentCustomer._id !== currentUser.customer._id) {
                    canBeAssignedCustomers = false
                  }
                  const isYou = user.email === currentUser.email
                  return (
                    <tr key={`user-${user.email}`}>
                      <td>{index + 1}</td>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>
                        {user.email}
                        {isYou && ` (Das sind Sie)`}
                      </td>
                      <td>{userRoleName}</td>
                      <td>{userStatus}</td>
                      <td>
                        {!isYou && (
                          <p className="buttons is-flex-wrap-nowrap">
                            {userRole !== 'cu-nologin' && (
                              <button
                                className="button"
                                onClick={() => this.props.resendHandler(user.email as string)}
                                disabled={user.confirmed}
                              >
                                <span className="icon is-small">
                                  <FontAwesomeIcon icon="sync" size="sm" />
                                </span>
                              </button>
                            )}
                            {canBeAssignedCustomers && (userRole === 'os-inspector' || userRole === 're-inspector') && (
                              <Link
                                className="button"
                                role="button"
                                title="Kund/innen auswählen"
                                to={`/benutzerverwaltung/${user.id}/customers`}
                              >
                                <span className="icon is-small">
                                  <FontAwesomeIcon icon="users" size="sm" />
                                </span>
                              </Link>
                            )}
                            <a
                              className={`button ${user.active === false ? 'is-success' : 'is-danger'}`}
                              onClick={() => this.props.toggleActiveHandler(user.email as string)}
                            >
                              <span className="icon is-small">
                                {user.active === false ? (
                                  <FontAwesomeIcon icon="user-check" size="sm" />
                                ) : (
                                  <FontAwesomeIcon icon="user-times" size="sm" />
                                )}
                              </span>
                            </a>
                            {/*<button
                              className="button is-danger"
                              onClick={() => this.props.deleteHandler(user.email as string)}
                            >
                              <span className="icon is-small">
                                <FontAwesomeIcon icon="times" size="sm" />
                              </span>
                            </button>*/}
                          </p>
                        )}
                      </td>
                    </tr>
                  )
                }
              )}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default UserList

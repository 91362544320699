import React from 'react'
import DatabaseManager from './utils/database-manager'
import { User } from './types/users-roles-privileges'
import { Customer } from './hooks/useCustomers'

// Holds our global application state and any methods needed to update it.
// Is initially populated in ./App.tsx

export interface InventoryUIState {
  filter: string;
  sortByWhat: string;
  currentMachineIds?: string[];
}

export interface AppState {
  currentUser?: User;
  currentCustomer?: Customer;
  hasNewServiceWorker?: boolean;
  inventoryUIState: InventoryUIState;
  databaseManager: DatabaseManager;
  setInventoryUIState(arg0: InventoryUIState): void;
  onPouchError(error: PouchDB.Core.Error): React.ReactElement | null;
  onCustomerSelect(customer: Customer, redirectTarget?: string): void;
  setCurrentCustomer(currentUser: User): void;
  updateCurrentUser(userData: User): void;
  onLogin(customerId: string, userData: User, postLoginTarget: string | undefined): void;
  onLogout(): void;
  findCustomerForMachineId(id: string): Promise<void>;
}

// React contexts and defaults are weird in Typescript, so we <Partial> the interface to make
// everything inside it optional, since the default is `{}`
// See https://www.typescriptlang.org/docs/handbook/utility-types.html#partialt
const AppContext = React.createContext<Partial<AppState>>({})

export const AppProvider = AppContext.Provider
export const AppConsumer = AppContext.Consumer

export default AppContext

import React from 'react'
import { RouteComponentProps } from 'react-router'
import SetPassword from '../components/forms/set-password'

interface MatchParams {
  token: string;
}

const Reset: React.FC<RouteComponentProps<MatchParams>> = props => {
  return <SetPassword token={props.match.params.token} title="Passwort zurücksetzen" isReset={true} />
}

export default Reset

import React, { Component } from 'react'
import PouchDB from 'pouchdb'

import { OsFluid, CustomerFluid } from '../types/fluids'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppContext from '../AppContext'
import { shouldNotSeeOSBrand } from '../utils/general'

/*

Displays an info box for any Öl Schüler-Fluid with links to their shop, info PDFs etc

If you already have the Öl Schüler fluids and the customer’s fluids (^^) you can directly use the
`FluidInfo` component, if you only have the `currentFluidID` but not the fluid catalog, use the
`FluidInfoHOC` instead, it will load those for you, provided you have the `customerId` set in the
AppContext.

*/
interface Props {
  currentFluidID: string;
  osFluids: OsFluid[];
  customerFluids: CustomerFluid[];
}

export const FluidInfo: React.FC<Props> = ({ currentFluidID, osFluids, customerFluids }) => {
  if (!currentFluidID) return null
  const allFluids = [...customerFluids, ...osFluids]
  const currentFluid: CustomerFluid | OsFluid | undefined = allFluids.find(
    (fluid: CustomerFluid | OsFluid): boolean => {
      return fluid._id === currentFluidID
    }
  )
  if (!currentFluid) return null
  const isOsFluid = !!(currentFluid as OsFluid).productUrl
  if (!isOsFluid) return null
  const hiddenForBrandingReasons = shouldNotSeeOSBrand()
  return (
    <article className="message is-info">
      <div className="message-header">
        <p>
          Fluid-Informationen für <strong>{currentFluid.name}</strong>
        </p>
      </div>
      <div className="message-body">
        {isOsFluid && (
          <>
            <ul>
              <li>
                <strong>Nummer</strong>: {currentFluid._id}
              </li>
              <li>
                <strong>Hersteller</strong>: {(currentFluid as OsFluid).supplier}
              </li>
            </ul>
            {!hiddenForBrandingReasons && (
              <div className="buttons">
                <a
                  className="button is-info"
                  href={(currentFluid as OsFluid).productUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon is-small">
                    <FontAwesomeIcon icon="shopping-cart" size="sm" />
                  </span>
                  <span>Produktseite im Öl Schüler-Shop</span>
                </a>
                <a
                  className="button is-info"
                  href={(currentFluid as OsFluid).piUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon is-small">
                    <FontAwesomeIcon icon="info-circle" size="sm" />
                  </span>
                  <span>Produktinformationen (PDF)</span>
                </a>
                <a
                  className="button is-warning"
                  href={(currentFluid as OsFluid).msdsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon is-small">
                    <FontAwesomeIcon icon="exclamation-triangle" size="sm" />
                  </span>
                  <span>Sicherheitsdatenblatt (PDF)</span>
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </article>
  )
}

interface HOCProps {
  currentFluidID: string;
}

interface HOCState {
  osFluids: OsFluid[];
  customerFluids: CustomerFluid[];
  isLoading: boolean;
}

class FluidInfoHOC extends Component<HOCProps, HOCState> {
  public static contextType = AppContext
  public state = {
    osFluids: [],
    customerFluids: [],
    isLoading: true
  }
  private fetchData = async (): Promise<void> => {
    const { currentCustomer } = this.context
    const { _id: customerId } = currentCustomer
    if (!customerId) return
    const customerDB = new PouchDB(customerId)
    const customerFluidRows = await customerDB.allDocs({
      include_docs: true,
      startkey: `fl_`,
      endkey: `fl_\ufff0`
    })
    const customerFluids = customerFluidRows.rows.map((row: any) => row.doc)
    const fluidDb = new PouchDB('oel-products')
    const osFluidRows = await fluidDb.allDocs({ include_docs: true })
    const osFluids = osFluidRows.rows.map((row: any) => row.doc)
    this.setState({
      osFluids,
      customerFluids,
      isLoading: false
    })
  }
  public componentDidMount(): void {
    this.fetchData()
  }
  public render(): React.ReactNode {
    const { currentFluidID } = this.props
    const { osFluids, customerFluids, isLoading } = this.state
    if (isLoading) return null
    return <FluidInfo currentFluidID={currentFluidID} osFluids={osFluids} customerFluids={customerFluids} />
  }
}

export default FluidInfoHOC

import React from 'react'
import { BigNumber, BigNumberProps } from './big-number'

export interface BigNumberGroupProps {
  bigNumbers: BigNumberProps[];
}

export const BigNumberGroup: React.FC<BigNumberGroupProps> = props => {
  return (
    <div className="metricStatuses">
      <div className="collectionLabel">Letzte Messungen</div>
      {props.bigNumbers.map((bigNumber: BigNumberProps) => (
        <BigNumber {...bigNumber} key={bigNumber.id} />
      ))}
    </div>
  )
}

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import './index.css'
import 'react-datepicker/dist/react-datepicker.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import ErrorBoundary from './utils/error-boundary'

ReactDOM.render(
  <BrowserRouter>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorkerRegistration.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event: Event) => {
        if (event && event!.target) {
          const s = event!.target as any
          if (s.state === 'activated') {
            window.location.reload()
          }
        }
      })
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
    }
  }
})

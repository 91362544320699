import usePersons from '../hooks/usePersons'
import { Customer, Privileges, User } from '../types/users-roles-privileges'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { UserRoles } from '../utils/user-roles'
import { GenericLoadingSpinner } from '../components/partials'
import { types, useAlert } from 'react-alert'
import AppContext from '../AppContext'
import { get } from 'lodash'
import NoMatch from './no-match'
import useCustomers from '../hooks/useCustomers'
import useSortableData from '../hooks/useSortableData'
import { SortableHeaderButton } from '../components/SortableHeaderButton'
import '../styles/customer-filter.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  userId: string
  renderWithUsers: (users: User[], user: User, currentUser: User, currentCustomer: Customer) => JSX.Element
}

function ForbiddenMessage() {
  return (
    <article className="message is-warning">
      <div className="message-header">
        <p>Ansicht nicht verfügbar</p>
      </div>
      <div className="message-body">
        <p className="mb2">
          Der Server konnte nicht erreicht werden; wahrscheinlich hat dieses Gerät gerade keine Internetverbindung.
          Alternativ gibt es Verbindungsprobleme, oder der Server läuft nicht.
        </p>
        <p>
          Das Verwalten von Benutzern erfordert eine Serververbindung. Überprüfen Sie Ihre Netzwerkverfügbarkeit oder
          versuchen Sie es später noch einmal.
        </p>
      </div>
    </article>
  )
}

const getCustomerType = (customer: Customer): string => {
  if (customer.stripe_id) {
    return 'Stripe'
  } else if (customer.reseller || customer.resellerStats) {
    return 'Reseller'
  }
  return '-'
}

type ListProps = {
  user: User
  currentCustomer: Customer
}

function CustomerSelectionList({ user, currentCustomer }: ListProps) {
  const { customers, hasLoadedCustomers: hasLoaded, error } = useCustomers()
  const history = useHistory()

  const [submissionError, setSubmissionError] = useState<Error>()
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([])
  const [filter, setFilter] = useState<string>('')
  const [filteredCustomers, setFilteredCustomer] = useState<Customer[]>(customers)
  const allSelected = useMemo(() => hasLoaded && selectedCustomers.length === customers.length, [selectedCustomers, customers])
  const noneSelected = useMemo(() => hasLoaded && selectedCustomers.length ===0, [selectedCustomers, customers])

  const { items: sortedCustomers, requestSort, sortConfig } = useSortableData(filteredCustomers, {
    key: 'name',
    direction: 'ascending'
  })

  const getSortDirection = (name: string) => {
    if (!sortConfig) {
      return
    }
    return sortConfig.key === name ? sortConfig.direction : undefined
  }

  const getSortProps = (id: string) => ({
    id,
    sortDirection: getSortDirection(id),
    onRequestSort: () => requestSort(id)
  })

  useEffect(() => {
    const filteredCustomers = customers.filter(customer => {
      // Filter out non-customer docs
      if (!customer.name) return false
      const allowedBySearchFilter = filter ? customer._id.toLowerCase().includes(filter.toLowerCase()) ||
      customer.name.toLowerCase().includes(filter.toLowerCase()) : true
      return allowedBySearchFilter
    })
    setFilteredCustomer(filteredCustomers)
  }, [customers, filter])

  useEffect(() => {
    if (!hasLoaded) {
      return
    }
    // handle the case of superfluous customers that were added manually on the database
    const customerIds = customers.map(({ _id }) => _id)
    const userCustomers = get(user, 'customers') || []
    setSelectedCustomers(userCustomers.filter(customerId => customerIds.indexOf(customerId) > -1))
  }, [user, customers, hasLoaded])

  const handleSelect = useCallback((customerId: string, selected: boolean) => {
      const index = selectedCustomers.indexOf(customerId)
      let updatedSelection = [...selectedCustomers]

      if (!selected && index > -1) {
        // remove customer
        updatedSelection.splice(index, 1)
      } else if (selected && index === -1) {
        // add customer
        updatedSelection.push(customerId)
      }

      if (updatedSelection.length === customers.length) {
        updatedSelection = []
      }
      setSelectedCustomers(updatedSelection)
    },
    [selectedCustomers]
  )

  const handleSubmit = useCallback(async () => {
    setSubmissionError(undefined)

    try {
      await fetch(`${process.env.REACT_APP_COUCHDB_ENDPOINT}/_backend/user/${currentCustomer._id}/${user.name}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json'
        },
        body: JSON.stringify({
          oelUser: {
            customers: selectedCustomers
          }
        })
      })
      history.push('/benutzerverwaltung')
    } catch (e) {
      setSubmissionError(e)
    }
  }, [selectedCustomers])

  const handleCancel = useCallback(() => {
    history.push('/benutzerverwaltung')
  }, [])

  const handleSelectAll = useCallback(() => {
    const ids = [...customers.map((c) => c._id)]
    setSelectedCustomers(ids)
  }, [customers])

  const handleDeselectAll = useCallback(() => {
    setSelectedCustomers([])
  }, [])

  if (!hasLoaded) {
    return <GenericLoadingSpinner />
  } else if (error) {
    return (
      <article className="message is-danger mt-2">
        <div className="message-header">
          <p>Fehler</p>
        </div>
        <div className="message-body"><p>{error}</p><p>Die Kundenauswahl für Angestellte funktioniert nur online.</p></div>
      </article>
    )
  }
  return (
    <div className="userList">
      <hr />
      <label className="label">Filtern</label>
      <div className="field has-addons">
        <div className="control has-icons-left is-expanded">
          <input
            className="input"
            type="text"
            placeholder="Firmenname/Kunden-ID"
            onChange={e => setFilter(e.currentTarget.value)}
            value={filter}
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon="search" />
          </span>
        </div>
        {filter && (
          <div className="control">
            <button className="button is-info" onClick={e => setFilter('')}>
              <span className="icon">
                <FontAwesomeIcon icon="times" size="sm" pull="left" />
              </span>
              <span>Filter entfernen</span>
            </button>
          </div>
        )}
      </div>
      {filter && (
        <p className="content ml-3">{filteredCustomers.length} von {customers.length} sichtbar</p>
      )}
      <div className="table-container">
        <table className="mt-4 user-table table is-striped is-fullwidth">
          <thead>
            <tr>
              <th><SortableHeaderButton label="Kundenname" {...getSortProps('name')} /></th>
              <th><SortableHeaderButton label="Kunden-ID" {...getSortProps('_id')} /></th>
              <th>Typ</th>
              <th>Für Nutzer sichtbar?</th>
            </tr>
          </thead>
          <tbody>
            {sortedCustomers.map(customer => (
              <tr key={`customer-${customer._id}`}>
                <td>{customer.name}</td>
                <td>{customer._id}</td>
                <td>{getCustomerType(customer)}</td>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedCustomers.includes(customer._id)}
                    onChange={event => handleSelect(customer._id, event.target.checked)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="box is-flex is-flex-direction-column tableActions">
        {submissionError && (
          <div className="notification is-danger general errorMessage">
            Bei der Übertragung der Daten ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
          </div>
        )}
        <div className="is-flex is-flex-direction-row is-align-items-center is-flex-wrap-wrap">
          <button className="button mr-4" onClick={handleSelectAll} disabled={allSelected}>
            Alle auswählen
          </button>
          <button className="button mr-4" onClick={handleDeselectAll} disabled={noneSelected}>
            Keine auswählen
          </button>
          <p className="mr-4">
            {noneSelected
              ? <strong>
                Keine Beschränkung (alle Kund/innen sichtbar)
              </strong>
              : <strong>
                Sie haben{' '}
                {selectedCustomers.length > 0 && selectedCustomers.length < customers.length
                  ? `${selectedCustomers.length} von ${customers.length}`
                  : 'alle'}{' '}
                Kund/innen ausgewählt.
              </strong>
            }
          </p>
          <div className="buttons ml-auto is-align-self-flex-end is-flex-wrap-nowrap">
            <button className="button is-primary" type="submit" onClick={handleSubmit}>
              Speichern
            </button>
            <button className="button" onClick={handleCancel}>
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function useCurrentUser(): { currentUser: User; currentCustomer?: Customer } {
  const { currentCustomer, currentUser } = useContext(AppContext)
  const history = useHistory()

  if (!currentUser) {
    history.push('/login')
  }
  return { currentUser: currentUser!, currentCustomer }
}

function ProtectedWithPrivileges({
  privileges,
  redirectUrl = '/',
  children
}: {
  privileges: Privileges[]
  redirectUrl?: string
  children: React.ReactNode
}) {
  const userRoles = UserRoles.fromSession()
  if (privileges.some(privilege => !userRoles.hasPrivilege(privilege))) {
    return <Redirect to={redirectUrl} />
  }
  return <>{children}</>
}

function ProtectedComponentWithUsers({ userId, renderWithUsers }: Props) {
  // `userId` is already lowercased when it arrives here
  const alert = useAlert()
  const { currentUser, currentCustomer } = useCurrentUser()
  const [user, setUser] = useState<User | undefined>()
  const history = useHistory()

  const customerId = currentCustomer ? currentCustomer._id : undefined
  const { persons: users, hasLoaded, responseStatus } = usePersons(customerId)
  useEffect(() => {
    setUser(users.find(user => user.id.toLowerCase() === userId))
  }, [hasLoaded, users])

  if (!hasLoaded) {
    return <GenericLoadingSpinner />
  } else if (responseStatus && responseStatus === 401) {
    alert.show('Ihre Session ist abgelaufen, bitte melden Sie Sich neu an.', {
      type: types.ERROR,
      timeout: 10000
    })
    history.push('/login')
    return null
  } else if (responseStatus && responseStatus !== 200) {
    return <ForbiddenMessage />
  } else if (hasLoaded && !user) {
    return <NoMatch />
  }
  return renderWithUsers(users, user!, currentUser, currentCustomer!)
}

type Params = {
  userId: string
}

export default function CustomerFilter() {
  const { userId } = useParams<Params>()

  return (
    <ProtectedWithPrivileges privileges={[Privileges.CAN_ASSIGN_CUSTOMERS, Privileges.CAN_SWITCH_CUSTOMERS]}>
      <ProtectedComponentWithUsers
        userId={userId.toLowerCase()}
        renderWithUsers={(users, user, currentUser, currentCustomer) => {
          return (
            <section>
              <h2 className="title is-size-2">Kundenauswahl</h2>
              <p>
                Sie können auswählen, welche Kund/innen <strong>{user.firstName} {user.lastName}</strong> (
                <code>{user.email}</code>) sehen und bearbeiten kann.
              </p>
              <p>
              Wenn Sie nichts auswählen, gilt keine Beschränkung, und die Person kann alles sehen und bearbeiten.
              </p>
              {users && <CustomerSelectionList user={user} currentCustomer={currentCustomer} />}
            </section>
          )
        }}
      />
    </ProtectedWithPrivileges>
  )
}

import React, { useState } from 'react'
import { FormContainer, FormValues } from '@luxx/forms'
import FormItem from '@luxx/forms'
import { useAlert, types } from 'react-alert'
import * as Yup from 'yup'

const validation = Yup.object({
  email: Yup.string()
    .email()
    .required()
})

const ResetPassword: React.FC = props => {
  const alert = useAlert()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const initial: FormValues = { email: '' }

  async function onSubmit(values: any, actions: any) {
    const { REACT_APP_COUCHDB_ENDPOINT } = process.env
    setIsSubmitting(true)
    await fetch(`${REACT_APP_COUCHDB_ENDPOINT}/_backend/user/resetpassword`, {
      method: 'put',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json'
      },
      body: JSON.stringify({
        email: values.email
      })
    }).then(
      async (res): Promise<void> => {
        const data = await res.json()
        if (!res.ok || !data) {
          alert.show('Fehler beim Anfragen des Backends', {
            type: types.ERROR
          })
          setIsSubmitting(false)
          return
        }

        alert.show('Falls ein Konto für diese Email-Adresse existiert, wurde soeben ein Link zum Zurücksetzen Ihres Passwortes verschickt.', {
          type: types.SUCCESS
        })
        setIsSubmitting(false)
      }
    )
  }

  return (
    <>
      <h1 className="title">Passwort zurücksetzen</h1>
      <p>Wenn Sie Ihr Passwort vergessen haben, können wir Ihnen eine Email zum Zurücksetzen schicken.</p>
      <FormContainer
        submitLabel="Abschicken"
        onSubmit={onSubmit}
        enableReinitialize={true}
        disabled={isSubmitting}
        validationSchema={validation}
        putDocument={values => null}
        initialValues={initial}
      >
        <FormItem label="Email-Addresse" name="email" type="text" />
      </FormContainer>
    </>
  )
}

export default ResetPassword

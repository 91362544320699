import { useState, useEffect } from 'react'
import PouchDB from 'pouchdb'
import { getCustomerIdFromCouchDBRoles } from '@luxx/utils/auth'
import { UserRoles } from '../utils/user-roles'
import { MachineState } from '../components/machine-inventory'

export type MachineStateCount = Record<MachineState, number>

export interface ResellerStats {
  reselleeAmount: number;
  reselleeMachines: MachineStateCount;
  resellerMachines: MachineStateCount;
}
export interface Customer {
  _id: string;
  name: string;
  users?: number;
  machines?: number;
  firstMachineDate?: string;
  createdAt?: string;
  activityType?: string;
  activityTime?: string;
  error?: any;
  isLockedForOsUsers?: boolean;
  resellerStats?: ResellerStats;
}

/*
`key` is any value you would like to use as a reload trigger
Common use cases:
1. passing in `isSynced` from the `useInitialSyncCheck` hook to trigger a reload of data after initially downloading the DBs
2. passing in the `key` param from react-router, to detect page reloads or redirections back onto the same URL or a sub-route of it
*/

const useCustomers = (key?: any, customerId?: string) => {
  const [customers, setCustomers] = useState<Customer[]>([])
  const [hasLoadedCustomers, setHasLoadedCustomers] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const userRoles = UserRoles.fromSession()
  let customersDatabaseName = 'oel-customers'
  if (userRoles.isReseller()) {
    // Danger: this must be the customerId OF THE RESELLER, not
    // the customer we're currently looking at
    const customerIdOfReseller = getCustomerIdFromCouchDBRoles(userRoles.roles)
    customersDatabaseName = `${customerIdOfReseller}-customers`
  }
  useEffect(() => {
    let didCancel = false
    const { REACT_APP_COUCHDB_ENDPOINT } = process.env
    const fetchData = async () => {
      // Get customers
      let endpoint = `${REACT_APP_COUCHDB_ENDPOINT}/_backend/customer`
      if (customerId) {
        endpoint = `${endpoint}/${customerId}`
      }
      await fetch(endpoint, {
        method: 'GET',
        credentials: 'include',
        headers: {
          accept: 'application/json'
        }
      })
        .then(
          async (res): Promise<void> => {
            if (!didCancel && res.status === 402) {
              setCustomers([])
              setHasLoadedCustomers(true)
            } else if (!didCancel) {
              const data = await res.json()
              // filter out any future prices doc
              setCustomers(data.filter((d) => d._id !== 'prices'))
              setHasLoadedCustomers(true)
            }
          }
        )
        .catch(async err => {
          setError(
            'Server nicht erreichbar. Die angezeigten Daten kommen kommen direkt von diesem Gerät und sind möglicherweise unvollständig oder veraltet.'
          )
          const customersDB = new PouchDB(customersDatabaseName)
          if (customerId) {
            // Infuriatingly, PouchDB doesn‘t include a type for a get response,
            // so TODO we need to build one
            const customer = await customersDB.get(customerId) as any
            setCustomers([customer])
          } else {
            const customers = await customersDB.allDocs({ include_docs: true })
            setCustomers(customers.rows.map(row => (row.doc as unknown) as Customer))
          }
          setHasLoadedCustomers(true)
        })
    }
    fetchData()
    // Clean up on unmount
    return () => {
      didCancel = true
    }
  }, [customersDatabaseName, key, customerId])
  return { customers, hasLoadedCustomers, error }
}
export default useCustomers

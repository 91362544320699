import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const getFaveStar = (
  isFaved: boolean,
  clickHandler: () => Promise<void>,
  tinyButton?: boolean
): React.ReactNode => {
  const containerClass = tinyButton ? 'tinyButtons' : 'buttons'
  return (
    <div className={containerClass}>
      {isFaved ? (
        <button className="button is-primary is-warning" onClick={clickHandler}>
          <span className="icon">
            <FontAwesomeIcon icon="star" />
          </span>
        </button>
      ) : (
        <button className="button is-outlined is-dark" onClick={clickHandler}>
          <span className="icon">
            <FontAwesomeIcon icon={['far', 'star']} />
          </span>
        </button>
      )}
    </div>
  )
}

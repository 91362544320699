import React from 'react'
import { InteractiveComponentProps } from '../machine-protocol'
import { format } from 'date-fns'
import { dateFormat } from '../../utils/general'

export default class Select extends React.PureComponent<InteractiveComponentProps> {
  public render(): React.ReactNode {
    const { measurement, meta } = this.props
    const localisedDate = format(new Date(measurement.measuredAt), dateFormat)
    const valueAndLabel = meta.options!.find((opt): boolean => {
      return opt[0] === measurement.value
    })
    let label = measurement.value
    if (valueAndLabel && valueAndLabel[1]) {
      label = valueAndLabel[1]
    }
    return (
      <div className="measurement-text-container">
        <div className="measurement-label">
          <span className="measurement-name">{meta.name}</span>
          <span className="measurement-date">vom {localisedDate}</span>
          <input className="measurement-value input readonly" value={label} readOnly disabled />
        </div>
      </div>
    )
  }
}

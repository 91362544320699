import { useState, useEffect } from 'react'

import DatabaseManager from '../utils/database-manager'

const useInitialSyncCheck = (databaseManager: DatabaseManager) => {
  const [isSynced, setIsSynced] = useState(false)
  const [hasAttemptedSync, sethasAttemptedSync] = useState(false)
  useEffect(() => {
    const checkSyncState = async () => {
      sethasAttemptedSync(true)
      const successfullySynced = await databaseManager!.isInitialSyncDone()
      setIsSynced(successfullySynced)
    }
    if (databaseManager) {
      checkSyncState()
    } else {
      setIsSynced(false)
    }
  }, [databaseManager])
  return { isSynced: hasAttemptedSync ? isSynced : undefined }
}
export default useInitialSyncCheck

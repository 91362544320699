import React, { useContext, useState } from 'react'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import { types, useAlert } from 'react-alert'

import AppContext from '../AppContext'
import { UserRoles } from '../utils/user-roles'
import UserInvitationForm from '../components/forms/user-invitation-form'
import { Privileges, User } from '../types/users-roles-privileges'
import UserList from '../components/user-list'
import usePersons from '../hooks/usePersons'
import { GenericLoadingSpinner } from '../components/partials'
import { basicInvitationTemplate } from '../components/templates/invitation-template'
import { appName } from '../utils/general'
import { getPersonById } from '../components/machine-action'
import { getCustomerRoleFromCouchDBRoles } from '../utils/auth'

interface Props extends RouteComponentProps {
  alert: any;
}

interface State {
  users: any[];
  error?: string;
  isLoading: boolean;
  redirectToLogin: boolean;
  couldNotConnectToServer: boolean;
}

const UserManagement: React.FC = props => {
  const alert = useAlert()
  const [key, setKey] = useState<number>(1)
  const { currentCustomer, currentUser: invitingUser } = useContext(AppContext)
  const { _id: customerId } = currentCustomer
  const { persons, hasLoaded, responseStatus } = usePersons(customerId, key.toString())
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  const forceRefresh = () => {
    setKey(key + 1)
  }

  const handleResend = async (userId: string): Promise<void> => {
    const invitee = getPersonById(persons, userId)
    if (!invitee) return
    const inviteeRole = getCustomerRoleFromCouchDBRoles(invitee.roles)
    if (!inviteeRole) return
    const payload = {
      subject: `Willkommen bei ${appName}, unserem Fluid-Management-Tool`,
      body: basicInvitationTemplate(inviteeRole, invitingUser)
    }
    const { REACT_APP_COUCHDB_ENDPOINT } = process.env
    await fetch(`${REACT_APP_COUCHDB_ENDPOINT}/_backend/resend-invitation/${customerId}/${invitee.email}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(
        async (res): Promise<void> => {
          const data = await res.json()
          if (res.status === 200) {
            alert.show(data.message, {
              type: types.SUCCESS
            })
          } else {
            alert.show(data.message, {
              type: types.ERROR
            })
          }
        }
      )
      .catch((error): void => {
        alert.show(`Fehler beim erneuten Einladen: ${error}`, {
          type: types.ERROR
        })
      })
  }

  const handleToggleActive = async (userId: string): Promise<void> => {
    const { REACT_APP_COUCHDB_ENDPOINT } = process.env
    let user = persons.find(user => {
      return user.email === userId
    })
    if (!user) return
    await fetch(REACT_APP_COUCHDB_ENDPOINT + `/_backend/user/${customerId}/${encodeURIComponent(userId)}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json'
      },
      body: JSON.stringify({
        oelUser: {
          active: !user.active
        }
      })
    })
      .then(
        async (res): Promise<void> => {
          const data = await res.json()
          if (res.status === 200) {
            alert.show(`Benutzer erfolgreich ${!user!.active ? 'aktiviert' : 'deaktiviert'}`, {
              type: types.SUCCESS
            })
            forceRefresh()
          } else {
            alert.show(data.message, {
              type: types.ERROR
            })
          }
        }
      )
      .catch((error): void => {
        alert.show(`Fehler bei der Nutzereditierung: ${error}`, {
          type: types.ERROR
        })
      })
  }

  if (redirectToLogin) {
    return <Redirect to="/login" />
  }
  const userRoles = UserRoles.fromSession()
  if (!userRoles.hasPrivilege(Privileges.CAN_MANAGE_USERS)) {
    return <Redirect to="/" />
  }
  if (!hasLoaded) {
    return <GenericLoadingSpinner />
  }
  if (responseStatus && responseStatus === 401) {
    // Session has run out, but user is online
    alert.show('Ihre Session ist abgelaufen, bitte melden Sie Sich neu an.', {
      type: types.ERROR,
      timeout: 10000
    })
    setRedirectToLogin(true)
  }
  if (responseStatus && responseStatus !== 200) {
    return (
      <article className="message is-warning">
        <div className="message-header">
          <p>Benutzerverwaltung nicht verfügbar</p>
        </div>
        <div className="message-body">
          <p className="mb2">
            Der Server konnte nicht erreicht werden; wahrscheinlich hat dieses Gerät gerade keine Internetverbindung.
            Alternativ gibt es Verbindungsprobleme, oder der Server läuft nicht.
          </p>
          <p>
            Das Verwalten von Benutzern erfordert eine Serververbindung. Überprüfen Sie Ihre Netzwerkverfügbarkeit oder
            versuchen Sie es später noch einmal.
          </p>
        </div>
      </article>
    )
  }
  return (
    <section>
      <h2 className="title is-size-2">Benutzerverwaltung</h2>
      <UserList
        users={persons}
        currentUser={invitingUser as User}
        currentCustomer={currentCustomer}
        isLoading={false}
        resendHandler={handleResend}
        deleteHandler={() => {}}
        toggleActiveHandler={handleToggleActive}
      />
      <hr />
      <UserInvitationForm invitingUser={invitingUser} postSuccessHandler={forceRefresh} />
    </section>
  )
}

export default UserManagement

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck,
  faExclamationTriangle,
  faQrcode,
  faEyeDropper,
  faPlusCircle,
  faCog,
  faSync,
  faFolderOpen,
  faHandPointRight,
  faSearch,
  faTimes,
  faArrowCircleLeft,
  faArrowCircleRight,
  faHome,
  faTrashAlt,
  faUsers,
  faChartBar,
  faInfoCircle,
  faShoppingCart,
  faFilePdf,
  faPaperPlane,
  faSignOutAlt,
  faFileAlt,
  faChartLine,
  faCube,
  faTint,
  faChevronDown,
  faChevronUp,
  faUserTimes,
  faUserCheck,
  faBell,
  faPlay,
  faPause,
  faBan,
  faStar,
  faAsterisk,
  faUnlockAlt,
  faLock,
  faShareSquare,
  faAddressCard,
  faTable,
  faSort,
  faSortUp,
  faSortDown,
  faCreditCard,
  faArchive,
  faReceipt,
  faHandshake
} from '@fortawesome/free-solid-svg-icons'

import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'

// Add icons to app library. Find icons here: https://fontawesome.com/icons?d=gallery&q=exclama&s=solid
library.add(
  faCheck,
  faExclamationTriangle,
  faQrcode,
  faEyeDropper,
  faPlusCircle,
  faCog,
  faSync,
  faFolderOpen,
  faHandPointRight,
  faSearch,
  faTimes,
  faArrowCircleLeft,
  faArrowCircleRight,
  faHome,
  faTrashAlt,
  faUsers,
  faChartBar,
  faInfoCircle,
  faShoppingCart,
  faFilePdf,
  faPaperPlane,
  faSignOutAlt,
  faFileAlt,
  faChartLine,
  faCube,
  faTint,
  faChevronDown,
  faChevronUp,
  faUserTimes,
  faUserCheck,
  faBell,
  faPlay,
  faPause,
  faBan,
  faStar,
  farStar,
  faAsterisk,
  faUnlockAlt,
  faLock,
  faShareSquare,
  faAddressCard,
  faTable,
  faSort,
  faSortUp,
  faSortDown,
  faCreditCard,
  faArchive,
  faReceipt,
  faHandshake
)

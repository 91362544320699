import React, { useContext, useState } from 'react'
import PouchDB from 'pouchdb'
import { addMilliseconds } from 'date-fns'
import * as Yup from 'yup'

import { CustomerFluid, OsFluid } from '../types/fluids'
import { metrics as defaultMetrics, MetricMeta, FluidFormData } from '../utils/default-metrics'
import AppContext from '../AppContext'
import { Validations } from '../utils/validations'
import { FormContainer, FormValues } from '@luxx/forms'
import MachineMeasurementPointMetricsForm from './forms/machine-measurement-point-metrics-form'
import { generateId, getCurrentMonthlyDb } from '../utils/general'
import { omit } from 'lodash'
import { UserRoles } from '../utils/user-roles'
import { Privileges } from '../types/users-roles-privileges'
import { FormikHelpers } from 'formik'
import { MetricMeasurement } from './machine-measurement'


export interface MeasurementPoint {
  _id?: string;
  _rev?: string;
  name: string;
  material: string;
  operation: string;
  fluid: FluidFormData;
  updatedAt: string;
  metrics: MetricMeta[];
  previous?: MeasurementPoint;
}

interface Props {
  isDisabled?: boolean;
  customerFluids: CustomerFluid[];
  osFluids: OsFluid[];
  measurementPoint: MeasurementPoint;
}

const MetricSchema = Yup.object({
  name: Validations.genericName,
  material: Yup.string(),
  operation: Yup.string(),
  fluid: Yup.object({
    id: Yup.string(),
    rfm: Yup.number()
  }).nullable(),
  metrics: Yup.array()
    .of(
      Yup.object({
        id: Yup.string().required('Pflichtfeld'),
        name: Validations.genericName,
        unit: Yup.string(),
        type: Yup.string().required('Pflichtfeld'),
        min: Yup.number()
          .test('min should be smaller than or equal to should', 'Minimum muss kleiner gleich Soll sein', function (value) {
            if (typeof this.parent.should !== 'undefined' && typeof value !== 'undefined') {
              return value <= this.parent.should
            } else {
              return true
            }
          })
          .test('min should be smaller than or equal to max', 'Minimum muss kleiner gleich Maximum sein', function (value) {
            if (typeof this.parent.max !== 'undefined' && typeof value !== 'undefined') {
              return value <= this.parent.max
            } else {
              return true
            }
          }),
        should: Yup.number()
          .test('should should be greater than or equal to min', 'Soll muss größer gleich Minimum sein', function (value) {
            if (typeof this.parent.min !== 'undefined' && typeof value !== 'undefined') {
              return value >= this.parent.min
            } else {
              return true
            }
          })
          .test('should should be smaller than or equal to than max', 'Soll muss kleiner gleich Maximum sein', function (value) {
            if (typeof this.parent.max !== 'undefined' && typeof value !== 'undefined') {
              return value <= this.parent.max
            } else {
              return true
            }
          }),
        max: Yup.number()
          .test('max should be greater than or equal to min', 'Maximum muss größer gleich Minimum sein', function (value) {
            if (typeof this.parent.min !== 'undefined' && typeof value !== 'undefined') {
              return value >= this.parent.min
            } else {
              return true
            }
          })
          .test('max should be greater than or equal to should', 'Maximum muss größer gleich Soll sein', function (value) {
            if (typeof this.parent.should !== 'undefined' && typeof value !== 'undefined') {
              return value >= this.parent.should
            } else {
              return true
            }
          }),
        options: Yup.array(),
        value: Yup.mixed(),
        enabled: Yup.boolean()
      })
    )
    .nullable(),
  previous: Yup.object()
})

export const initialValues = {
  name: 'main',
  updatedAt: '',
  material: '',
  operation: '',
  fluid: {
    id: '',
    rfm: 0
  },
  metrics: defaultMetrics
}

const MachineMeasurementPoint: React.FC<Props> = props => {
  const { currentCustomer } = useContext(AppContext)
  const { _id: customerId } = currentCustomer
  const [updateRev, setUpdateRev] = useState<string | undefined>()
  const { customerFluids, osFluids, measurementPoint: measurementPointFromProps, isDisabled } = props
  const [measurementPoint, setMeasurementPoint] = useState<MeasurementPoint | undefined>()
  const [previousMeasurementPoint, setPreviousMeasurementPoint] = useState<MeasurementPoint | undefined>()

  const userRoles = UserRoles.fromSession()
  const userCanManageMachines = userRoles.hasPrivilege(Privileges.CAN_MANAGE_MACHINES)

  // This is the first time we’re here
  if (!measurementPoint) {
    setMeasurementPoint(measurementPointFromProps)
    setPreviousMeasurementPoint(omit(measurementPointFromProps, ['_id', '_rev', 'previous']))
  }

  if (!measurementPoint || !customerId) return null

  const { name, material, operation, fluid, metrics } = measurementPoint

  const allFluids = [...customerFluids, ...osFluids]
  const getFluidById = (fluidId: string): CustomerFluid | OsFluid | undefined => {
    return allFluids.find((fluid: CustomerFluid): boolean => {
      return fluid._id === fluidId
    })
  }

  // Merge in new metrics this measurement point may not know yet
  const allMetrics = defaultMetrics.map(
    (defaultMetric: MetricMeta): MetricMeta => {
      const existingMetric = metrics.find(
        (metric: MetricMeta): Boolean => {
          return metric.id === defaultMetric.id
        }
      )
      if (existingMetric) {
        // Override names and units with the default, so
        // everyone gets the same labels
        const result = {
          ...existingMetric
        }
        if (defaultMetric.unit) {
          result.unit = defaultMetric.unit
        }
        if (defaultMetric.name) {
          result.name = defaultMetric.name
        }
        return result
      } else {
        return defaultMetric
      }
    }
  )
  const measurementPointIsNotEditable = !userCanManageMachines || isDisabled === true

  return (
    <FormContainer
      initialValues={{
        ...initialValues,
        fluid: {
          id: fluid.id || initialValues.fluid.id,
          // guard against rfm being `null`, which confuses react
          rfm: fluid.rfm || initialValues.fluid.rfm
        },
        name,
        material,
        operation,
        metrics: allMetrics
      }}
      validationSchema={MetricSchema}
      putDocument={() => { }}
      enableReinitialize={true}
      disabled={measurementPointIsNotEditable}
      onSubmit={async (values: FormValues, actions: FormikHelpers<FormValues>): Promise<void> => {
        actions.setSubmitting(true)
        const customerDb = new PouchDB(customerId)
        // Handle fluid input value (tranlate it to its ID)
        // Check if fluid.id is new and needs a new fluid doc to be created
        const existingFluid: CustomerFluid | OsFluid | undefined = getFluidById(values.fluid.id)
        let fluidId = values.fluid.id
        // If this fluid doesn’t exist yet, create it
        if (!existingFluid && values.fluid.id) {
          fluidId = generateId(8, 'fl')
          const fluidDoc: CustomerFluid = {
            _id: fluidId,
            name: values.fluid.id // the creatable select puts the name string in values.fluid.id
          }
          await customerDb.put(fluidDoc)
        }
        // If material, operation or fluid change, record that with an event doc in the current month db
        const now = new Date()
        const measurementPointId = (measurementPoint as MeasurementPoint)._id
        const machineId = measurementPointId.split(':')[0]
        const genericEvent = {
          measurementPointId,
          type: 'event'
        }
        const currentMonthDbName = getCurrentMonthlyDb(customerId)
        const currentMonthDb = new PouchDB(currentMonthDbName)
        if (values.material !== material) {
          const materialTimestamp = addMilliseconds(now, 1).toISOString()
          const materialEventDoc = {
            _id: `${machineId}:${materialTimestamp}`,
            ...genericEvent,
            eventType: 'materialChange',
            from: material,
            to: values.material
          }
          await currentMonthDb.put(materialEventDoc)
        }
        if (values.operation !== operation) {
          const operationTimestamp = addMilliseconds(now, 2).toISOString()
          const operationEventDoc = {
            _id: `${machineId}:${operationTimestamp}`,
            ...genericEvent,
            eventType: 'operationChange',
            from: operation,
            to: values.operation
          }
          await currentMonthDb.put(operationEventDoc)
        }
        if (fluidId !== fluid.id) {
          const fluidTimestamp = addMilliseconds(now, 3).toISOString()
          // Fluid event actually saves the name of the fluids, not their IDs, because
          // looking them up is expensive and they don’t change
          const from: CustomerFluid | OsFluid | undefined = getFluidById(fluid.id)
          const fluidEventDoc = {
            _id: `${machineId}:${fluidTimestamp}`,
            ...genericEvent,
            eventType: 'fluidChange',
            from: from ? from.name : undefined,
            to: existingFluid ? existingFluid.name : values.fluid.id
          }
          await currentMonthDb.put(fluidEventDoc)
        }
        // Save the measurement Point
        values = { ...values, fluid: { id: fluidId, rfm: values.fluid.rfm } }
        // `water-hardness` is a special hidden metric that cannot be enabled because
        // it is hidden from the UI. It enables itself automatically if `caHardness`
        // and `mgHardness` are enabled
        const caHardness = values.metrics.find((m: MetricMeasurement) => m.id === 'ca-hardness')
        const mgHardness = values.metrics.find((m: MetricMeasurement) => m.id === 'mg-hardness')
        const waterHardness = values.metrics.find((m: MetricMeasurement) => m.id === 'water-hardness')
        if (caHardness.enabled && mgHardness.enabled) {
          waterHardness.enabled = true
        }
        let updatedDoc: PouchDB.Core.ExistingDocument<any> = {
          ...measurementPoint,
          ...values,
          updatedAt: new Date().toISOString(),
          previous: previousMeasurementPoint
        }
        if (updateRev) {
          updatedDoc._rev = updateRev
        }
        const updateResponse = await customerDb.put(updatedDoc)
        // make sure this form can be submitted multiple times
        if (updateResponse && updateResponse.rev) {
          setUpdateRev(updateResponse.rev)
          // The form has been submitted and we want to update the state
          setMeasurementPoint(updatedDoc)
          setPreviousMeasurementPoint(values as MeasurementPoint)
        }
        actions.setSubmitting(false)
      }}
      submitLabel="Betriebsdaten speichern"
    >
      <hr />
      <MachineMeasurementPointMetricsForm
        customerFluids={customerFluids}
        osFluids={osFluids}
        isDisabled={measurementPointIsNotEditable}
      />
    </FormContainer>
  )
}

export default MachineMeasurementPoint

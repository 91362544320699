import React, { useContext, useState, useEffect, useCallback } from 'react'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import lodash, { compact, groupBy, /*flatten,*/ debounce } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AppContext from '../AppContext'
import { useAllData, AllDocsResult } from '../hooks/useAllData'
import useInitialSyncCheck from '../hooks/useInitialSyncCheck'
import { getMonthDBNamesForCustomer } from '../utils/network'
// import { getMetricMetadata } from '../utils/default-metrics'
// import { ResponsiveScatterPlot, Scale } from '@nivo/scatterplot'
import useFluids from '../hooks/useFluids'
import { CustomerFluid, OsFluid, FluidChangeEvent } from '../types/fluids'
// import { startOfWeek } from 'date-fns'
import { Machine, MachineDoc } from '../components/machine-inventory'
import MeasurementWaffle, { Measurement } from '../components/graphs/waffle-measurements'
import { GenericLoadingSpinner, PDFDownloadButton } from '../components/partials'
import doFilter from '../utils/filter'
import ActionWaffle from '../components/graphs/waffle-actions'
import { UserRoles } from '../utils/user-roles'
import { Privileges } from '../types/users-roles-privileges'
import { types, useAlert } from 'react-alert'
import GlobalMeasurementTable from '../components/global-measurement-table'
import GlobalMeasurementsPDF from '../components/global-measurements-pdf'
import { getMostRecentMeasurementsPerMetric } from '../utils/measurements'
import { dateFormat } from '../utils/general'
import { format } from 'date-fns'
import OpenActionsList from '../components/open-actions-list'
import JSZip from 'jszip'
import { pdf } from '@react-pdf/renderer'
import MachineCardPDF from '../components/machine-card-pdf'
import { Action } from '../components/machine-action'

interface MachineWithOptionalInlineFluid extends Machine {
  fluid?: CustomerFluid | OsFluid
}
interface ChartProps {
  customerId: string
  measurements: Measurement[]
  filterMachines: MachineWithOptionalInlineFluid[]
  actions: Action[]
  machines: Machine[]
}

/*
Used when we gather measurement docs by machine or metric etc., eg:
{
  "machine-1": [measurement-1-1, measurement-1-2, …],
  "machine-2": [measurement-2-1, measurement-2-2, …]
}
or
{
  "metric-1": [measurement-1-1, measurement-1-2, …],
  "metric-2": [measurement-2-1, measurement-2-2, …]
}
*/
interface MeasurementsByAnything {
  [key: string]: Measurement[]
}

interface ActionsByAnything {
  [key: string]: Action[]
}

const Charts = ({ customerId, measurements, filterMachines, actions, machines }: ChartProps) => {
  const [showOpenActionsList, setShowOpenActionsList] = useState(false)
  const measurementsGroupedByMetric: MeasurementsByAnything = groupBy(measurements, 'metricId')
  // lastMeasurementsPerMetric is an array of measurement arrays, each
  // array key matches a metric in ../utils/default-metrics, so
  // lastMeasurementsPerMetric[0] is an array of one pH measurement per machine,
  // lastMeasurementsPerMetric[1] is an array of one concentration measurement per machine
  // etc
  const lastMeasurementsPerMetric: Measurement[][] = lodash.map(
    measurementsGroupedByMetric,
    (measurements: Measurement[]) =>
      // We start with an array of measurement arrays, one per metric
      lodash(measurements)
        // Within each metric, group measurements by machineId
        // This results in an array of objects that each contain many `MeasurementsByAnything`:
        // [{ma_1: [m,m,m], ma_2: [m,m,m]}, ma_2: [m,m,m], ma_12: [m,m,m]}, {…}, …]
        .groupBy((measurement: Measurement) => measurement._id.substr(0, 11))
        // Sort each machine’s measurements by date and pick the most recent one
        // TODO: figure out this `any`
        .map((machine: any): Measurement => lodash.last(lodash.sortBy(machine, 'measuredAt')))
        .value()
  )
  const lastMeasurementsByMachine = filterMachines.map(
    (machine: Machine): MeasurementsByAnything => {
      return {
        [machine.id]: compact(
          lastMeasurementsPerMetric.map((measurementsPerMetric: Measurement[]) => {
            return measurementsPerMetric.find(
              (measurement: Measurement) => measurement._id.substr(0, 11) === machine.id
            )
          })
        )
      }
    }
  )

  const actionsByMachine = filterMachines.map(
    (machine: MachineWithOptionalInlineFluid): ActionsByAnything => {
      return {
        [machine.id]: compact(actions.filter((action: Action) => action._id.substr(0, 11) === machine.id))
      }
    }
  )

  // const generateScatterPlotData = (data: any[], metricId: string): any => {
  //   const meta = getMetricMetadata(metricId)
  //   const flatData = flatten(data).filter(datum => {
  //     return filterMachines.find((machine: any) => {
  //       return machine.id === datum._id.substr(0, 11)
  //     })
  //   })
  //   return [
  //     {
  //       id: meta.name,
  //       data: compact(
  //         flatData.map(datum => {
  //           if (!datum || !datum.value) return undefined
  //           return {
  //             x: startOfWeek(new Date(datum.measuredAt)).toLocaleDateString(),
  //             y: datum.value,
  //             measuredAt: datum.measuredAt,
  //             machineId: datum._id.substring(0, 11)
  //           }
  //         })
  //       )
  //     }
  //   ]
  // }

  // const pHScatterPlotData = generateScatterPlotData(measurementsGroupedByMetric.ph, 'ph')

  const getTitle = (amount: number) => {
    return amount === 1
      ? 'Daten der letzten drei Monate, für eine Maschine'
      : `Daten der letzten drei Monate, für ${amount} Maschinen`
  }

  // const renderScatterPlotToolTip = ({ node }: any): React.ReactNode => {
  //   const machineData = filterMachines
  //     ? filterMachines.find((machine: Machine) => machine.id === node.data.machineId)
  //     : []
  //   if (!machineData) return null
  //   const {
  //     doc: { name, location, inventoryNumber },
  //     fluid
  //   } = machineData as MachineWithOptionalInlineFluid
  //   return (
  //     <div
  //       style={{
  //         background: 'white',
  //         padding: '9px 12px',
  //         border: '1px solid #ccc'
  //       }}
  //     >
  //       <ul>
  //         <li>
  //           <strong>{name}</strong>
  //         </li>
  //         <li>
  //           <span>ID: {node.data.machineId}</span>
  //         </li>
  //         <li>
  //           <span>Inv.Nr.: {inventoryNumber}</span>
  //         </li>
  //         {location && (
  //           <li>
  //             <span>Standort: {location}</span>
  //           </li>
  //         )}
  //         {fluid && (
  //           <li>
  //             <span>Fluid: {fluid.name}</span>
  //           </li>
  //         )}
  //         <li>Vom: {new Date(node.data.measuredAt).toLocaleDateString()}</li>
  //         <li>
  //           Wert: <strong>{node.data.y}</strong>
  //         </li>
  //       </ul>
  //     </div>
  //   )
  // }

  return (
    <>
      <div className="row mb2">
        <h2 className="title is-size-2">{getTitle(filterMachines.length)}</h2>
      </div>
      <div className="row mb2">
        <h3 className="title is-size-3">Messwerte</h3>
        <h4 className="title is-size-4">Aktueller Gesamtzustand</h4>
        <MeasurementWaffle
          lastMeasurementsByMachine={lastMeasurementsByMachine}
          machines={filterMachines as Machine[]}
        />
      </div>
      <div className="row mb2">
        <h4 className="title is-size-4">Letzte pH-Messungen</h4>
        <MeasurementWaffle
          lastMeasurementsByMachine={lastMeasurementsByMachine}
          machines={filterMachines as Machine[]}
          metricId="ph"
        />
      </div>
      <div className="row mb2">
        <h4 className="title is-size-4">Letzte Konzentrations-Messungen</h4>
        <MeasurementWaffle
          lastMeasurementsByMachine={lastMeasurementsByMachine}
          machines={filterMachines as Machine[]}
          metricId="konzentration"
        />
      </div>
      <div className="row mb2">
        <h4 className="title is-size-4">Letzte Nitrit-Messungen</h4>
        <MeasurementWaffle
          lastMeasurementsByMachine={lastMeasurementsByMachine}
          machines={filterMachines as Machine[]}
          metricId="nitrit"
        />
      </div>
      <div className="row mb2">
        <h3 className="title is-size-3">Arbeitsaufträge und Aktionen</h3>
        <ActionWaffle actionsByMachine={actionsByMachine} machines={machines as Machine[]} />
      </div>
      <hr />
      <div className="row mb2">
        <div className="title is-size-3">
          <span>Offene Arbeitsaufträge</span>
          <button className="button is-light ml-2" onClick={() => setShowOpenActionsList(!showOpenActionsList)}>
            <span className="icon">
              <FontAwesomeIcon icon={showOpenActionsList ? 'chevron-up' : 'chevron-down'} size="sm" />
            </span>
            <span>{showOpenActionsList ? 'Ausblenden' : 'Einblenden'}</span>
          </button>
        </div>
        {showOpenActionsList && (
          <OpenActionsList
            customerId={customerId}
            actionsByMachine={actionsByMachine}
            machines={machines as Machine[]}
          />
        )}
      </div>
      <hr />
      {/* <div className="row mb2">
        <h4 className="title is-size-4">pH-Messungen der letzten drei Monate</h4>
        <div className="chart line">
          <ResponsiveScatterPlot
            data={pHScatterPlotData}
            margin={{ top: 40, right: 80, bottom: 90, left: 90 }}
            xScale={
              ({
                type: 'time',
                format: '%d.%m.%Y',
                precision: 'day'
              } as unknown) as Scale
            }
            xFormat="time:%d.%m.%Y"
            yScale={({ type: 'linear', min: 'auto', max: 'auto' } as unknown) as Scale}
            // nodeSize={{key: 'z', values: [1, 4], sizes: [9, 32]} as DynamicSizeSpec}
            //blendMode="multiply"
            tooltip={renderScatterPlotToolTip}
            animate={false}
            colors={['green']}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              format: '%d.%m',
              tickValues: `every week`,
              tickRotation: 30,
              tickSize: 5,
              tickPadding: 10,
              legend: 'Woche vom…',
              legendPosition: 'middle',
              legendOffset: 60
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'pH-Wert',
              legendPosition: 'middle',
              legendOffset: -60
            }}
          />
        </div>
      </div> */}
    </>
  )
}

const MemoCharts = React.memo(Charts)

interface MatchParams {
  customerId?: string
}

export interface MachineWithMostRecentMeasurements {
  machine: MachineDoc
  // The other keys will never be MachineDocs, but hey, Typescript
  [key: string]: Measurement | MachineDoc
}

const Analyser: React.FC<RouteComponentProps<MatchParams>> = props => {
  const alert = useAlert()
  const { customerId } = props.match.params
  // Load the current year’s worth of data, or the past three months, whichever is more
  const monthsToLoad = new Date().getMonth() + 1 > 3 ? new Date().getMonth() + 1 : 3
  const monthDBs = getMonthDBNamesForCustomer(customerId, monthsToLoad)
  const { databaseManager, inventoryUIState, setInventoryUIState } = useContext(AppContext)
  const { isSynced } = useInitialSyncCheck(databaseManager)
  const { machines, measurements, measurementPoints, actions, hasLoaded, events } = useAllData(customerId, monthDBs)
  const { allFluids, hasLoadedFluids } = useFluids(customerId)
  const [filter, setFilter] = useState<string>(inventoryUIState.filter || '')
  const [filterMachines, setFilterMachines] = useState<AllDocsResult[]>([])
  const [mostRecentMeasurementsByMachine, setMostRecentMeasurementsByMachine] = useState<
    MachineWithMostRecentMeasurements[]
  >([])

  const [readyToRender, setReadyToRender] = useState<boolean>(false)
  const [generatingArchive, setGeneratingArchive] = useState<boolean>(false)
  const [archiveGenerationProgress, setArchiveGenerationProgress] = useState<number>(0)
  const [unmounted, setUnmounted] = useState<boolean>(false)
  const [showLastMeasurementsList, setShowLastMeasurementsList] = useState(false)
  const [fluidChangeEvents, setFluidChangeEvents] = useState<FluidChangeEvent[]>(null)

  // Debounce so we don’t filter on every keystroke. This only makes the app _seem_ faster,
  // but that’s crucial, otherwise it feels super janky
  const delayedFilter = useCallback(debounce(performFilter, 100), [readyToRender])

  useEffect(() => {
    if (isSynced && hasLoaded && hasLoadedFluids) {
      setReadyToRender(true)
    }
  }, [isSynced, hasLoaded, hasLoadedFluids])

  useEffect(() => {
    if (readyToRender) {
      delayedFilter(filter, machines as AllDocsResult[], allFluids, measurementPoints)
    }
    return () => {
      setUnmounted(true)
    }
    // This is intentional, readyToRender being true means all the deps passed to
    // `delayedFilter` exist
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, readyToRender])

  // Generate the data for the cross-org machine protocol, with the most recent
  // measurements for each machine
  useEffect(() => {
    if (machines) {
      const metricsToShow = ['ph', 'konzentration', 'nitrit', 'anmerkungen']
      const allMostRecentMeasurementsByMachine = machines.map(machine => {
        const machineMeasurements = measurements.filter((measurement: Measurement) => {
          const machineId = measurement._id.split(':')[0]
          const metric = measurement._id.split(':')[2]
          return machineId === machine.id && metricsToShow.includes(metric)
        })
        const mostRecentMachineMeasurements = getMostRecentMeasurementsPerMetric(machineMeasurements)
        return {
          machine: machine.doc,
          ...mostRecentMachineMeasurements
        }
      })
      setMostRecentMeasurementsByMachine(allMostRecentMeasurementsByMachine)
    }
    if (events) {
      const fluidChangeEvents = events.filter(event => event.eventType === 'fluidChange') as FluidChangeEvent[]
      setFluidChangeEvents(fluidChangeEvents)
    }
  }, [machines, measurements, events])

  const handleGenerateBulkMachineCardArchive = useCallback(async () => {
    setGeneratingArchive(true)
    const archive = new JSZip()
    const now = new Date()
    const currentYear = now.getFullYear()
    const totalMachineAmount = machines.length

    let index = 1
    for (const machine of machines) {
      const machineDoc: MachineDoc = machine.doc
      const measurementPoint = measurementPoints.find(mp => {
        return mp.id.substring(0, 11) === machine.id
      })
      let fluidInfo
      if (measurementPoint) {
        fluidInfo = allFluids.find(f => {
          return f._id === measurementPoint.doc.fluid.id
        })
      }
      const metricsToShow = ['ph', 'konzentration', 'nitrit']
      const machineMeasurements = measurements.filter((measurement: Measurement) => {
        const machineId = measurement._id.split(':')[0]
        const metric = measurement._id.split(':')[2]
        const isInCurrentYear = measurement.measuredAt.startsWith(currentYear.toString())
        return machineId === machine.id && metricsToShow.includes(metric) && isInCurrentYear
      })
      const machineFluidEvent = fluidChangeEvents.filter(event => machineDoc._id === event._id.split(':')[0])
      const report = (
        <MachineCardPDF
          machine={machineDoc}
          measurementPoint={measurementPoint ? measurementPoint.doc : undefined}
          measurements={[...machineMeasurements]}
          fluidInfo={fluidInfo}
          year={currentYear}
          fluidChangeEvents={machineFluidEvent}
        />
      )

      const blob = await pdf(report).toBlob()
      archive.file(`Maschinenkarte-${machine.id}-${currentYear}-${now.toISOString()}.pdf`, blob)
      setArchiveGenerationProgress(Math.round((index / totalMachineAmount) * 100))
      index++
    }
    archive.generateAsync({ type: 'blob' }).then(blob => {
      saveAs(blob, `Maschinenkarten-${currentYear}-${now.toISOString()}.zip`)
    })
    setGeneratingArchive(false)
  }, [allFluids, machines, measurementPoints, measurements])

  const userRoles = UserRoles.fromSession()
  const userCanSwitchCustomers = userRoles.hasPrivilege(Privileges.CAN_SWITCH_CUSTOMERS)

  if (!userRoles.hasPrivilege(Privileges.CAN_ACCESS_ANALYTICS)) {
    alert.show('Sie haben keinen Zugang zu diesem Bereich', {
      type: types.ERROR
    })
    return <Redirect to="/" />
  }

  function performFilter(
    filter: string,
    machines: AllDocsResult[],
    allFluids: CustomerFluid[],
    measurementPoints: AllDocsResult[]
  ): void {
    if (!machines) return
    let filteredMachines: AllDocsResult[] = machines ? machines : []
    if (allFluids.length > 0) {
      filteredMachines = compact(
        filteredMachines.map(machine => {
          const correspondingMP = measurementPoints.find(mp => {
            return mp.id.substring(0, 11) === machine.id
          })
          if (!correspondingMP) return null
          const fluid = allFluids.find(f => {
            return f._id === correspondingMP.doc.fluid.id
          })
          if (!fluid) return machine
          return { fluid, ...machine }
        })
      )
    }
    filteredMachines = doFilter(filteredMachines, filter, [
      'doc._id',
      'doc.name',
      'doc.inventoryNumber',
      'doc.tags',
      'doc.location',
      'fluid.name'
    ])

    if (!unmounted) {
      setFilterMachines(filteredMachines)

      setInventoryUIState &&
        setInventoryUIState({
          filter,
          sortByWhat: inventoryUIState.sortByWhat,
          currentMachineIds: filteredMachines.map(m => m.id)
        })
    }
  }

  if (readyToRender) {
    const now = new Date()
    const currentYear = now.getFullYear()
    return (
      <>
        <div className="field">
          <label className="label">Maschinen filtern</label>
          <div className="control has-icons-left">
            <input
              className="input"
              type="text"
              placeholder="Nach Name/Standort/Fluid/Tag filtern"
              onChange={e => setFilter(e.currentTarget.value)}
              value={filter}
            />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon="search" />
            </span>
          </div>
        </div>
        {filterMachines.length === 0 ? (
          <h3 className="title is-size-3">Keine Maschinen gefunden</h3>
        ) : (
          <MemoCharts
            customerId={customerId}
            // We try to load all measurements/actions for the current year,
            // but at least for the last three months.
            // We only need the full year’s data for the archive download, so for
            // the rest of the interface, we only use the last three months worth of data.
            measurements={measurements.filter((measurement: Measurement) => {
              const monthLimit = new Date().getMonth() - 2
              const measurementMonth = parseInt(measurement.measuredAt.substr(5, 2), 10)
              return measurementMonth >= monthLimit
            })}
            filterMachines={filterMachines as MachineWithOptionalInlineFluid[]}
            actions={actions.filter((action: Action) => {
              const monthLimit = new Date().getMonth() - 2
              const actionDate = action._id.substr(12)
              const actionMonth = parseInt(actionDate.substr(5, 2), 10)
              return actionMonth >= monthLimit
            })}
            machines={machines as Machine[]}
          />
        )}
        <div className="title is-size-3">
          <span>Protokoll der letzten Messungen für alle Maschinen</span>
          <button
            className="button is-light ml-2"
            onClick={() => setShowLastMeasurementsList(!showLastMeasurementsList)}
          >
            <span className="icon">
              <FontAwesomeIcon icon={showLastMeasurementsList ? 'chevron-up' : 'chevron-down'} size="sm" />
            </span>
            <span>{showLastMeasurementsList ? 'Ausblenden' : 'Einblenden'}</span>
          </button>
        </div>
        {showLastMeasurementsList && (
          <>
            <div className="buttons">
              <PDFDownloadButton
                fileName={`Protokoll-Alle-Maschinen-${format(new Date(), dateFormat)}.pdf`}
                buttonLabel="Protokoll als PDF herunterladen"
                generatePDF={() => <GlobalMeasurementsPDF data={mostRecentMeasurementsByMachine} />}
              />
              {userCanSwitchCustomers && (
                <>
                  {generatingArchive ? (
                    <span className="button is-static">
                      <span className="icon">
                        <FontAwesomeIcon icon="sync" spin />
                      </span>
                      <span>Fortschritt: {archiveGenerationProgress}%</span>
                    </span>
                  ) : (
                    <button className="button is-light" onClick={handleGenerateBulkMachineCardArchive}>
                      <span className="icon">
                        <FontAwesomeIcon icon="archive" />
                      </span>
                      <span>Alle Maschinenkarten für {currentYear} herunterladen</span>
                    </button>
                  )}
                </>
              )}
            </div>
            <GlobalMeasurementTable data={mostRecentMeasurementsByMachine} />
          </>
        )}
      </>
    )
  } else {
    return <GenericLoadingSpinner message="Lade Analysedaten" />
  }
}

export default Analyser

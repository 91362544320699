export interface FluidFormData {
  id: string;
  rfm: number;
}
export interface MetricMeta {
  id: string;
  name: string;
  type: string;
  unit?: string;
  min?: number;
  should?: number;
  max?: number;
  floor?: number;
  ceil?: number;
  enabled?: boolean;
  options?: string[][]; // an array of an array of strings
  value?: number | string; // usually numbers, but is string in `text` types,
  // also, number inputs can only be initialised as empty with an empty string (Formik?)
  hidden?: boolean;
  fluid?: FluidFormData;
  refraktometerAblesewert?: number;
}

// If you add a new metric, set `enabled: false`, so users must enable it before using it,
// which will allow them to submit the measurment point form
export const metrics: MetricMeta[] = [
  {
    id: 'ph',
    name: 'pH-Wert',
    type: 'minmax',
    min: 0,
    should: 0,
    max: 0,
    floor: 0,
    ceil: 14,
    enabled: true
  },
  {
    id: 'konzentration',
    name: 'Konzentration',
    type: 'minmax',
    unit: '%',
    min: 0,
    should: 0,
    max: 0,
    ceil: 30,
    floor: 0,
    enabled: true
  },
  {
    id: 'nitrit',
    name: 'Nitrit',
    unit: 'mg/l',
    type: 'minmax',
    min: 0,
    should: 0,
    max: 0,
    enabled: true
  },
  {
    id: 'nitrat',
    name: 'Nitrat',
    unit: 'mg/l',
    type: 'minmax',
    min: 0,
    should: 0,
    max: 0,
    enabled: false
  },
  {
    id: 'chlorid',
    name: 'Chlorid',
    unit: 'mg/l',
    type: 'minmax',
    min: 0,
    should: 0,
    max: 0,
    enabled: false
  },
  {
    id: 'wasserhaerte',
    name: 'Wasserhärte (gemessen)',
    type: 'minmax',
    min: 0,
    should: 0,
    max: 0,
    enabled: false
  },
  {
    id: 'leitwert',
    name: 'Leitwert',
    unit: 'mS',
    type: 'minmax',
    min: 0,
    should: 0,
    max: 0,
    enabled: false
  },
  {
    id: 'fuellstand',
    name: 'Füllstand',
    unit: 'l',
    type: 'minmax',
    min: 0,
    should: 0,
    max: 0,
    enabled: false
  },
  {
    id: 'geruch',
    name: 'Geruch',
    type: 'select',
    options: [
      ['ok', 'In Ordnung'],
      ['not-ok', 'Nicht in Ordnung']
    ],
    value: '',
    enabled: false
  },
  {
    id: 'optik',
    name: 'Optik',
    type: 'text',
    value: '',
    enabled: true
  },
  {
    id: 'anmerkungen',
    name: 'Anmerkungen',
    type: 'text',
    value: '',
    enabled: true
  },
  {
    id: 'fremdstoffe',
    name: 'Fremdstoffe',
    type: 'text',
    value: '',
    enabled: false
  },
  {
    id: 'bacteria',
    name: 'Bakterien',
    type: 'select',
    options: [
      ['ohne', 'Ohne Befund'],
      ['10e1', '10 hoch 1'],
      ['10e2', '10 hoch 2'],
      ['10e3', '10 hoch 3'],
      ['10e4', '10 hoch 4'],
      ['10e5', '10 hoch 5'],
      ['10e6', '10 hoch 6'],
      ['10e7', '10 hoch 7']
    ],
    value: '',
    enabled: false
  },
  {
    id: 'fungus',
    name: 'Pilze',
    type: 'select',
    options: [
      ['ohne', 'ohne Befund'],
      ['weak', 'Schwach'],
      ['moderate', 'Mäßig'],
      ['medium', 'Mittel'],
      ['strong', 'Stark']
    ],
    value: '',
    enabled: false
  },
  {
    id: 'ca-hardness',
    name: 'Ca-Härte',
    unit: 'mg/L',
    type: 'minmax',
    min: 0,
    should: 0,
    max: 0,
    enabled: false
  },
  {
    id: 'mg-hardness',
    name: 'Mg-Härte',
    unit: 'mg/L',
    type: 'minmax',
    min: 0,
    should: 0,
    max: 0,
    enabled: false
  },
  {
    id: 'carbonate-hardness',
    name: 'Carbonat-Härte',
    unit: 'mg/L',
    type: 'minmax',
    min: 0,
    should: 0,
    max: 0,
    enabled: false
  },
  {
    id: 'water-hardness',
    name: 'Wasserhärte (errechnet)',
    unit: '°dH',
    type: 'minmax',
    min: 0,
    should: 0,
    max: 0,
    enabled: false,
    hidden: true
  },
  {
    id: 'alkalinity',
    name: 'Alkalinität (pH7)',
    unit: 'ml',
    type: 'minmax',
    min: 0,
    should: 0,
    max: 0,
    enabled: false
  },
  {
    id: 'total-oil',
    name: 'Gesamtöl',
    unit: '%',
    type: 'minmax',
    min: 0,
    should: 0,
    max: 0,
    enabled: false
  },
  {
    id: 'mineral-oil',
    name: 'Mineralöl',
    unit: '%',
    type: 'minmax',
    min: 0,
    should: 0,
    max: 0,
    enabled: false
  },
  {
    id: 'hycut',
    name: 'Hycut',
    unit: '%',
    type: 'minmax',
    min: 0,
    should: 0,
    max: 0,
    enabled: false
  },
  {
    id: 'additiv',
    name: 'Additiv',
    unit: '%',
    type: 'minmax',
    min: 0,
    should: 0,
    max: 0,
    enabled: false
  },
  {
    id: 'refraktometer',
    name: 'Refraktometer',
    unit: '°Bx',
    type: 'minmax',
    min: 0,
    should: 0,
    max: 0,
    enabled: false
  }
]

export const getMetricMetadata = (metricId: string): MetricMeta => {
  return metrics.find((metric: MetricMeta) => {
    return metric.id === metricId
  })
}

interface UserInterfaceWord {
  id: string;
  label: string;
}

export const materials = [
  {
    id: 'alu',
    label: 'Aluminium'
  },
  {
    id: 'messing',
    label: 'Messing'
  },
  {
    id: 'kupfer',
    label: 'Kupfer'
  },
  {
    id: 'stahl',
    label: 'Stahl'
  },
  {
    id: 'edelstahl',
    label: 'Edelstahl'
  },
  {
    id: 'hochstahl',
    label: 'Hochstahl'
  },
  {
    id: 'werkstahl',
    label: 'Werkstahl'
  },
  {
    id: 'mag',
    label: 'Magnesium'
  },
  {
    id: 'guss',
    label: 'Guß'
  },
  {
    id: 'titan',
    label: 'Titan'
  },
  {
    id: 'kunst',
    label: 'Kunststoffe'
  },
  {
    id: 'hart',
    label: 'Hartmetall'
  }
]

export const operations = [
  {
    id: 'drehen',
    label: 'Drehen'
  },
  {
    id: 'bohren',
    label: 'Bohren'
  },
  {
    id: 'fraesen',
    label: 'Fräsen'
  },
  {
    id: 'schleifen',
    label: 'Schleifen'
  },
  {
    id: 'tlbohren',
    label: 'Tieflochbohren'
  },
  {
    id: 'raeumen',
    label: 'Räumen'
  },
  {
    id: 'saegen',
    label: 'Sägen'
  }
]

const UserInterfaceWords: UserInterfaceWord[] = [...materials, ...operations]

export const getLabelForId = (id: string): string | undefined => {
  const result = UserInterfaceWords.find((words: UserInterfaceWord): boolean => {
    return words.id === id
  })
  return result ? result.label : undefined
}

export default metrics

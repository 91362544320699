import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { pdf } from '@react-pdf/renderer'
import { groupBy, compact, flatten } from 'lodash'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import usePersons from '../hooks/usePersons'
import { dateAndTimeFormat } from '../utils/general'
import { Action, actionTypeLabels, personIdToName } from './machine-action'
import { Machine, MachineDoc } from './machine-inventory'
import OneActionPDF from './one-action-pdf'
import { Link } from 'react-router-dom'
import { GenericLoadingSpinner } from './partials'
import doFilter from '../utils/filter'
import { Person } from '../types/users-roles-privileges'

interface ActionsForMachine {
  // ma_12345678: [Measurement, Measurement, …]
  [key: string]: Action[];
}

interface Props {
  customerId: string;
  actionsByMachine: ActionsForMachine[];
  machines: Machine[];
}

interface ActionBundle {
  machine: MachineDoc;
  action: DecoratedAction;
}

interface DecoratedAction extends Action {
  assigneeName?: string;
  actionTypeLabel?: string;
}

const OpenActionsList: React.FC<Props> = props => {
  const { customerId, actionsByMachine, machines } = props
  const { persons, hasLoaded: hasLoadedPersons } = usePersons(customerId)
  const [query, setQuery] = useState('')
  const [listData, setListData] = useState<ActionBundle[]>([])
  const [filteredListData, setFilteredListData] = useState<ActionBundle[]>([])

  // Format and decorate the data
  // Each action is decorated with its machine, the plaintext name of the assignee,
  // and the plaintext label of the action’s type, so we can filter by these
  useEffect(() => {
    if (customerId && actionsByMachine && machines) {
      const listData: ActionBundle[] = flatten(compact(actionsByMachine.map((machineDataBundle): ActionBundle[] => {
        const machineId = Object.keys(machineDataBundle)[0]
        const machineData: Machine | undefined = machines ? machines.find(machine => machine.id === machineId) : undefined
        if (!machineData) return null
        let { open } = groupBy((machineDataBundle[machineId] as unknown) as Action[], (action: Action) =>
          action.done ? 'done' : 'open'
        )
        open = flatten(open)
        if (open.length === 0) return null
        return open.map((openAction: Action): ActionBundle => {
          let assigneeName
          const user = persons.find((person: Person): boolean => {
            return person.userId === openAction.responsible
          })
          if (user) {
            assigneeName = [user.firstName, user.lastName].join(' ')
          }
          return {
            machine: machineData.doc,
            action: {
              ...openAction,
              assigneeName,
              actionTypeLabel: actionTypeLabels[openAction.actionType]
            }
          }
        })
      })))
      setListData(listData)
    }
  }, [actionsByMachine, customerId, machines, persons])

  // Filter the data
  useEffect(() => {
    if (query && query.length > 0) {
      setFilteredListData(doFilter(listData, query, [
        'machine.inventoryNumber',
        'machine.name',
        'machine.tags',
        'machine.location',
        'action.responsible',
        'action.assigneeName',
        'action.instruction',
        'action.actionTypeLabel'
      ]))
    } else {
      setFilteredListData(listData)
    }
  }, [listData, query])

  const downloadPDF = async (action: Action, machine: MachineDoc) => {
    if (!action) return
    const document = generateActionPDF(action, machine)
    if (!document) return
    const blob = await pdf(document).toBlob()
    saveAs(blob, `Arbeitsauftrag-${action._id}.pdf`)
  }

  const generateActionPDF = (action: Action, machine: MachineDoc) => {
    if (action && machine) {
      return (
        <OneActionPDF
          machine={machine}
          actionTimestamp={action._id.substr(12)}
          responsible={personIdToName(persons, action.responsible as string)}
          actionType={actionTypeLabels[action.actionType as string]}
          instruction={action.instruction as string}
          done={action.done}
        />
      )
    }
  }

  if (!hasLoadedPersons) {
    return <GenericLoadingSpinner />
  }

  return <>
    <div className="field">
      <label className="label">Maschinen filtern</label>
      <div className="control has-icons-left">
        <input
          className="input"
          type="text"
          placeholder="Name/Inventarnummer/Standort/Tag"
          onChange={e => setQuery(e.target.value.toLowerCase())}
          value={query}
        />
        <span className="icon is-small is-left">
          <FontAwesomeIcon icon="search" />
        </span>
      </div>
    </div>
    {filteredListData && filteredListData.length
    ? <div className="table-container">
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>Maschine</th>
            <th>Zeit der Aktion</th>
            <th>Aktionstyp</th>
            <th>Verantwortliche Person</th>
            <th>Instruktion</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
        {filteredListData.map((ActionBundle: ActionBundle) => {
          const { machine, action } = ActionBundle
          const clickTargetPrefix = `/maschine/${machine._id.substr(3)}/`
          const date = action._id
            .split(':')
            .slice(1)
            .join(':')
          const url = `${clickTargetPrefix || '/'}aktion/${action._id.substr(12)}`
          return (
            <tr key={`actions-list-action-${action._id}`} data-href={url} /*className={index === 0 ? 'has-top-border' : ''}*/>
              <td><Link to={url}>{machine.name}</Link></td>
              <td className="is-family-monospace">
                <Link to={url}>{format(new Date(date), dateAndTimeFormat)}</Link>
              </td>
              <td>
                <Link to={url}>{action.actionTypeLabel}</Link>
              </td>
              <td>
                <Link to={url}>{action.assigneeName || action.responsible}</Link>
              </td>
              <td>
                <Link to={url}>{action.instruction}</Link>
              </td>
              <td>
                <button className="button is-light" onClick={() => downloadPDF(action, machine)}>
                  <span className="icon">
                    <FontAwesomeIcon icon="file-pdf" size="sm" />
                  </span>
                  <span>Als PDF herunterladen</span>
                </button>
              </td>
            </tr>
            )
          })}
        </tbody>
      </table>
    </div>
    : <span>Keine Arbeitsaufträge gefunden</span>
    }
  </>
}

export default OpenActionsList

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik'

import { Usable } from './machine-inventory'
import FormItem from '@luxx/forms'

interface Props {
  isDisabled?: boolean;
}

enum UsableType {
  HYDRAULIKOEL = 'hydraulikoel',
  BETTBAHNSCHMIERUNG = 'bettbahnschmierung',
  GETRIEBESCHMIERUNG = 'getriebeschmierung',
  FETTSCHMIERUNG = 'fettschmierung',
  FILTER = 'filter'
}

// Defines that the key in `usableTypeLabel` must be in the enum `UsableType`,
// see https://stackoverflow.com/a/59213781
export type UsableTypeLabel = Record<UsableType, string>

export const usableTypeLabels: UsableTypeLabel = {
  hydraulikoel: 'Hydrauliköl',
  bettbahnschmierung: 'Bettbahnschmierung',
  getriebeschmierung: 'Getriebeschmierung',
  fettschmierung: 'Fettschmierung',
  filter: 'Filter'
}

const emptyUsable = {
  usableType: '',
  name: '',
  amount: '',
  interval: ''
}

const getTypeOptions = () => {
  const results = []
  for (const usableType in UsableType) {
    // sometimes I really hate Typescript… https://stackoverflow.com/a/58509049
    const type: UsableType = UsableType[usableType as keyof typeof UsableType]
    results.push(
      <option value={type} id={type}>
        {usableTypeLabels[type]}
      </option>
    )
  }
  // You can’t .map() over Enums, so we need to for loop, and then use React to turn the
  // array of elements into actual react nodes
  return React.Children.toArray(results)
}

export const MachineUsables: React.FC<Props> = props => {
  const { isDisabled } = props
  const { values }: any = useFormikContext()
  return (
    <>
      <hr />
      <h3 className="title is-3">Verbrauchsmittel</h3>
      <FieldArray
        name="usables"
        render={(arrayHelpers: FieldArrayRenderProps): React.ReactNode => {
          return (
            <div className="usablesWrapper mb2">
              {values.usables.length === 0 && (
                <div className="notification">
                  <p>Keine Verbrauchsmittel angelegt</p>
                </div>
              )}
              {values.usables.map(
                (usable: Usable, index: number): React.ReactNode => {
                  return (
                    <div className="well is-padded" key={`add-usable-row-${index}`}>
                      <div className="columns">
                        <div className="column is-one-fifth">
                          <FormItem component="select" name={`usables.${index}.usableType`} label="Typ*">
                            <option value="">Bitte wählen:</option>
                            {getTypeOptions()}
                          </FormItem>
                        </div>
                        <div className="column is-one-fifth">
                          <FormItem label="Name*" name={`usables.${index}.name`} type="input" placeholder="Name" />
                        </div>
                        <div className="column is-one-fifth">
                          <FormItem label="Menge" name={`usables.${index}.amount`} type="input" placeholder="Menge" />
                        </div>
                        <div className="column is-one-fifth">
                          <FormItem
                            label="Intervall"
                            name={`usables.${index}.interval`}
                            type="input"
                            placeholder="Intervall"
                          />
                        </div>
                        <div className="column is-one-fifth deleteButton">
                          <button
                            id="deleteUsable"
                            className="button is-danger"
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                            disabled={isDisabled}
                          >
                            <span className="icon">
                              <FontAwesomeIcon icon="times" size="sm" />
                            </span>
                            <span>Entfernen</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                }
              )}
              <button
                id="addUsable"
                className="button is-info"
                type="button"
                onClick={() => arrayHelpers.push(emptyUsable)}
                disabled={isDisabled}
              >
                <span className="icon">
                  <FontAwesomeIcon icon="plus-circle" size="sm" />
                </span>
                <span>{values.usables.length > 0 ? 'Weiteres' : ''} Verbrauchsmittel hinzufügen</span>
              </button>
            </div>
          )
        }}
      />
    </>
  )
}

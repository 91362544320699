import { Component } from 'react'
import { RouteComponentProps, Redirect } from 'react-router'
import QrReader from 'react-qr-reader'
import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/*

QR-Code scanning with https://www.npmjs.com/package/react-qr-reader

*/

interface State {
  result?: string;
}

class ScanQR extends Component<RouteComponentProps, State> {
  public constructor(props: RouteComponentProps) {
    super(props)
    this.state = {
      result: ''
    }
    this.handleScan = this.handleScan.bind(this)
  }

  private handleScan(data: any): void {
    if (data) {
      this.setState({
        result: data
      })
    }
  }
  private handleError(err: any): void {
    console.error(err)
  }
  public render(): React.ReactNode {
    const { result } = this.state
    const machineIdRegex = /^(ma_)[A-Z1-9]{8}/
    let isValidMachineId = false
    if (result) {
      isValidMachineId = !!result.match(machineIdRegex)
      if (isValidMachineId) {
        // If there’s a result and it’s a valid machineId code, redirect to the machine
        const machineId = result.substr(3)
        const targetURL = `/maschine/${machineId}`
        return <Redirect to={targetURL} />
      }
    }
    return (
      <div className="card mb1 qr-container">
        <header className="card-header has-background-white-ter">
          <p className="card-header-title">
            <FontAwesomeIcon icon="qrcode" size="sm" pull="left" />
            <span>QR-Code scannen</span>
          </p>
        </header>
        <div className="card-content pos-rel">
          <div className="media-content">
            <QrReader
              delay={250}
              facingMode="environment"
              className="qr-scanner"
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: '100%' }}
            />
          </div>
          {result && !isValidMachineId && (
            <div className="invalid-qr-code-overlay">
              <span>QR-Code ungültig</span>
            </div>
          )}
        </div>
        <footer className="card-footer">
          <Link to={`/`} className="card-footer-item">
            <FontAwesomeIcon icon="arrow-circle-left" size="sm" pull="left" />
            <span>Zurück zum Inventar</span>
          </Link>
        </footer>
      </div>
    )
  }
}

export default ScanQR

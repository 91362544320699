import React, { Component } from 'react'
import { RouteComponentProps, Redirect } from 'react-router'
import { Database, Aware } from '../react-pouchdb'

import MachineInventoryList, { MachineState } from '../components/machine-inventory'
import AppContext from '../AppContext'

import { getRemote } from '../utils/network'
import { isMachineDoc } from '../utils/general'
import GenericConnectionWarning from '../components/generic-connection-warning'
import { UserRoles } from '../utils/user-roles'
import { Privileges } from '../types/users-roles-privileges'
import { get } from 'lodash'

interface HOCProps extends RouteComponentProps {
  db?: PouchDB.Database;
}

class MachineInventoryHOC extends Component<HOCProps, {}> {
  public static contextType = AppContext
  public state = {
    data: [],
    error: undefined,
    isLoading: true,
    successfullySynced: true,
    redirectToCustomers: false,
    redirectToLogin: false
  }
  private fetchData = async (): Promise<void> => {
    const { db } = this.props
    if (!db) {
      return
    }

    this.setState({
      data: [],
      isLoading: true,
      error: undefined
    })

    let successfullySynced = false
    const { databaseManager } = this.context
    successfullySynced = await databaseManager.isInitialSyncDone()

    const docs = await db.allDocs({
      include_docs: true,
      startkey: 'ma_',
      endkey: 'ma_\ufff0'
    })
    const machines = docs.rows.filter(isMachineDoc)
    // Remove deleted machines
    const existingMachines = machines.filter(machine => get(machine, 'doc.state') !== MachineState.DELETED)
    const machinesWithMeasurementPoints = existingMachines.map(machine => {
      const measurementPoint = docs.rows.find(row => {
        return row.id.indexOf(`${machine.id}:mp_`) !== -1
      })
      return { ...machine, measurementPoint }
    })
    this.setState({
      data: machinesWithMeasurementPoints,
      isLoading: false,
      error: undefined,
      successfullySynced
    })
  }
  public componentDidMount(): void {
    const { currentCustomer, currentUser } = this.context
    const { _id: customerId } = currentCustomer
    const skipTheseNames = ['nh', 'oel']
    if (skipTheseNames.includes(customerId)) {
      if (currentUser && !currentUser.name) {
        // There are odd cases where localstorage has an incomplete session,
        // if this is the case we should avoid using it and force login
        this.setState({redirectToLogin: true});
      } else {
        this.setState({redirectToCustomers: true});
      }
    } else {
      this.fetchData()
    }
  }
  public render(): React.ReactNode {
    const { data: machines, isLoading, error, successfullySynced, redirectToCustomers, redirectToLogin } = this.state
    const inventoryProps = {
      machines,
      isLoading,
      error,
      ...this.props
    }

    if (redirectToLogin) {
      return <Redirect to="/login" />
    }

    if (redirectToCustomers) {
      return <Redirect to="/customers" />
    }

    return (
      <>
        {!successfullySynced && <GenericConnectionWarning />}
        <MachineInventoryList {...inventoryProps} />
      </>
    )
  }
}

class MachineInventory extends Component<RouteComponentProps, {}> {
  public static contextType = AppContext
  public render(): React.ReactNode {
    const { currentCustomer } = this.context
    const { _id: customerId } = currentCustomer
    if (!customerId) {
      const userRoles = UserRoles.fromSession()
      const userCanSwitchCustomers = userRoles.hasPrivilege(Privileges.CAN_SWITCH_CUSTOMERS)
      if (userCanSwitchCustomers) {
        return (
          <Redirect
            to={{
              pathname: '/customers'
            }}
          />
        )
      }
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: this.props.location }
          }}
        />
      )
    }
    const remote = getRemote(customerId)
    return (
      <Database database={customerId} remote={remote} onError={this.context.onPouchError}>
        <Aware>
          <MachineInventoryHOC {...this.props} />
        </Aware>
      </Database>
    )
  }
}

export default MachineInventory

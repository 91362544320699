import { User } from '../types/users-roles-privileges'

/*

Given a customerId:
Will return an array of all person info we have for this customer:
1. All employees
2. All app users

All docs will be normalised down to the Person interface:

Person: {
  firstName: "Björn"
  lastName: "Björnsen"
  email: "bbb@bbb.de"
  roles: (2) ["cu_12345678", "cu-analyst"]
  confirmed: true
  userId: "bbb@bbb.de" // _user docs `name`
  isAppUser: true/false
}
*/
export const getAllPersons = async (customerId: string): Promise<User[]> => {
  const { REACT_APP_COUCHDB_ENDPOINT } = process.env
  const userDataResponse = await fetch(
    `${REACT_APP_COUCHDB_ENDPOINT}/_backend/user/${encodeURIComponent(customerId)}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json'
      }
    }
  ).catch(e => {
    console.log('Couldn’t  fetch app users, propably offline. That’s ok though.', e)
  })
  let formattedUserDocs = []
  if (userDataResponse && userDataResponse.status === 200) {
    let userDocs
    const data = await userDataResponse.json()
    userDocs = data.users
    formattedUserDocs = userDocs.map((userDoc: any) => {
      return {
        ...userDoc,
        userId: userDoc.email,
        isAppUser: true
      }
    })
  }
  return [...formattedUserDocs]
}

export const attachUserInfoToActions = (actions: any[], users: any[], customerId: string) => {
  return actions.map((action: any) => {
    const user = users.find((person: any): boolean => {
      return person.userId === action.responsible
    })
    if (user) {
      const assigneeName = [user.firstName, user.lastName].join(' ')
      action.assigneeName = assigneeName
    }
    return action
  })
}

export const updateUserDoc = async (
  currentUser: User,
  updateCurrentUser: (userData: User) => void,
  customerId: string,
  payload: any
) => {
  const { REACT_APP_COUCHDB_ENDPOINT } = process.env
  // Don’t use the user’s email as the ID, as that might change in the future
  const userId = currentUser!.id!.replace('org.couchdb.user:', '')
  await fetch(REACT_APP_COUCHDB_ENDPOINT + `/_backend/user/${customerId}/${encodeURIComponent(userId)}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json'
    },
    body: JSON.stringify({
      oelUser: {
        ...payload
      }
    })
  })
    .then(
      async (res): Promise<void> => {
        if (res.status === 200) {
          updateCurrentUser!({ ...currentUser, ...payload })
        } else {
          console.log('Konnte Benutzer nicht aktualisieren')
        }
      }
    )
    .catch((error): void => {
      console.log('Konnte Benutzer nicht aktualisieren', error)
    })
}

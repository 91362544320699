import React from 'react'
import { dateAndTimeFormat, shouldNotSeeOSBrand } from '../utils/general'
import { format } from 'date-fns'
import chunk from 'lodash/chunk'
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer'
import { Table, TableBody, TableHeader, TableCell, DataTableCell } from '@david.kucsai/react-pdf-table'

import { getMetricMetadata } from '../utils/default-metrics'
import { metricsToShow } from './measurement-table'
import { MachineDoc } from './machine-inventory'
import PDFMachineInfoBlock from './pdf-machine-info'

import logo from '../assets/logo-small.png'
import { OsFluid, CustomerFluid } from '../types/fluids'
import { Measurement } from './graphs/waffle-measurements'
import { MeasurementPoint } from './machine-measurement-point'

interface Props {
  measurements?: Measurement[];
  machine: MachineDoc;
  measurementPoint?: MeasurementPoint;
  fluidInfo?: OsFluid | CustomerFluid;
}
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFF',
    padding: 10
  },
  logo: {
    height: '8mm',
    width: 'auto',
    marginRight: '5mm'
  },
  textRow: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  tableCell: {
    padding: 5,
    justifyContent: 'flex-start'
  },
  header: {
    fontSize: 24
  },
  subHeader: {
    fontSize: 18,
    marginBottom: 8
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    top: '5mm',
    right: '10mm',
    width: '10mm',
    color: 'grey',
    textAlign: 'right'
  },
  divider: {
    width: '100%',
    height: '1pt',
    backgroundColor: '#DDD',
    marginBottom: '2mm',
    marginTop: '2mm'
  }
})

const Divider = () => <View style={styles.divider} />
const rowsPerPage = 27

const MeasurementsPDF: React.FC<Props> = ({ measurements = [], machine, measurementPoint, fluidInfo }) => {
  const hiddenForBrandingReasons = shouldNotSeeOSBrand()
  const measurementsChunks = chunk(measurements, rowsPerPage)

  return (
    <Document>
      {measurementsChunks.map((measurements, index) => {
        return (
          <Page size="A4" style={styles.page} key={`page-${index + 1}`}>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              fixed
            />
            <View style={styles.textRow}>
              {!hiddenForBrandingReasons && (
                <Image src={logo} style={styles.logo} />
              )}
              <Text style={styles.header}>Maschinenprotokoll</Text>
            </View>
            <Divider />
            <PDFMachineInfoBlock machine={machine} measurementPoint={measurementPoint} fluidInfo={fluidInfo}/>
            <Table data={measurements}>
              <TableHeader
                includeBottomBorder={true}
                includeLeftBorder={false}
                includeRightBorder={false}
                includeTopBorder={false}
              >
                <TableCell style={styles.tableCell} weighting={0.25} isHeader={true}>
                  Zeit der Messung
                </TableCell>
                {metricsToShow.map((metricId: string) => (
                  <TableCell style={styles.tableCell} weighting={0.25} isHeader={true} key={`${metricId}-table-header`}>
                    {getMetricMetadata(metricId).name}
                  </TableCell>
                ))}
              </TableHeader>
              <TableBody
                includeBottomBorder={true}
                includeLeftBorder={false}
                includeRightBorder={false}
                includeTopBorder={true}
              >
                <DataTableCell
                  style={styles.tableCell}
                  weighting={0.25}
                  getContent={([r]) => format(new Date(r.measuredAt), dateAndTimeFormat)}
                />
                {metricsToShow.map(metricId => {
                  return (
                    <DataTableCell
                      key={metricId}
                      style={styles.tableCell}
                      weighting={0.25}
                      getContent={r => {
                        const meta = getMetricMetadata(metricId)

                        const metric = r.find(
                          (individualMeasurement: Measurement) =>
                            individualMeasurement && individualMeasurement.metricId === metricId
                        )
                        if (metric && metric.value) {
                          return `${metric.value} ${meta.unit ? meta.unit : ''}`
                        }
                      }}
                    />
                  )
                })}
              </TableBody>
            </Table>
          </Page>
        )
      })}
    </Document>
  )
}

export default MeasurementsPDF
